
export const loadingProfileCalls = () => {
  return { type: 'LOADING_PROFILE_CALLS' }
};

export const getProfilesCalls = (informations) => {
  return {
    type: 'GET_PROFILES_CALLS',
    payload: informations
  }
};

export const getProfileCalls = (informations) => {
  return {
    type: 'GET_PROFILE_CALLS',
    payload: informations
  }
};

export const stopLoadingProfileCalls = () => {
  return { type: 'STOP_LOADING_PROFILE_CALLS' }
};

export const startEditingProfileCalls  = (profileId) => {
  return {
    type: 'START_EDITING_PROFILE_CALLS',
    payload: profileId
  }
};

export const stopEditingProfileCalls  = () => {
  return { type: 'STOP_EDITING_PROFILE_CALLS' }
};
