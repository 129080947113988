import { toast } from 'react-toastify';

const options = {
  hideProgressBar: true,
  autoClose: 5000
}

export const notifySuccess = (message) => {
  return toast.success(message,  options);
}

export const notifyInfo = (message) => {
  return toast.info(message,  options);
}

export const notifyWarning = (message) => {
  return toast.warning(message,  options);
}

export const notifyError = (message) => {
  return toast.error(message,  options);
}
