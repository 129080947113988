import { store } from 'store/index.js';
import { getCompanyInformation } from 'components/commons/api/admin/company';
import {
  loadingCompany,
  getCompany as getCompanyAction,
  stopLoadingCompany,
} from 'actions/admin/companyActions';

export const getCompany = async (props) => {

    store.dispatch(loadingCompany());

    try {
      await getCompanyInformation().then((result) => {
        return store.dispatch(getCompanyAction(result.data));
      })
    } catch (e) {
      console.log(e);
    } finally {
      store.dispatch(stopLoadingCompany());
    }
  }
