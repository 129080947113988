const billsReducer = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return [];
    case 'LOADING_BILLS':
      return { ...state, isLoading: true };
    case 'FETCH_BILLS':
      return { ...state,  datas: action.payload }
    case 'STOP_LOADING_BILLS':
      return { ...state, isLoading: false };
    default:
      return state
  }
};

export default billsReducer;
