export const loadingNotifications = () => {
  return {
    type: 'LOADING_NOTIFICATIONS'
  }
};

export const stopLoadingNotifications = () => {
  return {
    type: 'STOP_LOADING_NOTIFICATIONS'
  }
};

export const addSidebarNotification = (context, number) => {
  return {
    type: 'ADD_SIDEBAR_NOTIFICATION',
    context: context,
    number: number

  }
};
