import { GET, POST, PATCH, DELETE } from './api';

export const getListOutgoingFaxes = () => {
  return GET('/fax/outgoings');
}

export const getIncomingFaxes = (user_id = null) => {
  if (user_id == null || typeof user_id === 'object') {
    return POST('/fax/incomings', {});
  }
  else {
    return POST('/fax/incomings', {
      user_id: user_id
    });
  }
}

export const getOutgoingFaxes = (user_id = null) => {
  if (user_id == null || typeof user_id === 'object') {
    return POST('/fax/outgoings', {});
  }
  else {
    return POST('/fax/outgoings', {
      user_id: user_id
    });
  }
}

export const getFax = (faxId) => {
  return POST('/fax/incoming', {
    fax_id: faxId
  });
}

export const getFaxPreview = (file) => {
  return POST('/fax/preview', {
    file: file
  });
}

export const sendFax = (fax) => {
  return POST('/fax/send', fax);
}

export const getFaxAck = (faxId) => {
  return GET('/fax/ack/receipt', {
    fax_id: faxId
  });
}

export const deleteFaxIncomings = (faxIds) => {
  return DELETE('/fax/incomings', {
    fax_ids: [faxIds]
  });
}

export const deleteFaxOutgoings = (faxIds) => {
  return DELETE('/fax/outgoings', {
    fax_ids: [faxIds]
  });
}

export const setFaxAsSeen = (faxId) => {
  return PATCH('/fax/incomings', {
    fax_ids: [faxId]
  });
}
