import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialTable from 'material-table';
import { deleteRingtoneGeneral } from 'components/commons/helpers/admin/ringtoneGeneralHelper.js';
import { startEditingRingtoneGeneral } from 'actions/admin/ringtoneGeneralActions.js';
import Preloader from 'components/commons/Preloader.js';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';

function RingtoneGeneralTab ({ isLoading, ringtoneGeneral, startEditingRingtoneGeneral }) {

  const navigate = useNavigate();

  function format_number(numbers) {

    forEach(numbers, function(value, index, numbers) {
        var first = value.substring(0, 2);
            if (first === "+3") {
              //.match(/.{1,2}/g).join(' ')
              numbers[index] = ("0" + value.slice(3, value.length));
            } else if (first === "33") {
              numbers[index] = ("0" + value.slice(2, value.length));
        }
      })

    if (typeof numbers === "object") {
      return numbers.join(', ');
    } else {
      return numbers;
    }
  }

    if (isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <MaterialTable
        columns={[
          { title: 'ID de poste', field: 'id' },
          { title: 'Nom', field: 'name' },
          {
            title: 'N° Long',
            field: 'e164',
            grouping: false,
            render: rowData => (
                <div>{(rowData.e164 != null) ? format_number(rowData.e164) : ""}</div>
          )},
          {
            title: 'N° Court',
            field: 's164',
            grouping: false,
            render: rowData => (
                <div>{(rowData.s164 != null)?rowData.s164.join(', '):""}</div>
            )
          },
          { title: 'Nombre de membres', sorting: false,
            render: rowData => {
              if (!isEmpty(rowData.member)) {
                return <div>{rowData.member.length}</div>
              }
              return 0
            }
          },
        ]}
        data={ringtoneGeneral}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
                labelRowsPerPage: "Sonnerie générale par page",
                labelDisplayedRows: "{from}-{to} sur {count}",
                labelRowsSelect: "lignes",
            },
          }
        }
        options={{
            actionsColumnIndex: -1,
            pageSize: 10,
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Modifier',
            onClick: (event, rowData) => {
                startEditingRingtoneGeneral(rowData.id);
                return navigate('/admin/editringtonegeneral');
            },
          },
          {
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
              return deleteRingtoneGeneral(rowData.id);
            },
          }
        ]}
      />
    );
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingRingtoneGeneral,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RingtoneGeneralTab);
