const initialState = {
  datas: [],
  data: [],
  isLoading: false,
  editing: {
    isEditing: false,
    isLoading: false,
    datas: {},
    profileId: null
  }
}

const profileCallsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
    case 'LOADING_PROFILE_CALLS':
      return { ...state, isLoading: true };
    case 'GET_PROFILES_CALLS':
      return { ...state, datas: action.payload }
    case 'GET_PROFILE_CALLS':
      return { ...state, data: action.payload }
    case 'STOP_LOADING_PROFILE_CALLS':
      return { ...state, isLoading: false };

    case 'START_EDITING_PROFILE_CALLS':
      return { ...state, editing: { ...state.editing, isEditing: true, profileId: action.payload } }
    case 'STOP_EDITING_PROFILE_CALLS':
      return { ...state, editing: { ...state.editing, isEditing: false, profileId: null, datas: {} } }
    default:
      return state
  }
}

export default profileCallsReducer;
