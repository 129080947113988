const initialState = {
  lastRoomId: '',
  mxToken: '',
  mxUserId: '',
  mxDeviceId: '',
  selectedRoom: '',
  rooms: [],
  newMessage: false
}

const imReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'ADD_IM_DATAS':
      return {
        ...state,
        lastRoomId: action.payload.roomId,
        mxToken: action.payload.mxToken,
        mxUserId: action.payload.mxUserId,
        mxDeviceId: action.payload.mxDeviceId,
      };
      
      case 'ADD_SELECTED_ROOM':
        return {
          ...state,
          selectedRoom: action.payload,
        };
        
      case 'ADD_ROOMS':
        return {
          ...state,
          rooms: action.payload,
        };
        
      case 'ADD_NEW_MESSAGE':
        return {
          ...state,
          newMessage: true,
        };
        
      case 'REMOVE_NEW_MESSAGE':
        let newRooms = state.rooms;
        for (let index = 0; index < newRooms.length; index++) {
          newRooms[index].messagesNb = 0;          
        }

        return {
          ...state,
          newMessage: false,
          rooms: newRooms
        };
        
      case 'CLEAR_IM_SESSION':
        return {
          lastRoomId: '',
          mxToken: '',
          mxUserId: '',
          mxDeviceId: '',
          selectedRoom: '',
          rooms: [],
          newMessage: false
        };  

    default:
      return state
  }
}

export default imReducer;
