const sviReducer = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return [];
    // SVI MENUS
    case 'LOADING_SVI':
      return { ...state, isLoading: true };
    case 'GET_SVI_MENUS':
      return { ...state,  menus: action.payload }
    case 'STOP_LOADING_SVI':
      return { ...state, isLoading: false };
    
    // SVI MENUS (Edition)
    case 'START_EDITING_SVI_MENU':
      return { ...state, editing: { ...state.editing, isEditing: true, menuId: action.payload } }
    case 'STOP_EDITING_SVI_MENU':
      return { ...state, editing: { ...state.editing, isEditing: false, menuId: null} }
    
    // SVI CONFIG
    case 'START_CONFIGURING_SVI':
      return { ...state, configuring: { ...state.configuring, menuId: action.payload } }
    case 'GET_SVI_CONFIG':
      return { ...state,  configuring: { ...state.configuring, data: action.payload } }
    case 'STOP_CONFIGURING_SVI':
      return { ...state, configuring: { ...state.configuring, menuId: null } }    
    case 'LOADING_CONFIGURING_SVI':
      return { ...state, configuring: { ...state.configuring, isLoading: true } }
    case 'STOP_LOADING_CONFIGURING_SVI':
      return { ...state, configuring: { ...state.configuring, isLoading: false } }
      
    // SVI FILES
    case 'LOADING_SVI_FILES':
      return { ...state, files: { ...state.files, isLoading: true } };
    case 'GET_SVI_FILES':
      return { ...state, files: { ...state.files, data: action.payload } }
    case 'STOP_LOADING_SVI_FILES':
      return { ...state, files: { ...state.files, isLoading: false } };
    
    default:
      return state
  }
}

export default sviReducer;
