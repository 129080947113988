import { DELETE, PATCH } from './api';

export const deleteMessagesMevoGroup = (messageId, user_id) => {
  return DELETE('/mevo/messages', {
    message_ids: [messageId],
    user_id: user_id
  });
}

export const setMessagesMevoGroupsAsSeen = (params) => {
  return PATCH('/mevo/messages', params);
}
