import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchCompanyContacts} from 'components/commons/helpers/admin/companyContactHelper.js';
import MaterialTable from 'material-table';
import { notifyInfo } from 'components/commons/Toasts.js';
import swal from 'sweetalert';
import CreateCompanyContact from 'components/views/modals/CreateCompanyContact';
import { deleteCompanyContact } from 'components/commons/api/admin/companyContact.js';
import { showModal } from 'actions/modalActions';
import { bindActionCreators } from 'redux';
import { startEditingCompanyContact } from 'actions/admin/companyContactActions.js';

class CompanyContact extends Component {
  componentDidMount() {
    fetchCompanyContacts();
  }

  format_number(number){
      var newnumber = number;
      var first = number.substring(0,2);
      if(first === "+3"){
        newnumber = "0"+number.slice(3,number.length);
      } else if(first === "33"){
        newnumber = "0"+number.slice(2,number.length);
      }
      return newnumber;
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Annuaire société"]} title="Annuaire société" addCompany={true} />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#user" role="tab" onClick={fetchCompanyContacts}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Annuaire société
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" id="company" role="tabpanel">
              <MaterialTable
                columns={[
                  { title: 'Prénom', field: 'firstname' },
                  { title: 'Nom', field: 'lastname'},
                  { title: 'Fixe', field: 'telephone',
                  render: rowData => (
                      <div>{(rowData.telephone != null)? this.format_number(rowData.telephone).match(/.{1,2}/g).join(' '):rowData.telephone}</div>
                  )},
                  { title: 'Mobile', field: 'mobile',
                  render: rowData => (
                      <div>{(rowData.mobile != null)? this.format_number(rowData.mobile).match(/.{1,2}/g).join(' '):rowData.mobile}</div>
                  )},
                  { title: 'Fax', field: 'fax',
                  render: rowData => (
                      <div>{(rowData.fax != null)? this.format_number(rowData.fax).match(/.{1,2}/g).join(' '):rowData.fax}</div>
                  )},
                  { title: 'Adresse mail', field: 'mail'},
                ]}
                data={this.props.companyContact.datas}
                title=""
                localization={{
                  toolbar: { searchPlaceholder: 'Rechercher' } ,
                  pagination: {
                    labelRowsPerPage: "Contacts par page",
                    labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
                  }
                }}
                options={{
                  pageSize: 20,
                  actionsColumnIndex: -1
                }}
                actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Modifier',
                    onClick: (event, rowData) => {
                      this.props.startEditingCompanyContact(rowData.contact_id);
                      return this.props.showModal({
                        title: "Modifier un contact",
                        component: CreateCompanyContact
                      })
                    },
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Supprimer',
                    onClick: (event, rowData) => {
                      return swal({
                        title: "Confirmation",
                        text: `Etes-vous sûr(e) de vouloir retirer ${rowData.firstname} de votre carnet d'adresse ?`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      })
                      .then((willDelete) => {
                        if (willDelete) {
                          return deleteCompanyContact(rowData.contact_id).then((result) => {
                            notifyInfo("Contact supprimé avec succès !");
                            return this.props.fetchCompanyContacts();
                          }).catch((e) => {
                            return console.log(e);
                          });
                        }
                      });
                    },
                  }
                ]}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyContact: state.companyContact
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal,
    startEditingCompanyContact,
    fetchCompanyContacts
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContact);
