const initialState = {
  search: {
    isLoading: false,
    contacts: []
  },
  frequent: {
    isLoading: false,
    contacts: []
  },
  company: {
    isLoading: false,
    datas: []
  },
  user: {
    isLoading: false,
    datas: []
  },
  editing: {
    isEditing: false,
    isLoading: false,
    datas: {},
    contactId: null
  }
}

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_SESSION':
      return initialState;

    /* LOADING */

    case 'LOADING_COMPANY_CONTACTS':
      return { ...state, company: { ...state.company, isLoading: true } };

    case 'LOADING_USER_CONTACTS':
      return { ...state, user: { ...state.user, isLoading: true } };

    case 'LOADING_USER_CONTACT':
      return { ...state, editing: { ...state.editing, isLoading: true } };

    case 'LOADING_FREQUENT_CONTACTS':
      return { ...state, frequent: { ...state.frequent, isLoading: true } };

    case 'LOADING_SEARCH_CONTACTS':
      return { ...state, search: { ...state.search, isLoading: true } }

    /* STOP LOADING */

    case 'STOP_LOADING_FREQUENT_CONTACTS':
      return { ...state, frequent: { ...state.frequent, isLoading: false } };

    case 'STOP_LOADING_COMPANY_CONTACTS':
      return { ...state, company: { ...state.company, isLoading: false } };

    case 'STOP_LOADING_USER_CONTACTS':
      return { ...state, user: { ...state.user, isLoading: false } };

    case 'STOP_LOADING_USER_CONTACT':
      return { ...state, editing: { ...state.editing, isLoading: false } };

    case 'STOP_LOADING_SEARCH_CONTACTS':
      return { ...state, search: { ...state.search, isLoading: false } }

    /* FETCH */

    case 'FETCH_FREQUENT_CONTACTS':
      return { ...state, frequent: { ...state.frequent, contacts: [action.payload] } }

    case 'FETCH_SEARCH_CONTACTS':
      return { ...state, search: { ...state.search, contacts: [action.payload] } }

    case 'FETCH_COMPANY_CONTACTS':
      return { ...state, company: { ...state.company, datas: action.payload } }

    case 'FETCH_USER_CONTACTS':
      return { ...state, user: { ...state.user, datas: action.payload } }

    case 'FETCH_USER_CONTACT':
      return { ...state, editing: { ...state.editing, datas: action.payload } }


    case 'START_EDITING_CONTACT':
      return { ...state, editing: { ...state.editing, isEditing: true, contactId: action.payload } }

    case 'STOP_EDITING_CONTACT':
      return { ...state, editing: { ...state.editing, isEditing: false } }

    /* CREATE CONTACT FORM */

    case 'CLEAR_CREATE_CONTACT_FORM':
      return { ...state, editing: { isLoading: false, datas: {} } }

    default:
      return state
  }
}

export default contactsReducer;
