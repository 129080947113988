import { POST, GET } from './api';
import { store } from 'store/index.js';

export const getUser = () => {
  return GET('/user');
}

export const getUsers = (userId) => {
  const state = store.getState();
  const company = state.kertelSession.datas.data.company || "";
  const provider = state.kertelSession.datas.data.provider || "";

  return POST('/user', {
    user_id: userId || '*',
    company: company,
    provider: provider
  });
}
