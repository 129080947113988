export const getLiteralForward = (forward, data = '') => {
  switch (forward) {
    case 'NONE':
      return 'Ne rien faire';
    case 'USER_MOBILE':
      return 'Mon mobile';
    case 'USER_DIRECTORY':
      return 'Annuaire';
    case 'PHONE':
      return 'Mon poste';
    case 'USER':
      if (typeof data === 'object' && data.dest === "S164") 
        return 'Collègue';
      return 'Mon mobile'
    case 'E164':
    case 'NUMBER':
      return 'Numéro';
    case 'MEVO':
      return 'Ma messagerie';
    case 'SVI_ANNOUNCE':
    case 'MESSAGE':
      if (parseInt(data.id) === 1)
        return 'Message d\'absence';
      if (parseInt(data.id) === 2)
        return 'Message de non-disponibilité'

      return 'Mon annonce'
    case 'CONFIG':
      return 'Configuration personnalisée'    
    case 'MEVO_GROUP':
    case 'MEVO_CC':
      return 'Messagerie de groupe';
    case 'HIDDEN':
      return 'Numéro caché';
    default:
      return 'forward';
  }
};
