import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSviMenu  } from 'components/commons/helpers/admin/sviHelper.js';
import { stopEditingSviMenu } from 'actions/admin/sviActions.js';
import Preloader from 'components/commons/Preloader.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

function CreateSviMenu ({ url }) {

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [expiredLicence, setExpiredLicence] = useState(false);
	const [svis, setSvis] = useState({
        "menu_id": '',
        "comment": '',
      });

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

	setSvis(
		...svis,
		{
			[name]: value
		}
	)
  }

  async function addSviMenu(){
	let info = {
	  "menu_id": '',
	  "comment": '',
	};
	
	if (!isUndefined(svis.menu_id)) info.menu_id = svis.menu_id;
	if (!isUndefined(svis.comment)) info.comment = svis.comment;
	
	if (isEmpty(info.menu_id)|| info.menu_id.length > 4 ) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: "L'identifiant du menu est invalide.",
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	
	try {
      await createSviMenu(info);
	  navigate('/admin/svi');
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }

  async function init() {
	await new Promise((resolve, reject) => {
	  fetch(
		url + '/license', {
		  method: 'post',
		  headers: {
			'Content-Type':'application/json',
			'auth-token': `${localStorage.getItem('_k')}`
		  },
		  body: JSON.stringify({
			"service" : "svi"
		  })
		}
	  )
		.then(response => response.json())
		.then(result => {
		  if (!isUndefined(result.status) && result.status !== 200) {
			return swal({
			  type: 'error',
			  icon: "error",
			  title: 'Oops...',
			  text: result.detail,
			  confirmButtonColor: 'LightSeaGreen',
			})
		  }
		  
		  if (!isUndefined(result.data)) {
			if (!isUndefined(result.data.available)) {
			  if (result.data.available === 0) {
				return setExpiredLicence(true);
			  }
			}
		  }
		  
		  setIsLoading(false);
		})
	})
  }

  useEffect(function() {
	  init();
  }, []);
	
	if (expiredLicence) {
	  return (
		<div className="col-lg-12">
		  <div className="card">
			<div className="card-body p-b-0">
            <Breadcrumb routes={["Menus SVI", "Création du menu svi"]} url="/admin/svi" title="Création du menu svi" />
			  <ul className="nav nav-tabs customtab" role="tablist">
				<li className="nav-item">
				  <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
					<span className="hidden-sm-up">
					  <i className="ti-home"></i>
					</span>
					<span className="hidden-xs-down">
					  Information générales
					</span>
				  </a>
				</li>
			  </ul>
			  <div className="tab-content">
				<div className="tab-pane active p-20" role="tabpanel" id="infos">
				  <div className="col-lg-12 text-center">Le nombre maximum de licences a été atteint.</div>
				</div>
			  </div>
            </div>
          </div>
        </div>
      )
	}
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
    if (isEmpty(svis)) return navigate('/admin/svi');
	
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Menus SVI", "Création du menu svi"]} url="/admin/svi" title="Création du menu svi" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Information générales
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
				<Container component="main">
				  <Grid container spacing={2}>
					<Grid item xs={12} sm={2}>
					  <TextField
						name="menu_id"
						label="Identifiant menu"
						fullWidth
						InputProps={{
						  startAdornment: (
							<InputAdornment position="start">
							  <AccountCircle />
							</InputAdornment>
						  ),
						}}
						value={svis.menu_id}
						onChange={handleOptions}
					  />
					</Grid>
					<Grid item xs={12} sm={10} />
					<Grid item xs={12} sm={4}>
					  <TextField
						name="comment"
						label="Commentaire"
						multiline
						variant="outlined"
						rows={12}
						rowsMax={4}
						fullWidth
						value={svis.comment}
						onChange={handleOptions}
					  />
					</Grid>
					<Grid item xs={12} sm={8} />
				  </Grid>
				</Container>
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/svi');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={addSviMenu} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    mevoGroup: state.mevoGroup,
	url: state.kertelSession.apiUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingSviMenu
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSviMenu);
