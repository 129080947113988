import React, { Component } from "react";
import {hangUp, dtmf, hold, unHold, mute, unMute} from 'actions/phoneActions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

class DTMF extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDtmf: false,
            dtmfInputValue: ""
        }
    }

    render() {
        const { phone, white } = this.props;
              
        const keys = [
          {
            number: "1",
            letters: ""
          }, {
            number: "2",
            letters: "ABC"
          }, {
            number: "3",
            letters: "DEF"
          }, {
            number: "4",
            letters: "GHI"
          }, {
            number: "5",
            letters: "JKL"
          }, {
            number: "6",
            letters: "MNO"
          }, {
            number: "7",
            letters: "PQRS"
          }, {
            number: "8",
            letters: "TUV"
          }, {
            number: "9",
            letters: "WXYZ"
          }, {
            number: "*",
            letters: ""
          }, {
            number: "0",
            letters: ""
          }, {
            number: "#",
            letters: ""
          }
        ];
      
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-12 text-center text-white">
                <h4>{this.state.dtmfInputValue}</h4>
               </div> 
             </div> 
            <div className="row">
              {
                  keys.map((keyTouch, index) => {
                      return (
                      <div className="col-lg-4" key={index}>
                          <button type="button" className={`col-12 btn ${white ? 'text-success bg-white' : 'btn-default'}`} onClick={() => {
                              const number = keyTouch.number;
                              
                              this.setState((prevState) => {
                              return {
                                  ...prevState,
                                  dtmfInputValue: prevState.dtmfInputValue + number
                              }
                              })
                              
                              return phone.webPhone.sendDTMF(number);

                          }}>
                          <span className="dialNumber">{keyTouch.number}</span><br/>{keyTouch.letters}
                          </button>
                      </div>
                      );
                  })
              }
            </div>
          </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return ({phone: state.kertelphone, dtmf: false});
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
      hangUp,
      hold,
      unHold,
      mute,
      unMute,
      dtmf,
    }, dispatch);
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(DTMF);