export const activateCustomForwards = () => {
  return {
    type: 'ACTIVATE_CUSTOM_FORWARDS'
  }
};

export const deactivateCustomForwards = () => {
  return {
    type: 'DEACTIVATE_CUSTOM_FORWARDS'
  }
};

export const addForwards = (forwards) => {
  return {
    type: 'ADD_FORWARDS',
    payload: forwards
  }
};
