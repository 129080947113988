import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchWaitingQueues } from 'components/commons/helpers/admin/waitingQueueHelper.js';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import Preloader from 'components/commons/Preloader.js';
import WaitingQueueTab from './WaitingQueueTab.js';

class WaitingQueue extends Component {
  async componentDidMount() {
    await fetchWaitingQueues();
	await fetchUsers();
  }

  render() {

    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
	
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["File d'attente"]} title="File d'attente" addWq="true" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#list" role="tab" onClick={fetchWaitingQueues}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Vos files d'attentes
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" id="list" role="tabpanel">
                <WaitingQueueTab
				  listWaitingQueue={this.props.waitingQueue.datas}
                  isLoading={this.props.waitingQueue.isLoading}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    waitingQueue: state.waitingQueue
  }
}

export default connect(mapStateToProps, null)(WaitingQueue);
