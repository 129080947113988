import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

class Table extends Component {
    render () {

        const { ExportCSVButton } = CSVExport;
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Lignes { from } à { to } sur { size } entrées
            </span>
          );
      
          const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
          };

            const pagination = paginationFactory({
                sizePerPage: this.props.sizePerPage ?? 20,
                hideSizePerPage: true,
                firstPageText: 'Première',
                prePageText: 'Précédente',
                nextPageText: 'Suivante',
                lastPageText: 'Dernière',
                nextPageTitle: 'Première page',
                prePageTitle: 'Page précédente',
                firstPageTitle: 'Page suivante',
                lastPageTitle: 'Dernière page',
            })

        return (
            <ToolkitProvider
                keyField={this.props.keyField || "id"}
                data={this.props.datas}
                columns={this.props.columns}
                exportCSV={ {
                    fileName: this.props.CSVExport || 'contacts.csv',
                    blobType: 'text/csv;charset=ansi',              
                } }
                search={{
                    searchFormatted: true
                }}
            >
                {
                props => (
                    <div>
                    {!isEmpty(this.props.searchPlaceholder) && <SearchBar { ...props.searchProps } placeholder={this.props.searchPlaceholder} className="col-lg-12" />}{' '}
                    {!isUndefined(this.props.CSVExport) && <ExportCSVButton className="btn btn-primary" { ...props.csvProps }>Exporter au format CSV</ExportCSVButton>}
                    <BootstrapTable
                        { ...props.baseProps }
                        keyField={this.props.keyField || "id"}
                        data={ this.props.datas }
                        columns={ this.props.columns }
                        striped
                        hover
                        condensed
                        bordered={ false }
                        title=""
                        options={
                            options
                        }
                        pagination={pagination}
                    />
                    </div>
                )
                }
            </ToolkitProvider>
        );
    }
}

export default Table;