import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {ringback} from 'actions/phoneActions.js';
import Table from 'components/commons/tables/Table.js'

class CompanyTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      shared: []
    }
  }

  async componentDidMount() {
    try {
      const shared = await filter(this.props.contacts, function(contact) {
        return contact.shared
      });

      return this.setState({ shared: shared });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const that = this;

    if (this.state.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    if (isEmpty(this.state.shared)) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}>Aucun contact</td>
            </tr>
          </tbody>
        </table>
      );
    }

    const columns = [
      { title: '', csvExport: false, dataField: 'action',
      formatter: (cell, rowData, rowIndex) => (<div style={{
          display: "table",
          width: "100%",
          textAlign: "right"
        }}>
        {
          ((rowData.telephone != null || rowData.mobile != null) && !isUndefined(that.props.phone) && that.props.phone.hasWebPhone) &&
            <div className="btn btn-success btn-circle" onClick={(e) => {
                  e.preventDefault();
                  
                  that.props.handleNumberModal(true, rowData.telephone, rowData.mobile, `${rowData.firstname} ${rowData.lastname}`)
                }}>
                <i className="fa fa-phone"></i>
              </div>
        }
      </div>)},
      { title: 'Civilité', text: 'Civilité', dataField: 'civility',  lookup: {
        'mr': 'Monsieur',
        'ms': 'Mademoiselle',
        'mrs': 'Madame'
      } },
      { title: 'Prénom', text: 'Prénom', dataField: 'firstname' },
      { title: 'Nom', text: 'Nom', dataField: 'lastname', defaultSort: 'asc'},
      {
      title: 'Fixe',
      text: 'Fixe', 
      dataField: 'telephone',
      render: rowData => (<div style={{
          display: "table",
          width: "100%",
          textAlign: "right"
        }}>
        <p style={{
            display: "inline"
          }}>{rowData.telephone}</p>
      </div>)
    }, {
      title: 'Mobile',
      text: 'Mobile', 
      dataField: 'mobile',
      render: rowData => (<div style={{
          display: "table",
          width: "100%",
          textAlign: "right"
        }}>
        <p style={{
            display: "inline"
          }}>{rowData.mobile}</p>
      </div>)
    },
      { title: 'Adresse mail', text: 'Adresse mail', dataField: 'mail'},
      { title: 'Fax', text: 'Fax', dataField: 'fax'},
      { title: 'Code postal', text: 'Code postal', dataField: 'postalcode'},
      { title: 'Ville', text: 'Ville', dataField: 'city'}
    ]

    return (
      <Table
        datas={this.props.contacts}
        columns={columns}
        searchPlaceholder={"Chercher un contact"} />
    );
  }
}

const mapStateToProps = (state) => {
  return ({phone: state.kertelphone})
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    ringback
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTab);
