import { store } from 'store/index.js';
import { getVoiceMails as voicesMails } from 'components/commons/api/phone.js';
import { loadingVoiceMails, stopLoadingVoiceMails, fetchVoiceMails, fetchUnseenVoiceMails } from 'actions/voiceMailActions.js';
import isEmpty from 'lodash/isEmpty';

export const getVoiceMails = async (props) => {
  store.dispatch(loadingVoiceMails());

  try {
    const mails = await voicesMails();
    let unseen  = [];

    if (!isEmpty(mails.datas)) {
      unseen = (mails.datas).filter(mail => mail.seen === false);
    }

    store.dispatch(fetchVoiceMails(mails.datas));
    store.dispatch(fetchUnseenVoiceMails(unseen));
  } catch (error) {
    console.log(error);
  } finally {
    return store.dispatch(stopLoadingVoiceMails());
  }
};
