import { store } from 'store/index.js';
import {
  getSviMenus,
  getSviConfig,
  setSviConfig,
  createSviMenuInformation,
  updateSviMenuInformation,
  deleteSviMenuInformation,
  getSviFiles,
  deleteSviFileInformation,
} from 'components/commons/api/admin/svi';
import {
  loadingSvi,
  getSviMenus as getSviMenusAction,
  stopLoadingSvi,
  loadingConfiguringSvi,
  getSviConfig as getSviConfigAction,
  stopLoadingConfiguringSvi,
  loadingSviFiles,
  getSviFiles as getSviFilesAction,
  stopLoadingSviFiles,  
} from 'actions/admin/sviActions';
import {notifySuccess, notifyError} from 'components/commons/Toasts.js';

export const fetchSviMenus = async (menuId = null) => {
  store.dispatch(loadingSvi());

  try {
    await getSviMenus(menuId).then((result) => {
      return store.dispatch(getSviMenusAction(Object.values(result.data)));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSvi());
  }
}

export const createSviMenu = async (informations, history) => {
  store.dispatch(loadingSvi());
  try {
    await createSviMenuInformation(informations).then((result) => {
      if (result.status === 200) {
        notifySuccess("Menu svi ajoutée avec succès !");
        fetchSviMenus();
      }
      
      return fetchSviMenus();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSvi());
  }
}

export const updateSviMenu = async (menuId, informations, history) => {
  store.dispatch(loadingSvi());
  try {
    await updateSviMenuInformation(menuId, informations).then((result) => {
      if (result.status === 200) {
        notifySuccess("Menu svi mis à jour avec succès !");
        fetchSviMenus();
      }

      return fetchSviMenus();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSvi());
  }
}

export const deleteSviMenu = async (menuId) => {
  store.dispatch(loadingSvi());
  try {
    await deleteSviMenuInformation(menuId).then((result) => {
      return fetchSviMenus();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSvi());
  }
}

export const fetchSviConfig = async (menuId, loading = true) => {
  if (loading) store.dispatch(loadingConfiguringSvi());

  try {
    await getSviConfig(menuId).then((result) => {
      return store.dispatch(getSviConfigAction(result.data));
    })
  } catch (e) {
    console.log(e);
  } finally {
    if (loading) store.dispatch(stopLoadingConfiguringSvi());
  }
}

export const updateSviConfig = async (menuId, informations) => {
  try {
    await setSviConfig(menuId, informations);
  } catch (e) {
    console.log(e);
  } finally {
  }
}

export const fetchSviFiles = async (menuId) => {
  store.dispatch(loadingSviFiles());
  try {
    await getSviFiles(menuId).then((result) => {
      return store.dispatch(getSviFilesAction(result.data));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSviFiles());
  }
}

export const deleteSviFile = async (menuId, name, type) => {
  store.dispatch(loadingSviFiles());
  try {
    await deleteSviFileInformation(menuId, name, type).then((result) => {
      if (result.status !== 200) {
        notifySuccess('Fichier supprimé avec succès !');
        return fetchSviFiles(menuId);
      }
      console.log(result);
      notifyError(result.detail);
      return;
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSviFiles());
  }
}