import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';
import swal from 'sweetalert';
import { deleteFaxIncomings, deleteFaxOutgoings } from 'components/commons/api/fax.js';
import { getListIncomingFaxes, getListOutgoingFaxes, setIncomingFaxAsSeen } from 'components/commons/helpers/faxHelper';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { connect } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import forEach from 'lodash/forEach';

class FaxsTab extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      faxes: []  
    };
    
	this.theme = createMuiTheme({
      palette: {
        primary: {
          main: green[400],
        },
        secondary: {
          main: green[600],
        },
      },
    });
  }
  
  rowStyle(rowData){
    
    if (this.props.type === "incomings" && parseInt(rowData.state) !== 4 && parseInt(rowData.state) !== 5 && !rowData.seen) {
      return '#d1e8d1';
    }
    
    return 'auto';
  }
  
  tooltipAction(rowData){
    
    if (this.props.type === "incomings" && parseInt(rowData.state) !== 4 && parseInt(rowData.state) !== 5) {
      return 'Télécharger';
    }
    else if (this.props.type === "outgoings" && parseInt(rowData.state) === 3) {
      return "Télécharger : Accusé d'émission";
    }
    
    return '';
  }
  
  disabledAction(rowData){
    if (this.props.type === "incomings" && (parseInt(rowData.state) === 4 || parseInt(rowData.state) === 5)) {
      return true;
    }
    else if (this.props.type === "outgoings" && parseInt(rowData.state) !== 3) {
      return true;
    }
    
    return false;
  }
  
  selectFaxes(datas){
    
    let faxes = [];
    
    forEach(datas, function(elements) {
      faxes.push(elements.id);
    })
    
    this.setState({ faxes });
  }
  
  tableRef = React.createRef();
  
  render() {
    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MuiThemeProvider theme={this.theme}>
        <MaterialTable
          columns={[
            {
              title: '', field: 'state', render: (rowData) => {
                if (this.props.type === "incomings" && (parseInt(rowData.state) === 4 || parseInt(rowData.state) === 5)) {
                  return <span className="badge badge-pill badge-danger">Échec de réception</span>
                }
                else if (this.props.type === "incomings" && (parseInt(rowData.state) === 2 || parseInt(rowData.state) === 3) && !rowData.seen) {
                  return <div><span className="badge badge-pill badge-primary">Nouveau</span></div>
                }
                else if (this.props.type === "outgoings" && parseInt(rowData.state) !== 3) {
                  return <div><span className="badge badge-pill badge-danger">Échec d'envoi</span></div>
                }
              }
            },
            {
              title: 'Date et heure', field: 'date_start', render: (rowData) => {
                return <React.Fragment>Le <Moment locale='fr' format="DD MMMM YYYY à HH:mm:ss">{rowData.date_start || "-"}</Moment></React.Fragment>
              }
            },
            { title: 'Emetteur', field: 'src_number'},
            { title: 'Destinataire', field: 'dst_number'},
            { title: 'Nombre de pages', field: 'page_count'},
            { title: 'Poids (ko)', field: 'size'},
            { title: 'Type', field: 'type', hidden: true},
          ]}
          data={this.props.faxs}
          title=""
          localization={
            {
              toolbar: {
                searchPlaceholder: 'Rechercher',
                nRowsSelected: '{0} Fax(s) sélectionné(s)'
              },
              body: {
                emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                filterRow: {
                    filterTooltip: 'Filtre'
                }
              },
              pagination: {
                labelRowsPerPage: "Fax par page",
                labelDisplayedRows: "{from}-{to} sur {count}",
                labelRowsSelect: "lignes",
                firstTooltip: "Première page",
                lastTooltip: "Dernière page",
                previousTooltip: "Page précédente",
                nextTooltip: "Page suivante",
              },
            }
          }
          options={{
            search: this.props.search,
            toolbar: this.props.search,
            pageSize: this.props.rowsPag,
            selection: this.props.selection,
            actionsColumnIndex: -1,
            rowStyle: rowData => ({
              backgroundColor: !this.props.webFax ? this.rowStyle(rowData) : 'auto'
            })
          }}
          tableRef={this.tableRef}
          onSelectionChange={(datas) => this.selectFaxes(datas) }
          actions={
            !this.props.webFax ? [
              {
                icon: 'delete_outline',
                tooltip: 'Supprimer',
                onClick: (event, rowData) => {
                  return swal({
                    title: "Confirmation",
                    text: `Etes-vous sûr(e) de vouloir supprimer ce fax ?`,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  })
                  .then((willDelete) => {
                    if (willDelete) {
                      if (this.props.type === "incomings") {
                        deleteFaxIncomings(rowData.id).then((result) => {
                          swal.close();
                          return getListIncomingFaxes();
                        });
                      }
                      else if (this.props.type === "outgoings") {
                        deleteFaxOutgoings(rowData.id).then((result) => {
                          swal.close();
                          return getListOutgoingFaxes();
                        });
                      }
                    }
                  });
                },
              },
              rowData => ({
                icon: 'cloud_download',
                isFreeAction: true,
                tooltip: this.tooltipAction(rowData),
                disabled: this.disabledAction(rowData),
                onClick: (event, rowData) => {
                  if (parseInt(rowData.state)=== 4) return null;
                  const url = this.props.type === "incomings" ? `${this.props.session.apiUrl}/fax/incoming?fax_id=${rowData.id}` : `${this.props.session.apiUrl}/fax/ack/receipt?fax_id=${rowData.id}`;
                  var element = document.createElement('a');
                  element.setAttribute('href', url);
                  element.setAttribute('download', 'fax.pdf');
                  element.style.display = 'none';
                  document.body.appendChild(element);
                  element.click();
                  document.body.removeChild(element);
                  if (this.props.type === "incomings") setIncomingFaxAsSeen(rowData.id);
                  return null;
                },
              }),
            ] : [
              rowData => ({
                icon: 'cloud_download',
                isFreeAction: true,
                tooltip: this.tooltipAction(rowData),
                disabled: this.disabledAction(rowData),
                onClick: (event, rowData) => {
                  if (parseInt(rowData.state)=== 4) return null;
                  const url = this.props.type === "incomings" ? `${this.props.session.apiUrl}/fax/incoming?fax_id=${rowData.id}` : `${this.props.session.apiUrl}/fax/ack/receipt?fax_id=${rowData.id}`;
                  var element = document.createElement('a');
                  element.setAttribute('href', url);
                  element.setAttribute('download', 'fax.pdf');
                  element.style.display = 'none';
                  document.body.appendChild(element);
                  element.click();
                  document.body.removeChild(element);
                  return null;
                },
              })
            ]}
        />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    session: state.kertelSession
  })
}

export default connect(mapStateToProps, null)(FaxsTab);
