import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import Music from 'components/views/dashboard/Music.js';
import Preloader from 'components/commons/Preloader.js';

class Musics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb title='Messages et musiques' />
            <MusicsRender
              isLoading={this.state.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

const MusicsRender = (props) => {
  if (props.isLoading) {
    return (<div className="text-center"><Preloader className="primary" /></div>);
  }

  return <Music />;
}

const mapStateToProps = (state) => {
  return {
    music: state.music
  };
}

export default connect(mapStateToProps, null)(Musics);
