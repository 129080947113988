import React, { Component } from 'react';
import { createCompanyContact, updateCompanyContact } from 'components/commons/api/admin/companyContact.js';
import { notifySuccess } from 'components/commons/Toasts.js';
import { hideModal } from 'actions/modalActions.js';
import { stopEditingCompanyContact } from 'actions/admin/companyContactActions.js';
import { fetchCompanyContacts, fetchCompanyContact } from 'components/commons/helpers/admin/companyContactHelper.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Preloader from 'components/commons/Preloader.js';

class CreateContactCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      contact: {
        contacts_info:
        {
          civility : "0",
          contact_id: this.props.companyContact.editing.contactId
        }
      }
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (this.props.companyContact.editing.isEditing) {
      try {
        await await fetchCompanyContact(this.props.companyContact.editing.contactId).then((result) => {
          this.setState((prevState) => ({
            contact: {
              ...prevState.contact,
              contacts_info: this.props.companyContact.editing.datas
            }
          }));
        })
      } catch (e) {
        console.log(e);
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.props.companyContact.editing.isEditing) {
      try {
        this.setState({ isCreating: true });
        return await updateCompanyContact(this.state.contact.contacts_info).then((result) => {
          notifySuccess("Contact mis à jour avec succès !");
          this.props.hideModal();
          return fetchCompanyContacts();
        })
      } catch (e) {
        console.log(e);
      } finally {
        this.props.stopEditingCompanyContact();
        return this.setState({ isCreating: false });
      }
    }

    try {
      this.setState({ isCreating: true });
      return await createCompanyContact(this.state.contact.contacts_info).then((result) => {
        notifySuccess("Contact créé avec succès !");
        this.props.hideModal();
        return fetchCompanyContacts();
      })
    } catch (e) {
      console.log(e);
    } finally {
      return this.setState({ isCreating: false });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(state => ({
      contact: {
        contacts_info: {
          ...state.contact.contacts_info,
          [name]: value
        }
      }
    }));
  }

  render() {
    if (this.props.companyContact.editing.isLoading) {
      return (<Preloader />);
    }

    const editingContact = this.state.contact.contacts_info;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <div className="row">
            {/*
            <div className="col-md-6">
              <div className="form-group">
                <select name="civility" value={this.state.contact.contacts_info.civility} className="form-control custom-select" onChange={this.handleInputChange}>
                  <option value="0">Indéfini</option>
                  <option value="1">Madame</option>
                  <option value="2">Mademoiselle</option>
                  <option value="3">Monsieur</option>
                </select>
              </div>
            </div>*/}
                </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  name="firstname"
                  type="text"
                  placeholder="Prénom"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.firstname || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  name="lastname"
                  type="text"
                  placeholder="Nom"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  required={true}
                  value={editingContact.lastname || ''}
                />
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  name="telephone"
                  type="text"
                  placeholder="N° fixe"
                  id="example-text-input"
                  value={editingContact.telephone || ''}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  name="fax"
                  type="text"
                  placeholder="N° fax"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.fax || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  name="mobile"
                  type="text"
                  placeholder="N° mobile"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.mobile || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  className="form-control"
                  name="mail"
                  type="email"
                  placeholder="Adresse email"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.mail || ''}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              this.props.stopEditingCompanyContact();
              return this.props.hideModal()
            }}
          >Annuler</button>
          <input
            disabled={this.state.isCreating}
            type="submit"
            className="btn btn-success waves-effect text-left right-side-toggle"
            value={this.state.isCreating ? 'Création...' : 'Confirmer'}
            />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyContact: state.companyContact
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal,
    stopEditingCompanyContact
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateContactCompany);
