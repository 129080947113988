const initialState = {
  isLoading: false,
  datas: [],
  unseen: { datas : [] }
}

const messagesMevoGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'LOADING_MESSAGES_MEVO_GROUP':
      return { ...state, isLoading: true };

    case 'STOP_LOADING_MESSAGES_MEVO_GROUP':
      return { ...state, isLoading: false };

    case 'FETCH_MESSAGES_MEVO_GROUP':
      return { ...state, datas: action.payload };

    case 'FETCH_UNSEEN_MESSAGES_MEVO_GROUP':
      return { ...state, unseen: { datas: action.payload} };

    case 'HAS_READ_UNSEEN_MESSAGES_MEVO_GROUP':
      return { ...state, unseen: { datas: []} };

    default:
      return state
  }
}

export default messagesMevoGroupReducer;
