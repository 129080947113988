import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import { connect } from 'react-redux';
import { activateCustomForwards, deactivateCustomForwards, addForwards } from 'actions/forwardActions.js';
import { bindActionCreators } from 'redux';
import ForwardLayout from './ForwardLayout';
import MaterialTable from 'material-table';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import filter from 'lodash/filter';

class ForwardContainer extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    }

  }

  find_location(id){
    if(this.props.company.location !== null) {
      for (var location in this.props.company.location) {
        if(this.props.company.location[location].id === id){
          return this.props.company.location[location].name;
        }
      }
    }
    return id;
  }

  async componentDidMount() {
    fetchUsers().then(response => {}).then(() => this.setState({isLoading: false}));
  }

  render() {

    if (this.state.isLoading) {
      return (
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-b-0">
              <Breadcrumb routes={["Renvois d'appels"]} title="Renvois d'appels"/>
              <ul className="nav nav-tabs customtab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#users" role="tab" onClick={fetchUsers}>
                    <span className="hidden-sm-up">
                      <i className="ti-home"></i>
                    </span>
                    <span className="hidden-xs-down">
                      Utilisateurs
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#list" role="tab" onClick={fetchUsers}>
                    <span className="hidden-sm-up">
                      <i className="ti-home"></i>
                    </span>
                    <span className="hidden-xs-down">
                      Files d'attentes
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#gi" role="tab" onClick={fetchUsers}>
                    <span className="hidden-sm-up">
                      <i className="ti-home"></i>
                    </span>
                    <span className="hidden-xs-down">
                      Sonneries générales
                    </span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane p-20 active" id="users" role="tabpanel">
                  <div className="col-lg-12 text-center"><Preloader /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    return(
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Renvois d'appels"]} title="Renvois d'appels"/>
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#users" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Utilisateurs
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#list" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Files d'attentes
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#gi" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Sonneries générales
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane p-20 active" id="users" role="tabpanel">
                <MaterialTable
                  columns={[
                    { title: 'Prénom', field: 'firstname', grouping: false},
                    { title: 'Nom', field: 'lastname', grouping: false},
                    { title: 'Département', field: 'location_id', grouping: false,
                      render: rowData => (
                        <div>{(rowData.location_id !== null) ? this.find_location(rowData.location_id) : "Pas de département"}</div>
                      )
                    }
                  ]}
                  data={filter(this.props.users.datas, item => item.user_type === "1" || item.user_type === "2")}
                  detailPanel={rowData => {
                    return(
                      <ForwardLayout
                        userId={rowData.user_id}
                      />
                     );
                  }}
                  title=""
                  localization={{
                    toolbar: { searchPlaceholder: 'Rechercher' } ,
                    pagination: {
                      labelRowsPerPage: "Contacts par page",
                      labelDisplayedRows: "{from}-{to} sur {count}",
                      labelRowsSelect: "lignes",
                    }
                  }}
                  options={{
                    pageSize: 20,
                    detailPanelType: "single"
                  }}
                />
              </div>
              <div className="tab-pane p-20" id="list" role="tabpanel">
                <MaterialTable
                  columns={[
                    { title: 'Prénom', field: 'firstname', grouping: false},
                    { title: 'Nom', field: 'lastname', grouping: false},
                    { title: 'Département', field: 'location_id', grouping: false,
                      render: rowData => (
                        <div>{(rowData.location_id !== null) ? this.find_location(rowData.location_id) : "Pas de département"}</div>
                      )
                    }
                  ]}
                  data={filter(this.props.users.datas, item => item.user_type === "7")}
                  detailPanel={rowData => {
                    return(
                      <ForwardLayout
                        userId={rowData.user_id}
                      />
                     );
                  }}
                  title=""
                  localization={{
                    toolbar: { searchPlaceholder: 'Rechercher' } ,
                    pagination: {
                      labelRowsPerPage: "Contacts par page",
                      labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
                    }
                  }}
                  options={{
                    pageSize: 20,
                    detailPanelType: "single"
                  }}
                />
              </div>
              <div className="tab-pane p-20" id="gi" role="tabpanel">
                <MaterialTable
                  columns={[
                    { title: 'Prénom', field: 'firstname', grouping: false},
                    { title: 'Nom', field: 'lastname', grouping: false},
                    { title: 'Département', field: 'location_id', grouping: false,
                      render: rowData => (
                        <div>{(rowData.location_id !== null) ? this.find_location(rowData.location_id) : "Pas de département"}</div>
                      )
                    }
                  ]}
                  data={filter(this.props.users.datas, item => item.user_type === "8")}
                  detailPanel={rowData => {
                    return(
                      <ForwardLayout
                        userId={rowData.user_id}
                      />
                     );
                  }}
                  title=""
                  localization={{
                    toolbar: { searchPlaceholder: 'Rechercher' } ,
                    pagination: {
                      labelRowsPerPage: "Contacts par page",
                      labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
                    }
                  }}
                  options={{
                    pageSize: 20,
                    detailPanelType: "single"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    users: state.users,
    company: state.company,
    forward: state.forward,
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    activateCustomForwards,
    deactivateCustomForwards,
    addForwards
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForwardContainer);
