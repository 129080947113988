export const loadingNewMessages = () => {
  return {
    type: 'LOADING_NEW_MESSAGES'
  }
};

export const stopLoadingNewMessages = () => {
  return {
    type: 'STOP_LOADING_NEW_MESSAGES'
  }
};

export const fetchNewMessages = (messages) => {
  return {
    type: 'FETCH_NEW_MESSAGES',
    payload: messages
  }
};

export const fetchNewCalls = (calls) => {
  return {
    type: 'FETCH_NEW_CALLS',
    payload: calls
  }
};
