/* LOADING */
export const loadingVoiceMails = () => { return { type: 'LOADING_VOICE_MAILS' } };

/* STOP LOADING */
export const stopLoadingVoiceMails = () => { return { type: 'STOP_LOADING_VOICE_MAILS' } };

/* FETCH */
export const fetchVoiceMails = (voiceMails) => { return { type: 'FETCH_VOICE_MAILS', payload: voiceMails } };

/* UNSEEN / SEEN  */
export const fetchUnseenVoiceMails = (voiceMails) => { return { type: 'FETCH_UNSEEN_VOICE_MAILS', payload: voiceMails } };
export const hasReadUnseenVoiceMails = () => { return { type: 'HAS_READ_UNSEEN_VOICE_MAILS' } };
