import React, { Component, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { fetchMevoGroup, updateMevo  } from 'components/commons/helpers/admin/mevoGroupHelper.js';
import { stopEditingMevo } from 'actions/admin/mevoGroupActions.js';
import Preloader from 'components/commons/Preloader.js';
import EditMevoGroupInfos from './EditMevoGroupInfos.js';
import EditMevoGroupMembers from './EditMevoGroupMembers.js';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';

function EditMevoGroup({ users, company, mevoGroup, stopEditingMevo }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [mevoGroupState, setMevoGroupState] = useState({
    "name": '',
    "password": '',
    "s164": [],
    "members": [],
    "dataMembers": [],
  });

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setMevoGroupState(prevState => ({
      ...prevState,
      [name]: value
    }));

    return;
  }

  function handleOptionsNumbers(type, number) {
  	if (type === 'addShort' && !isUndefined(number)) mevoGroupState.s164.push(number.short);
  	else if (type === 'delShort' && !isUndefined(number)) {
  	  let s164 = mevoGroupState.s164;
  	  forEach(number, function(value, key) {
    		var index = s164.indexOf(value);
    		if (index > -1) s164.splice(index, 1);
  	  })

      setMevoGroupState(prevState => ({
        ...prevState,
        s164: s164
      }));
  	}
  }

  async function componentDidMount() {
    fetchUsers();
    if (mevoGroup.editing.isEditing) {
      try {
        await fetchMevoGroup(mevoGroup.editing.groupId).then((result) => {
          let members = mevoGroup.data.member;
          var dataMembers = [];
          forEach(users.datas, function(details) {
            if (details.user_type === "1" || details.user_type === "2") {
              let user = {
              "user_id": details.user_id,
              "mail": !isUndefined(details.mail) ? details.mail : '',
              "firstname": !isUndefined(details.firstname) ? details.firstname : '',
              "lastname": !isUndefined(details.lastname) ? details.lastname : '',
              "group_id": details.group_id,
              "location_id": details.location_id,
              "user_type" : details.user_type,
              tableData: { checked: members.includes(details.user_id) ? true : false}
              };
              
              dataMembers.push(user);
            }
          })

          setMevoGroupState(prevState => ({
            ...prevState,
            "name":prevState.data.name,
            "id":prevState.data.id,
            "password": '********',
            "s164": prevState.data.s164,
            "members":prevState.data.member,
            "dataMembers":dataMembers,
          }));
        })
      } catch (e) {
        console.log(e);
      } finally {
	    setIsLoading(false);
	  }
    }
  }

  async function updateMevoGroup(){
    try {
	  let info = {
		member :  {
          "add": [],
          "delete": [],
        },
	  };
	  
	  if (!isUndefined(mevoGroupState.name)) info.name = mevoGroupState.name;
	  if (!isUndefined(mevoGroupState.s164)) info.s164 = mevoGroupState.s164;
	  if (mevoGroupState.password !== "********") info.password = mevoGroupState.password;
	  
	  forEach(mevoGroupState.dataMembers, function(details) {
		if (details.tableData.checked === true) {
		  info.member.add.push(details.user_id);
		}
	  })
	  
	  forEach(mevoGroupState.members, function(user_id) {
		  if (!info.member.add.includes(user_id)) {
			info.member.delete.push(user_id);
		  }
	  })
	  
      await updateMevo(mevoGroup.editing.groupId, info);

      navigate('/admin/mevogroup');
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }

	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);

    if (isEmpty(mevoGroupState)) return navigate('/admin/mevogroup');

	let numbers = {
	  'short' : [],
	  'lenghShort' : company.short_length
	};

	forEach(company.short, function(value, number) {
	  numbers.short.push(number);
	})

    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Messagerie de Groupe", "Édition de Messagerie de Groupe"]} url="/admin/mevogroup" title="Édition de Messagerie de Groupe" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Information générales
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Gestion des membres
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
                <EditMevoGroupInfos
                  options={mevoGroupState}
                  handleOptions={handleOptions}
                  handleOptionsNumbers={handleOptionsNumbers}
                  numbers={numbers}
                />
              </div>
              <div className="tab-pane p-20" role="tabpanel" id="users">
                <EditMevoGroupMembers
                  dataMembers={mevoGroupState.dataMembers}
                  company={company}
                />
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/mevogroup');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={updateMevoGroup} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    mevoGroup: state.mevoGroup
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingMevo
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMevoGroup);
