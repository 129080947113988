export const loadingUsers = () => {
  return { type: 'LOADING_USERS' }
};

export const fetchUsers = (contacts) => {
  return {
    type: 'FETCH_USERS',
    payload: contacts
  }
};

export const stopLoadingUsers = () => {
  return { type: 'STOP_LOADING_USERS' }
};

export const startEditingUser = (userId) => {
  return {
    type: 'START_EDITING_USER',
    payload: userId
  }
};

export const stopEditingUser = () => {
  return { type: 'STOP_EDITING_USER' }
};

export const fetchUser = (userId) => {
  return {
    type: 'FETCH_USER',
    payload: userId
  }
};

export const loadingUser = () => {
  return { type: 'LOADING_USER' }
};

export const stopLoadingUser = () => {
  return { type: 'STOP_LOADING_USER' }
};
