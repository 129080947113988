import React, { Component } from 'react';
import Inbox from './Inbox.js';
import Frequent from './Frequent.js';
import Phone from './Phone.js';
import Forward from './forward/ForwardContainer.js';
import IM from './IM.js';
import { connect } from 'react-redux';
import {getUser} from 'components/commons/api/user.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import Preloader from 'components/commons/Preloader.js';
import { createUser } from 'actions/authActions.js';
import { bindActionCreators } from 'redux';
import { addImDatas } from 'actions/imActions.js';
import { getCallLog } from 'components/commons/helpers/phoneHelper';
import { getListIncomingFaxes } from 'components/commons/helpers/faxHelper';
import { getVoiceMails } from 'components/commons/helpers/voiceMailHelper';
import { getMiniToken } from 'components/commons/api/auth.js';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }

    this.receiveMessage = this.receiveMessage.bind(this);
    this.hideSpinner = this.hideSpinner.bind(this);
  }

  hideSpinner = () => {
    this.setState({
      isLoading: false
    });
  };

  receiveMessage(event) {    
    const { roomId, mxToken, mxUserId, mxDeviceId } = event.data;

    if (!isUndefined(mxUserId)) {
      this.props.addImDatas({roomId, mxToken, mxUserId, mxDeviceId});
    }
  }

  async componentDidMount() {
    try {
      window.addEventListener("message", this.receiveMessage, false);
      let user = {};

      if (isEmpty(this.props.session.user)) {
        const userData = await getUser();
        user = userData.data;
        this.props.createUser(user);
      }      
      
      const miniToken = await getMiniToken();
      localStorage.setItem('_mt', miniToken.data.mini_token);
      
      localStorage.setItem('provider', this.props.session.user.provider);
      localStorage.setItem('company', this.props.session.user.company);
      
      getCallLog();
      getVoiceMails();
      getListIncomingFaxes();
      
      if(window.soundManager != null ){
        window.soundManager.setVolume('ringback', 0);
        window.soundManager.destroySound('ringback');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <div className="Dashboard">
              <Preloader />
            </div>
          </div>
        </div>
      );
    }

    

    const mxToken = isUndefined(this.props.im) ? '' : this.props.im.mxToken;
    let imUrl = !isEmpty(this.props.session.user) ? this.props.session.user.servers.matrix.im4ui + "/#" : 'https://im4ui.at.kertel.one/#';
    //let imUrl = "http://localhost:8080/#";
    let frameUrl = '';

    if (isEmpty(mxToken)) {
      frameUrl = `${imUrl}/login`
    } else {
      frameUrl = `${imUrl}/home`
    }

    return (
      <div className={`Dashboard ${this.props.settings.darkMode && 'darkModeLight'}`}>
        <div className="row justify-content-star">
          <div className="col-lg-8">
            <div className="row justify-content-star">
              <div className={`col-lg-12 ${this.props.settings.darkMode && 'darkModeLight'}`} style={{ height: "552px" }}>
                <Inbox />
              </div>
            </div>  
            <div className="row justify-content-star">
              <div className={`col-lg-5 ${this.props.settings.darkMode && 'darkModeLight'}`}>
                <Phone />
              </div>
              <div className={`col-lg-7 ${this.props.settings.darkMode && 'darkModeLight'}`}>
                <Forward />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className='row'>
              <div className={`col-lg-12 ${this.props.settings.darkMode && 'darkModeLight'}`}>
                <Frequent />
              </div>
            </div>
            <div className='row'>
              <div className={`col-lg-12 ${this.props.settings.darkMode && 'darkModeLight'}`}>
              {this.state.isLoading ? (
                  <Preloader />
                ) : null}
                {!isEmpty(this.props.session.user) && <iframe id="imIframe"
                    allow="camera;microphone"
                    style={{ width: 0, height: 0, float: 'right' }}
                    title="Iframe instant messaging"
                    width="0"
                    height="0"
                    onLoad={this.hideSpinner}
                    src={frameUrl}>
                </iframe>}
                {!isEmpty(this.props.session.user) && !isUndefined(this.props.im) && !isEmpty(this.props.im.mxToken) && <IM />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    session: state.kertelSession,
    phone: state.kertelphone,
    users: state.users,
    im: state.im
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createUser,
    addImDatas,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);