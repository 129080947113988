const initialState = {
  dashboard: {
    darkMode: false
  }
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_DARK_MODE':
      return {
        ...state,
        darkMode: !state.darkMode
      }

    default:
      return state
  }
}

export default settingsReducer;
