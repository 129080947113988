import { GET, POST, PUT, PATCH, DELETE } from './../api';

export const getRingtonesGeneralInformation = () => {
  return POST('/ringtone/general',{
    company: localStorage.getItem('company')
  });
}

export const getRingtoneGeneralInformation = (ringtoneId) => {
  return POST('/ringtone/general', {
    company: localStorage.getItem('company'),
    tty_id: ringtoneId
  });
}

export const checkTtyIdRingtoneGeneralInformation = (ttyId) => {
  return GET('/ringtone/general', {
    action: 'check',
    tty_id: ttyId
  });
}

export const createRingtoneGeneralInformation = (informations) => {
  return PUT('/ringtone/general', {
    company: localStorage.getItem('company'),
    data: informations
  });
}

export const updateRingtoneGeneralInformation = (ringtoneId, informations) => {
  return PATCH('/ringtone/general', {
    company: localStorage.getItem('company'),
    tty_id: ringtoneId,
    data: informations
  });
}

export const deleteRingtoneGeneralInformation = (ringtoneId) => {
  return DELETE('/ringtone/general', {
    company: localStorage.getItem('company'),
    tty_id: ringtoneId
  });
}