import { POST, PUT, PATCH, DELETE } from './../api';

export const getInterceptionsInformation = () => {
  return POST('/company/pool',{
    company: localStorage.getItem('company')
  });
}

export const getInterceptionInformation = (interceptionId) => {
  return POST('/company/pool', {
    company: localStorage.getItem('company'),
    pool_id: interceptionId
  });
}

export const createInterceptionInformation = (informations) => {
  return PUT('/company/pool', {
    company: localStorage.getItem('company'),
    data: informations
  });
}

export const updateInterceptionInformation = (interceptionId, informations) => {
  return PATCH('/company/pool', {
    company: localStorage.getItem('company'),
    pool_id: interceptionId,
    data: informations
  });
}

export const deleteInterceptionInformation = (interceptionId) => {
  return DELETE('/company/pool', {
    company: localStorage.getItem('company'),
    pool_id: interceptionId
  });
}


