import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { deleteMevo } from 'components/commons/helpers/admin/mevoGroupHelper.js';
import { startEditingMevo, startConfigMevo } from 'actions/admin/mevoGroupActions.js';
import MevoMessages from './MevoMessages.js';
import { bindActionCreators } from 'redux';
import Preloader from 'components/commons/Preloader.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
library.add(faCogs);

function MevoGroupTab ({ isLoading, mevoGroup, startEditingMevo, startConfigMevo }) {

  const navigate = useNavigate();

    if (isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MaterialTable
        columns={[
          { title: 'Nom', field: 'name' },
          { title: 'N° Court', field: 's164', render: rowData => (
              <div>{(rowData.s164 != null)?rowData.s164.join(', '):""}</div>
          )},
          { title: 'Nombre de membres', sorting: false,
            render: rowData => (
              <div>{rowData.member.length}</div>
            )
          },
        ]}
        data={mevoGroup}
        detailPanel={[
          {
            tooltip: "Plus d'information",
            render: rowData => {
              return (
                <MevoMessages
                  userId={rowData.owner}
                />
              )
            }
          },
        ]}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Messageries de groupe par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
              labelRowsSelect: "Lignes"
            },
          }
        }
        options={{
            search: true,
            actionsColumnIndex: -1,
            pageSize: 10,
            detailPanelType: "single"
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Modifier',
            onClick: (event, rowData) => {
              startEditingMevo(rowData.id);
              return navigate('/admin/editmevogroup');
            },
          },
          {
            icon: () => (<FontAwesomeIcon icon={["fa", "cogs"]} size="sm" />),
            tooltip: 'Configuration',
            onClick: (event, rowData) => {
              startConfigMevo(rowData.id);
              return navigate('/admin/mevogroup/config');
            },
          },
          {
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
                return deleteMevo(rowData.id)
            },
          }
        ]}
      />
    );
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingMevo,
    startConfigMevo,
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(MevoGroupTab);
