import React, { Component } from "react";
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import ShortNumberModal from '../../modals/ShortNumberModal.js'
import forEach from 'lodash/forEach';
import CustomSelect from './CustomSelect.js'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

class UpdateUserOptionsNumbers extends Component {

  createCustomModalHeader = (closeModal) => {
	const headerStyle = {
	  fontWeight: 'bold',
	  fontSize: 'large',
	  textAlign: 'center',
	  backgroundColor: '#eeeeee',
	  padding:'50px'
	};
	
	return (
	  <div className='modal-header' style={headerStyle} />
	);
  }
  
  createCustomModalFooter = (onClose, onSave) => {
    return (
      <div className='modal-footer' style={{ backgroundColor: '#ffffff' }}>
        <button className='btn btn-inverse mr-2' onClick={ onClose }>Annuler</button>
        <button className='btn btn-success' onClick={ onSave }>Enregistrer</button>
      </div>
    );
  }
  
  createCustomModal = (onModalClose, onSave, columns, validateState, ignoreEditable) => {
    const attr = {
      onModalClose, onSave, columns, validateState, ignoreEditable
    };
    
    return (
      <ShortNumberModal
        { ... attr }
        numbers={this.props.numbers}
        options={this.props.options}
      />
    );
  }
  
  customNoConfirm(next) {
	next();
  }
  
  selectRowProp = {
	mode: 'checkbox'
  };

  render() {
	
	var longNumbers = [];
	var listLongNumbers = this.props.numbers.long;
	forEach(this.props.options.e164, function(value) {
	  var index = listLongNumbers.indexOf(value);
	  if (index > -1) listLongNumbers.splice(index, 1);
	  longNumbers.push({long: value});
	})
	
	var faxes = [];
	var listFaxNumbers = this.props.numbers.fax;
	forEach(this.props.options.fax, function(value) {
	  var index = listFaxNumbers.indexOf(value);
	  if (index > -1) listFaxNumbers.splice(index, 1);
	  faxes.push({fax: value});
	})
	
	var shortNumbers = [];
	forEach(this.props.options.s164, function(value) {
	  shortNumbers.push({short: value});
	})
	
	return (
	  <Container component="main">
		<Grid container spacing={2}>
		  
		  <Grid item xs={12} sm={3}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>N° mobile</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={1} />
		  <Grid item xs={12} sm={3}>
			<TextField
			  name="mobile"
			  fullWidth
			  value={this.props.options.mobile}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>	
		  <Grid item xs={12} sm={5} />
		  
		  <Grid item xs={12} sm={3}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>N° présenté au destinataire</FormLabel>
			<CustomSelect
			  name="alt_e164"
			  value={this.props.options.alt_e164}
			  options={this.props.company.long}
			  handleOptions={this.props.handleOptions}
			  handleAutocomplete={this.props.handleAutocomplete}
			/>
		  </Grid>
		  <Grid item xs={12} sm={1} />
		  <Grid item xs={12} sm={2}>
			<TextField
			  name="alt_e164"
			  value={this.props.options.alt_e164}
			  margin="normal"
			  InputProps={{
				readOnly: true,
			  }}
			/>
		  </Grid>
		  <Grid item xs={12} sm={4} style={{paddingTop: '20px' }}>
  	        <IconButton aria-label="delete" onClick={() => { this.props.handleAutocomplete({name:"alt_e164", value:""}) }}  style={{display: 'flex', flexDirection: 'row' }}>
			  <DeleteIcon fontSize="small" />
			</IconButton>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		  
		  <Grid item xs={12} sm={3}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>Masquer le numéro</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={5}>
			<FormControl component="fieldset" style={{ float: "left", width: "80%", paddingLeft: '50px'}}>
			  <FormControlLabel style={{ paddingLeft: '50px' }}
				control={<Switch name="clip_clir" checked={this.props.options.clip_clir} onChange={this.props.handleOptions} />}
			  />
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={4} />
		  
		  <Grid item xs={12} sm={3}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>Affichage du numéro appelé</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={5}>
			<FormControl component="fieldset" style={{ float: "left", width: "80%", paddingLeft: '50px'}}>
			  <FormControlLabel style={{ paddingLeft: '50px' }}
				control={<Switch name="clip_called" checked={this.props.options.clip_called} onChange={this.props.handleOptions} />}
			  />
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={4} />
		  
		  <Grid item xs={12} sm={3}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>Affichage de la personne renvoyant l'appel</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={5}>
			<FormControl component="fieldset" style={{ float: "left", width: "80%", paddingLeft: '50px'}}>
			  <FormControlLabel style={{ paddingLeft: '50px' }}
				control={<Switch name="clip_previous" checked={this.props.options.clip_previous} onChange={this.props.handleOptions} />}
			  />
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={4} />
		  
		  <Grid item xs={12} sm={4}>
			<BootstrapTable
			  data={ longNumbers }
			  deleteRow
			  insertRow
			  selectRow={this.selectRowProp}
			  renderAlert={false}
			  options={{
				handleConfirmDeleteRow: this.customNoConfirm,
				afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addLong'),
				afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delLong'),
				insertModalHeader: this.createCustomModalHeader,
				insertModalFooter: this.createCustomModalFooter,
				noDataText: 'Aucun numéro disponible',
				insertText: 'Ajouter',
				deleteText: 'Supprimer',
			  }}
			>
			  <TableHeaderColumn dataField='long' editable={{type: 'select', options: {values: listLongNumbers}}} isKey >Numéros de téléphone</TableHeaderColumn>
			</BootstrapTable>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<BootstrapTable
			  data={ faxes }
			  deleteRow
			  insertRow
			  selectRow={this.selectRowProp}
			  renderAlert={false}
			  options={{
				handleConfirmDeleteRow: this.customNoConfirm,
				afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addFax'),
				afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delFax'),
				insertModalHeader: this.createCustomModalHeader,
				insertModalFooter: this.createCustomModalFooter,
				noDataText: 'Aucun numéro disponible',
				insertText: 'Ajouter',
				deleteText: 'Supprimer',
			  }}
			>
			  <TableHeaderColumn dataField='fax' editable={{type: 'select', options: {values: listFaxNumbers}}} isKey >Numéros de fax</TableHeaderColumn>
			</BootstrapTable>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<BootstrapTable
			  data={ shortNumbers }
			  deleteRow
			  insertRow
			  selectRow={this.selectRowProp}
			  renderAlert={false}
			  options={{
                handleConfirmDeleteRow: this.customNoConfirm,
				afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addShort'),
				afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delShort'),
                insertModal: this.createCustomModal,
				noDataText: 'Aucun numéro disponible',
				insertText: 'Ajouter',
				deleteText: 'Supprimer',
			  }}
			>
			  <TableHeaderColumn dataField='short' isKey >Numéro(s) court(s)</TableHeaderColumn>
			</BootstrapTable>
		  </Grid>
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(UpdateUserOptionsNumbers);
