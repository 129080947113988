import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stopEditingMevo } from 'actions/admin/mevoGroupActions.js';
import { updateMevo } from 'components/commons/helpers/admin/mevoHelper.js';
import { getMevoMessages } from 'components/commons/api/admin/mevo';
import { getMevoInformation } from 'components/commons/api/admin/mevo';
import Preloader from 'components/commons/Preloader.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import swal from 'sweetalert';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ProgressBar from 'react-bootstrap/ProgressBar'
import MusicModal from 'components/views/modals/MusicModal.js';
import { showModal } from 'actions/modalActions';
import { post } from 'axios';
import { notifyWarning } from 'components/commons/Toasts.js';
import { useNavigate } from 'react-router';

function ConfigMevo ({ showModal, session, componentDidMount }) {

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [mevo, setMevo] = useState({
		"password": "",
		"id": "",
		"owner": "",
		"quota": "0",
		"nbrMessage": "0",
		"typeMessagerie": "record",
		"typeAnnonce": 'default',
		"fileAnnonce": {name: "______________"},
		"urlAnnonce": "",
		"fullboxNotification": "disable",
		"emailNotification": "disable"
	})

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

	setMevo(prevState => ({
		...prevState,
		[name]: value
	}));
  }

  function handleFileOptions(file) {
	setMevo(prevState => ({
		...prevState,
		"fileAnnonce": file
	}));
  }
  
  async function init() {
	
	await getMevoInformation().then((result) => {
	  setMevo(prevState => ({
		...prevState,
		  "id": session.user.user_id,
		  "owner": session.user.user_id,
		  "quota": result.data.quota,
		  "typeMessagerie": result.data.type,
		  "typeAnnonce": result.data.announce,
		  "urlAnnonce": `${session.apiUrl}/mevo/announce?user_id=${session.user.user_id}&token=${localStorage.getItem('_mt')}`,
		  "fullboxNotification": result.data.notif_fullbox.email,
		  "emailNotification": result.data.notif.email,
	  }));
	})
	
	await getMevoMessages(session.user.user_id).then((result) => {
		setMevo(prevState => ({
			...prevState,
		  	"nbrMessage": result.count
		}));
	})
	
	setIsLoading(false);
  }

  useEffect(function() {
	init();
  }, []);

  async function saveConfigMevo(){
	
	if (isEmpty(mevo.id)) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: "L'id de la messagerie est invalide",
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	  
	let info = {};
	
	info.notif = mevo.typeMessagerie;
	info.type = mevo.typeMessagerie;
	info.announce = mevo.typeAnnonce;
	if (!isEmpty(mevo.password)) {
	  info.password = mevo.password;
	  if (!(parseFloat(info.password) === info.password >>> 0) || info.password.length < 6 ) {
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: 'Le mot de passe de la messagerie est invalide',
		  confirmButtonColor: 'LightSeaGreen',
		})
	  }
	}
	info.notif_fullbox = '{"email":"' + mevo.fullboxNotification + '","sms":"disable"}';
	info.notif = '{"email":"' + mevo.emailNotification + '","sms":"disable"}';
	
	setIsLoading(true);
	
	if (mevo.fileAnnonce.name !== "______________" && mevo.typeAnnonce !== "default") {
	  let formData = new FormData();
	  
	  formData.append('user_id', mevo.owner);
	  
	  let type = mevo.typeAnnonce;
	  if (type === "name_specified") type = "specified";
	  formData.append('type', type);
	  
	  formData.append('file', mevo.fileAnnonce);
	  
	  const token = localStorage.getItem('_mt');
	  const config = {
		headers: {
		  'content-type': 'multipart/form-data',
		  'auth-token': `${token}`
		}
	  };
	  
	  await post(`${session.apiUrl}/mevo/announce`, formData, config).then()
	  .catch((err) => {
		let { response } = err;
		response = response || {};
		
		let message = "Une erreur est survenue.";
		if (!isUndefined(response.data)) {
		  if (!isUndefined(response.data.validation_messages)) {
			const validationMessage = Object.values(response.data.validation_messages);
			const errDetails = Object.values(validationMessage[0]);
			message = errDetails[0];
		  } else {
			message = response.data.detail;
		  }
		}
		
		notifyWarning(message);
	  });
	}
	
	try {
	  await updateMevo(mevo.id, info);
    } catch (e) {
      console.log(e);
    } finally {

		setIsLoading(false);
		setMevo(prevState => ({
			...prevState.mevo,
		  "fileAnnonce": {"name": "______________"},
		}));
    }
  }
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
	
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
				<Container component="main" style={{marginTop: '30px'}}>
				  <Grid container spacing={2}>
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Modifier le mot de passe</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <TextField
						name="password"
						label="Mot de passe"
						type="password"
						fullWidth
						helperText="6 chiffres minimum."
						value={mevo.password}
						onChange={handleOptions}
					  />
					</Grid>
					<Grid item xs={12} sm={6} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Quota</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <ProgressBar now={(mevo.nbrMessage*100)/mevo.quota} label={`${mevo.nbrMessage}`} />
					</Grid>
					<Grid item xs={12} sm={2}>
					  <FormLabel> / {`${mevo.quota}`} messages</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Type de messagerie</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<select
						  name="typeMessagerie"
						  value={mevo.typeMessagerie}
						  className="custom-select col-12"
						  onChange={handleOptions}
						>
						  <option value="record">Répondeur</option>
						  <option value="message_only">Répondeur non enregistreur</option>
						</select>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Type d'annonce</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<select
						  name="typeAnnonce"
						  value={mevo.typeAnnonce}
						  className="custom-select col-12"
						  onChange={handleOptions}
						>
						  <option value="default">Message par défaut</option>
						  <option value="name_specified">Message semi-personnalisé (Seulement le nom)</option>
						  <option value="personalized">Message personnalisé (Annonce complète)</option>
						</select>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
					
					{ mevo.typeAnnonce !== "default" && (
						<React.Fragment>
						  <Grid item xs={12} sm={2}>
							<FormLabel component="legend" style={{marginTop: '10px'}}>Message d'annonce</FormLabel>
						  </Grid>
						  <Grid item xs={12} sm={4}>
							<audio src={mevo.urlAnnonce} controls>
							  <p>Votre navigateur ne prend pas en charge l'audio HTML. Voici un
								 un <a href={mevo.urlAnnonce}>lien vers le fichier audio</a> pour le 
								 télécharger.</p>
							</audio>
						  </Grid>
						  <Grid item xs={12} sm={1}>
							<a href={'data:audio/mp3;charset=utf-8,' + mevo.urlAnnonce} download="announce_message.mp3">
							  <button className="btn btn-info btn-circle">
								 <i className="fa fa-download"/>
							  </button>
						   </a>
						  </Grid>
						  <Grid item xs={12} sm={5} />
						  
						  <Grid item xs={12} sm={2}>
							<FormLabel component="legend" style={{marginTop: '10px'}}>Modifier l'annonce</FormLabel>
						  </Grid>
						  <Grid item xs={12} sm={4}>
							<FormLabel component="legend" style={{marginTop: '10px'}}>Fichier : {mevo.fileAnnonce.name}</FormLabel>
						  </Grid>
						  <Grid item xs={12} sm={1}>
							<button
							  className="btn btn-danger btn-circle"
							  style={{marginRight: '10px'}}
							  onClick={() => {
								return showModal({
								  title: "Envoyer un fichier audio",
								  component: MusicModal,
								  context: 'mevoGroup',
								  file: handleFileOptions,
								  callback: () => componentDidMount()
								})
							  }}
							  ><i className="fa fa-edit"></i> </button>
						  </Grid>
						  <Grid item xs={12} sm={5} />
						</React.Fragment>
					  )
					}
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Notification de messagerie pleine</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<select
						  name="fullboxNotification"
						  value={mevo.fullboxNotification}
						  className="custom-select col-12"
						  onChange={handleOptions}
						>
						  <option value="disable">Aucune notification</option>
						  <option value="enable">Notification par email</option>
						</select>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Notification par email de nouveau message</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<select
						  name="emailNotification"
						  value={mevo.emailNotification}
						  className="custom-select col-12"
						  onChange={handleOptions}
						>
						  <option value="disable">Aucune notification</option>
						  <option value="enable">Notification par email</option>
						  <option value="enable_with_file">Notification par email avec fichier attaché</option>
						</select>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
				  </Grid>
				</Container>
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/mevogroup');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={saveConfigMevo} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    mevoGroup: state.mevoGroup,
    messagesMevoGroup: state.messagesMevoGroup,
	session: state.kertelSession
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingMevo,
	showModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigMevo);
