import React, { useState } from 'react';
import { getCallLog } from 'components/commons/helpers/phoneHelper';
import RecentsTab from './RecentsTab.js';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

function Recents () {

  const phone = useSelector(state => state.kertelphone);

  async function init() {
    await getCallLog();
  }

  useState(function() {
    init();
  }, []);
  
  if (isUndefined(phone)) {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb title="Journal d'appels" />
            <h4 className="text-muted">Erreur lors du chargement</h4>
          </div>
      </div>
      </div>
    ); 
  }

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body p-b-0">
          <Breadcrumb title="Journal d'appels" downloadCallsHist={true} />
          <ul className="nav nav-tabs customtab" role="tablist">
            <li className="nav-item customtab">
              <a className="nav-link" data-toggle="tab" href="#incomings" role="tab" onClick={getCallLog}>
                <i className="mdi mdi-phone-incoming text-success"></i>
                <span className="hidden-xs-down">&nbsp;&nbsp;Appels entrants&nbsp;&nbsp;
                  <span className="badge badge-pill badge-light">
                    {!isEmpty(phone.calls.incoming.datas) ? phone.calls.incoming.datas.length : 0}
                  </span>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#outgoings" role="tab" onClick={getCallLog}>
                <i className="mdi mdi-phone-outgoing text-info"></i>
                <span className="hidden-xs-down">&nbsp;&nbsp;Appels sortants&nbsp;&nbsp;
                  <span className="badge badge-pill badge-light">
                    {!isEmpty(phone.calls.outgoing.datas) ? phone.calls.outgoing.datas.length : 0}
                  </span>
                </span>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active p-20" id="incomings" role="tabpanel">
              <RecentsTab
                recents={phone.calls.incoming.datas}
                isLoading={phone.calls.both.isLoading}
                type={"incomings"}
              />
            </div>
            <div className="tab-pane p-20" id="outgoings" role="tabpanel">
              <RecentsTab
                recents={phone.calls.outgoing.datas}
                isLoading={phone.calls.both.isLoading}
                type={"outgoings"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recents;
