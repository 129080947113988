export const loadingRecords = () => {
  return { type: 'LOADING_RECORDS' }
};

export const fetchRecords = (records) => {
  return {
    type: 'FETCH_RECORDS',
    payload: records
  }
};

export const fetchCountRecords = (records) => {
  return {
    type: 'FETCH_COUNT_RECORDS',
    payload: records
  }
};

export const stopLoadingRecords = () => {
  return { type: 'STOP_LOADING_RECORDS' }
};