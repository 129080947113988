import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getMevoMessages } from 'components/commons/api/admin/mevo';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import 'moment/locale/fr';
import axios from 'axios';

class MevoMessages extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: true,
      datas: []
    }
  }

  async componentDidMount() {
    try {
      await getMevoMessages(this.props.userId).then((result) => {
        if(!isEmpty(result.datas))
          this.setState((prevState) => ({
            datas: result.datas,
          }));
      })
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {

    if (this.state.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (

      <div style={{
         paddingLeft: "1%",
         paddingRight: "1%",
       }}>
      <MaterialTable
      title=""
      columns={[
        { title: 'De', field: 'caller_number' },
        { title: 'Date', field: 'date', render: (rowData) => {
          return <React.Fragment>Le <Moment locale='fr' unix format="DD MMMM YYYY à HH:mm:ss">{rowData.date || "-"}</Moment></React.Fragment>
        } },
        { title: 'Durée', field: 'duration'},
        { title: 'Ecouter', field: 'play', render: (rowData) => {
          return (
            <audio id={`player_${rowData.id}`} controls src={`${axios.defaults.baseURL}/mevo/message?message_id=${rowData.id}&u=${this.props.userId}`} type="audio/mp3"></audio>
          );
        }}
      ]}
      components={{
           Container: props => <Paper {...props} elevation={0}/>
      }}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar : false
      }}
      data={this.state.datas}
      actions={[
        {
          icon: 'cloud_download',
          tooltip: 'Télécharger',
          onClick: (event, rowData) => {
            const url = `${axios.defaults.baseURL}/mevo/message?message_id=${rowData.id}&u=${this.props.userId}`;
            var element = document.createElement('a');
            element.setAttribute('href', 'data:audio/mpeg;charset=utf-8,'+url);
            element.setAttribute('download', 'message_vocal_extranet.mp3');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            return null;
          },
        }
      ]}
    />
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    session: state.kertelSession
  }
}

export default connect(mapStateToProps, null)(MevoMessages);
