import { store } from 'store/index.js';
import {
  getInterceptionsInformation,
  getInterceptionInformation,
  createInterceptionInformation,
  updateInterceptionInformation,
  deleteInterceptionInformation
} from 'components/commons/api/admin/interceptionGroup';
import {
  loadingInterceptions,
  getInterceptions as getInterceptionsAction,
  stopLoadingInterceptions,
  loadingInterception,
  getInterception as getInterceptionAction,
  stopLoadingInterception,
} from 'actions/admin/interceptionGroupActions';
import { notifySuccess } from 'components/commons/Toasts.js';

export const fetchInterceptions = async (props) => {
  store.dispatch(loadingInterceptions());
  try {
    await getInterceptionsInformation().then((result) => {
      return store.dispatch(getInterceptionsAction(Object.values(result.data)));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingInterceptions());
  }
}

export const fetchInterception = async (interceptionId) => {
  store.dispatch(loadingInterception());
  try {
    await getInterceptionInformation(interceptionId).then((result) => {
      return store.dispatch(getInterceptionAction(Object.values(result.data)[0]));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingInterception());
  }
}

export const createInterception = async (informations, history) => {
  store.dispatch(loadingInterceptions());
  try {
    await createInterceptionInformation(informations).then((result) => {
      if (result.status === 200) {
        notifySuccess("Interception de groupe ajoutée avec succès !");
        fetchInterceptions();
        
        return;
      }
      
      return fetchInterceptions();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingInterceptions());
  }
}

export const updateInterception = async (interceptionId, informations) => {
  store.dispatch(loadingInterceptions());
  try {
    await updateInterceptionInformation(interceptionId, informations).then((result) => {
      return fetchInterceptions();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingInterceptions());
  }
}

export const deleteInterception = async (interceptionId) => {
  store.dispatch(loadingInterceptions());
  try {
    await deleteInterceptionInformation(interceptionId).then((result) => {
      return fetchInterceptions();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingInterceptions());
  }
}
