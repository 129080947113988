import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { deleteSviMenu } from 'components/commons/helpers/admin/sviHelper.js';
import { startEditingSviMenu, startConfiguringSvi, loadingConfiguringSvi, loadingSviFiles } from 'actions/admin/sviActions.js';
import { bindActionCreators } from 'redux';
import Preloader from 'components/commons/Preloader.js';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
library.add(faCogs);
library.add(faFileAudio);

function SviMenus({ startEditingSviMenu, startConfiguringSvi, loadingSviFiles, loadingConfiguringSvi, isLoading, menusSvi }) {

  const navigate = useNavigate();

    if (isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MaterialTable
        columns={[
          { title: 'Identifiant menu', field: 'menu_id'},
          { title: 'Commentaire', field: 'comment'},
        ]}
        data={menusSvi}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Menu SVI par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
              labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          search: true,
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Modifier',
            onClick: (event, rowData) => {
              startEditingSviMenu(rowData.menu_id);
              return navigate('/admin/svi/menu/edit');
            },
          },
          {
            icon: () => (<FontAwesomeIcon icon={["fa", "cogs"]} size="sm" />),
            tooltip: 'Configurer',
            onClick: (event, rowData) => {
              startConfiguringSvi(rowData.menu_id);
              return navigate('/admin/svi/config');
            },
          },
          {
            icon: () => (<FontAwesomeIcon icon="file-audio" size="sm" />),
            tooltip: 'Fichiers',
            onClick: (event, rowData) => {
              startConfiguringSvi(rowData.menu_id);
              loadingSviFiles();
              return navigate('/admin/svi/files');
            },
          },
          {
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
              swal({
                title: 'Etes-vous sûr de vouloir supprimer cet élément?',
                text: "Une fois supprimé, vous ne pourrez pas récupérer l'ensemble des éléments (configurations & fichiers) associés !",
                icon: "warning",
                buttons: {
                  check: {
                    text: "Ok",
                    value: "willDelete",
                  }
                },
                dangerMode: true,
              })
              .then((value) => {
                if (value === "willDelete") {
                  return deleteSviMenu(rowData.menu_id);
                }
              });
            },
          }
        ]}
      />
    );
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingSviMenu,
    startConfiguringSvi,
    loadingSviFiles,
    loadingConfiguringSvi,
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(SviMenus);
