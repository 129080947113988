import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import Preloader from 'components/commons/Preloader';
import { getForwardsSet, deleteForwardRule } from 'components/commons/api/forward';
import { getLiteralForward } from 'components/commons/helpers/forwardsHelper';
import MaterialTable from 'material-table';
import { notifyInfo } from 'components/commons/Toasts.js';
import swal from 'sweetalert';
import isUndefined from 'lodash/isUndefined';

class InboundManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      rules: []
    }

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  async componentDidMount() {
    let rulesApi = [];
    let rules = [];
    try {
      this.setState({ isLoading: true });
      rulesApi = await getForwardsSet();
      rulesApi = Object.entries(rulesApi.data);
      rulesApi.forEach((rule, i) => {
        rules.push(rule[1]);
      });

    } catch (e) {

    } finally {
      this.setState({ isLoading: false, rules: rules});
      console.log(this.state);
    }
  }

  async onDeleteClick (evt, data) {
    console.log(data);
    return swal({
      title: "Confirmation",
      text: `Etes-vous sûr(e) de vouloir supprimer cette règle ?`,
      icon: "warning",
      buttons: true,
      warning: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        return deleteForwardRule(this.props.session.datas.data.user_id, data.id).then((result) => {
          this.componentDidMount();
          return notifyInfo("Règle supprimée avec succès !");
        }).catch((e) => {
          return console.log(e);
        });
      }
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className='row'>
          <div className='col-lg-12'>
            <Preloader />
          </div>
        </div>
      );
    }

    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb
              title='Configuration avancée'
              addInboundManagement={true}
            />
          </div>
          <div className="tab-content">
            <div className="tab-pane p-20 active" id="users" role="tabpanel">
              <MaterialTable
                columns={[
                 { title: 'Nom de la configuration', field: 'name', grouping: false},
                 { title: '', field: '', grouping: false},
                 { title: '', field: '', grouping: false},
                 { title: '', field: '', grouping: false},
                 { title: '', field: '', grouping: false},
                 { title: '', field: '', grouping: false},
                 { title: '', field: '', grouping: false},
               ]}
               detailPanel={rowData => {
                 console.log(rowData);
                return rowData.forwards.map((rule, index) => {
                  let allCalls = true;

                  if (!isUndefined(rule.filters)) {
                    allCalls = false;
                  }

                  return (
                    <div className="card" key={index}>
                      <div className="card-body p-b-0">
                        <h5>Règle n°{index + 1}</h5><br />
                        {!allCalls && <table className='table'>
                        <thead>
                          <tr>
                            <th colSpan={2}>Tous les appels en provenance de</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          rule.filters.map((filter, index) => {
                            return (
                              <tr key={index}>
                                <td>{getLiteralForward(filter.entity.type)}</td>
                                <td>{!isUndefined(filter.entity.data) && (typeof filter.entity.data === 'object') ? filter.entity.data.dest.id : filter.entity.data}</td>
                              </tr>
                            );
                          })
                        }
                        </tbody>
                       </table>}
                       <table className='table'>
                         <thead>
                           <tr>
                            <th>{allCalls && "Tous les appels "} {allCalls ? "s" : "S"}ont renvoyés dans l'ordre vers</th>
                           </tr>
                           {
                            rule.dests.map((dest, index) => {
                              return (
                                <tr key={index}>
                                  <td>{getLiteralForward(dest.entity.type, dest.entity.data)}</td>
                                  <td>
                                    {
                                      (typeof dest.entity.data !== "object" && !isUndefined(dest.entity.data) && dest.entity.type !== "MEVO_CC") && dest.entity.data}
                                  </td>
                                </tr>
                              );
                            })
                          }
                         </thead>
                       </table>
                      </div>
                      <hr />
                    </div>    
                  )
                });
               }}
               title=""
               data={this.state.rules}
               localization={{
                toolbar: {
                  searchPlaceholder: 'Rechercher',
                },
                 pagination: {
                   labelRowsPerPage: "Règles par page",
                   labelDisplayedRows: "{from}-{to} sur {count}",
                   labelRowsSelect: "lignes",
                 }
               }}
               
               options={{
                 pageSize: 20,
                 detailPanelType: "single",
                 actionsColumnIndex: -1
               }}
               actions={[
           			{
           			  tooltip: 'Supprimer',
           			  icon: 'delete',
           			  onClick: (evt, data) => this.onDeleteClick(evt, data)
           			}
           		  ]}
               />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    phone: state.kertelphone,
    session: state.kertelSession
  };
}

export default connect(mapStateToProps, null)(InboundManagement);
