import React, { Component } from "react";
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Switch from '@material-ui/core/Switch';
import isUndefined from 'lodash/isUndefined';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: green[100],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: green[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

class UpdateUserOptionsInfos extends Component {

	constructor(props) {
		super(props);

		this.state = {
			groups: [],
			locations: []
		}
	}

	async componentDidMount() {
		let groups = await this.props.groups();
		let locations = await this.props.locations();

		this.setState({ groups, locations });
	}

  render() {
	return (
	  <Container component="main">
		<Grid container spacing={2}>
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>Civilité</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={10}>
			<FormControl component="fieldset">
			  <RadioGroup defaultValue="" aria-label="call_direction" name="customized-radios" style={{display: 'flex', flexDirection: 'row' }}>
				<FormControlLabel style={{ padding: '0 20px' }}
				  name="civility"
				  value="0"
				  control={<StyledRadio />}
				  label="Indéfini"
				  checked={this.props.options.civility === "0"}
				  onChange={this.props.handleOptions}
				/>
				<FormControlLabel style={{ padding: '0 15px' }}
				  name="civility"
				  value="1"
				  control={<StyledRadio />}
				  label="Madame"
				  checked={this.props.options.civility === "1"}
				  onChange={this.props.handleOptions}
				/>
				<FormControlLabel style={{ padding: '0 15px' }}
				  name="civility"
				  value="2"
				  control={<StyledRadio />}
				  label="Mademoiselle"
				  checked={this.props.options.civility === "2"}
				  onChange={this.props.handleOptions}
				/>
				<FormControlLabel style={{ padding: '0 15px' }}
				  name="civility"
				  value="3"
				  control={<StyledRadio />}
				  label="Monsieur"
				  checked={this.props.options.civility === "3"}
				  onChange={this.props.handleOptions}
				/>
			  </RadioGroup>
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={3}>
			<TextField
			  name="lastname"
			  label="Nom"
			  multiline
			  fullWidth
			  value={this.props.options.lastname}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		  <Grid item xs={12} sm={3}>
			<TextField
			  name="firstname"
			  label="Prénom"
			  multiline
			  fullWidth
			  value={this.props.options.firstname}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={4} />
		  <Grid item xs={12} sm={3}>
			<TextField
			  name="login"
			  label="Login"
			  multiline
			  fullWidth
			  value={this.props.options.login}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		  <Grid item xs={12} sm={3}>
			<TextField
			  name="password"
			  label="Mot de passe"
			  type="password"
			  fullWidth
			  helperText="6 chiffres minimum."
			  value={!isUndefined(this.props.options.password) ? this.props.options.password : "********"}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={4} />
		  <Grid item xs={12} sm={3}>
			<TextField
			  name="mail"
			  label="Email"
			  multiline
			  fullWidth
			  value={this.props.options.mail}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		  <Grid item xs={12} sm={3}>
			<TextField
			  name="sso_email"
			  label="SSO email"
			  multiline
			  fullWidth
			  value={this.props.options.sso_email}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={4} />
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend" style={{ float: "left", paddingTop: '30px'}}>Administrateur</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={3}>
			<FormControlLabel style={{ paddingTop: '20px'}}
			  control={<Switch name="administrator" checked={this.props.options.administrator} onChange={this.props.handleOptions} style={{ float: "left" }}/>}
			/>
		  </Grid>
		  { this.props.session.user_id === this.props.options.user_id &&
			<React.Fragment>
			  <Grid item xs={12} sm={2}>
				<FormLabel component="legend" style={{ float: "left", paddingTop: '30px'}}>Autoriser l'accès à votre compte</FormLabel>
			  </Grid>
			  <Grid item xs={12} sm={5}>
				<FormControlLabel style={{ paddingTop: '20px'}}
				  control={
					<Switch
					  name="allow_impersonate"
					  checked={this.props.options.allow_impersonate}
					  onChange={this.props.handleOptions}
					  style={{ float: "left" }}
					/>
				  }
				/>
			  </Grid>
			</React.Fragment>
		  }
		  { this.props.session.user_id !== this.props.options.user_id &&
			<React.Fragment>
			  <Grid item xs={12} sm={7} />
			</React.Fragment>
		  }
		  <Grid item xs={12} sm={5}>
			<FormLabel component="legend">Département</FormLabel>
			<select
			  name="group_id"
			  value={this.props.options.group_id}
			  onChange={this.props.handleOptions}
			  className="custom-select col-12"
			  key="groupIdSelect"
			  style={{ float: "left", width: "50%" }}
			>
			  {
				this.state.groups.map(items => {
				  return <option value={items.value} key={items.value}>{items.label}</option>;
				})
			  }
			</select>
		  </Grid>
		  <Grid item xs={12} sm={5}>
			<FormLabel component="legend">Site</FormLabel>
			<select
			  name="location_id"
			  value={this.props.options.location_id}
			  onChange={this.props.handleOptions}
			  className="custom-select col-12"
			  key="locationIdSelect"
			  style={{ float: "left", width: "50%" }}
			>
			  {
				this.state.locations.map(items => {
				  return <option value={items.value} key={items.value}>{items.label}</option>;
				})
			  }
			</select>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession.datas.data
  };
}

export default connect(mapStateToProps, null)(UpdateUserOptionsInfos);
