import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal, hideModal } from '../../actions/modalActions.js';
import { bindActionCreators } from 'redux';
import isUndefined from 'lodash/isUndefined';
import { isObject } from 'lodash';

class Modal extends Component {
  render() {
    if (isUndefined(this.props.modal.component)) {
      this.props.hideModal();
      return null;
    }

    /*if (isObject(this.props.modal.component)) {
      console.log(this.props.modal.props);
      this.props.hideModal();
      return null;
    }*/

    const ModalComponent = this.props.modal.component;

    return (
      <div>
        <div className="modal bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{display: 'block'}}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="box-title m-b-0" id="myLargeModalLabel">{this.props.modal.title}</h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={() => {
                    return this.props.hideModal();
                  }}
                >×</button>
              </div>
              <div className="modal-body">
                <div className="col-12">
                  <div className="card">
                    <ModalComponent props={this.props.modal.props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    modal: state.modal
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal,
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
