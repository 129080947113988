import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isUndefined from 'lodash/isUndefined';
import { addImDatas } from 'actions/imActions.js';
import { isEmpty } from 'lodash';

function InstantMessaging({ im, session, addImDatas }) {

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, [])

  function receiveMessage(event) {   
    const { roomId, mxToken, mxUserId, mxDeviceId } = event.data;

    if (!isUndefined(mxUserId)) {
      addImDatas({roomId, mxToken, mxUserId, mxDeviceId});
    }
  }

    if (isUndefined(im)) {
      return ("Déconnecté")      
    }

    const {selectedRoom, mxToken} = im;
    let imUrl = !isEmpty(session.user) ? session.user.servers.matrix.im4ui + "/#" : 'https://im4ui.at.kertel.one/#';
    let frameUrl = '';

    if (isEmpty(mxToken)) {
      frameUrl = `${imUrl}/login`
    } else if (isEmpty(selectedRoom)) {
      frameUrl = `${imUrl}/home`
    } else {
      frameUrl = `${imUrl}/room/${selectedRoom}`
    }

    return (
      <iframe id="imIframe"
          title="Iframe instant messaging"
          width="100"
          height="200"
          allow="camera;microphone"
          allowFullScreen="true"
          src={frameUrl}>
      </iframe>
    );
  }

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession,
    im: state.im
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addImDatas
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantMessaging);
