import React, { Component } from "react";
import { connect } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import isUndefined from 'lodash/isUndefined';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';

class UpdateUserOptionProfils extends Component {

	constructor(props) {
		super(props);

		this.state = {
			profils: [],
		}
	}

	async componentDidMount() {
		let profils = await this.props.profils();

		this.setState({ profils });
	}
  
  getProfilValue(type, data, opt) {
	let ans = {};
	let profile = "";
	
	switch (type) {
	  case 'outgoingcall':
		if (!isUndefined(data.outgoingcall_profile_id)) profile = data.outgoingcall_profile_id;
		else if (!isUndefined(data.profil_calls)) {
		  if (!isUndefined(data.profil_calls.profil_out)) {
			profile = data.profil_calls.profil_out;
		  }
		}
		break;
	  case 'incomingcall':
		if (!isUndefined(data.incomingcall_profile_id)) profile = data.incomingcall_profile_id;
		else if (!isUndefined(data.profil_calls)) {
		  if (!isUndefined(data.profil_calls.profil_in)) {
			profile = data.profil_calls.profil_in;
		  }
		}
		break;
	  default:
	}
	

	if (isEmpty(profile) && !isEmpty(opt)) {
	  opt.forEach(items => {
		if (items.label === 'Sans blocage') ans = items;
	  })
	} else if (!isEmpty(profile) && !isEmpty(opt)) {
	  opt.forEach(items => {
		if (items.value === profile) ans = items;
	  })
	}
	
	return ans; 
  }
  
  render() {
	return (
	  <Container component="main">
		<Grid container spacing={2}>
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>Profil de service</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<FormControl component="fieldset" style={{ float: "left", width: "80%" }}>
			  <select name="profile_id" value={this.props.options.profile_id} disabled={true} className="custom-select col-12" key="profileRefSelect">
				{
				  !isEmpty(this.state.profils) && this.state.profils.profile_services.map(items => {
					return <option value={items.value} key={items.value}>{items.label}</option>;
				  })
				}
			  </select>
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={6} />
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>Profil d'appel sortant</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<FormControl component="fieldset" style={{ float: "left", width: "80%" }}>
			  <Select
				name="outgoingcall_profile_id"
				options={this.state.profils.profile_calls_out}
				value={this.getProfilValue("outgoingcall", this.props.options, this.state.profils.profile_calls_out)}
				onChange={this.props.handleAutocomplete}
				/>
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={6} />
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>Profil d' appel entrant</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<FormControl component="fieldset" style={{ float: "left", width: "80%" }}>
			  <Select
				name="incomingcall_profile_id"
				options={this.props.profils.profile_calls_in}
				value={this.getProfilValue("incomingcall", this.props.options, this.props.profils.profile_calls_in)}
				onChange={this.props.handleAutocomplete}
				/>
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={6} />
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(UpdateUserOptionProfils);
