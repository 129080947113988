const initialState = {
  incomings: {
    datas: [],
    unseen: { datas: [] },
    users: { isLoading: false, datas: [] },
    isLoading: false
  },
  outgoings: {
    datas: [],
    unseen: { datas: [] },
    isLoading: false
  }
}

const faxReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    /* LOADING INCOMING FAX*/
    case 'LOADING_FAX_INCOMING':
      return { ...state, incomings: { ...state.incomings, isLoading: true } };

    /* STOP LOADING INCOMING FAX*/
    case 'STOP_LOADING_FAX_INCOMING':
      return { ...state, incomings: { ...state.incomings, isLoading: false } };
    
    /* LOADING OUTGOING FAX*/
    case 'LOADING_FAX_OUTGOING':
      return { ...state, outgoings: { ...state.outgoings, isLoading: true } };

    /* STOP LOADING OUTGOING FAX*/
    case 'STOP_LOADING_FAX_OUTGOING':
      return { ...state, outgoings: { ...state.outgoings, isLoading: false } };
    
    /* FETCH */
    case 'FETCH_FAX_LIST':
      return { ...state, incomings: { ...state.incomings, datas: action.payload } };

    case 'FETCH_OUTGOING_FAX_LIST':
      return { ...state, outgoings: { ...state.outgoings, datas: action.payload } };

    case 'FETCH_UNSEEN_FAX':
      return { ...state, incomings: { ...state.incomings, unseen: { datas: action.payload } }};

    case 'HAS_READ_UNSEEN_FAX':
      return { ...state, outgoings: { ...state.outgoings, unseen: { datas: [] } }};
    
    default:
      return state
  }
}

export default faxReducer;
