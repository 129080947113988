import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { startEditingMevo } from 'actions/admin/mevoGroupActions.js';
import SupervisionTab from './SupervisionTab.js';

class Supervision extends Component {
  componentDidMount() {
    fetchUsers();
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Supervision"]} title="Supervision" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" role="tab" href="#gi" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Utilisateurs
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane p-20 active" id="gi" role="tabpanel">
                <SupervisionTab
				  isLoading={this.props.users.isLoading}
				  listUsers={this.props.users.datas}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingMevo,
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Supervision);
