import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountBox from '@material-ui/icons/AccountBox';

class InfosLogin extends Component {

  constructor(props) {
    super(props);
	
    this.state = {
	  username: "",
	  company: "",
	  login:""
    }
	
	this.handleOptions = this.handleOptions.bind(this);
  }

 
  async handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
	
    await this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
	
	let company = this.state.company;
	
	const findFor = ['à', 'á', 'â', 'ã', 'ä', 'å', 'ç', 'è', 'é', 'ê', 'ë', 'ì', 'í', 'î', 'ï', 'ð', 'ò', 'ó', 'ô', 'õ', 'ö', 'ù', 'ú', 'û', 'ü', 'ý', 'ÿ', '\'', ' '];
	
	const replaceWith = ['a', 'a', 'a', 'a', 'a', 'a', 'c', 'e', 'e', 'e', 'e', 'i', 'i', 'i', 'i', 'o', 'o', 'o', 'o', 'o', 'o', 'u', 'u', 'u', 'u', 'y', 'y', '_', '_'];
	
	let modifiedString = company.toLowerCase();
	
	findFor.forEach( (tag, i) => modifiedString = modifiedString.replace(new RegExp(tag, "g"), replaceWith[i]) )

	let login = "";
	login = this.state.username + '@' + modifiedString.replace(/[^a-z0-9\-_]/g,'-');
	
    this.setState(prevState => ({
      ...prevState,
	  login: login
    }));
  }

  render() {
	
    return (
	  <React.Fragment>
		<Grid container spacing={2}>
		  <Grid item xs={12} sm={12}>
			<h5 className="card-header">Pour obtenir votre login utilisateur, veuillez renseigner les informations ci-dessous :</h5>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="username"
			  label="Identifiant"
			  fullWidth
			  value={this.state.username}
			  onChange={this.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="company"
			  label="Société"
			  fullWidth
			  value={this.state.company}
			  onChange={this.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		  
		  <Grid item xs={12} sm={12} style={{marginTop: "20px"}}>
			<h5 className="card-header">L'obtention du login utilisateur se fait de façon automatique.</h5>
		  </Grid>
		  
		  <Grid item xs={12} sm={4} />
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="login"
			  label="Login"
			  fullWidth
			  InputProps={{
				readOnly: true,
				startAdornment: (
				  <InputAdornment position="start">
					<AccountBox />
				  </InputAdornment>
				),
			  }}
			  value={this.state.login}
			/>
		  </Grid>
		  <Grid item xs={12} sm={4} />
		  
		</Grid>
		
        <div className="modal-footer" style={{marginTop: "20px"}}>
          <button
            type="button"
            className="btn btn-outline-success waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              navigator.clipboard.writeText(this.state.login);
            }}
          >Copier</button>
          <button
            type="button"
            className="btn btn-outline-secondary waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              this.props.closeModal();
            }}
          >Annuler</button>
        </div>
	  </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
	
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InfosLogin);
