import {  PATCH, POST } from './../api';

export const getForwardInformation = (user_id) => {
  return POST('/forward', {
    user_id: user_id
  });
}

export const saveForwardConfig = ({user_id,forward_type, forwards}) => {
  return PATCH('/forward', {
    user_id: user_id,
    forward_type: forward_type,
    forwards: forwards
  });
}
