import React, { useState } from 'react';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined'
import { bindActionCreators } from 'redux';
import { addSelectedRoom, removeNewMessage } from 'actions/imActions.js';
import { useNavigate } from 'react-router';

function IM ({ addSelectedRoom, removeNewMessage }) {
  const [rooms] = useState([]);
  const navigate = useNavigate();

  return (
    <div className="card" id="dashBoardIMFrequents">
      <div className="card-body p-b-0">
        <h4 className="card-title">Messagerie instantanée</h4>
        <ul className="list-group list-group-flush">
          {
            rooms.map((room, index) => {
              return (
                <li style={{ cursor: 'pointer' }} key={index} className="list-group-item" onClick={async () => {
                  await addSelectedRoom(room.roomId);
                  await removeNewMessage();
                  return navigate('/instant-messaging')
                  }
                }>
                  {room.name} {(!isUndefined(room.messagesNb) && room.messagesNb !== 0) && <span className="badge badge-pill badge-danger"><h6 className='text-white' style={{ fontWeight: 'bold', margin: 0 }}>{room.messagesNb}</h6></span>}
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addSelectedRoom,
    removeNewMessage
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IM);
