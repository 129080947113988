import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { getFrequentContacts } from 'components/commons/api/contacts.js';
import { loadingFrequentContacts, fetchFrequentContacts, stopLoadingFrequentContacts } from 'actions/contactsActions.js';
import { ringback, composeNum } from 'actions/phoneActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Gravatar from 'react-gravatar';
import md5 from 'md5'
import { clickCall } from 'components/commons/helpers/phoneHelper';

class Frequent extends Component {
  async componentDidMount() {
    this.props.loadingFrequentContacts();
    try {
      const contacts = await getFrequentContacts();
      return this.props.fetchFrequentContacts(contacts.data || []);
    } catch (e) {
      return console.log(e);
    } finally {
      return this.props.stopLoadingFrequentContacts();
    }
  }

  render() {
    return (
      <div className="card" id="dashBoardFrequents">
        <div className="card-body p-b-0">
          <h4 className="card-title">Contacts fréquents</h4>
          <FrequentContainer
            contacts={this.props.contacts}
            call={this.props.call}
            state={this.props}
            composeNum={this.props.composeNum}
            phone={this.props.phone}
          />
        </div>
      </div>
    );
  }
}

const FrequentContainer = (props) => {
  if (props.contacts.loading) {
    return (
      <Preloader />
    );
  }

  if (isEmpty(props.contacts[0])) {
    return (
      <div className="text-center">
        <h5 className="text-muted">Aucun contact récent</h5>
      </div>
    );
  }

  return (
    (props.contacts[0]).map((cont, index) => {
      const contact = cont[0];

      if (index > 7) {
        return null;
      }

      return (
        <div className="col-md-6 col-lg-6 col-xlg-6 frequentItem" key={index}>          
          <div className="card">
            <div className="row">
              <div className="col-md-4 col-lg-3 text-center">
                {isUndefined(contact.mail) ? <Gravatar md5={md5(`${contact.firstname}${contact.lastname}`)} /> : <Gravatar email={contact.mail} />}
              </div>
              <div className="col-md-8 col-lg-9">
                <h5 className="box-title m-b-1">{contact.firstname} {contact.lastname}</h5>
                <h4 className="text-muted underlinedOnHover" onClick={() => {
                  const number = !isUndefined(contact.mobile) ? contact.mobile : contact.telephoneNumber;
                  return clickCall(number); 
                }}>{contact.mobile || contact.telephoneNumber || ""}</h4>
              </div>
            </div>
          </div>
        </div>
      );
    })
  );
}

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts.frequent.contacts,
    phone: state.kertelphone
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadingFrequentContacts,
    fetchFrequentContacts,
    stopLoadingFrequentContacts,
    ringback,
    composeNum
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Frequent);
