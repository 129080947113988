import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import {connect} from 'react-redux';
import {Bar, Line} from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { getStatsCallsInformation} from 'components/commons/api/admin/statsCalls';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import {notifyError} from 'components/commons/Toasts.js';

function rfc3339(d) {

    function pad(n) {
        return n < 10 ? "0" + n : n;
    }

    function timezoneOffset(offset) {
        var sign;
        if (offset === 0) {
            return "Z";
        }
        sign = (offset > 0) ? "-" : "+";
        offset = Math.abs(offset);
        return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
    }

    return d.getFullYear() + "-" +
        pad(d.getMonth() + 1) + "-" +
        pad(d.getDate()) + "T" +
        pad(d.getHours()) + ":" +
        pad(d.getMinutes()) + ":" +
        pad(d.getSeconds()) +
        timezoneOffset(d.getTimezoneOffset());
}

class StatsCallsGraph extends Component {

  constructor(props) {
    super(props);
	this.state = {
	  statsCalls: [],
	  statsCallsUsers: [],
	  isLoading: true,
	}
  }

  async componentDidMount() {
    try {
	  window.scrollTo(0, 0);
	  let numbers = [];
	  forEach(this.props.options.numbers, function(value, key) {
		if (!isEmpty(value.name)) numbers.push(value.name)
	  })

	  let startDate = rfc3339(new Date(this.props.options.startDate));
	  let endDate = rfc3339(new Date(this.props.options.endDate));

	  let datas = {
		"users": this.props.options.users,
		"numbers": numbers,
		"call_direction": this.props.options.typeCalls,
		"call_internal": this.props.options.internalCalls,
		"start_date": startDate,
		"end_date": endDate,
		"grain": this.props.options.grain,
		"rendering": this.props.options.rendering,
	  };

	  return await getStatsCallsInformation(datas).then((result) => {
		if (result.status !== 200) {
		  notifyError(result.detail);
		} else {
			this.setState({ statsCalls: Object.values(result.data), statsCallsUsers: Object.keys(result.data)} );
		}
	  })
	} catch (e) {
	  console.log(e);
	} finally {
      this.setState({ isLoading: false });
    }
  }

  render() {

    if (this.state.isLoading) {
      return (
        <Preloader />
      );
    }

	const statsCalls = this.state.statsCalls;

	let nameUsers = [];
	var keys = Object.keys(this.props.users);
	for(var i = 0; i < keys.length; i++) {
      var key = (keys[i]) ;
	  nameUsers[this.props.users[key].user_id] = this.props.users[key].firstname + ' ' + this.props.users[key].lastname;
	}

	return (
	  statsCalls.map((value, index) => {
		return (
		  <Container component="main" key={index}>
			<h2>{nameUsers[this.state.statsCallsUsers[index]]}</h2>
			<Grid container spacing={10} key={index}>
			  <Grid item xs={12} sm={6}>
				<Bar
				  data={value.barGraph}
				  width={100}
				  height={100}
				  options={{
					maintainAspectRatio: false
				  }}
				/>
			  </Grid>
			  <Grid item xs={12} sm={6}>
				<Line
				  width={100}
				  height={100}
				  data={value.lineGraph}
				/>
			  </Grid>
			</Grid>
		  </Container>
		)
	  })
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(StatsCallsGraph);
