import React, { Component, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';
import { showModal } from 'actions/modalActions';
import Gravatar from 'react-gravatar';
import { startEditingUser } from 'actions/admin/userActions.js';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import { green, red } from '@material-ui/core/colors';
import { fetchUser } from 'components/commons/helpers/admin/userHelper.js';
import { getCompany } from 'components/commons/helpers/admin/companyHelper.js';
import UserInfos from './UserInfos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import Table from '../../../commons/tables/Table';
import { isUndefined } from 'lodash';

library.add(faCheck, faTimes);

function UserTab ({ isLoading, company, startEditingUser, listUsers }) {

  const navigate = useNavigate();

  async function initCompagny() {
    await getCompany();
  }

  useEffect(function() {
    initCompagny()
  }, []);

  function format_number(numbers) {
    if (typeof numbers === "object") {
      forEach(numbers, function(value, index, numbers) {
        var first = value.substring(0, 2);
        if (first === "+3") {
          //.match(/.{1,2}/g).join(' ')
          numbers[index] = ("0" + value.slice(3, value.length));
        } else if (first === "33") {
          numbers[index] = ("0" + value.slice(2, value.length));
        }
      })
      return numbers.join(', ');
    } else {
      var first = numbers.substring(0, 2);
      if (first === "+3") {
        //.match(/.{1,2}/g).join(' ')
        numbers = ("0" + numbers.slice(3, numbers.length));
      } else if (first === "33") {
        numbers = ("0" + numbers.slice(2, numbers.length));
      }
      return numbers;
    }
  }

    if (isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    const columns = [
      { title: '', text: 'Avatar', dataField: 'avatar',
        formatter: (cell, rowData, rowIndex) => {
          return (<React.Fragment>{ !isEmpty(rowData.mail) && <Gravatar email={rowData.mail}/> }</React.Fragment>)
        }, csvExport: false
      },
      { title: '', text: 'Action', dataField: 'action',
        formatter: (cell, rowData) => {
          return (
            <div style={{display: "flex"}}>
              <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" tabIndex="0" type="button" title="Modifier" onClick={
                () => {
                  fetchUser(rowData.user_id);
                  startEditingUser(rowData.user_id);

                  return navigate('/admin/updateuser');
                }
              } >
                <span className="MuiIconButton-label"><span className="material-icons MuiIcon-root" aria-hidden="true">edit</span></span>
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
          );
        }, csvExport: false
      },
      { title: 'Civilité', text: 'Civilité', dataField: 'civility', formatter: (cell, rowData, rowIndex) => {
        return rowData.civility == 0 ? "" : rowData.civility == 1 ? "Madame" : rowData.civility == 2 ? "Mademoiselle" : "Monsieur";
      }, csvFormatter: (cell, rowData, rowIndex) => {
        return rowData.civility == 0 ? "" : rowData.civility == 1 ? "Madame" : rowData.civility == 2 ? "Mademoiselle" : "Monsieur";
      }},
      { title: 'Prénom', text: 'Prénom', dataField: 'firstname', csvFormatter: (cell, rowData, rowIndex) => {
        return isUndefined(rowData.firstname) ? "" : rowData.firstname;
      }},
      { title: 'Nom', text: 'Nom', dataField: 'name', csvFormatter: (cell, rowData, rowIndex) => {
        return isUndefined(rowData.name) ? "" : rowData.admin;
      }},
      { title: 'Admin', text: 'Admin', dataField: 'admin', formatter: (cell, rowData, rowIndex) => {
        return (rowData.administrator === true) ? <FontAwesomeIcon icon="check" color={green[300]} size="sm" /> : <FontAwesomeIcon icon="times" color={red[300]} size="sm" />;
      }, csvFormatter: (cell, rowData, rowIndex) => {
        return isUndefined(rowData.admin) ? "" : rowData.admin;
      }},
      { title: 'N° Long', text: 'N° Long', dataField: 'e164', formatter: (cell, rowData, rowIndex) => {
        return <div>{(rowData.e164 != null)? format_number(rowData.e164):""}</div>;
      }, csvFormatter: (cell, rowData, rowIndex) => {
        return isUndefined(rowData.e164) ? "" : rowData.e164;
      }},
      { title: 'N° Court', text: 'N° Court', dataField: 's164', formatter: (cell, rowData, rowIndex) => {
        return <div>{(rowData.s164 != null)? rowData.s164.join(', '):""}</div>;
      }, csvFormatter: (cell, rowData, rowIndex) => {
        return isUndefined(rowData.s164) ? "" : rowData.s164;
      }},
      { title: 'Fax', text: 'Fax', dataField: 'fax', formatter: (cell, rowData, rowIndex) => {
        return <div>{(rowData.fax != null)? format_number(rowData.fax):""}</div>;
      }, csvFormatter: (cell, rowData, rowIndex) => {
        return isUndefined(rowData.fax) ? "" : rowData.fax;
      }},
      { title: 'Mobile', text: 'Mobile', dataField: 'mobile', formatter: (cell, rowData, rowIndex) => {
        return <div>{(rowData.mobile != null) ? format_number(rowData.mobile) : ""}</div>;
      }, csvFormatter: (cell, rowData, rowIndex) => {
        return isUndefined(rowData.mobile) ? "" : rowData.mobile;
      }},
      { title: 'Adresse mail', text: 'Adresse mail', dataField: 'mail', csvFormatter: (cell, rowData, rowIndex) => {
        return isUndefined(rowData.mail) ? "" : rowData.mail;
      }},
    ];

    return (

      <Table
        datas={filter(listUsers, item => item.user_type === "1" || item.user_type === "2")}
        columns={columns}
        searchPlaceholder={"Chercher un utilisateur"}
        sizePerPage={25}
        CSVExport={"Utilisateurs"}
      />
    );
}

const mapStateToProps = (state) => {
  return ({
    phone: state.kertelphone,
    company: state.company,
    users: state.users
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal,
    startEditingUser
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTab);
