import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountBox from '@material-ui/icons/AccountBox';
import Dialpad from '@material-ui/icons/Dialpad';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import {fetchSviConfig, updateSviConfig} from 'components/commons/helpers/admin/sviHelper.js';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: green[100],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: green[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();
  
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

class SviConfigInfos extends Component {

  constructor(props) {
    super(props);
	
    this.state = {
	  id: this.props.node.id,
	  parent: this.props.node.parent,
	  name: this.props.node.name,
	  details: this.props.node.details,
	  comment: this.props.node.comment,
	  tts: this.props.node.tts,
	  text: this.props.node.text,
	  actionsWithFile: ['SAY', 'SAY_ONE_DIGIT', 'SAY_MULTI_DIGIT', 'CALL_MOH', 'HTTP_REQUEST_MULTI_DIGIT', 'HTTP_REQUEST'],
	  touch: this.props.infos.node.userKey,
	  actions: this.props.actions,
	  infos: {
		id: "",
		parent: "",
	  }
    }
	
	this.theme = createMuiTheme({
      palette: {
        primary: {
          main: green[400],
        },
        secondary: {
          main: green[600],
        },
      },
    });
	
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitTouch = this.handleSubmitTouch.bind(this);
	this.infosDetails = this.infosDetails.bind(this);
	this.handleOptions = this.handleOptions.bind(this);
	this.listFiles = this.listFiles.bind(this);
	this.getPostes = this.getPostes.bind(this);
	this.handleAutocomplete = this.handleAutocomplete.bind(this);
  }

  listFiles(elements, that){
	let listFiles = [];
	
	forEach(elements.files, function(file) {
	  listFiles.push(
		<tr key={file.name}>
		  <td>
			<FormControlLabel
			  name={file.name}
			  value={file.name}
			  control={
				<StyledRadio
				  inputProps={{
					'data-param': 'param1'
				  }}
				/>
			  }
			  label={file.name}
			  checked={file.name === elements.value && !that.state.tts}
			  onChange={that.handleOptions}
			/>
		  </td>
		  <td>
			{file.size}
		  </td>
		  <td>
			{file.lastupdate}
		  </td>
		</tr>
	  )
	})
	
	listFiles.push(
	  <tr key="all">
		<td colSpan="3">
		  <FormControlLabel
			name="all"
			value=""
			control={
			  <StyledRadio
				inputProps={{
				  'data-param': 'param1'
				}}
			  />
			}
			label="Tous les fichiers"
			checked={"" === elements.value || that.state.tts}
			onChange={that.handleOptions}
		  />
		</td>
	  </tr>
	)
	
	return listFiles;
  }
  
  getPostes(param) {
	let postes = [];
	
	if (isUndefined(this.props.users.datas)) return postes;
	
    forEach(this.props.users.datas, function(items) {
	  
	  let tty = !isUndefined(items.tty) ? items.tty : [];
		if (!isEmpty(tty)) {
		forEach(tty, function(poste) {
		  if (!isUndefined(poste.line_id)) postes.push({name:param, value: poste.line_id, label: poste.line_id});
		})
	  }
	})
    
    return postes;
  }
  
  getElementsIds(param) {
	let elements = [
	  {name:param, value: "", label: "Elements ID"}
	];
	
	if (isUndefined(this.props.nodes)) return elements;
	if (isUndefined(this.props.nodes.nodeDataArray)) return elements;
	
    forEach(this.props.nodes.nodeDataArray, function(items) {
	  
	  elements.push({name:param, value: items.id, label: items.name + ' (id : ' + items.id + ')'});
	  
	})
    
    return elements;
  }
  
  getElementsValue(param, id) {
	if (id !== "") {
	  let label = "";
	  if (!isUndefined(this.props.nodes.nodeDataArray)) {
		forEach(this.props.nodes.nodeDataArray, function(items) {
		  if (items.id === parseInt(id)) label = items.name + ' (id : ' + id + ')';
		})
	  }
	  
	  return {name:param, value: id, label: label};
	}
	
	return {name:param, value: "", label: "Elements ID"};
  }
  
  infosDetails () {
	
	const that=this;
	let form = [];
	
	forEach(that.state.details, function(elements, key) {
	  switch (key) {
		case 'param1':
		{
		  form.push(
			<table key={that.state.id + '_' +  key} style={{ marginLeft: "2%", width: "98%"}}>
			  <tbody>
				<tr>
				  <td>{elements.title}</td>
				</tr>
				{
				  elements.type === "files" &&
				  <tr>
					<td>
					  <RadioGroup defaultValue="" aria-label="svi_files" name="customized-radios">
						<table className="table-sviListFiles">
						  <thead>
							  <tr>
								  <th>Nom du fichier</th>
								  <th>Taille (Ko)</th>
								  <th>Dernière modification</th>
							  </tr>
						  </thead>
						  <tbody>
							{that.listFiles(that.state.details.param1, that)}
						  </tbody>
						</table>
					  </RadioGroup>
					</td>
				  </tr>				  
				}
				{
				  elements.type === "textarea" &&
				  <tr>
					<td>
					  <Grid item xs={12} sm={12}>
						<TextField
						  name="script"
						  label="Script"
						  multiline
						  variant="outlined"
						  rows={3}
						  fullWidth
						  style={{ marginTop: "2%", paddingRight: "2%"}}
						  inputProps={{
							'data-param': 'param1'
						  }}
						  value={that.state.details.param1.value}
						  onChange={that.handleOptions}
						/>
					  </Grid>
					</td>
				  </tr>
				}
				{
				  elements.type === "field" &&
				  <tr>
					<td>
					  <Grid item xs={12} sm={12}>
						<TextField
						  name="field1"
						  label=""
						  variant="outlined"
						  fullWidth
						  style={{ marginTop: "2%", paddingRight: "2%"}}
						  inputProps={{
							'data-param': 'param1'
						  }}
						  value={that.state.details.param1.value}
						  onChange={that.handleOptions}
						/>
					  </Grid>
					</td>
				  </tr>
				}
				{
				  elements.type === "field_digit" &&
				  <tr>
					<td colSpan={3}>
					  <Grid item xs={12} sm={12}>
						<TextField
						  name="field1"
						  label=""
						  fullWidth
						  style={{ width: "20%", marginTop: "2%", paddingRight: "2%"}}
						  inputProps={{
							maxLength: that.state.details.param1.maxLength,
							'data-param': 'param1',
						  }}
						  value={that.state.details.param1.value}
						  onChange={that.handleOptions}
						/>
					  </Grid>
					</td>
				  </tr>
				}
				{
				  elements.type === "select_next" &&
				  <tr>
					<td>
					  <table style={{ marginLeft: "2%", width: "30%"}}>
						<tbody>
						  <tr>
							<td>
							  <Select
								name="select"
								options={ that.getElementsIds('param1') }
								value={that.getElementsValue('param1', that.state.details.param1.value)}
								onChange={that.handleAutocomplete}
								/>
							</td>
						  </tr>
						</tbody>
					  </table>
					</td>
				  </tr>
				}
			  </tbody>
			</table>
		  );
		  break;
		}
	    case 'param2':
		{
		  if (isEmpty(elements.title)) break;
		  form.push(
			<table key={that.state.id + '_' +  key} style={{ marginTop: "8px", marginLeft: "2%", width: "98%"}}>
			  <tbody>
				<tr>
				  <td>{elements.title}</td>
				</tr>
				{
				  elements.type === "field" &&
				  <tr>
					<td>
					  <Grid item xs={12} sm={12}>
						<TextField
						  name="field2"
						  label=""
						  variant="outlined"
						  fullWidth
						  style={{ marginTop: "2%", paddingRight: "2%"}}
						  inputProps={{
							'data-param': 'param2'
						  }}
						  value={that.state.details.param2.value}
						  onChange={that.handleOptions}
						/>
					  </Grid>
					</td>
				  </tr>
				}
				{
				  elements.type === "field_digit" &&
				  <tr>
					<td colSpan={3}>
					  <Grid item xs={12} sm={12}>
						<TextField
						  name="field2"
						  label=""
						  fullWidth
						  style={{ width: "10%", marginLeft: "2%"}}
						  inputProps={{
							maxLength: that.state.details.param2.maxLength,
							'data-param': 'param2',
						  }}
						  value={that.state.details.param2.value}
						  onChange={that.handleOptions}
						/>
					  </Grid>
					</td>
				  </tr>
				}
				{
				  elements.type === "select" &&
				  <tr>
					<td>
					  <table style={{ marginLeft: "2%"}}>
						<tbody>
						  <tr>
							<td style={{ width: "50%"}}>
							  <div className="section">
								<Select
								  placeholder="Rechercher un poste"
								  options={that.getPostes('param2')}
								  value={that.state.details.param2.value}
								  onChange={that.handleAutocomplete}
								/>
							  </div>
							</td>
							<td>
							  <TextField
								name="select"
								value={that.state.details.param2.value}
								margin="normal"
								InputProps={{
								  readOnly: true,
								}}
								style={{marginLeft: "10%"}}
							  />
							</td>
							<td>
							  <IconButton aria-label="delete" onClick={() => { that.handleAutocomplete({name:"param2", value:""}) }}  style={{display: 'flex', flexDirection: 'row', marginTop: "20px" }}>
								<DeleteIcon fontSize="small" />
							  </IconButton>
							</td>
						  </tr>
						</tbody>
					  </table>
					</td>
				  </tr>
				}
				{
				  elements.type === "select_next" &&
				  <tr>
					<td>
					  <table style={{ marginLeft: "2%", width: "30%"}}>
						<tbody>
						  <tr>
							<td>
							  <Select
								name="select"
								options={ that.getElementsIds('param2') }
								value={that.getElementsValue('param2', that.state.details.param2.value)}
								onChange={that.handleAutocomplete}
								/>
							</td>
						  </tr>
						</tbody>
					  </table>
					</td>
				  </tr>
				}
			  </tbody>
			</table>
		  );
		  break;
		}
	    case 'param3':
		{
		  if (isEmpty(elements.title)) break;
		  form.push(
			<table key={that.state.id + '_' +  key} style={{ marginLeft: "2%", width: "98%"}}>
			  <tbody>
				<tr>
				  <td>{elements.title}</td>
				</tr>
				{
				  elements.type === "waitingMusic" &&
				  <tr>
					<td>
					  <RadioGroup defaultValue="" aria-label="svi_waittingMusic" name="customized-radios">
						<table className="table-sviListFiles">
						  <thead>
							  <tr>
								  <th>Nom du fichier</th>
								  <th>Taille (Ko)</th>
								  <th>Dernière modification</th>
							  </tr>
						  </thead>
						  <tbody>
							{that.listFiles(that.state.details.param3, that)}
						  </tbody>
						</table>
					  </RadioGroup>
					</td>
				  </tr>				  
				}
			  </tbody>
			</table>
		  );
		  break;
		}
		default:
	  }
	})
	
	return form;
  }
  
  handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const param = target.getAttribute('data-param');
	
	if (!isEmpty(param)) {
	  this.setState(prevState => ({
		...prevState,
		details: {
		  ...prevState.details,
		  [param]: {
			...prevState.details[param],
			value: value
		  }
		}
	  }));
	  
	  return;
	}
	
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  
  handleAutocomplete(data) {
	const param = !isUndefined(data.name) ? data.name : null;
	
	if (!isEmpty(param)) {
	  this.setState(prevState => ({
		...prevState,
		details: {
		  ...prevState.details,
		  [param]: {
			...prevState.details[param],
			value: data.value
		  }
		}
	  }));
	  
	  return;
	}
  }
  
  // Check data Svi Config
  checkDataSviConfig(infos) {
	switch (infos.name) {
	  case 'SAY':
	  {
		// Param1
		if (infos.tts && isEmpty(infos.text.trim())) return "[Enonce le texte] le texte est requis.";
		
		// Param2
		if (infos.details.param2.require && infos.details.param2.value === "") return "Le choix d'un prochain élément ID est requis.";
		
		break;
	  }
	  case 'SAY_ONE_DIGIT':
	  case 'SAY_MULTI_DIGIT':
	  {
		// Param1
		if (infos.tts && isEmpty(infos.text.trim())) return "[Enonce le texte] le texte est requis.";
		  
		// Param2
		if (infos.details.param2.require && infos.details.param2.value === "") return "La touche tapée est vide.";
		else if (infos.details.param2.value.length > infos.details.param2.maxLength) return "la taille touche tapée est incorrecte.";
		else if (infos.name === "SAY_ONE_DIGIT" && "0123456789*+#".indexOf(infos.details.param2.value) < 0) return "La touche tapée est incorrecte.";
		else if (infos.name === "SAY_MULTI_DIGIT" && !isEmpty(infos.details.param2.value.replace(/[0123456789*+]/g,''))) return "La suite de touche tapée est incorrecte.";
		
		break;
	  }
	  case 'LUA':
	  {
		// Param1
		if (infos.details.param1.require && infos.details.param1.value.trim() === "") return "Le script est requis.";
		
		break;
	  }
	  case 'CALL':
	  case 'CALL_MOH':
	  {
		// Param1
		if (infos.details.param1.require && !infos.details.param1.value.trim().match(/^(\+33|0033|0)[1-9]{1}[0-9]{8}$/g)) return "Le numéro d'appelle est incorrect.";
		else if (!isEmpty(infos.details.param1.value.trim()) && !infos.details.param1.value.trim().match(/^(\+33|0033|0)[1-9]{1}[0-9]{8}$/g)) return "Le numéro d'appelle est incorrect.";
		
		// Param2
		if (infos.details.param2.require && infos.details.param2.value.trim() === "") return "Le poste de l'appelant est requis.";
		
		// Param3
		if (infos.name === "CALL_MOH" && infos.tts && isEmpty(infos.text.trim())) return "[Enonce le texte] le texte est requis.";
		
		break;
	  }
	  case 'HTTP_REQUEST_MULTI_DIGIT':
	  case 'HTTP_REQUEST':
	  {
		// Param1
		if (infos.tts && isEmpty(infos.text.trim())) return "[Enonce le texte] le texte est requis.";
		
		// Param2
		if (infos.details.param2.require && infos.details.param2.value === "") return "L'url est requise.";
		
		break;
	  }
	  case 'SAY_DATE':
	  case 'SAY_DATETIME':
	  case 'SAY_TIME':
	  case 'SAY_NUMBER':
	  case 'SAY_DIGITS':
	  case 'SAY_PHONETIC':
	  {
		// Param1
		if (infos.details.param1.require && infos.details.param1.value === "") return "Le choix d'un prochain élément ID est requis.";
		
		break;
	  }
	  case 'SET_BUFFER':
	  {
		// Param1
		if (infos.details.param1.require && infos.details.param1.value.trim() === "") return "Le contenu du buffer est requis.";
		
		// Param2
		if (infos.details.param2.require && infos.details.param2.value === "") return "Le choix d'un prochain élément ID est requis.";
		
		break;
	  }
	  case 'JUMP':
	  {
		// Param1
		if (infos.details.param1.require && infos.details.param1.value === "") return "Le choix d'un prochain élément ID est requis.";
		
		break;
	  }
	  case 'EXIT':
		break;
	  default :
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: "Action inconnue!",
		  confirmButtonColor: 'LightSeaGreen',
		})
	}
	
    return "";
  }
  
  // Check data Svi Config
  checkKeySviConfig(infos) {
	switch (infos.name) {
	  case 'SAY':
	  case 'SAY_ONE_DIGIT':
	  case 'LUA':
	  {
		if (infos.userKey.length > 1) return "la taille touche tapée est incorrecte.";
		else if ("0123456789*+#".indexOf(infos.userKey) < 0) return "La touche tapée est incorrecte.";
		
		break;
	  }
	  case 'SAY_MULTI_DIGIT':
	  {
		if (!isEmpty(infos.userKey.replace(/[0123456789*+]/g,''))) return "La suite de touche tapée est incorrecte.";
		
		break;
	  }
	  default :
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: "Action de touche inconnue!",
		  confirmButtonColor: 'LightSeaGreen',
		})
	}
  }
  
  async handleSubmit(event) {
    event.preventDefault();
    try {
	  let infos = this.state;
	  infos["type"] = 'update';
	  
	  let msgCeckData = this.checkDataSviConfig(infos);
	  if (!isEmpty(msgCeckData)) {
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: msgCeckData,
		  confirmButtonColor: 'LightSeaGreen',
		})
	  }
	  
	  await updateSviConfig(this.props.menuId, infos);
	  await fetchSviConfig(this.props.menuId);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async handleSubmitTouch(event) {
    event.preventDefault();
    try {
	  let infos = {};
	  infos["type"] = 'update';
	  infos["id"] = this.props.infos.node.to;
	  infos["userKey"] = this.state.touch;
	  infos["onlyParent"] = true;
	  
      if (!isUndefined(this.props.nodes.nodeDataArray)) {
        forEach(this.props.nodes.nodeDataArray, function(details) {
          if (details.id === infos.id) {
            infos["name"] = details.name;
          }
        })
      }
	  
	  let msgCeckKey = this.checkKeySviConfig(infos);
	  if (!isEmpty(msgCeckKey)) {
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: msgCeckKey,
		  confirmButtonColor: 'LightSeaGreen',
		})
	  }
	  
	  await updateSviConfig(this.props.menuId, infos);
	  await fetchSviConfig(this.props.menuId);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
  
  async componentDidMount() {
	
	let id = this.props.infos.node.to;
	let parent = this.props.infos.node.from;
	
	this.setState(prevState => {
	  return {
		...prevState,
		infos: {
		  id: id,
		  parent: parent,
		}
	  }
	});
	
	await fetchUsers();
  }

  render() {
	
	if (this.props.infos.link) {
	  return (
		<form onSubmit={this.handleSubmitTouch}>
		  <Grid container spacing={2}>
			<Grid item xs={12} sm={1}>
			  <TextField
				name="parent"
				label="Père"
				fullWidth
				InputProps={{
				  readOnly: true,
				  startAdornment: (
					<InputAdornment position="start">
					  <AccountBox />
					</InputAdornment>
				  ),
				}}
				value={this.state.infos.parent}
			  />
			</Grid>
			<Grid item xs={12} sm={3} />
			<Grid item xs={12} sm={1}>
			  <TextField
				name="id"
				label="ID"
				fullWidth
				InputProps={{
				  readOnly: true,
				  startAdornment: (
					<InputAdornment position="start">
					  <AccountCircle />
					</InputAdornment>
				  ),
				}}
				value={this.state.infos.id}
			  />
			</Grid>
			<Grid item xs={12} sm={2} />
			<Grid item xs={12} sm={2}>
			  <TextField
				name="touch"
				label="Touche"
				fullWidth
				InputProps={{
				  startAdornment: (
					<InputAdornment position="start">
					  <Dialpad />
					</InputAdornment>
				  ),
				}}
				value={this.state.touch}
				onChange={this.handleOptions}
			  />
			</Grid>
			<Grid item xs={12} sm={3} />
		  </Grid>
		  <div className="modal-footer" style={{marginTop: "20px"}}>
			<button
			  type="button"
			  className="btn btn-outline-secondary waves-effect text-left"
			  data-dismiss="modal"
			  onClick={() => {
				this.props.closeModal();
			  }}
			>Annuler</button>
			<input
			  type="submit"
			  className="btn btn-success waves-effect text-left right-side-toggle"
			  value='Enregistrer'
			  />
		  </div>
		</form>
	  );
	}
	
    return (
      <form onSubmit={this.handleSubmit}>
		<Grid container spacing={2}>
		  <Grid item xs={12} sm={12}>
			<TextField
			  name="Nom"
			  label="Type d'action"
			  fullWidth
			  InputProps={{
				readOnly: true,
			  }}
			  value={this.state.actions[this.state.name]}
			/>
		  </Grid>
		  
		  <Grid item xs={12} sm={1}>
			<TextField
			  name="id"
			  label="ID"
			  fullWidth
			  InputProps={{
				readOnly: true,
				startAdornment: (
				  <InputAdornment position="start">
					<AccountCircle />
				  </InputAdornment>
				),
			  }}
			  value={Math.abs(parseInt(this.state.id))}
			  onChange={this.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={1} />
		  <Grid item xs={12} sm={1}>
			<TextField
			  name="parent"
			  label="Père"
			  fullWidth
			  InputProps={{
				readOnly: true,
				startAdornment: (
				  <InputAdornment position="start">
					<AccountBox />
				  </InputAdornment>
				),
			  }}
			  value={this.state.parent}
			  onChange={this.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={8} />
		  
		  <Grid item xs={12} sm={12}>
			<h5 className="card-header">Détails</h5>
		  </Grid>
		  
		  {this.infosDetails()}
		  
		  <Grid item xs={12} sm={12}>
			<h5 className="card-header">Commentaire</h5>
		  </Grid>
		  
		  <Grid item xs={12} sm={12}>
			<TextField
			  name="comment"
			  label=""
			  multiline
			  variant="outlined"
			  rows={2}
			  rowsMax={2}
			  fullWidth
			  style={{ marginLeft: "2%", paddingRight: "3%"}}
			  inputProps={{maxLength: 250}}
			  value={this.state.comment}
			  onChange={this.handleOptions}
			/>
		  </Grid>
		  
		  { this.state.actionsWithFile.indexOf(this.state.name) !== -1 &&
			<React.Fragment>
			  <Grid item xs={12} sm={12}>
				<h5 className="card-header">Enonce le texte
				  <span style={{ fontSize: "12px", marginLeft: "2px"}}>(remplace le fichier par le texte ci-dessous)</span>
				</h5>
			  </Grid>
			  
			  <Grid item xs={12} sm={12}>
				<table style={{ marginLeft: "2%"}}>
				  <tbody>
					<tr>
					  <td style={{width: "10%"}}>
						<FormLabel component="legend" style={{ float: "left", marginTop: "20px"}}>Activer</FormLabel>
						<FormControlLabel
						  style={{ float: "right" }}
						  control={
							<Switch name="tts" checked={this.state.tts} onChange={this.handleOptions} />
						  }
						/>
					  </td>
					  <td style={{width: "90%"}}>
						<TextField
						  name="text"
						  label=""
						  multiline
						  variant="outlined"
						  rows={2}
						  rowsMax={2}
						  fullWidth
						  style={{paddingRight: "2%"}}
						  inputProps={{maxLength: 500}}
						  value={this.state.text}
						  onChange={this.handleOptions}
						/>
					  </td>
					</tr>
				  </tbody>
				</table>
			  </Grid>
			</React.Fragment>
		  }
		  
		</Grid>
		
        <div className="modal-footer" style={{marginTop: "20px"}}>
          <button
            type="button"
            className="btn btn-outline-secondary waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              this.props.closeModal();
            }}
          >Annuler</button>
          <input
            type="submit"
            className="btn btn-success waves-effect text-left right-side-toggle"
            value='Enregistrer'
            />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
	
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SviConfigInfos);
