import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { fetchInterceptions } from 'components/commons/helpers/admin/interceptionGroupHelper.js';
import { startEditingInterception } from 'actions/admin/interceptionGroupActions.js';
import InterceptionGroupTab from './InterceptionGroupTab.js';

class InterceptionGroup extends Component {
  componentDidMount() {
    fetchInterceptions();
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Groupe d'interception"]} title="Groupe d'interception" addGi={true} />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" role="tab" href="#gi" onClick={fetchInterceptions}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Groupes d'interception
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" id="gi" role="tabpanel">
                <InterceptionGroupTab
                  isLoading={this.props.interceptionGroup.isLoading}
                  interception={this.props.interceptionGroup.datas}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    interceptionGroup: state.interception
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingInterception,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InterceptionGroup);
