/* LOADING INCOMING FAX*/
export const loadingIncomingFax = () => { return { type: 'LOADING_FAX_INCOMING' } };
export const loadingUserIncomingFax = () => { return { type: 'LOADING_USER_FAX_INCOMING' } };

/* STOP LOADING INCOMING FAX*/
export const stopLoadingIncomingFax = () => { return { type: 'STOP_LOADING_FAX_INCOMING' } };

/* LOADING OUTGOING FAX*/
export const loadingOutgoingFax = () => { return { type: 'LOADING_FAX_OUTGOING' } };

/* STOP LOADING OUTGOING FAX*/
export const stopLoadingOutgoingFax = () => { return { type: 'STOP_LOADING_FAX_OUTGOING' } };

/* FETCH */
export const fetchFax = (fax) => { return { type: 'FETCH_FAX_LIST', payload: fax } };
export const fetchOutgoingFax = (fax) => { return { type: 'FETCH_OUTGOING_FAX_LIST', payload: fax } };

/* SEEN / UNSEEN  */
export const fetchUnseenFax = (fax) => { return { type: 'FETCH_UNSEEN_FAX', payload: fax } };
export const hasReadUnseenFax = () => { return { type: 'HAS_READ_UNSEEN_FAX' } };