import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchRingtonesGeneral } from 'components/commons/helpers/admin/ringtoneGeneralHelper.js';
import { getCompany } from 'components/commons/helpers/admin/companyHelper.js';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import RingtoneGeneralTab from './RingtoneGeneralTab.js';

class RingtoneGeneral extends Component {
  componentDidMount() {
    fetchUsers();
	getCompany();
    fetchRingtonesGeneral();
  }
  
  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Sonnerie générale"]} title="Sonnerie générale" addGr="true" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#gi" role="tab" onClick={fetchRingtonesGeneral}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Sonneries générales
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" id="gi" role="tabpanel">
                <RingtoneGeneralTab
                  isLoading={this.props.ringtoneGeneral.isLoading}
                  ringtoneGeneral={this.props.ringtoneGeneral.datas}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    ringtoneGeneral: state.ringtoneGeneral
  }
}

export default connect(mapStateToProps, null)(RingtoneGeneral);
