import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Preloader from '../Preloader.js';
import Moment from 'react-moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clickCall } from 'components/commons/helpers/phoneHelper';
import {ringback} from 'actions/phoneActions.js';
import isUndefined from 'lodash/isUndefined'
import { useNavigate } from 'react-router';

function NotificationsContainer ({
    isLoading,
    context,
    notifications,
    title,
    call,
    phone,
    session,
    urlSeeMore,
    seeMore
  }) {

    const navigate = useNavigate();

  function playVoiceMail(audioId) {
    var myMessage = document.getElementById(audioId);
    myMessage.play();
  }

  return (
    <div className="dropdown-menu mailbox">
      <ul>
        <li>
          <div className="drop-title">{title}</div>
        </li>
        <li>
        <NotificationsRender
          context={context}
          isLoading={isLoading}
          notifications={notifications.datas}
          call={call}
          phone={phone}
          session={session}
          playVoiceMail={playVoiceMail}
         />
        </li>
        <li>
          <button onClick={() => (navigate(urlSeeMore)) } className="nav-link text-center"> {seeMore} <i className="fa fa-angle-right"></i> </button>
        </li>
      </ul>
    </div>
  );
}

const NotificationsRender = (props) => {
  if (props.isLoading) {
    return (
      <div>
        <Preloader />
      </div>
    );
  }

  if (isEmpty(props.notifications)) {
    return (
      <div className="card-body">
        <h5 className="text-muted">Aucune notification</h5>
      </div>
    );
  }

  return (
    <div className="message-center">
      {props.notifications.map((notification, index) => {
        return (<div className='topbarNotifContainer' key={index}>
          <div className="mail-contnet">
            <h5>{notification.src_name || notification.src_number || notification.caller_number}</h5>
            <span className="time">
              {props.context === 'faxs' && <React.Fragment>Le <Moment format="DD MMMM YYYY à HH:mm:ss">{notification.date_start || "-"}</Moment></React.Fragment>}
              {props.context !== 'faxs' && <React.Fragment>Le <Moment unix format="DD MMMM YYYY à HH:mm:ss">{notification.date || "-"}</Moment></React.Fragment>}
            </span>
          </div>
          <ActionsRender
            context={props.context}
            number={notification.src_number}
            name={notification.src_name}
            call={props.call}
            phone={props.phone}
            notification={notification}
            session={props.session}
            playVoiceMail={props.playVoiceMail}
            />
        </div>);
      })}
    </div>
  );
}

const ActionsRender = (props) => {

  const {number, name, notification, session, playVoiceMail} = props;

  if ((props.context === 'calls') && (props.phone.hasWebPhone)) {
    return (
      <div id="actions">
        <div className="btn btn-success btn-circle start_call" onClick={ () => {
              clickCall(number, name);
              }}><i className="fa fa-phone"></i></div>
      </div>
    );
  }

  if (props.context === 'voices') {
    const voicemailsStyles={ width: "30px", height: "30px", padding: "4px 0 0 2px" };

    return (
      <div id="actions">
        <audio style={{ width: '1px', opacity: 0 }} id={`player_${notification.id}`} controls src={`${session.apiUrl}/mevo/message?message_id=${notification.id}&u=${session.datas.data.user_id}`} type="audio/mp3"></audio>
        <button
          className="btn btn-warning btn-circle"
          style={voicemailsStyles}
          onClick={(e) => { e.preventDefault(); playVoiceMail(`player_${notification.id}`); return null; }}
        ><i className="fa fa-play"></i></button>
        {(!isUndefined(props.phone) ? props.phone.hasWebPhone : false) && <div
          className="btn btn-success btn-circle"
          style={voicemailsStyles}
          onClick={ () => { return clickCall(props, number, name); }}
        ><i className="fa fa-phone"></i></div>}
      </div>
    );
  }

  if (props.context === 'chats') {
    return (
      <div id="actions">
        <div className="btn btn-info btn-circle"><i className="fa fa-comment"></i></div>
      </div>
    );
  }

  if (props.context === 'faxs') {
    return (
      <div id="actions">
        {notification.state === 4 && <div className="btn btn-info btn-circle"><i className="fa fa-download"></i></div>}
      </div>
    );
  }

  return (
    <div id="actions"></div>
  );
}

const mapStateToProps = (state) => {
  return ({
    phone: state.kertelphone,
    session: state.kertelSession
  })
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    ringback
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
