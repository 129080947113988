import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import {connect} from 'react-redux';
import { isUndefined } from 'lodash';
import Table from 'components/commons/tables/Table.js'

class ColleaguesTab extends Component {
  render() {
    const that = this;

    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    const columns = [
      { title: '', csvExport: false, dataField: 'action',
      formatter: (cell, rowData, rowIndex) => (<div style={{
          display: "table",
          width: "100%",
          textAlign: "right"
        }}>
        {
          ((rowData.telephone != null || rowData.mobile != null) && !isUndefined(that.props.phone) && that.props.phone.hasWebPhone) &&
            <div className="btn btn-success btn-circle" onClick={(e) => {
                  e.preventDefault();
                  
                  that.props.handleNumberModal(true, rowData.telephone, rowData.mobile, `${rowData.firstname} ${rowData.lastname}`)
                }}>
                <i className="fa fa-phone"></i>
              </div>
        }
      </div>)},
      { title: 'Civilité', text: 'Civilité', dataField: 'civility',
      lookup: {
        'mr': 'Monsieur',
        'ms': 'Mademoiselle',
        'mrs': 'Madame'
      }},
      { title: 'Prénom', text: 'Prénom', dataField: 'firstname' },
      { title: 'Nom', text: 'Nom', dataField: 'lastname', defaultSort: 'asc'},
      { title: 'Fixe', text: 'Fixe', dataField: 'telephone'},
      { title: 'Mobile', text: 'Mobile', dataField: 'mobile'},
      { title: 'Adresse mail', text: 'Adresse mail', dataField: 'mail'},
      { title: 'Fax', text: 'Fax', dataField: 'fax'},
      { title: 'Code postal', text: 'Code postal', dataField: 'postalcode'},
      { title: 'Ville', text: 'Ville', dataField: 'city'}
    ];



    return (
      <Table
        datas={this.props.contacts}
        columns={columns}
        searchPlaceholder={"Chercher un contact"} />
    );
  }
}

const mapStateToProps = (state) => {
  return ({phone: state.kertelphone})
}

export default connect(mapStateToProps, null)(ColleaguesTab);
