import React, { Component } from 'react';
import { getCompanyContacts, getUserContacts } from 'components/commons/api/contacts';
import Breadcrumb from 'components/commons/Breadcrumb.js';

class Downloads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      companyContacts: [],
      userContacts: [],
      isCreating: false
    }

    this.fetchCompanyContacts = this.fetchCompanyContacts.bind(this);
    this.fetchUserContacts = this.fetchUserContacts.bind(this);
  }

  componentDidMount() {
    this.fetchCompanyContacts();
  }

  async fetchCompanyContacts() {
    this.setState({ isLoading: true });

    try {
      await getCompanyContacts().then((result) => {
        this.setState({ companyContacts: result.datas })
      })
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async fetchUserContacts() {
    this.setState({ isLoading: true });

    try {
      await getUserContacts().then((result) => {
        this.setState({ userContacts: result.datas })
      })
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={['Téléchargements']} title='Téléchargements' />
          </div>
        </div>
      </div>
    );
  }
}

export default Downloads;
