import React, { Component } from 'react';
import {connect} from 'react-redux';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';
import Gravatar from 'react-gravatar';
import { createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';

class StatsCallsUsers extends Component {

  constructor(props) {
    super(props);
	this.theme = createMuiTheme({
      palette: {
        primary: {
          main: green[400],
        },
        secondary: {
          main: green[600],
        },
      },
    });
  }

  componentDidMount() {
	window.scrollTo(0, 0);
  }

  tableRef = React.createRef();

  render() {
	if (this.props.isLoading) {
	  return (
		<table className="table">
			<tbody>
				<tr>
					<td colSpan={10}><Preloader /></td>
				</tr>
			</tbody>
		</table>
	  );
	}

	return (
	  <MuiThemeProvider theme={this.theme}>
		<MaterialTable
		  columns={[
			{
			  title: '', field: 'avatar', cellStyle: {textAlign: "center"},
			  render: rowData => (
				<Gravatar email={rowData.mail}/>
			  )
			},
			{ title: 'Prénom', field: 'firstname'},
			{ title: 'Nom', field: 'lastname'},
			{ title: 'N° Court', field: 's164'},
			{
			  title: 'N° Long',
			  field: 'e164',
			  render: rowData => {
				let numbers = '';
				if (!isEmpty(rowData.e164)) {
				  for (var i=0;i<rowData.e164.length;i++){
					numbers += rowData.e164[i] + ' ';
				  }
				}

				return numbers;
			  }
			},
			{
			  title: '', field: 'user_type',
			  lookup: {
				'1': 'Utilisateur',
				'2': 'Poste opérateur',
				'3': "Centre d'appel",
				'4': "Utilisateur et poste opérateur",
				'5': "Centre d'appel",
				'6': "Messagerie de groupe",
				'7': "File d'attente",
				'8': "Sonnerie générale",
				'9': "Ipbx",
			  }
			},
		  ]}
		  data={this.props.users}
		  title=""
		  localization={
			{
			  toolbar: {
				searchPlaceholder: 'Rechercher',
				nRowsSelected: '{0} Utilisateur(s) sélectionné(s)'
			  },
			  body: {
				emptyDataSourceMessage: 'Aucun enregistrement à afficher',
				filterRow: {
					filterTooltip: 'Filtre'
				}
			  },
			  pagination: {
  				labelRowsPerPage: "Utilisateurs par page",
  				labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
			  },
			}
		  }
		  options={{
  			pageSize: 10,
  			actionsColumnIndex: -1,
  			selection: true,
		  }}
		  tableRef={this.tableRef}
		  onSelectionChange={(rows) => {
  			let users = [];
  			rows.map((user, key) => { return users.push(user.user_id); });
  			this.props.handleOptionsUsers(users)
		  }}
		  actions={[
			{
			  tooltip: 'Supprimer tous les utilisateurs sélectionnés',
			  icon: 'delete',
			  onClick: (evt, data) => this.tableRef.current.onAllSelected(false)
			}
		  ]}
		/>
	  </MuiThemeProvider>
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(StatsCallsUsers);
