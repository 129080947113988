import React, { Component, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { createMevo  } from 'components/commons/helpers/admin/mevoGroupHelper.js';
import { stopEditingMevo } from 'actions/admin/mevoGroupActions.js';
import Preloader from 'components/commons/Preloader.js';
import EditMevoGroupInfos from './EditMevoGroupInfos.js';
import EditMevoGroupMembers from './EditMevoGroupMembers.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

function CreateMevoGroup ({ mevoGroup, url, users, company }) {

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [expiredLicence, setExpiredLicence] = useState(false);
	const [mevoGroupState, setMevoGroupState] = useState({
        "name": '',
        "password": '',
        "s164": [],
        "members": [],
		"dataMembers": [],
      });

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

	setMevoGroupState(prevState => ({
		...prevState,
		[name]: value
	}));
  }

  function handleOptionsNumbers(type, number) {

	if (type === 'addShort' && !isUndefined(number)) mevoGroupState.s164.push(number.short);
	else if (type === 'delShort' && !isUndefined(number)) {
	  var index = mevoGroupState.s164.indexOf(number[0]);
	  if (index > -1) mevoGroupState.s164.splice(index, 1);
	}
  }

  async function componentDidMount() {
	await new Promise((resolve, reject) => {
	  fetch(
		url + '/license', {
		  method: 'post',
		  headers: {
			'Content-Type':'application/json',
			'auth-token': `${localStorage.getItem('_k')}`
		  },
		  body: JSON.stringify({
			"service" : "mevoGroup"
		  })
		}
	  )
		.then(response => response.json())
		.then(result => {
		  if (!isUndefined(result.status) && result.status !== 200) {
			return swal({
			  type: 'error',
			  icon: "error",
			  title: 'Oops...',
			  text: result.detail,
			  confirmButtonColor: 'LightSeaGreen',
			})
		  }
		  
		  if (!isUndefined(result.data)) {
			if (!isUndefined(result.data.available)) {
			  if (result.data.available === 0) {
				return setExpiredLicence(true);
			  }
			}
		  }
		  
		  var dataMembers = [];
		  forEach(users.datas, function(details) {
			if (details.user_type === "1" || details.user_type === "2") {
			  let user = {
				"user_id": details.user_id,
				"mail": !isUndefined(details.mail) ? details.mail : '',
				"firstname": !isUndefined(details.firstname) ? details.firstname : '',
				"lastname": !isUndefined(details.lastname) ? details.lastname : '',
				"group_id": details.group_id,
				"location_id": details.location_id,
				"user_type" : details.user_type,
				tableData: { checked: false}
			  };
			  
			  dataMembers.push(user);
			}
		  })

		  setMevoGroupState(prevState => ({
			  ...prevState,
			  dataMembers: dataMembers
		  }))
		  
		  setIsLoading(false);
		})
	})
  }

  async function addMevoGroup(){
	let info = {
	  "name": '',
	  "password": '',
	  "s164": [],
	  "members": [],
	};

	if (!isUndefined(mevoGroupState.name)) info.name = mevoGroupState.name;
	if (!isUndefined(mevoGroupState.s164)) info.s164 = mevoGroupState.s164;
	if (mevoGroupState.password !== "********") info.password = mevoGroupState.password;
	forEach(mevoGroupState.dataMembers, function(details) {
	  if (details.tableData.checked === true) {
		info.members.push(details.user_id);
	  }
	})
	
	let listMevoGroup = [];
	if (!isUndefined(mevoGroup.datas)) {
	  mevoGroup.datas.forEach((details, i) => {
		listMevoGroup.push(details.name);
	  })
	}

	if (isEmpty(info.name)) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: 'Le nom de la messagerie est invalide',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	else if (listMevoGroup.includes((info.name))) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: 'Le nom de la messagerie existe déjà',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	else if (isEmpty(info.password)|| !(parseFloat(info.password) === info.password >>> 0) || info.password.length < 6 ) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: 'Le mot de passe de la messagerie est invalide',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}

	try {
      await createMevo(info);
	  navigate('/admin/mevogroup');
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }

	if (expiredLicence) {
	  return (
		<div className="col-lg-12">
		  <div className="card">
			<div className="card-body p-b-0">
            <Breadcrumb routes={["Messagerie de Groupe", "Création de Messagerie de Groupe"]} url="/admin/mevogroup" title="Création de Messagerie de Groupe" />
			  <ul className="nav nav-tabs customtab" role="tablist">
				<li className="nav-item">
				  <a className="nav-link active" data-toggle="tab" href="#infos" role="tab" onClick={fetchUsers}>
					<span className="hidden-sm-up">
					  <i className="ti-home"></i>
					</span>
					<span className="hidden-xs-down">
					  Information générales
					</span>
				  </a>
				</li>
			  </ul>
			  <div className="tab-content">
				<div className="tab-pane active p-20" role="tabpanel" id="infos">
				  <div className="col-lg-12 text-center">Le nombre maximum de licences a été atteint.</div>
				</div>
			  </div>
            </div>
          </div>
        </div>
      )
	}
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);

    if (isEmpty(mevoGroupState)) return navigate('/admin/mevogroup');

	let numbers = {
	  'short' : [],
	  'lenghShort' : company.short_length
	};

    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Messagerie de Groupe", "Création de Messagerie de Groupe"]} url="/admin/mevogroup" title="Création de Messagerie de Groupe" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Information générales
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Gestion des membres
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
                <EditMevoGroupInfos
                  options={mevoGroupState}
                  handleOptions={handleOptions}
                  handleOptionsNumbers={handleOptionsNumbers}
                  numbers={numbers}
                />
              </div>
              <div className="tab-pane p-20" role="tabpanel" id="users">
                <EditMevoGroupMembers
                  dataMembers={mevoGroupState.dataMembers}
                  company={company}
                />
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/mevogroup');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={addMevoGroup} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    mevoGroup: state.mevoGroup,
	url: state.kertelSession.apiUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingMevo
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMevoGroup);
