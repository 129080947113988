import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSviMenus, updateSviMenu  } from 'components/commons/helpers/admin/sviHelper.js';
import { stopEditingSviMenu } from 'actions/admin/sviActions.js';
import Preloader from 'components/commons/Preloader.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useNavigate } from 'react-router';

function EditSviMenu ({ svi }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [svis, setSvis] = useState({
    "menu_id": '',
    "comment": '',
  });

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setSvis(...svis, {
      svis,
      [name]: value
    })
  }

  async function updateSviMenu(){
    let info = {
      "comment": '',
    };
	
	  if (!isUndefined(svis.comment)) info.comment = svis.comment;
	
    try {
      await updateSviMenu(svi.editing.menuId, info);
      navigate('/admin/svi');
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }

  async function init() {
    if (svi.editing.isEditing) {
      await fetchSviMenus(svi.editing.menuId);

      setIsLoading(false);
      setSvis({
        ...svis,
        "comment": svis.menus[0].comment,
      })
    }
  }
  
  useEffect(function() {
    init();
  }, [])
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
    if (isEmpty(svis)) return navigate('/admin/svi');

    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Menus SVI", "Modification du menu svi"]} url="/admin/svi" title="Modification du menu svi" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Information générales
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
				<Container component="main">
				  <Grid container spacing={2}>
					<Grid item xs={12} sm={2}>
					  <TextField
						name="menu_id"
						label="Identifiant menu"
						fullWidth
						InputProps={{
						  readOnly: true,
						  startAdornment: (
							<InputAdornment position="start">
							  <AccountCircle />
							</InputAdornment>
						  ),
						}}
						value={svi.editing.menuId}
						onChange={handleOptions}
					  />
					</Grid>
					<Grid item xs={12} sm={10} />
					<Grid item xs={12} sm={4}>
					  <TextField
						name="comment"
						label="Commentaire"
						multiline
						variant="outlined"
						rows={12}
						rowsMax={4}
						fullWidth
						value={svis.comment}
						onChange={handleOptions}
					  />
					</Grid>
					<Grid item xs={12} sm={8} />
				  </Grid>
				</Container>
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/svi');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={updateSviMenu} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = (state) => {
  return {
    svi: state.svi
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingSviMenu
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSviMenu);
