/* CREATE CONTACT FORM */

export const clearCreateContactForm = () => {
  return { type: 'CLEAR_CREATE_CONTACT_FORM' }
};

export const clearCreateBillForm = () => {
  return { type: 'CLEAR_CREATE_BILL_FORM' }
};

export const clearCreateCompanyBillForm = () => {
  return { type: 'CLEAR_CREATE_COMPANY_BILL_FORM' }
};
