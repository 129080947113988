import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCallLog } from 'components/commons/helpers/phoneHelper.js';
import { getVoiceMails } from 'components/commons/helpers/voiceMailHelper.js';
import { getListIncomingFaxes } from 'components/commons/helpers/faxHelper.js';
import { loadingNewMessages, fetchNewMessages, stopLoadingNewMessages } from 'actions/inboxActions.js';
import RecentsTab from 'components/views/phone/RecentsTab';
import MailsTab from 'components/views/phone/MailsTab';
import FaxListTab from 'components/views/fax/FaxListTab';
import isUndefined from 'lodash/isUndefined';

class Inbox extends Component {
  render() {
    const minitoken = localStorage.getItem('_mt');
      
    return (
      <div className="card">
        <div className="card-body p-b-0">
          <ul className="nav nav-tabs customtab" role="tablist">
            <li className="nav-item" onClick={getCallLog}>
              <a className="nav-link active" data-toggle="tab" href="#calls" role="tab" onClick={getCallLog}>
                <span className="hidden-sm-up"><i className="ti-user"></i></span>
                 <span className="hidden-xs-down">Journal d'appels</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#voiceMails" role="tab" onClick={getVoiceMails}>
                <span className="hidden-sm-up">
                  <i className="ti-email"></i>
                </span>
                <span className="hidden-xs-down">Messagerie vocale</span>
              </a>
            </li>
            <li className="nav-item" onClick={getListIncomingFaxes}>
              <a className="nav-link" data-toggle="tab" href="#incomingFax" role="tab">
                <span className="hidden-sm-up">
                  <i className="ti-email"></i>
                </span>
                <span className="hidden-xs-down">Fax</span>
              </a>
            </li>
          </ul>
          <div className="tab-content callsLogsDashboard">
            <div className="tab-pane  p-20 active show" id="calls" role="tabpanel">
              <RecentsTab
                isLoading={!isUndefined(this.props.phone) ? this.props.phone.calls.both.isLoading : false}
                recents={!isUndefined(this.props.phone) ? this.props.phone.calls.both.datas : []}
                type={"calls"}
                hideSearch={true}
                hideCSVExport={true}
                sizePerPage={4}
              />
            </div>
            <div className="tab-pane p-20" id="voiceMails" role="tabpanel">
              <MailsTab
                isLoading={this.props.voiceMails.isLoading}
                mails={this.props.voiceMails.datas}
                search={false}
                rowsPag={5}
                type={"dashboard"}
                minitoken={minitoken}
              />
            </div>
            <div className="tab-pane p-20" id="incomingFax" role="tabpanel">
              <FaxListTab
                faxs={this.props.fax.incomings.datas}
                isLoading={this.props.fax.incomings.isLoading}
                type={"incomings"}
                search={false}
                selection={false}
                rowsPag={5}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    phone: state.kertelphone,
    fax: state.fax,
    voiceMails: state.voiceMail
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadingNewMessages,
    fetchNewMessages,
    stopLoadingNewMessages
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
