import React, {Component} from "react";
import {connect} from 'react-redux';
import forEach from 'lodash/forEach';

class EditProfileCallsZones extends Component {

  constructor(props) {
    super(props);

    this.state = {
	  isLoading: true,
    };
	
	this.infosZone = this.infosZone.bind(this);
	this.locationZone = this.locationZone.bind(this);
  }

  infosZone (type) {
	
	const that=this;
	let form = [];
	
	switch (type) {
	  case 'title':
	  {
		forEach(this.props.zones, function(elements, key) {
		  form.push(
			<th style={{ margin: "2%", width: "12.5%"}}>
			  {key}
			  <input type="checkbox" onClick={that.props.handleAllChecked} value="checkedall" dataZone={key}  style={{ float: "right"}}/>
			</th>
		  );
		})
		break;
	  }
	  case 'body':
	  {
		forEach(this.props.zones, function(elements, key) {
		  form.push(
			<td style={{ margin: "2%", width: "12.5%", verticalAlign: "top"}}>
			  {that.locationZone(elements, key, that)}
			</td>
		  );
		})
		break;
	  }
	  default:
		break;
	}
	
	return form;
  }
  
  locationZone(elements, zone, that){
	let locationZone = [];
	
	forEach(elements, function(details, key) {
	  locationZone.push(
		<li style={{listStyleType: "none"}}>
		 <input key={details.id} onClick={that.props.handleCheckChieldElement} type="checkbox" checked={details.isChecked} dataZone={zone} dataLocation={key} /> {key} <span style={{color:"#ff0000", fontFamily: "verdana"}}>{details.id}</span>
		</li>
	  )
	})
	
	return locationZone;
  }
  
  render() {
    return (
	  <table  className="table-sviListFiles" style={{ marginLeft: "2%", width: "100%"}}>
		<tr>
		  {this.infosZone("title")}
		</tr>  
		<tr>
		  {this.infosZone("body")}
		</tr>
	  </table>
	);
  }
}

const mapStateToProps = (state) => {
  return {
    profileCalls : state.profileCalls
  }
}

export default connect(mapStateToProps, null)(EditProfileCallsZones);
