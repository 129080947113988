import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { bindActionCreators } from 'redux';
import SviFilesTab from './SviFilesTab.js';
import { fetchSviFiles } from 'components/commons/helpers/admin/sviHelper.js';

class SviFiles extends Component {
  
  async componentDidMount() {
    await fetchSviFiles(this.props.svi.configuring.menuId);
  }
    
  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={[
              "Menus SVI",
              (<span>Fichiers du menu svi N° : 
                <span className="btn btn-warning react-bs-table-del-btn" style={{
                  padding: "2px",
                  marginLeft: "5px"
                }}>{this.props.svi.configuring.menuId}</span>
              </span>)
            ]} url="/admin/svi" title="Fichiers du menu svi"  addSviFile={true} />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#users" role="tab" onClick={() => fetchSviFiles(this.props.svi.configuring.menuId)}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Liste des fichiers
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="users" role="tabpanel">
                <SviFilesTab
                  menuId={this.props.svi.configuring.menuId}
                  history={this.props.history}
                  isLoading={this.props.svi.files.isLoading}
                  files={this.props.svi.files.data}
                  url={this.props.url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    svi: state.svi,
    url: state.kertelSession.apiUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(SviFiles);
