import React, { useEffect, useState } from 'react';
import NotificationsContainer from './navbar/NotificationsContainer.js';
import { showModal, hideModal } from 'actions/modalActions.js';
import { setCallsAsSeen } from 'components/commons/api/phone.js';
import { loadingNotifications, stopLoadingNotifications, addSidebarNotification } from 'actions/notificationsActions.js';
import { fetchCallLog, fetchUnseenCalls, hasReadUnseenCalls, hangUp, dtmf, hold, unHold, mute, unMute } from 'actions/phoneActions.js';
import { hasReadUnseenVoiceMails } from 'actions/voiceMailActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { getCallLog } from 'components/commons/helpers/phoneHelper';
import { getListIncomingFaxes } from 'components/commons/helpers/faxHelper';
import { getVoiceMails } from 'components/commons/helpers/voiceMailHelper';
import { removeNewMessage } from 'actions/imActions.js';
import CallTimer from 'components/commons/CallTimer.js';
import DTMF from 'components/commons/DTMF.js'
import { useNavigate } from 'react-router';

function NavBar({
  loadingNotifications,
  stopLoadingNotifications,
  ToIP,
  hangUp,
  addSidebarNotification,
  phone,
  hasReadUnseenCalls,
  notifications,
  voicemails,
  removeNewMessage,
  hold,
  unHold,
  mute,
  unMute,
  im,
  fax }) {

  const [showDTMF, setShowDTMF] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      loadingNotifications();
      getCallLog();
      getVoiceMails();
      getListIncomingFaxes();
    } catch (e) {
      console.log(e);
    } finally {
      stopLoadingNotifications();
    }
  }, [])

  return (
    <React.Fragment>
      <div className="row text-center" id="sidebarIcons">
        <div className="col-lg-3">
          <button
            className="nav-link bg-white"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={async () => {
              addSidebarNotification('call', 0);

              if (!isEmpty(phone.calls.unseen.datas)) {
                setCallsAsSeen();
                return hasReadUnseenCalls();
              }
            }}
          >
          <i className="mdi mdi-phone"></i>
            {
              notifications.call > 0 && <div className="notify"><span className="badge badge-danger">{notifications.call}</span></div> }
          </button>
          <NotificationsContainer
            title='Appels manqués'
            notifications={!isUndefined(phone) ? phone.calls.incoming : []}
            isLoading={false}
            seeMore="Voir le journal d'appels"
            urlSeeMore="/recents"
            context='calls'
            hasWebPhone={!isUndefined(phone) ? phone.hasWebPhone : false}
          />
        </div>
        <div className="col-lg-3">
          <button
            className="nav-link bg-white"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >
              <i className="mdi mdi-voicemail"></i>
            {
              notifications.mevo > 0 && <div className="notify"><span className="badge badge-danger">{notifications.mevo}</span></div> }
          </button>
          <NotificationsContainer
            title='Messages vocaux'
            notifications={voicemails || []}
            isLoading={notifications.isLoading}
            seeMore="Voir la messagerie vocale"
            urlSeeMore='/voicemail'
            context='voices'
          />
        </div>
        <div className="col-lg-3">
          <button
            className="nav-link bg-white"
            id="2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"> <i className="mdi mdi-fax"></i>
          {notifications.fax > 0 && <div className="notify"><span className="badge badge-danger">{notifications.fax}</span></div> }
          </button>
          <NotificationsContainer
            title='Fax'
            notifications={fax.incomings || []}
            isLoading={notifications.isLoading}
            seeMore="Voir les fax"
            context='faxs'
            urlSeeMore='/fax'
          />
        </div>
        <div className="col-lg-3">
          <button
            className="nav-link bg-white"
            onClick={() => {
              removeNewMessage();
              return navigate('/instant-messaging')}
            }
            id="3">
            <i className="fa fa-comments-o"></i>
            {!isUndefined(im) && im.newMessage && <div className="notify"> <span className="heartbit"></span> <span className="point"></span> </div>}
          </button>
        </div>
      </div>
      {(!isUndefined(phone) && (phone.answered || phone.ringback)) && <div className="row">
        <div className="col-lg-12 alert">
          <div className={`activeCall ${phone.held && "activeCallHold"}`}>
            {phone.ringback && <h4 className="text-white">Appel...</h4>}
            <div className="row">
              <div className="col-lg-12">
                {(!isUndefined(phone) && !isEmpty(phone.selectedContact)) && (isUndefined(phone.selectedContact.firstname) && isUndefined(phone.selectedContact.lastname)) ? phone.num : `${phone.selectedContact.firstname || ""} ${phone.selectedContact.lastname || ""}`}
              </div>       
            </div>
            {phone.answered && <div className="row">
              <div className="col-lg-12">
                <div className="activeCallIcons">
                  <CallTimer />
                </div>
              </div>
            </div>}
            <div className="row callActionIcons">
              <div className="col-lg-3">
                <i className={`fa fa-microphone-slash fa-lg ${phone.muted && "mutedIcon"}`} title="Sourdine" onClick={() => {
                  if (phone.muted) {
                    ToIP.unmute();

                    return unMute();
                  }
  
                  ToIP.mute();
                  return mute();
                }}></i>
              </div>
              {navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && <div className="col-lg-3">
                <i className="fa fa-pause fa-lg" onClick={async () => {
                   if (phone.held) {
                    ToIP.unhold()

                    return unHold();
                  }

                  try {
                    await ToIP.hold();

                    return hold(); 
                  } catch (error) {
                    console.log(error);
                  }
              }}></i>
              </div>}
              <div className="col-lg-3">
                <i className="fa fa-th fa-lg" onClick={(showDTMF) => setShowDTMF(!showDTMF)}></i>
              </div>
              <div className="col-lg-3">
                <i className="fa fa-phone hangup" title="Raccrocher" onClick={() => {
                  ToIP.hangup();

                  return hangUp();
                  }}></i>
              </div>
            </div>
            {showDTMF && <div className="row activeCallDTMF">
                <div className="col-lg-12">
                  <DTMF white={true} />
                </div>
            </div>}           
          </div>
        </div>
      </div>}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    phone: state.kertelphone,
    voicemails: state.voiceMail,
    notifications: state.notifications,
    settings: state.settings,
    fax: state.fax,
    im: state.im
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadingNotifications,
    stopLoadingNotifications,
    fetchCallLog,
    fetchUnseenCalls,
    showModal,
    hideModal,
    hasReadUnseenCalls,
    hasReadUnseenVoiceMails,
    addSidebarNotification,
    removeNewMessage,
    hangUp,
    hold,
    unHold,
    mute,
    unMute,
    dtmf,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
