import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import forEach from 'lodash/forEach';

class SviConfigConsistency extends Component {

  constructor(props) {
    super(props);
	
    this.state = {
    }
	
	this.msgDetails = this.msgDetails.bind(this);
  }

  msgDetails () {
	let msg = [];
	
	if (this.props.status) {
	  msg.push(
		<tr key={0}>
		  <td colSpan={3}>Aucune incohérence n'a été détectée.</td>
		</tr>
	  )
	} else {
	  forEach(this.props.messages, function(elements, key) {
		msg.push(
		  <tr key={key}>
			<td>{elements.action}</td>
			<td>{elements.id}</td>
			<td>{elements.message}</td>
		  </tr>
		)
	  })
	}
	
	return msg;
  }
	
  async componentDidMount() {
	
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
		<Grid container spacing={2}>
		  
		  <table className="table-sviListFiles">
			<thead>
			  <tr>
				<th>Action</th>
				<th>ID</th>
				<th>Message</th>
			  </tr>
			</thead>
			<tbody>
			  {this.msgDetails()}
			</tbody>
		  </table>
		  
		</Grid>
		
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SviConfigConsistency);
