import { store } from 'store/index.js';
import split from 'lodash/split';
import startsWith from 'lodash/startsWith';
import { fetchApiUrl, fetchImUrl } from 'actions/authActions.js';

export const getApiUrl = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let imUrl           = '';
            let hostOnly        = '';
            let urlWithoutHttp  = '';
            let apiUrl          = '';
            const currentUrl    = window.location.href;
            const isLocalhost   = startsWith(currentUrl, 'https://localhost') || startsWith(currentUrl, 'https://10.255.3.11');
    
            if (isLocalhost) {
                urlWithoutHttp  = currentUrl.substring(8);
                hostOnly        = split(urlWithoutHttp, '/', 1);
                apiUrl          = 'https://at.kertel.one/appli/v2/api';
                imUrl           = 'https://im4ui.at.kertel.one/#';       
            } else {
                urlWithoutHttp  = currentUrl.substring(8);
                hostOnly        = split(urlWithoutHttp, '/', 1);
                apiUrl          = 'https://' + hostOnly[0] + '/appli/v2/api';
                imUrl           = 'https://im4ui.' + hostOnly[0];
            }
    
            await store.dispatch(fetchApiUrl(apiUrl));
            await store.dispatch(fetchImUrl(imUrl));
            return resolve({apiUrl, imUrl});
        } catch (error) {
            return reject(error);
        }
        
    })
}
