export const addWeek = (week) => {
  return {
    type: 'ADD_WEEK',
    payload: week
  }
};

export const addDay = (day) => {
  return {
    type: 'ADD_DAY',
    payload: day
  }
};

export const toggleScheduler = () => {
  return {
    type: 'TOGGLE_SCHEDULER'
  }
};
