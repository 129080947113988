import axios from 'axios';
import { notifyWarning } from 'components/commons/Toasts.js';
import { store } from 'store/index.js';
import { clearSession } from 'actions/authActions.js';
import isUndefined from 'lodash/isUndefined';
import { clearImSession } from 'actions/imActions';
import { getApiUrl } from 'components/commons/helpers/apiHelper.js';

const request = (endPoint, method, params, headers = {}) => {
  return getApiUrl().then((result) => {
    axios.defaults.baseURL = result.apiUrl
    let config = {
      method: method.toLowerCase(),
      url: endPoint,
      //headers: {'Access-Control-Allow-Origin': '*'}
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json'
      }
    };
  
    config[(method === 'get' ? 'params' : 'data')] = params;
  
    const token = localStorage.getItem('_k');
  
    if (token) {
      config.headers = {
        ...config.headers,
        'auth-token': `${token}`
      }
    }
  
    return new Promise((resolve, reject) => {
      axios(config)
      .then((res) => {
        resolve(res.data, res);
      }).catch((err) => {
        let { response } = err;
        if (response && response.status === 401) {
          store.dispatch(clearImSession());
          store.dispatch(clearSession());
        }
  
        response = response || {};
  
        let message = "Une erreur est survenue.";
  
        if (!isUndefined(response.data)) {
          if (!isUndefined(response.data.validation_messages)) {
            const validationMessage = Object.values(response.data.validation_messages);
            const errDetails = Object.values(validationMessage[0]);
            message = errDetails[0];
          } else {
            message = response.data.detail;
          }
        }
  
        const errorObject = {
          config: response.config,
          status: response.status,
          message: message
        };
  
        notifyWarning(message);
  
        reject(errorObject);
      });
    });
  
  });
  
}

export function GET(endPoint, params) {
  return request(endPoint, 'get', params);
}

export function POST(endPoint, params) {
  return request(endPoint, 'post', params);
}

export function PUT(endPoint, params) {
  return request(endPoint, 'put', params);
}

export function PATCH(endPoint, params) {
  return request(endPoint, 'patch', params);
}

export function DELETE(endPoint, params) {
  return request(endPoint, 'delete', params);
}