import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { createInterception  } from 'components/commons/helpers/admin/interceptionGroupHelper.js';
import { stopEditingInterception } from 'actions/admin/interceptionGroupActions.js';
import Preloader from 'components/commons/Preloader.js';
import EditInterceptionGroupInfos from './EditInterceptionGroupInfos.js';
import EditInterceptionGroupMembers from './EditInterceptionGroupMembers.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

function CreateInterceptionGroup({ interceptionGroup, users, company, url }) {

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [expiredLicence, setExpiredLicence] = useState(false);
	const [interceptionGroupState, setInterceptionGroupState] = useState({
        "name": '',
        "members": [],
		"dataMembers": [],
      });

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

	let interception = interceptionGroupState;
	interception.push({[name]: value});
    
    setInterceptionGroupState(interception);
  } 

  async function addInterceptionGroup(){
    
	let info = {
	  "name": '',
	  "members": [],
	};
	
	if (!isUndefined(interceptionGroupState.name)) info.name = interceptionGroupState.name;
	forEach(interceptionGroupState.dataMembers, function(details) {
	  if (details.tableData.checked === true) {
		info.members.push(details.user_id);
	  }
	})
	
	let listInterceptionGroup = [];
	if (!isUndefined(interceptionGroup.datas)) {
	  interceptionGroup.datas.forEach((details, i) => {
		listInterceptionGroup.push(details.name);
	  })
	}
	
	if (isEmpty(info.name)) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: 'Le nom de la messagerie est invalide',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	else if (listInterceptionGroup.includes((info.name))) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: 'Le nom de la messagerie existe déjà',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	
	try {  
      createInterception(info).then(result => {
		navigate('/admin/groupinterception');
	  });
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }

  async function initInterception() {
	await new Promise((resolve, reject) => {
		fetch(
		  url + '/license', {
			method: 'post',
			headers: {
			  'Content-Type':'application/json',
			  'auth-token': `${localStorage.getItem('_k')}`
			},
			body: JSON.stringify({
			  "service" : "interceptionGroup"
			})
		  }
		)
		  .then(response => response.json())
		  .then(result => {
			if (!isUndefined(result.status) && result.status !== 200) {
			  return swal({
				type: 'error',
				icon: "error",
				title: 'Oops...',
				text: result.detail,
				confirmButtonColor: 'LightSeaGreen',
			  })
			}
			
			if (!isUndefined(result.data)) {
			  if (!isUndefined(result.data.available)) {
				if (result.data.available === 0) {
				  setExpiredLicence(true);
				}
			  }
			}
			
			var dataMembers = [];
			forEach(users.datas, function(details) {
			  if (details.user_type === "2" || details.user_type === "8") {
				let user = {
				  "user_id": details.user_id,
				  "mail": !isUndefined(details.mail) ? details.mail : '',
				  "firstname": !isUndefined(details.firstname) ? details.firstname : '',
				  "lastname": !isUndefined(details.lastname) ? details.lastname : '',
				  "group_id": details.group_id,
				  "location_id": details.location_id,
				  "user_type" : details.user_type,
				  tableData: { checked: false}
				};
				
				dataMembers.push(user);
			  }
			})

			let interception = interceptionGroupState;
			interception.dataMembers = dataMembers;

			setInterceptionGroupState(interception);
			setIsLoading(false);
		  })
	  })
  }
  
  useEffect(function() {
	initInterception();
  }, [])
    
	if (expiredLicence) {
	  return (
		<div className="col-lg-12">
		  <div className="card">
			<div className="card-body p-b-0">
            <Breadcrumb routes={["Groupe d'interception", "Création du groupe d'interception"]} url="/admin/groupinterception" title="Création du groupe d'interception" />
			  <ul className="nav nav-tabs customtab" role="tablist">
				<li className="nav-item">
				  <a className="nav-link active" data-toggle="tab" href="#infos" role="tab" onClick={fetchUsers}>
					<span className="hidden-sm-up">
					  <i className="ti-home"></i>
					</span>
					<span className="hidden-xs-down">
					  Information générales
					</span>
				  </a>
				</li>
			  </ul>
			  <div className="tab-content">
				<div className="tab-pane active p-20" role="tabpanel" id="infos">
				  <div className="col-lg-12 text-center">Le nombre maximum de licences a été atteint.</div>
				</div>
			  </div>
            </div>
          </div>
        </div>
      )
	}
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
	
    if (isEmpty(interceptionGroupState)) return navigate('/admin/groupinterception');
	
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Groupe d'interception", "Création du groupe d'interception"]} url="/admin/groupinterception" title="Création du groupe d'interception" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Information générales
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Gestion des membres
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
                <EditInterceptionGroupInfos
                  options={interceptionGroupState}
                  handleOptions={handleOptions}
                />
              </div>
              <div className="tab-pane p-20" role="tabpanel" id="users">
                <EditInterceptionGroupMembers
                  dataMembers={interceptionGroupState.dataMembers}
                  company={company}
                />
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/groupinterception');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={addInterceptionGroup} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    interceptionGroup: state.interception,
	url: state.kertelSession.apiUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingInterception
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInterceptionGroup);
