import { POST } from './api';

export const login = (credentials) => {
  const { username, company, password } = credentials;

  return POST('/auth', {
    username: username,
    company: company,
    password: password
  });
}

export const shortToken = (credentials) => {
  const { username, company, password } = credentials;

  return POST('/auth', {
    username: username,
    company: company,
    password: password,
    shortToken: true
  });
}

export const getConfigAuth = (type) => {
  return POST('/configuration', {
    type: type,
  });
}

export const getTokenWithCallbackAuth = (token) => {
  return POST('/tokenAuth', {
    token: token,
  });
}

export const getMiniToken = () => {
  return POST('/miniTokenAuth', {
    "auth-token": localStorage.getItem('_k'),
  });
}

export const getTokenConnectAs = (token, from = 'ictools') => {
  return POST('/tokenAuth', {
    token: token,
    from: from
  });
}

export const getAuthAs = (user_id) => {
  return POST('/authAs', {
    user_id: user_id,
  });
}