import { store } from 'store/index.js';
import {
  getUsersSupervisionInformation,
  addUsersSupervisionInformation,
  deleteUsersSupervisionInformation
} from 'components/commons/api/admin/supervision';
import {
  loadingSupervision,
  getUsersSupervision as getUsersSupervisionAction,
  stopLoadingSupervision,

} from 'actions/admin/supervisionActions';

export const fetchUsersSupervision = async (user_id) => {
  store.dispatch(loadingSupervision());
  try {
    await getUsersSupervisionInformation(user_id).then((result) => {
      return store.dispatch(getUsersSupervisionAction(Object.keys(result.data)));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSupervision());
  }
}

export const addUsersSupervision = async (user_id, informations) => {
  store.dispatch(loadingSupervision());
  try {
    await addUsersSupervisionInformation(user_id, informations);
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSupervision());
  }
}

export const deleteUsersSupervision = async (user_id, informations) => {
  store.dispatch(loadingSupervision());
  try {
    await deleteUsersSupervisionInformation(user_id, informations);
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingSupervision());
  }
}