import React, { Component } from "react";
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

class UpdateWaitingQueueInfos extends Component {

  render() {
	return (
	  <Container component="main">
		<Grid container spacing={2}>
		
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend">Nom de la file d'attente</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="name"
			  fullWidth
			  value={this.props.options.name}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={6} />
		  
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend">Type de la file d'attente</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<FormControl component="fieldset">
			  <select
				name="func"
				value={this.props.options.func}
				className="custom-select col-12"
				onChange={this.props.handleOptions}
			  >
				<option value="1">Ring</option>
				<option value="2">Parking</option>
			  </select>
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={6} />
		  
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(UpdateWaitingQueueInfos);
