import { POST, PUT, PATCH, DELETE } from './../api';

export const getProfilesCallsInformation = () => {
  return POST('/profile/calls',{
    company: localStorage.getItem('company')
  });
}

export const getProfileCallsInformation = (profileId) => {
  return POST('/profile/calls',{
    company: localStorage.getItem('company'),
    profile_id: profileId
  });
}

export const createProfileCallsInformation = (informations) => {
  return PUT('/profile/calls',{
    company: localStorage.getItem('company'),
    data: informations
  });
}

export const updateProfileCallsInformation = (profileId,informations) => {
  return PATCH('/profile/calls',{
    company: localStorage.getItem('company'),
    profile_id: profileId,
    data: informations
  });
}

export const deleteProfileCallsInformation = (profileId) => {
  return DELETE('/profile/calls',{
    company: localStorage.getItem('company'),
    profile_id: profileId
  });
}

export const getConfigProfileCalls = () => {
  return POST('/configuration', {
    type: "profile_calls",
  });
}