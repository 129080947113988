import { POST, PUT, PATCH, DELETE } from './../api';

export const getWaitingQueuesInformation = () => {
  return POST('/waiting/queues');
}

export const getWaitingQueueInformation = (waitingId) => {
  return POST('/waiting/queues', {
    tty_id: waitingId
  });
}

export const createWaitingQueue = (informations) => {
  return PUT('/waiting/queues', {
    data: informations
  });
}

export const deleteWaitingQueueInformation = (waitingId) => {
  return DELETE('/waiting/queues', {
    tty_id: waitingId
  });
}

export const updateWaitingQueueInformation = (waitingId,informations) => {
  return PATCH('/waiting/queues', {
    tty_id: waitingId,
    data: informations
  });
}
