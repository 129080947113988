const initialState = {
  supervision: []
}

const supervisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
    case 'LOADING_SUPERVISION':
      return { ...state, isLoading: true };
    case 'GET_USERS_SUPERVISION':
      return { ...state, users: action.payload }
    case 'STOP_LOADING_SUPERVISION':
      return { ...state, isLoading: false };
    
    case 'START_EDITING_SUPERVISION':
      return { ...state, isEditing: true, supervisor: action.payload};
    case 'STOP_EDITING_SUPERVISION':
      return { ...state, isEditing: false, supervisor: "", users: [] };
    
    default:
      return state
  }
}

export default supervisionReducer;
