import React, { Component } from 'react';
import RecordsTab from './RecordsTab.js';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchRecords } from 'components/commons/helpers/admin/recordsHelper.js';

class Records extends Component {
  
  render() {
    
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={['Enregistrements']} title='Enregistrements' />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#users" role="tab" onClick={fetchRecords}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Liste des communications enregistrees
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="users" role="tabpanel">
                <RecordsTab
                  listRecords={this.props.records.datas}
                  isLoading={this.props.records.isLoading}
                  urlRecords={this.props.urlRecords}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    records: state.records,
    urlRecords: state.kertelSession.apiUrl
  }
}

export default connect(mapStateToProps, null)(Records);
