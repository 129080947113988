export const composeNum   = (number) => { return { type: 'COMPOSE_NUM', payload: number } };
export const ringback     = (number, displayName) => { return { type: 'RINGBACK', payload: {number, displayName} } };
export const ringing      = () => { return { type: 'RINGING' } };
export const hangOut      = () => { return { type: 'HANGOUT' } };
export const hangUp       = () => { return { type: 'HANGUP' } };
export const hold         = () => { return { type: 'HOLD' } };
export const unHold       = () => { return { type: 'UNHOLD' } };
export const mute         = () => { return { type: 'MUTE' } };
export const unMute       = () => { return { type: 'UNMUTE' } };
export const answered     = () => { return { type: 'ANSWERED' } };
export const dtmf         = (number) => { return { type: 'DTMF_NUM', payload: number } };

/* LOADING */
export const loadingIncomingCalls = () => { return { type: 'LOADING_INCOMING_CALLS' } };
export const loadingOutgoingCalls = () => { return { type: 'LOADING_OUTGOING_CALLS' } };
export const loadingCallLog       = () => { return { type: 'LOADING_CALL_LOG' } };

/* STOP LOADING */
export const stopLoadingIncomingCalls = () => { return { type: 'STOP_LOADING_INCOMING_CALLS' } };
export const stopLoadingOutgoingCalls = () => { return { type: 'STOP_LOADING_OUTGOING_CALLS' } };
export const stopLoadingCallLog       = () => { return { type: 'STOP_LOADING_CALL_LOG' } };

/* FETCH */
export const fetchIncomingCalls     = (calls) => { return { type: 'FETCH_INCOMING_CALLS', payload: calls } };
export const fetchIncomingBothCalls = (calls) => { return { type: 'FETCH_INCOMING_BOTH_CALLS', payload: calls } };
export const fetchOutgoingCalls     = (calls) => { return { type: 'FETCH_OUTGOING_CALLS', payload: calls } };
export const fetchOutgoingBothCalls = (calls) => { return { type: 'FETCH_OUTGOING_BOTH_CALLS', payload: calls } };
export const fetchCallLog           = (calls) => { return { type: 'FETCH_CALL_LOG', payload: calls } };
export const fetchUnseenCalls       = (calls) => { return { type: 'FETCH_UNSEEN_CALLS', payload: calls } };

/* READ */
export const hasReadUnseenCalls = () => { return { type: 'HAS_READ_UNSEEN_CALLS' } };


/* WEBPHONE */
export const hasWebPhone = (boolean) => { return { type: 'SET_WEB_PHONE', payload: boolean } };
export const addSelectedContact = (contact) => { return { type: 'ADD_SELECTED_CONTACT', payload: contact } };
export const setCallTimer = (timer) => { return { type: 'SET_CALL_TIMER', payload: timer } };
