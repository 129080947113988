import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import MaterialTable from 'material-table';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import FaxListByUser from './../fax/FaxListByUser.js';

class Fax extends Component {
  
  constructor(props) {
    super(props);
    
    this.tableRef = React.createRef();
  }
  
  componentDidMount() {
    fetchUsers();
  }

  format_number(numbers) {
    if (typeof numbers === "object") {
      forEach(numbers, function(value, index, numbers) {
        var first = value.substring(0, 2);
        if (first === "+3") {
          numbers[index] = ("0" + value.slice(3, value.length));
        } else if (first === "33") {
          numbers[index] = ("0" + value.slice(2, value.length));
        }
      })
      return numbers.join(', ');
    } else {
      var first = numbers.substring(0, 2);
      if (first === "+3") {
        numbers = ("0" + numbers.slice(3, numbers.length));
      } else if (first === "33") {
        numbers = ("0" + numbers.slice(2, numbers.length));
      }
      return numbers;
    }
  }

  find_location(id){
    if(this.props.company.location != null) {
      for (var location in this.props.company.location) {
        if(this.props.company.location[location].id === id){
          return this.props.company.location[location].name;
        }
      }
    }
    return id;
  }

  render() {
    
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={['Webfax']} title='WebFax' />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#users" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Utilisateurs
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" id="users" role="tabpanel">
                <MaterialTable
                  tableRef={this.tableRef}
                  columns={[
                    { title: 'Prénom', field: 'firstname' },
                    { title: 'Nom', field: 'lastname'},
                    { title: 'Fax', field: 'fax',render: rowData => (
                        <div>{(rowData.fax != null)?this.format_number(rowData.fax):""}</div>
                      )},
                    { title: 'Département', field: 'location_id', grouping: false,
                    render: rowData => (
                      <div>{(rowData.location_id != null)?this.find_location(rowData.location_id):"Pas de département"}</div>
                    )}
                  ]}
                  data={filter(this.props.users.datas, item => (item.user_type === "1" || item.user_type === "2") && !isEmpty(item.fax))}
                  title=""
                  localization={
                    {
                      toolbar: {
                        searchPlaceholder: 'Rechercher',
                      },
                      body: {
                        emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                        filterRow: {
                            filterTooltip: 'Filtre'
                        }
                      },
                      pagination: {
                        labelRowsPerPage: "Utilisateurs par page",
                        labelDisplayedRows: "{from}-{to} sur {count}",
                        labelRowsSelect: "lignes",
                      },
                    }
                  }
                  options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    detailPanelType: "single"
                  }}
                  onToggleDetailPanel={() => alert('test toggle detail panel')}
                  detailPanel={
                    rowData => {
                      return (
                        <FaxListByUser
                          user_id={rowData.user_id}
                        />
                      )
                    }
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company
  }
}

export default connect(mapStateToProps, null)(Fax);
