const initialState = {
  active: false,
  week: [],
  day: {}
}

const schedulerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'ADD_WEEK':
    return {
      ...state,
      week: action.payload
    };

    case 'ADD_DAY':
    return {
      ...state,
      day: action.payload
    };

    case 'TOGGLE_SCHEDULER':
    return {
      ...state,
      active: !state.active
    };

    default:
      return state
  }
}

export default schedulerReducer;
