import { store } from 'store/index.js';
import {
  getIncomingFaxes,
  getOutgoingFaxes,
  setFaxAsSeen
 } from 'components/commons/api/fax.js';
import {
  loadingIncomingFax,
  stopLoadingIncomingFax,
  loadingOutgoingFax,
  stopLoadingOutgoingFax,
  fetchFax,
  fetchOutgoingFax,
  fetchUnseenFax
} from 'actions/faxActions.js';
import filter from 'lodash/filter';

export const getListIncomingFaxes = async (user_id = null) => {
  store.dispatch(loadingIncomingFax());
  
  try {
    const faxs = await getIncomingFaxes(user_id);
    const faxsWithoutDeleted = filter(faxs.data, function(fax) {
      return fax.recycled_date === '0000-00-00 00:00:00';
    });
    faxsWithoutDeleted.sort(compare);
    
    const unseen  = (faxsWithoutDeleted).filter(fax => fax.seen === false && parseInt(fax.state) !== 4 && parseInt(fax.state) !== 5);
    
    store.dispatch(fetchFax(faxsWithoutDeleted));
    store.dispatch(fetchUnseenFax(unseen));
    
  } catch (error) {
    console.log(error);
  } finally {
    return store.dispatch(stopLoadingIncomingFax());
  }
};

export const getListOutgoingFaxes = async (user_id = null) => {
  store.dispatch(loadingOutgoingFax());

  try {
    const faxs = await getOutgoingFaxes(user_id);
    const faxsWithoutDeleted = filter(faxs.data, function(fax) {
      return fax.recycled_date === '0000-00-00 00:00:00';
    });

    faxsWithoutDeleted.sort(compare);
    return store.dispatch(fetchOutgoingFax(faxsWithoutDeleted));
  } catch (error) {
    console.log(error);
  } finally {
    return store.dispatch(stopLoadingOutgoingFax());
  }
};

export const setIncomingFaxAsSeen = async (fax_id, user_id = null) => {
  store.dispatch(loadingIncomingFax());
  
  try {
    await setFaxAsSeen(fax_id).then((result) => {
      return getListIncomingFaxes();
    })
    
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingIncomingFax());
    const faxs = await getIncomingFaxes(user_id);
    const faxsWithoutDeleted = filter(faxs.data, function(fax) {
      return fax.recycled_date === '0000-00-00 00:00:00';
    });
    
    faxsWithoutDeleted.sort(compare);
    const unseen  = (faxsWithoutDeleted).filter(fax => fax.seen === false && parseInt(fax.state) !== 4 && parseInt(fax.state) !== 5);
    store.dispatch(fetchUnseenFax(unseen));
  }
}

function compare(a, b) {
  const dateA = a.date_start;
  const dateB = b.date_start;

  let comparison = 0;
  if (dateA > dateB) {
    comparison = -1;
  } else if (dateA < dateB) {
    comparison = 1;
  }
  return comparison;
}
