import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import random from 'lodash/random';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { createForwardsSet } from 'components/commons/api/forward';
import { fetchCompanyContacts, getAlphabeticallyOrderedUsers } from 'components/commons/helpers/contactHelper.js';
import { getUsers } from 'components/commons/api/user';
import { getMevosGroupInformation } from 'components/commons/api/admin/mevoGroup.js';
import { useNavigate } from 'react-router';

function CreateInboundManagement ({ session }) {

  const navigate = useNavigate();

  const [forwardName, setForwardName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [mevoGroups, setMevoGroups] = useState([]);
  const [users, setUsers] = useState({
    callCenter: [],
    users: [],
    commonRings: [],
    waitingQueues: []
  });
  const [rules, setRules] = useState({
    0: {
      key: random(0, 1500),
      source: 'from',
      sources: { 0: { key: random(1500, 3000), from: 'E164', data: '' } },
      destinations: { 0: { key: random(3000, 4500), to: 'PHONE', data: '', timeout: '15', order: 0 }},
      data: '0'
    }
  });

  async function init() {
    fetchCompanyContacts();
    const users = await getUsers();
    let filteredUsers = getAlphabeticallyOrderedUsers(users.data);
    const mevoGroups = await getMevosGroupInformation();

    setUsers(filteredUsers);
    setMevoGroups(mevoGroups.data);
  }

  useEffect(function() {
    init();
  }, []);

  function handleforward_nameChange(event) {

    setForwardName(event.target.value);
  }

  function handleSourceChange(ruleKey, sourceKey, event) {
    let newRules = rules;
    newRules[ruleKey].sources[sourceKey].from = event.target.value;
    newRules[ruleKey].sources[sourceKey].data = event.target.value === 'E164' ? '' : event.target.value;

    setRules(newRules);
  }

  function handleSourceDataChange(ruleKey, sourceKey, event) {
    let newRules = rules;
    newRules[ruleKey].sources[sourceKey].data = event.target.value;

    setRules(newRules);
  }

  function handleDestinationChange(ruleKey, destinationKey, value) {
    let newRules = rules;
    newRules[ruleKey].destinations[destinationKey].to = value;

    setRules(newRules);
  }

  function handleDestinationDataChange(ruleKey, destinationKey, event) {
    let newRules = rules;
    newRules[ruleKey].destinations[destinationKey].data = event.target.value;

    setRules(newRules);
  }

  function handleDestinationAfterChange(ruleKey, destinationKey, event) {
    let newRules = rules;
    newRules[ruleKey].destinations[destinationKey].timeout = event.target.value;

    setRules(newRules);
  }

  function addRule() {
    setRules(prevState => ({
      ...prevState,
      [random(0, 1500)]: {
        key: random(0, 1500),
        source: 'from',
        sources: { 0: { key: random(1500, 3000), from: 'E164', data: '' } },
        destinations: { 0: { key: random(3000, 4500), to: 'POSTE', timeout: '15', data: '', order: 0 }},
        data: '0'
      }
    }))
  }

  function removeRule(ruleKey) {
    let newRules = rules;
    delete newRules[ruleKey];

    setRules(newRules);
  }

  function handleSourceRadio(ruleKey, value) {
    let newRules = rules;
    newRules[ruleKey].source = value;

    setRules(newRules);
  }

  function addSource(ruleKey) {
    let newRules = rules;
    const sourcesNumber = Object.keys(newRules[ruleKey].sources).length;
    newRules[ruleKey].sources[sourcesNumber] = { key: random(1500, 3000), from: 'E164', data: '' };

    setRules(newRules);
  }

  function removeSource(ruleKey, sourceKey) {
    let newRules = rules;
    delete newRules[ruleKey].sources[sourceKey];

    setRules(newRules);
  }

  function addDestination(ruleKey) {
    let newRules = rules;
    const destinationsNumber = Object.keys(newRules[ruleKey].destinations).length;
    newRules[ruleKey].destinations[destinationsNumber] = {
      key: random(3000, 4500),
      to: 'PHONE',
      timeout: '15',
      order: 0,
      data: ''
    };

    setRules(newRules);
  }

  function removeDestination(ruleKey, destinationKey) {
    let newRules = rules;
    delete newRules[ruleKey].destinations[destinationKey];

    setRules(newRules);
  }

  async function createRules() {
    try {
      setIsCreating(true);
      let rulesAsForwards = []

      Object.entries(rules).forEach((rule, ruleIndex) => {
        rulesAsForwards.push({dests: []});
        Object.entries(rule[1].destinations).forEach((destination, destIndex) => {
          rulesAsForwards[ruleIndex].dests.push({
            entity: {
              type: ["USER_MOBILE", "USER_DIRECTORY"].includes(destination[1].to) ? "USER" : destination[1].to,
              data: destination[1].to === "USER_MOBILE" ? { dest: "GSM", id: ""} : destination[1].to === "USER_DIRECTORY" ? {dest: "S164", id: destination[1].data}: destination[1].data
            },
            timeout: destination[1].timeout
          })
        })

        if (rule[1].source === 'from') {
          rulesAsForwards[ruleIndex].filters = []
          Object.entries(rule[1].sources).forEach((source) => {
            rulesAsForwards[ruleIndex].filters.push({
              entity: {
                type: ["USER_MOBILE", "USER_DIRECTORY"].includes(source[1].from) ? "USER" : source[1].from,
                data: source[1].from === "USER_MOBILE" ? { dest: "GSM", id: ""} : source[1].from === "USER_DIRECTORY" ? {dest: "S164", id: source[1].data} : source[1].data
              },
              add: true
            })
          })          
        }
      })

      const forwardsSet = {
        user_id: session.datas.data.user_id,
        forwardName: forwardName,
        forwards: rulesAsForwards
      }

      await createForwardsSet(forwardsSet);
      return navigate('/inbound-management');
    } catch (e) {
      console.log(e);
    } finally {
      setIsCreating(false);
    }
  }

    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={['Configuration avancée']} title='Configuration avancée' />
            <form>
              <input
                type='text'
                value={forwardName}
                placeholder='Nom de la configuration'
                onChange={handleforward_nameChange}
                className='form-control' />
            </form>
            <hr />
            {
              Object.entries(rules).map(([key, value], index) => {
                return (
                  <div className="card border" key={key}>
                    <h5 className="card-header">{`Règle n°${index + 1}`} {key !== 0 && <button className='btn btn-link text-danger' onClick={() => removeRule(key)}>supprimer</button>}</h5>
                    <div className="card-body">
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`sourceRadio_${index + 1}`}
                              value="all"
                              onChange={() => handleSourceRadio(key, 'all')}
                              checked={value.source === 'all' ? true : false} />
                            <label className="form-check-label" htmlFor={`sourceRadio_${index + 1}`}>
                              Tous les appels
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`sourceRadio_${index + 1}`}
                              checked={value.source === 'from' ? true : false}
                              onChange={() => handleSourceRadio(key, 'from')}
                              value="from" />
                            <label className="form-check-label" htmlFor={`sourceRadio_${index + 2}`}>
                              Tous les appels en provenance de ...
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr className='col-lg-12' />
                      <div className='row'>
                        <div className='col-lg-12'>
                        {value.source === 'from' && <div>
                          {
                            Object.entries(value.sources).map(([sourceKey, sourceValue]) => {
                              return (
                                <div className='row' key={sourceKey}>
                                  <div className='col-lg-4'>
                                    <div className='form-group'>
                                      <select value={sourceValue.from} className="custom-select" onChange={(event) => handleSourceChange(key, sourceKey, event)}>
                                        <option value="E164">Numéro</option>
                                        <option value="USER_DIRECTORY">Collègue</option>
                                        <option value="HIDDEN">Numéro caché</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className='col-lg-4'>
                                    <div className='form-group'>
                                      {sourceValue.from === 'E164' && <input
                                        type='text'
                                        className='form-control'
                                        placeholder='N° de téléphone'
                                        onChange={(event) => handleSourceDataChange(key, sourceKey, event)}
                                        value={sourceValue.data}
                                      />}
                                      {sourceValue.from === 'USER_DIRECTORY' &&
                                        <select value={sourceValue.data} onChange={(event) => handleSourceDataChange(key, sourceKey, event)} className="custom-select col-12">
                                        <option disabled={true}>Destinataire</option>
                                        {!isEmpty(users.callCenter) && <optgroup label="Centre d'appels">
                                          {
                                            users.callCenter.map((data, index) => {
                                              return (
                                                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
                                              );
                                            })
                                          }
                                        </optgroup>}
                                        {!isEmpty(users.commonRings) && <optgroup label='Sonneries générales'>
                                          {
                                            users.commonRings.map((data, index) => {
                                              return (
                                                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
                                              );
                                            })
                                          }
                                        </optgroup>}
                                        {!isEmpty(users.usersList) && <optgroup label='Utilisateurs'>
                                          {
                                            users.usersList.map((data, index) => {
                                              return (
                                                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
                                              );
                                            })
                                          }
                                        </optgroup>}
                                        {!isEmpty(users.waitingQueues) && <optgroup label="Files d'attente">
                                          {
                                            users.waitingQueues.map((data, index) => {
                                              return (
                                                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
                                              );
                                            })
                                          }
                                        </optgroup>}
                                      </select>
                                      }
                                    </div>
                                  </div>
                                  {sourceKey !== 0 && <div className='col-lg-4'>
                                    <div className='form-group'>
                                      <button className='btn btn-link text-danger' onClick={() => removeSource(key, sourceKey)}>supprimer</button>
                                    </div>
                                  </div>}
                                </div>
                              );
                            })
                          }
                        <div className='row'>
                          <div className='col-lg-12 text-left'>
                            <button className='btn btn-link' onClick={() => addSource(key)}>+ Ajouter une provenance</button>
                          </div>
                        </div><hr />
                      </div>}
                          <div className='row'>
                            <div className='col-lg-12'>
                              Sont renvoyés dans l'ordre vers
                              <hr />
                            </div>
                          </div>
                          <div>
                            {
                              Object.entries(value.destinations).map(([destinationKey, destinationValue]) => {
                                return (
                                  <div className='row' key={destinationKey}>
                                    <div className='col-lg-2'>
                                      <div className='form-group'>
                                        <select value={destinationValue.to} className="custom-select" onChange={(event) => { const value = event.target.value; return handleDestinationChange(key, destinationKey, value) }}>
                                          <option value="PHONE">Mon poste</option>
                                          {!isUndefined(session.mobile) && <option value="USER_MOBILE">Mon mobile</option>}
                                          <option value="E164">Numéro</option>
                                          <option value="MEVO">Messagerie vocale</option>
                                          <option value="SVI_ANNOUNCE">Annonce</option>
                                          <option value="MEVO_CC">Messagerie groupée</option>
                                          <option value="USER_DIRECTORY">Autres</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className='col-lg-3'>
                                      {destinationValue.to === 'E164' && <input
                                        type='text'
                                        className='form-control'
                                        placeholder='N° de téléphone'
                                        onChange={(event) => handleDestinationDataChange(key, destinationKey, event)}
                                        value=''
                                      />}
                                      {destinationValue.to === 'SVI_ANNOUNCE' &&
                                        <select value={destinationValue.data} onChange={(event) => handleDestinationDataChange(key, destinationKey, event)} className="custom-select col-12">
                                          <option value="AWAY">Message d'absence</option>
                                          <option value="MEVO">Message de non disponibilité</option>
                                        </select>
                                      }
                                      {destinationValue.to === 'MEVO_CC' &&
                                        <select value={destinationValue.data} onChange={(event) => handleDestinationDataChange(key, destinationKey, event)} className="custom-select col-12">
                                          {
                                            Object.entries(mevoGroups).map((mevoGroup, index) => {
                                              return (
                                                <option key={index} value={mevoGroup[1].id}>{mevoGroup[1].name}</option>
                                              )
                                            })
                                          }
                                        </select>
                                      }
                                      {['USER_DIRECTORY'].includes(destinationValue.to) &&
                                      <select value={destinationValue.data} onChange={(event) => handleDestinationDataChange(key, destinationKey, event)} className="custom-select col-12">
                                        <option disabled={true}>Destinataire</option>
                                        {!isEmpty(users.callCenter) && <optgroup label="Centre d'appels">
                                          {
                                            users.callCenter.map((data, index) => {
                                              return (
                                                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
                                              );
                                            })
                                          }
                                        </optgroup>}
                                        {!isEmpty(users.commonRings) && <optgroup label='Sonneries générales'>
                                          {
                                            users.commonRings.map((data, index) => {
                                              return (
                                                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
                                              );
                                            })
                                          }
                                        </optgroup>}
                                        {!isEmpty(users.users) && <optgroup label='Utilisateurs'>
                                          {
                                            users.users.map((data, index) => {
                                              return (
                                                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
                                              );
                                            })
                                          }
                                        </optgroup>}
                                        {!isEmpty(users.waitingQueues) && <optgroup label="Files d'attente">
                                          {
                                            users.waitingQueues.map((data, index) => {
                                              return (
                                                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
                                              );
                                            })
                                          }
                                        </optgroup>}
                                      </select>
                                      }
                                    </div>
                                    {['PHONE', 'USER_MOBILE', 'E164', 'USER_DIRECTORY'].includes(destinationValue.to) && <React.Fragment> <div className='col-lg-1 text-right'>
                                      après
                                    </div>
                                    <div className='col-lg-1'>
                                      <div className='form-group'>
                                        <input
                                          type='text'
                                          className='form-control'
                                          placeholder='15'
                                          value={destinationValue.timeout}
                                          onChange={(event) => handleDestinationAfterChange(key, destinationKey, event)}
                                        />
                                      </div>
                                    </div>
                                    <div className='col-lg-2 text-left'>
                                      secondes
                                    </div></React.Fragment> }
                                    {destinationKey !== 0 && <div className='col-lg-2'>
                                      <button className='btn btn-link text-danger' onClick={() => removeDestination(key, destinationKey)}>supprimer</button>
                                    </div>}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className='row'>
                            <button className='btn btn-link' onClick={() => addDestination(key)}>+ Ajouter une destination</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)
              })
            }
            <div className='row'>
              <div className='col-lg-12 text-left card-body'>
                <button className='btn btn-link' onClick={addRule}>+ Ajouter une règle</button>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 text-right'>
                <button className='btn btn-default' onClick={() => navigate('/inbound-management')}>Annuler</button>{' '}
                <button className='btn btn-success' disabled={isCreating} onClick={createRules}>{isCreating ? 'Veuillez patienter...' : 'Enregistrer'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => {
  return {
    phone: state.kertelphone,
    session: state.kertelSession,
    contacts: state.contacts
  };
}

export default connect(mapStateToProps, null)(CreateInboundManagement);
