import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';
import { deleteVoiceMail, setVoiceMailsAsSeen } from 'components/commons/api/voicemails.js';
import { notifyInfo } from 'components/commons/Toasts.js';
import swal from 'sweetalert';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { connect } from 'react-redux';
import { clickCall } from 'components/commons/helpers/phoneHelper';

class MailsTab extends Component {
  componentDidMount() {
    var audios = document.getElementsByTagName("audio");
    const that = this;
    for (var i = 0; i < audios.length; i++) {
      audios[i].addEventListener('play', async function(audio) {
        if (audio.target.getAttribute('data-seen') === 'false') {
          const audioId = (audio.target.id).substring(7);
          try {
            await setVoiceMailsAsSeen({ message_ids: [audioId], seen: true });
            return that.props.refreshData();
          } catch (e) {
            console.log(e);
          }
        }
      })
    }
  }

  render() {
    
    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MaterialTable
        columns={[
          { title: 'De', field: 'caller_number' },
          { title: 'Date', field: 'date', render: (rowData) => {
            return <React.Fragment>Le <Moment locale='fr' unix format="DD MMMM YYYY à HH:mm:ss">{rowData.date || "-"}</Moment></React.Fragment>
          } },
          { title: 'Ecouter', field: 'play', render: (rowData) => {
            return (
              <audio
                id={`player_${rowData.id}`}
                controls
                controlsList="nodownload"
                data-seen={rowData.seen}
                src={`${this.props.session.apiUrl}/mevo/message?message_id=${rowData.id}&u=${this.props.session.datas.data.user_id}&token=${this.props.minitoken}`}
                type="audio/mp3"
                style={
                  this.props.type === "dashboard" ? {height: "30px"} : {}
                }
              ></audio>
            );
          }}
        ]}
        data={this.props.mails}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
              nRowsSelected: '{0} Appel(s) sélectionné(s)'
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Appels par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
              labelRowsSelect: "lignes",
              firstTooltip: "Première page",
              lastTooltip: "Dernière page",
              previousTooltip: "Page précédente",
              nextTooltip: "Page suivante",
            }
          }
        }
        options={{
          search: this.props.search,
          toolbar: this.props.search,
          pageSize: this.props.rowsPag,
          actionsColumnIndex: -1,
          rowStyle: rowData => ({
            backgroundColor: !rowData.seen ? '#d1e8d1' : 'auto'
          })
        }}
        actions={[
          {
            icon: 'delete_outline',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
              return swal({
                title: "Confirmation",
                text: `Etes-vous sûr(e) de vouloir supprimer ce message vocal ?`,
                icon: "warning",
                buttons: {
                  cancel: {
                    text: "Annuler",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                  },
                  confirm: {
                    text: "Confimer",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                },
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                  return deleteVoiceMail([rowData.id]).then((result) => {
                    notifyInfo("Message supprimé avec succès !");
                    return this.props.refreshData();
                  }).catch((e) => {
                    return console.log(e);
                  });
                }
              });
            },
          },
          {
            icon: 'cloud_download',
            tooltip: 'Télécharger',
            onClick: (event, rowData) => {

              const url = `${this.props.session.apiUrl}/mevo/message?message_id=${rowData.id}&u=${this.props.session.datas.data.user_id}&token=${this.props.minitoken}`;

              var xhr = new XMLHttpRequest();
              xhr.open('GET', encodeURI(url), true);
              xhr.setRequestHeader('Content-Type', 'application/json');
              xhr.responseType = 'blob';
              xhr.onload = function(evt) {
                var blob = new Blob([xhr.response], {type: 'audio/wav'});
                var objectUrl = URL.createObjectURL(blob);
                var element = document.createElement('a');
                element.setAttribute('href', objectUrl);
                element.setAttribute('download', 'message_vocal_extranet.wav');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
              };

              var data = JSON.stringify({text: ""});
              xhr.send(data);
              
              return null;
            },
          },
          {
            icon: 'phone',
            tooltip: 'Appeler',
            onClick: (event, rowData) => {
              return clickCall(rowData.caller_number)
            },
          },
        ]}
      />      
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    session: state.kertelSession
  })
}

export default connect(mapStateToProps, null)(MailsTab);
