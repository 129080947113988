import { GET, PATCH, DELETE } from './api';

export const getIncomingCalls = () => {
  return GET('/calls/incoming');
}

export const getOutgoingCalls = () => {
  return GET('/calls/outgoing');
}

export const downloadCallsHist = (context) => {
  return GET('/calls/' + context + '?format=csv');
}

export const getVoiceMails = () => {
  return GET('/mevo/messages');
}

export const setCallsAsSeen = () => {
  return PATCH('/calls/incoming');
}

export const getMessagesMevoGroup = () => {
  return GET('/mevo/group');
}

export const toggleHideNumberApi = (datas) => {
  return PATCH('/user', {
    data: {
      clip_clir: datas.clipClir
    },
    user_id: datas.userId
  });
}

export const deleteIncomingCalls = (callIds) => {
  return DELETE('/calls/incoming', {
    call_ids: callIds
  });
}

export const deleteOutgoingCalls = (callIds) => {
  return DELETE('/calls/outgoing', {
    call_ids: callIds
  });
}