import { isEmpty } from 'lodash';
import React from 'react';
import { Navigate, Outlet } from 'react-router';

export default function PrivateRoute({ component: Component, ...rest }) {

  const auth = !isEmpty(localStorage.getItem('_k')); // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet /> : <Navigate to="/login" />;
}