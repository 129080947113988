
export const loadingCompany = () => {
  return { type: 'LOADING_COMPANY' }
};

export const getCompany = (informations) => {
  return {
    type: 'GET_COMPANY',
    payload: informations
  }
};

export const stopLoadingCompany = () => {
  return { type: 'STOP_LOADING_COMPANY' }
};

export const startEditingUser = (companyId) => {
  return {
    type: 'START_EDITING_COMPANY',
    payload: companyId
  }
};

export const stopEditingUser = () => {
  return { type: 'STOP_EDITING_COMPANY' }
};
