const initialState = {
  isLoading: false,
  mevo: 0,
  call: 0,
  fax: 0
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'LOADING_NOTIFICATIONS':
      return {
        ...state,
        isLoading: true
      };

    case 'STOP_LOADING_NOTIFICATIONS':
      return {
        ...state,
        isLoading: false
      };

    case 'ADD_SIDEBAR_NOTIFICATION':
      return {
        ...state,
        [action.context]: action.number
      }; 

    default:
      return state
  }
}

export default notificationsReducer;
