import { store } from 'store/index.js';
import { getUsers, getUser, addUser, updateUser } from 'components/commons/api/admin/user';
import {
  loadingUsers,
  loadingUser,
  fetchUsers as fetchUsersAction,
  fetchUser as fetchUserAction,
  stopLoadingUsers,
  stopLoadingUser
} from 'actions/admin/userActions';

export const fetchUsers = async (props) => {
  store.dispatch(loadingUsers());

  try {
    await getUsers().then((result) => {
      return store.dispatch(fetchUsersAction(result.data));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingUsers());
  }
}

export const fetchUser = async (userId) => {
  store.dispatch(loadingUser());
  try {
    await getUser(userId).then((result) => {
      return store.dispatch(fetchUserAction(result.data));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingUser());
  }
}

export const updateUserInformation = async (userId, informations) => {
  store.dispatch(loadingUser());
  try {
    await updateUser(userId, informations).then((result) => {
      return fetchUsers();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingUser());
  }
}

export const addUserInformation = async (informations) => {
  store.dispatch(loadingUser());
  try {
    await addUser(informations).then((result) => {
      return fetchUsers();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingUser());
  }
}
