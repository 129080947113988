import React, { Component } from 'react';
import swal from 'sweetalert';

class ShortNumberModal extends Component {

  handleSaveBtnClick = () => {
    const { columns, onSave } = this.props;

    const newRow = {};
    columns.forEach((column, i) => {
      newRow[column.field] = this.refs[column.field].value;
    }, this);

    let number = newRow.short;
	
	if (parseInt(number.length) !== parseInt(this.props.numbers.lenghShort) || !(parseFloat(number) === number >>> 0) || number < 1) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: 'Numéro court invalid',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	else if (this.props.options.s164.includes(number)) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: 'Duplication du numéro court',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	else if (this.props.numbers.short.includes(number)) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: 'Numéro court déjà utilisé',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}

    // You should call onSave function and give the new row
    onSave(newRow);
  }

  render() {
    const {
      onModalClose,
      onSave,
      columns,
      validateState
    } = this.props;

    const headerStyle = {
      fontWeight: 'bold',
      fontSize: 'large',
      textAlign: 'center',
      backgroundColor: '#eeeeee',
      padding:'50px'
    };

    return (
      <div className='modal-content'>
        <div className='modal-header' style={headerStyle} />
        <div className="modal-body">
          {
            columns.map((column, i) => {
              const {
                field,
                name,
                hiddenOnInsert
              } = column;

              if (hiddenOnInsert) {
                // when you want same auto generate value
                // and not allow edit, for example ID field
                return null;
              }

              const error = validateState[field] ? (<span className='help-block bg-danger'>{ validateState[field] }</span>) : null;

              return (
                <div className='form-group' key={ field }>
                  <label>{ name } : </label>
                  <input ref={ field } type='number' defaultValue={ '' } className=' form-control editor edit-text' maxLength={this.props.numbers.lenghShort} min="1" />
                  { error }
                </div>
              );
            })
          }
        </div>
        <div className="modal-footer">
          <button className='btn btn-inverse mr-2' onClick={ onModalClose }>Annuler</button>
          <button className='btn btn-success' onClick={ () => this.handleSaveBtnClick(columns, onSave) }>Enregistrer</button>
        </div>
      </div>
    );
  }
}

export default ShortNumberModal;
