import React, { Component, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import SviConfigDiagram from './SviConfigDiagram';
import Preloader from 'components/commons/Preloader.js';
import { fetchSviConfig } from 'components/commons/helpers/admin/sviHelper.js';
import { green, red } from '@material-ui/core/colors';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import Popup from "reactjs-popup";
import SviConfigConsistency from './SviConfigConsistency';
import DeleteIcon from '@material-ui/icons/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';

library.add(faCheck, faTimes);

function SviConfigure ({ svi }) {

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(true);
  const [messages, setMessages] = useState([]);
  
  function openModal() {
    setOpen(true);
  }
  
  function closeModal() {
    setOpen(false);
  }
  
  function statusConsistency() {
	
    if (isLoading) {
      return (
      <div className="preloader" style={{width: "1em", height: "1em", margin: "0 0 0 8px"}}>
        <svg className="circular" viewBox="25 25 50 50">
        <circle className="path" cx="50" cy="50" r="8" fill="none" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
      </div>
      );
    }
    
    if (status) {
      return (<FontAwesomeIcon icon="check" color={green[300]} size="sm" />);
    }
    
    return (<FontAwesomeIcon icon="times" color={red[300]} size="sm" />);
  }
  
  function handleOptions(loading, status, messages) {
    setIsLoading(loading);
    setStatus(status);
    setMessages(messages);
  }
  
  async function load() {
	  await fetchSviConfig(svi.configuring.menuId);
  }
    
    if (svi.configuring.isLoading) {
      return (
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-b-0">
              <Breadcrumb routes={[
                "Menus SVI",
                (<span>Configuration du menu svi N° : 
                  <span
                    className="btn btn-warning react-bs-table-del-btn"
                    style={{
                      padding: "2px",
                      marginLeft: "5px"
                    }}
                    onClick={() => {
                      load()
                    }}
                  >{svi.configuring.menuId}</span>
                </span>)
              ]} url="/admin/svi" title="Configuration du menu svi" />
              <table className="table">
                <tbody>
                  <tr>
                    <td colSpan={10}><Preloader /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
    
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={[
              "Menus SVI",
              (<span>Configuration du menu svi N° : 
                <span
                  className="btn btn-warning react-bs-table-del-btn"
                  style={{
                    padding: "2px",
                    marginLeft: "5px"
                  }}
                  onClick={() => {
                    fetchSviConfig(svi.configuring.menuId);
                  }}
                >{svi.configuring.menuId}</span>
              </span>)
            ]} url="/admin/svi" title="Configuration du menu svi" />
              <table style={{width: "100%", marginBottom: "5%"}}>
                <tbody>
				  <tr>
                    <td style={{width: "5%", verticalAlign: "top"}}>
					  <div className="menuConfigSvi">
						<div className="titleConfigSvi"><span className="fa fa-bars" /></div>
						<div className="dropdownConfigSvi">
						
						  <div
							onClick={openModal}
							className="btConfigSvi"
							>
							<table style={{width: "100%"}}>
							  <tbody>
								<tr><td style={{textAlign: "center"}}>Cohérence</td></tr>
								<tr><td style={{textAlign: "center"}}>
								  {statusConsistency()}
								</td></tr> 
							  </tbody>
							</table>
						  </div>
						  
						  <div id="zoomToFit" className="btConfigSvi" style={{marginTop: "8px"}}>
							<table style={{width: "100%"}}>
							  <tbody>
								<tr><td style={{textAlign: "center"}}>Zoom</td></tr>
								<tr><td style={{textAlign: "center"}}><ZoomOutMap style={{marginLeft: "4px"}} /></td></tr> 
							  </tbody>
							</table>
						  </div>
						  
						  <div id="deleteNode" className="btConfigSvi" style={{marginTop: "8px"}}>
							<table style={{width: "100%"}}>
							  <tbody>
								<tr><td style={{textAlign: "center"}}>Suppimer</td></tr>
								<tr><td style={{textAlign: "center"}}><DeleteIcon style={{marginLeft: "4px"}} /></td></tr> 
							  </tbody>
							</table>
						  </div>
						  
						  <div
							className="btConfigSvi"
							style={{marginTop: "8px"}}
							onClick={() => {
							  return navigate('/admin/svi');
							}}
							>
							  <table style={{width: "100%"}}>
								<tbody>
								  <tr><td style={{textAlign: "center"}}>Annuler</td></tr>
								  <tr><td style={{textAlign: "center"}}><span className="fa fa-sign-out" /></td></tr>  
								</tbody>
							  </table>
						  </div>
						  
						</div>
					  </div>
                    </td>
					<td>
					  <SviConfigDiagram
					    svi={svi}
						  handleOptions={handleOptions}
						/>
					</td>
                  </tr>
                </tbody>
              </table>
		  </div>
        </div>
		
        <Popup
          open={open}
          closeOnDocumentClick
          onClose={closeModal}
        >
          <div className="modalPopup">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <div className="header"> Cohérences </div>
            <div className="content" style={{padding: "2%", maxHeight: "600px",overflowY: "scroll",overflowX: "hidden"}}>
              <SviConfigConsistency
                status={status}
				messages={messages}
              />
            </div>
          </div>
        </Popup>
      </div>
    );
}

const mapStateToProps = (state) => {
  return {
    svi: state.svi,
  }
}

export default connect(mapStateToProps, null)(SviConfigure);
