import { store } from 'store/index.js';
import { getBills } from 'components/commons/api/admin/bills';
import {
  loadingBills,
  fetchBills as fetchBillsAction,
  stopLoadingBills
} from 'actions/admin/billsActions';

export const fetchBills = async (props) => {
  store.dispatch(loadingBills());
  
  try {
    await getBills().then((result) => {
      return store.dispatch(fetchBillsAction(result.data));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingBills());
  }
}
