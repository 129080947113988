import { POST  } from './../api';

export const getRecords = (page = 0, limit = 10) => {
  const session = JSON.parse(localStorage.getItem('persist:root')).session;
  
  return POST('/records', {
    "page": page + 1,
    "limit": limit,
    "domaine": JSON.parse(session).apiUrl
  });
}

export const getCountRecords = () => {
  return POST('/records', {
    "action": "count"
  });
}