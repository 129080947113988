import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import Direct from 'components/views/dashboard/Direct.js';
import Preloader from 'components/commons/Preloader.js';

class Directs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb title='Touches de fonction' />
            <DirectsRender
              isLoading={this.state.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

const DirectsRender = (props) => {
  if (props.isLoading) {
    return (<div className="text-center"><Preloader className="primary" /></div>);
  }

  return <Direct />;
}

export default Directs;
