import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stopEditingMevo } from 'actions/admin/mevoGroupActions.js';
import { fetchMevoGroup, updateMevo } from 'components/commons/helpers/admin/mevoGroupHelper.js';
import { updateMevo as updateUserMevo } from 'components/commons/helpers/admin/mevoHelper.js';
import { getMessagesMevoGroup } from 'components/commons/helpers/messagesMevoGroupHelper';
import { getMevoByUser } from 'components/commons/api/admin/mevo';
import Preloader from 'components/commons/Preloader.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import swal from 'sweetalert';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ProgressBar from 'react-bootstrap/ProgressBar'
import MusicModal from 'components/views/modals/MusicModal.js';
import { showModal } from 'actions/modalActions';
import { post } from 'axios';
import { notifyWarning } from 'components/commons/Toasts.js';
import { useNavigate } from 'react-router';

function EditMevoGroupConfig ({ mevogroup, session, messagesMevoGroup, showModal }) {

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [mevogroupState, setMevogroupState] = useState({
		"name": "",
		"id": "",
		"owner": "",
		"quota": "0",
		"nbrMessage": "0",
		"typeMessagerie": "record",
		"typeAnnonce": 'default',
		"fileAnnonce": {"name": "______________"},
		"urlAnnonce": "",
		"fullboxNotification": "disable",
		"emailNotification": "disable",
		"mail": ""
	});

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setMevogroupState(prevState => ({
        ...prevState.mevoGroup,
        [name]: value
    }));
  }

  function handleFileOptions(file) {
    setMevogroupState(prevState => ({
        ...prevState.mevoGroup,
        "fileAnnonce": file
    }));
  }
  
  async function init() {
    if (mevogroup.config.isConfig) {
      try {
        await fetchMevoGroup(mevogroup.config.groupId).then((result) => {
          setMevogroupState(prevState => ({
              ...prevState.mevoGroup,
              "name": mevogroup.data.name,
              "id": mevogroup.data.id,
              "owner": mevogroup.data.owner,
              "quota": mevogroup.data.config.quota,
              "typeMessagerie": mevogroup.data.config.type,
              "typeAnnonce": mevogroup.data.config.announce,
			  "urlAnnonce": `${session.apiUrl}/mevo/announce?user_id=${mevogroup.data.owner}&token=${localStorage.getItem('_mt')}`,
          }));
        })
		
		await getMevoByUser(mevogroup.data.owner).then((result) => {
		  console.log(result);
		  setMevogroupState(prevState => ({
			  ...prevState.mevoGroup,
			  "fullboxNotification": !isUndefined(result.data.notif_fullbox) ? result.data.notif_fullbox.email : "disable",
			  "emailNotification": !isUndefined(result.data.notif) ? result.data.notif.email : "disable",
			  "mail": !isUndefined(result.data.mail) ? result.data.mail : "",
		  }));
		})
		
		await getMessagesMevoGroup();
		
		let messagesMevoGroup = messagesMevoGroup.datas;
		Object.keys(messagesMevoGroup).forEach(function(key) {
		  if (key === mevogroupState.name) {
			setMevogroupState(prevState => ({
				...prevState.mevoGroup,
				"nbrMessage":Object.keys(messagesMevoGroup[key]).length
			}));
		  }
		});
      } catch (e) {
        console.log(e);
      } finally {
	    setIsLoading(false);
	  } 
    }
  }

  useEffect(function() {
	init();
  }, []);

  async function saveEditMevoGroupConfig(){
	
	if (isEmpty(mevogroupState.id)) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: "L'id de la messagerie est invalide",
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	
	let info = {};
	
	info.vms_type = mevogroupState.typeMessagerie;
	info.vms_announce = mevogroupState.typeAnnonce;
	info.notif_fullbox = '{"email":"' + mevogroupState.fullboxNotification + '","sms":"disable"}';
	info.notif = '{"email":"' + mevogroupState.emailNotification + '","sms":"disable"}';
	info.mail = mevogroupState.mail;
	
	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
	
	if (!pattern.test(info.mail) && (mevogroupState.fullboxNotification !== "disable" || mevogroupState.emailNotification !== "disable")) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: "Le mail de la messagerie est invalide",
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	
	if (mevogroupState.fileAnnonce.name !== "______________" && mevogroupState.typeAnnonce !== "default") {
	  let formData = new FormData();
	  
	  formData.append('user_id', mevogroupState.owner);
	  
	  let type = mevogroupState.typeAnnonce;
	  if (type === "name_specified") type = "specified";
	  formData.append('type', type);
	  
	  formData.append('file', mevogroupState.fileAnnonce);
	  
	  const token = localStorage.getItem('_mt');
	  const config = {
		headers: {
		  'content-type': 'multipart/form-data',
		  'auth-token': `${token}`
		}
	  };
	  
	  await post(`${session.apiUrl}/mevo/announce`, formData, config).then()
	  .catch((err) => {
		let { response } = err;
		response = response || {};
		
		let message = "Une erreur est survenue.";
		if (!isUndefined(response.data)) {
		  if (!isUndefined(response.data.validation_messages)) {
			const validationMessage = Object.values(response.data.validation_messages);
			const errDetails = Object.values(validationMessage[0]);
			message = errDetails[0];
		  } else {
			message = response.data.detail;
		  }
		}
		
		notifyWarning(message);
	  });
	}
	
	try {
	  await updateMevo(mevogroupState.id, info);
	  await updateUserMevo(mevogroupState.owner, info, false);
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
	
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Messagerie de Groupe", "Configuration"]} url="/admin/mevogroup" title="Configuration de Messagerie de Groupe" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Paramètres
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
				<Container component="main" style={{marginTop: '30px'}}>
				  <Grid container spacing={2}>
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Messagerie</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <TextField
						name="name"
						disabled={true}
						type="text"
						fullWidth
						value={mevogroupState.name}
					  />
					</Grid>
					<Grid item xs={12} sm={6} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Quota</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <ProgressBar now={(mevogroupState.nbrMessage*100)/mevogroupState.quota} label={`${mevogroupState.nbrMessage}`} />
					</Grid>
					<Grid item xs={12} sm={2}>
					  <FormLabel> / {`${mevogroupState.quota}`} messages</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Type de messagerie</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<select
						  name="typeMessagerie"
						  value={mevogroupState.typeMessagerie}
						  className="custom-select col-12"
						  onChange={handleOptions}
						>
						  <option value="record">Répondeur</option>
						  <option value="message_only">Répondeur non enregistreur</option>
						</select>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Type d'annonce</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<select
						  name="typeAnnonce"
						  value={mevogroupState.typeAnnonce}
						  className="custom-select col-12"
						  onChange={handleOptions}
						>
						  <option value="default">Message par défaut</option>
						  <option value="name_specified">Message semi-personnalisé (Seulement le nom)</option>
						  <option value="personalized">Message personnalisé (Annonce complète)</option>
						</select>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
					
					{ mevogroupState.typeAnnonce !== "default" && (
						<React.Fragment>
						  <Grid item xs={12} sm={2}>
							<FormLabel component="legend" style={{marginTop: '10px'}}>Message d'annonce</FormLabel>
						  </Grid>
						  <Grid item xs={12} sm={4}>
							<audio src={mevogroupState.urlAnnonce} controls>
							  <p>Votre navigateur ne prend pas en charge l'audio HTML. Voici un
								 un <a href={mevogroupState.urlAnnonce}>lien vers le fichier audio</a> pour le 
								 télécharger.</p>
							</audio>
						  </Grid>
						  <Grid item xs={12} sm={1}>
							<a href={'data:audio/mp3;charset=utf-8,' + mevogroupState.urlAnnonce} download="announce_message.mp3">
							  <button className="btn btn-info btn-circle">
								 <i className="fa fa-download"/>
							  </button>
						   </a>
						  </Grid>
						  <Grid item xs={12} sm={5} />
						  
						  <Grid item xs={12} sm={2}>
							<FormLabel component="legend" style={{marginTop: '10px'}}>Modifier l'annonce</FormLabel>
						  </Grid>
						  <Grid item xs={12} sm={4}>
							<FormLabel component="legend" style={{marginTop: '10px'}}>Fichier : {mevogroupState.fileAnnonce.name}</FormLabel>
						  </Grid>
						  <Grid item xs={12} sm={1}>
							<button
							  className="btn btn-danger btn-circle"
							  style={{marginRight: '10px'}}
							  onClick={() => {
								return showModal({
								  title: "Envoyer un fichier audio",
								  component: MusicModal,
								  context: 'mevoGroup',
								  file: handleFileOptions,
								  callback: () => init()
								})
							  }}
							  ><i className="fa fa-edit"></i> </button>
						  </Grid>
						  <Grid item xs={12} sm={5} />
						</React.Fragment>
					  )
					}
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Notification de messagerie pleine</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<select
						  name="fullboxNotification"
						  value={mevogroupState.fullboxNotification}
						  className="custom-select col-12"
						  onChange={handleOptions}
						>
						  <option value="disable">Aucune notification</option>
						  <option value="enable">Notification par email</option>
						</select>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Notification par email de nouveau message</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<select
						  name="emailNotification"
						  value={mevogroupState.emailNotification}
						  className="custom-select col-12"
						  onChange={handleOptions}
						>
						  <option value="disable">Aucune notification</option>
						  <option value="enable">Notification par email</option>
						  <option value="enable_with_file">Notification par email avec fichier attaché</option>
						</select>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
					
					<Grid item xs={12} sm={2}>
					  <FormLabel component="legend" style={{marginTop: '10px'}}>Email</FormLabel>
					</Grid>
					<Grid item xs={12} sm={4}>
					  <FormControl component="fieldset" style={{ float: "left" }}>
						<TextField
						  name="mail"
						  multiline
						  fullWidth
						  value={mevogroupState.mail}
						  onChange={handleOptions}
						/>
					  </FormControl>
					</Grid>
					<Grid item xs={12} sm={6} />
				  
				  </Grid>				  
				</Container>
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/mevogroup');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={saveEditMevoGroupConfig} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    mevoGroup: state.mevoGroup,
    messagesMevoGroup: state.messagesMevoGroup,
	session: state.kertelSession
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingMevo,
	showModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMevoGroupConfig);
