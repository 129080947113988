
export const loadingRingtonesGeneral = () => {
  return { type: 'LOADING_RINGTONES_GENERAL' }
};

export const getRingtonesGeneral = (informations) => {
  return {
    type: 'GET_RINGTONES_GENERAL',
    payload: informations
  }
};

export const stopLoadingRingtonesGeneral = () => {
  return { type: 'STOP_LOADING_RINGTONES_GENERAL' }
};

export const loadingRingtoneGeneral = () => {
  return { type: 'LOADING_RINGTONE_GENERAL' }
};

export const getRingtoneGeneral = (informations) => {
  return {
    type: 'GET_RINGTONE_GENERAL',
    payload: informations
  }
};

export const stopLoadingRingtoneGeneral = () => {
  return { type: 'STOP_LOADING_RINGTONE_GENERAL' }
};

export const startEditingRingtoneGeneral = (ringId) => {
  return {
    type: 'START_EDITING_RINGTONE_GENERAL',
    payload: ringId
  }
};

export const stopEditingRingtoneGeneral = () => {
  return { type: 'STOP_EDITING_RINGTONE_GENERAL' }
};

export const checkTtyIdRingtonesGeneral = (informations) => {
  return {
    type: 'CHECK_TTY_ID_RINGTONES_GENERAL',
    payload: informations
  }
};