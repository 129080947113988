/* LOADING */

export const addImDatas = (datas) => {
  return {
    type: 'ADD_IM_DATAS',
    payload: datas
  }
};

export const addSelectedRoom = (roomId) => {
  return {
    type: 'ADD_SELECTED_ROOM',
    payload: roomId
  }
};

export const addRooms = (rooms) => {
  return {
    type: 'ADD_ROOMS',
    payload: rooms
  }
};

export const addNewMessage = () => {
  return {
    type: 'ADD_NEW_MESSAGE'
  }
};

export const removeNewMessage = () => {
  return {
    type: 'REMOVE_NEW_MESSAGE'
  }
};

export const clearImSession = () => {
  return {
    type: 'CLEAR_IM_SESSION'
  }
};
