const initialState = {
  absence: {
    src: "",
    playing: false
  },
  unavailability: {
    src: "",
    playing: false
  },
  wait: {
    src: "",
    playing: false
  }
}

const musicReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'PLAY_ABSENCE':
      return {
        ...state,
        absence: {
          ...state.absence.src,
          playing: true
        }
      };

    case 'STOP_PLAY_ABSENCE':
      return {
        ...state,
        absence: {
          ...state.absence.src,
          playing: false
        }
      };

    case 'PLAY_UNAVAILABILITY':
      return {
        ...state,
        unavailability: {
          ...state.unavailability.src,
          playing: true
        }
      };

    case 'STOP_PLAY_UNAVAILABILITY':
      return {
        ...state,
        unavailability: {
          ...state.unavailability.src,
          playing: false
        }
      };

    case 'PLAY_WAIT':
      return {
        ...state,
        wait: {
          ...state.wait.src,
          playing: true
        }
      };

    case 'STOP_PLAY_WAIT':
      return {
        ...state,
        wait: {
          ...state.wait.src,
          playing: false
        }
      };

    default:
      return state
  }
}

export default musicReducer;
