
const mevoGroupReducer = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return [];
    case 'LOADING_MEVOS':
      return { ...state, isLoading: true };
    case 'GET_MEVOS':
      return { ...state, datas: action.payload }
    case 'STOP_LOADING_MEVOS':
      return { ...state, isLoading: false };

    case 'LOADING_MEVO':
      return { ...state, isLoading: true };
    case 'GET_MEVO':
      return { ...state, data: action.payload }
    case 'STOP_LOADING_MEVO':
      return { ...state, isLoading: false };

    case 'START_EDITING_MEVO':
      return { ...state, editing: { ...state.editing, isEditing: true, groupId: action.payload } }
    case 'STOP_EDITING_MEVO':
      return { ...state, editing: { ...state.editing, isEditing: false, groupeId: null, datas: {} } }
    
    case 'START_CONFIG_MEVO':
      return { ...state, config: { ...state.config, isConfig: true, groupId: action.payload } }
    case 'STOP_CONFIG_MEVO':
      return { ...state, config: { ...state.config, isConfig: false, groupId: null, datas: {} } }
    
    default:
      return state
  }
}

export default mevoGroupReducer;
