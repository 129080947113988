
export const loadingWaitingQueues = () => {
  return { type: 'LOADING_WAITING_QUEUES' }
};

export const getWaitingQueues = (informations) => {
  return {
    type: 'GET_WAITING_QUEUES',
    payload: informations
  }
};

export const stopLoadingWaitingQueues = () => {
  return { type: 'STOP_LOADING_WAITING_QUEUES' }
};

export const loadingWaitingQueue = () => {
  return { type: 'LOADING_WAITING_QUEUE' }
};

export const getWaitingQueue = (informations) => {
  return {
    type: 'GET_WAITING_QUEUE',
    payload: informations
  }
};

export const stopLoadingWaitingQueue = () => {
  return { type: 'STOP_LOADING_WAITING_QUEUE' }
};

export const startEditingWaitingQueue = (waitingQueueId) => {
  return {
    type: 'START_EDITING_WAITING_QUEUE',
    payload: waitingQueueId
  }
};

export const stopEditingWaitingQueue = () => {
  return { type: 'STOP_EDITING_WAITING_QUEUE' }
};
