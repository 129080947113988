import React, { Component } from "react";
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import Gravatar from 'react-gravatar';
import { createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';

class EditRingtoneGeneralMembers extends Component {

  constructor(props) {
    super(props);

	this.theme = createMuiTheme({
      palette: {
        primary: {
          main: green[400],
        },
        secondary: {
          main: green[600],
        },
      },
    });
  }

  findLocation(id) {

    if (isUndefined(this.props.company.location)) return "Pas de site";

    for (var location in this.props.company.location) {
      if (this.props.company.location[location].id === id) {
        return this.props.company.location[location].name;
      }
    }

    return "Pas de site";
  }

  findGroup(id) {

    if (isUndefined(this.props.company.group)) return "Pas de département";

    for (var group in this.props.company.group) {
      if (this.props.company.group[group].id === id) {
        return this.props.company.group[group].name;
      }
    }

    return "Pas de département";
  }

  tableRef = React.createRef();

  render() {
  	return (
  	  <MuiThemeProvider theme={this.theme}>
  		<MaterialTable
  		  columns={[
  			{ title: 'id', field: 'user_id', hidden: true},
  			{ title: '', field: 'avatar', cellStyle: {textAlign: "center"},
  			  render: rowData => {
            return !isEmpty(rowData.mail) && <Gravatar email={rowData.mail}/>
          }
  			},
  			{ title: 'Prénom', field: 'firstname'},
  			{ title: 'Nom', field: 'lastname'},
  			{ title: 'Département', field: 'group_id',
  			  render: rowData => {
  				return this.findGroup(rowData.group_id);
  			  }
  			},
  			{ title: 'Site', field: 'location_id',
  			  render: rowData => {
  				return this.findLocation(rowData.location_id);
  			  }
  			},
  			{
  			  title: '', field: 'user_type',
  			  lookup: {
  				'1': 'Utilisateur',
  				'2': 'Poste opérateur',
  				'3': "Centre d'appel",
  				'4': "Utilisateur et poste opérateur",
  				'5': "Centre d'appel",
  				'6': "Messagerie de groupe",
  				'7': "File d'attente",
  				'8': "Sonnerie générale",
  				'9': "Ipbx",
  			  }
  			},
  		  ]}
  		  data={this.props.dataMembers}
  		  title=""
  		  localization={
  			{
  			  toolbar: {
  				searchPlaceholder: 'Rechercher',
  				nRowsSelected: '{0} Utilisateur(s) sélectionné(s)'
  			  },
  			  body: {
  				emptyDataSourceMessage: 'Aucun enregistrement à afficher',
  				filterRow: {
  					filterTooltip: 'Filtre'
  				}
  			  },
  			  pagination: {
  				labelRowsPerPage: "Utilisateurs par page",
  				labelDisplayedRows: "{from}-{to} sur {count}",
				labelRowsSelect: "lignes",
  			  },
  			}
  		  }
  		  options={{
  			search: true,
  			pageSize: 10,
  			selection: true,
  			rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? green[100] : '' })
  		  }}
  		  tableRef={this.tableRef}
  		  actions={[
  			{
  			  tooltip: 'Supprimer tous les utilisateurs sélectionnés',
  			  icon: 'delete',
  			  onClick: (evt, data) => this.tableRef.current.onAllSelected(false)
  			}
  		  ]}
  		/>
  	  </MuiThemeProvider>
  	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(EditRingtoneGeneralMembers);
