import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notifySuccess } from 'components/commons/Toasts.js';
import { hideModal } from 'actions/modalActions.js';
import { updateUser } from 'components/commons/api/admin/user';
import { getCompany } from 'components/commons/helpers/admin/companyHelper.js';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

class UpdateShortNumber extends Component {

  constructor(props) {
    super(props);
	
    this.state = {
      old_user_id: props.props.numbers.user_id,
	  new_user_id: "",
      user_s164: [],
	  usersGroup: [],
      user: props.props.numbers.user_id,
      s164: props.props.numbers.number
    }
	
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
	try {
	  if (!isEmpty(this.state.s164) && !isEmpty(this.state.new_user_id)) {
        // Delete s164 number of the old user
        if (!isEmpty(this.state.old_user_id)) {
          let info = {};
          if (!isUndefined(this.state.user_s164[this.state.old_user_id])) info.s164 = this.state.user_s164[this.state.old_user_id];
          var index = info.s164.indexOf(this.state.s164);
          if (index > -1) info.s164.splice(index, 1);
		  
          await updateUser(this.state.old_user_id, info)
        }
		
        // Add s164 number of the new user
        let info = {
		  "s164" : []
		};
		
		if (!isUndefined(this.state.user_s164[this.state.new_user_id])) {
		  info.s164 = !isEmpty(this.state.user_s164[this.state.new_user_id]) ? this.state.user_s164[this.state.new_user_id] : [];
		}
		info.s164.push(this.state.s164);
		
        await updateUser(this.state.new_user_id, info).then((result) => {
          notifySuccess("Numéro court mis à jour avec succès !");
        })
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.props.hideModal();
      return getCompany();
    }
  }

  handleInputChange(idUser) {
    if (idUser.substring(0, 1) === 'G') return false;
    this.setState(state => ({
		...state,
		new_user_id: idUser,
		user: idUser,
      })
    );
  }

  componentDidMount() {
    var typeUser = {
      '1': 'Utilisateur',
      '2': 'Poste opérateur',
      '3': "Centre d'appel",
      '4': "Utilisateur et poste opérateur",
      '5': "Centre d'appel",
      '6': "Messagerie de groupe",
      '7': "File d'attente",
      '8': "Sonnerie générale",
      '9': "Ipbx",
    };
  	var usersGroup = [];
  	var user_s164 = [];
	
    forEach(this.props.users.datas, function(elements) {
      if (!isEmpty(elements.user_type)) {
        let type = (elements.user_type === 5) ? 3 : elements.user_type;
        if (!isUndefined(typeUser[type])) usersGroup[type] = {title: typeUser[type], value: "G" + type, key: "G" + type, children: []};
      }
	  
      user_s164[elements.user_id] = elements.s164;
    })
	
    forEach(this.props.users.datas, function(elements) {
      if (!isEmpty(elements.user_type)) {
        let type = (elements.user_type === 5) ? 3 : elements.user_type;
        let name = !isUndefined(elements.firstname) ? elements.firstname + ' ' + elements.lastname : elements.lastname;
        if (!isUndefined(typeUser[type])) usersGroup[type].children.push({ title: name, value: elements.user_id, key: elements.user_id });
      }
	})
	
    this.setState({ user_s164, usersGroup });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={2}>
		
		  <Grid item xs={12} sm={3}>
			<FormLabel component="legend" style={{marginTop: '20px'}}>N° long</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={1} />
		  <Grid item xs={12} sm={3}>
			<TextField
			  name="s164"
			  value={this.props.props.numbers.number}
			  margin="normal"
			  InputProps={{
				readOnly: true,
			  }}
			/>
		  </Grid>
          <Grid item xs={12} sm={5} />
		  
		  <Grid item xs={12} sm={3}>
			<FormLabel component="legend" style={{marginTop: '10px'}}>Affecté à</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={1} />
		  <Grid item xs={12} sm={4}>
            <TreeSelect
              style={{ width: 300 }}
              value={this.state.user}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={Object.values(this.state.usersGroup)}
              placeholder="Please select"
              treeDefaultExpandAll
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={1} />
        </Grid>
		
        <div className="modal-footer" style={{marginTop: "20px"}}>
          <button
            type="button"
            className="btn btn-outline-secondary waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              return this.props.hideModal()
            }}
          >Annuler</button>
          <input
            type="submit"
            className="btn btn-success waves-effect text-left right-side-toggle"
            value='Enregistrer'
            />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    users: state.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateShortNumber);
