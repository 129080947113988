import { DELETE, PATCH } from './api';

export const deleteVoiceMail = (messageId) => {
  return DELETE('/mevo/messages', {
    message_ids: messageId
  });
}

export const setVoiceMailsAsSeen = (mails) => {
  return PATCH('/mevo/messages', mails);
}
