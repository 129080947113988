const initialState = {
  hasWebPhone: false,
  num: '',
  ringing: false,
  ringback: false,
  displayName: '',
  answered: false,
  held: false,
  muted: false,
  name: '',
  label: '',
  dtmf: '',
  email: '',
  modal_dtmf: false,
  selectedContact: { displayName: '', number: '' },
  calls: {
    incoming: { isLoading: false, datas: [] },
    outgoing: { isLoading: false, datas: [] },
    both: { isLoading: false, datas: [] },
    unseen: { isLoading: false, datas: [], hasReadUnseenCalls: false },
  },
  webPhone: {}
}

const phoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;

    case 'COMPOSE_NUM':
      return { ...state, num: action.payload };

    case 'RINGBACK':
      return { ...state, ringback: true, ringing: false, num: action.payload.number, displayName: action.payload.displayName };

    case 'RINGING':
        return { ...state, ringing: true, ringback: false, answered: false };

    case 'HANGOUT':
      return { ...state, ringing: false, ringback: false, answered: true };
      
    case 'HANGUP':
      return { ...state, ringing: false, ringback: false, answered: false, muted: false, held: false, selectedContact: '' };
      
    case 'ANSWERED':
      return { ...state, ringback: false, ringing: false, answered: true };
      
    case 'HOLD':
      return { ...state, held: true };

    case 'UNHOLD':
          return { ...state, held: false  };

    case 'MUTE':
      return { ...state, muted: true };
      
    case 'UNMUTE':
      return { ...state, muted: false };      

    case 'DTMF_NUM':
            return { ...state, dtmf: action.payload };

    /* LOADING */

    case 'LOADING_INCOMING_CALLS':
      return { ...state, calls: { ...state.calls, incoming: { ...state.calls.incoming, isLoading: true } } };

    case 'LOADING_OUTGOING_CALLS':
      return { ...state, calls: { ...state.calls, outgoing: { ...state.calls.outgoing, isLoading: true } } };

    case 'LOADING_CALL_LOG':
      return { ...state, calls: { ...state.calls, both: { ...state.calls.both, isLoading: true } } };

    /* STOP LOADING */

    case 'STOP_LOADING_INCOMING_CALLS':
      return { ...state, calls: { ...state.calls, incoming: { ...state.calls.incoming, isLoading: false } } };

    case 'STOP_LOADING_OUTGOING_CALLS':
      return { ...state, calls: { ...state.calls, outgoing: { ...state.calls.outgoing, isLoading: false } } };

    case 'STOP_LOADING_CALL_LOG':
      return { ...state, calls: { ...state.calls, both: { ...state.calls.both, isLoading: false } } };

    case 'STOP_LOADING_CALLS':
      return { ...state, loadingMissedCalls: false };

    /* FETCH */

    case 'FETCH_INCOMING_CALLS':
      return { ...state, calls: { ...state.calls, incoming: { ...state.calls.incoming, datas: action.payload } } };

    case 'FETCH_INCOMING_BOTH_CALLS':
      return { ...state, calls: { ...state.calls, both: { ...state.calls.both, datas: action.payload } } };

    case 'FETCH_OUTGOING_CALLS':
      return { ...state, calls: { ...state.calls, outgoing: { ...state.calls.outgoing, datas: action.payload } } };

    case 'FETCH_OUTGOING_BOTH_CALLS':
      return { ...state, calls: { ...state.calls, both: { ...state.calls.both, datas: action.payload } } };

    case 'FETCH_CALL_LOG':
      return { ...state, calls: { ...state.calls, both: { ...state.calls.both, datas: action.payload } } };

    case 'FETCH_UNSEEN_CALLS':
      return { ...state, calls: { ...state.calls, unseen: { ...state.calls.unseen, datas: action.payload, hasReadUnseenCalls: false } } };

    /* READ */

    case 'HAS_READ_UNSEEN_CALLS':
      return { ...state, calls: { ...state.calls, unseen: { datas: [], hasReadUnseenCalls: true } } };

    /* READ */

    case 'SET_WEB_PHONE':
      return { ...state, hasWebPhone: action.payload };

    case 'ADD_SELECTED_CONTACT':
      return { ...state, selectedContact: action.payload };

    default:
      return state
  }
}

export default phoneReducer;
