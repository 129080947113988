import { store } from 'store/index.js';
import { getCompanyContacts, getUserContacts, getUserContact } from 'components/commons/api/contacts';
import filter from 'lodash/filter';
import {
  loadingCompanyContacts,
  loadingUserContacts,
  loadingUserContact,
  fetchCompanyContacts as fetchCompanyContactsAction,
  fetchUserContacts as fetchUserContactsAction,
  fetchUserContact as fetchUserContactAction,
  stopLoadingCompanyContacts,
  stopLoadingUserContacts,
  stopLoadingUserContact,

 } from 'actions/contactsActions';

 export const getContacts = async (props) => {
    var res;
    await getCompanyContacts().then((result) => {
        res = result.datas;
    })

    await getUserContacts().then((result) => {
       res = res.concat(result.datas);
    })

     return res;
   }


export const fetchCompanyContacts = async (props) => {
    store.dispatch(loadingCompanyContacts());

    try {
      await getCompanyContacts().then((result) => {
        return store.dispatch(fetchCompanyContactsAction(result.datas));
      })
    } catch (e) {
      console.log(e);
    } finally {
      store.dispatch(stopLoadingCompanyContacts());
    }
  }

export const fetchUserContacts = async (props) => {
    store.dispatch(loadingUserContacts());

    try {
      await getUserContacts().then((result) => {
        return store.dispatch(fetchUserContactsAction(result.datas));
      })
    } catch (e) {
      console.log(e);
    } finally {
      store.dispatch(stopLoadingUserContacts());
    }
  }

export const fetchUserContact = async (contactId) => {
    store.dispatch(loadingUserContact());

    try {
      await getUserContact(contactId).then((result) => {
        return store.dispatch(fetchUserContactAction(result.datas[0]));
      })
    } catch (e) {
      console.log(e);
    } finally {
      store.dispatch(stopLoadingUserContact());
    }
  }

export const getAlphabeticallyOrderedUsers = (users) => {
  const usersList = filter(users, function(u) { return u.user_type === "Utilisateur" || parseInt(u.user_type) === 2 });
  const callCenter = filter(users, function(u) { return u.user_type === "Poste opérateur" || parseInt(u.user_type) === 1 });
  const commonRings = filter(users, function(u) { return u.user_type === "Sonnerie générale" || parseInt(u.user_type) === 3 });
  const waitingQueues = filter(users, function(u) { return u.user_type === "File d'attente" || parseInt(u.user_type) === 4 });

  return { usersList: usersList, callCenter: callCenter, commonRings: commonRings, waitingQueues: waitingQueues }
}
