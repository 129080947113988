import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearSession, toggleHideNumber } from 'actions/authActions.js';
import { toggleDarkMode } from 'actions/settingsActions.js';
import Gravatar from 'react-gravatar';
import SidebarIcons from 'components/commons/SidebarIcons.js';
import { getConfigAuth } from 'components/commons/api/auth.js';
import { toggleHideNumberApi } from 'components/commons/api/phone.js';
import swal from 'sweetalert';
import isUndefined from 'lodash/isUndefined';
import { removeNewMessage, clearImSession } from 'actions/imActions.js';
import { isEmpty, isNull } from 'lodash';
import { useLocation, useNavigate } from 'react-router';

function SideBar({ session, im, settings, ToIP, removeNewMessage, clearSession, clearImSession }) {

  const [phoneNode, setPhoneNode] = useState(false);
  const [showUserOptions, setShowUserOptions] = useState(false);

  const navigate = useNavigate();
  const pathname = useLocation();

  let clipClir = !isUndefined(session.user) ? session.user.clip_clir : false;
  const userId = !isUndefined(session.user) ? session.user.user_id : 0;

  function toggleShowUserOptions() {
    setShowUserOptions((showUserOptions) => !showUserOptions)

    return;
  }

  async function handleSwitchChange() {
    try {
      if (settings.darkMode) {
        document.body.setAttribute('style', 'background: #FFFFFF !important');
      } else {
        document.body.setAttribute('style', 'background: #181a1b !important');
      }

      return toggleDarkMode();
    } catch (e) {
      console.log(e);
    }
  }

  async function logout() {
    const currentUrl  = window.location.origin + '/';
    let apiUrl = session.apiUrl;
    
    await getConfigAuth('auth').then((result) => {
      
      if (result.status !== 200) {
        return swal({
          type: 'error',
          icon: "error",
          title: 'Oops...',
          text: "Échec de la déconnexion Keycloak.",
          confirmButtonColor: 'LightSeaGreen',
        })
      }
      
      let keycloak_url = "";
      if (!isUndefined(result.data)) {
        if (!isUndefined(result.data.AUTH_KEYCLOAK_URL)) {
          keycloak_url = result.data.AUTH_KEYCLOAK_URL;
        }
      }

      const { mxToken } = im;

      clearImSession();
      clearSession();

      const imFrame = document.getElementById("imIframe");

      if (!isUndefined(imFrame) && !isNull(imFrame)) {
        return imFrame.contentWindow.postMessage('logout', '*');
      }
      
      let redirect_uri = encodeURIComponent( apiUrl + "/ssoAuth/" + new Buffer(currentUrl).toString('base64') + '?type=logout');

      fetch(`${session.user.servers.matrix.server}/_matrix/client/r0/logout`, { 
        method: 'post', 
        headers: new Headers({
          'Authorization': 'Bearer ' + mxToken, 
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
      }).then(function() {
        window.location.href = keycloak_url + "/protocol/openid-connect/logout?redirect_uri=" + redirect_uri;
      });
    })
  }
  
  return (
    <aside className="left-sidebar">
      <div className="scroll-sidebar">
        <div className="user-profile">
          <Gravatar email={session.datas.data.user_email} />
          <div className="profile-text">
            <button
              id="sidebar-profile-btn"
              className="dropdown-toggle link u-dropdown"
              onClick={toggleShowUserOptions}
              style={{ cursor: 'pointer' }}
              >{session.datas.data.user_display_name} <span className="caret"></span></button>
            {showUserOptions && <div className="btn">
              <button
                onClick={() => {logout();}}
                className="btn btn-outline-warning"
              >
                <i className="fa fa-power-off mr-2"></i>
                  Déconnexion
              </button><br /><br />
              <button
                onClick={() => {navigate('/me');}}
                className="btn btn-outline-info"
              >
                <i className="fa fa-user mr-2"></i>
                  Mes informations
              </button>
            </div>}
          </div>
        </div>
        <nav className="sidebar-nav">
          <SidebarIcons ToIP={ToIP} />
          <ul id="sidebarnav">
            <li><button className={pathname === '/dashboard' ? 'selected' : undefined} onClick={() => { return navigate('/dashboard')}} aria-expanded="false"><i className="mdi mdi-home"></i><span className="hide-menu">Accueil</span></button></li>
            <li className={phoneNode ? 'active' : ''}>
              <button className="has-arrow" aria-expanded={phoneNode} onClick={() => { if (!phoneNode) navigate('/recents');  return setPhoneNode((phoneNode) => !phoneNode) }}>
                <i className="mdi mdi-phone"></i>
                <span className="hide-menu">Téléphone</span>
              </button>
              <ul aria-expanded={phoneNode} className={`collapse ${phoneNode ? 'in' : ''}`}>
                <li>
                  <button className={pathname === '/recents' ? 'selected' : undefined} onClick={() => {
                    return navigate('/recents');
                  }}> Journal d'appels</button>
                </li>
                <li>
                  <button className={pathname === '/voicemail' ? 'selected' : undefined} onClick={() => {
                    return navigate('/voicemail');
                  }}> Messagerie vocale</button>
                </li>
                <li>
                  <button className={pathname === '/musics' ? 'selected' : undefined} onClick={() => {
                    return navigate('/musics');
                  }}> Messages et musiques</button>
                </li>
                <li>
                  <button className={pathname === '/inbound-management' ? 'selected' : undefined} onClick={() => {
                    return navigate('/inbound-management');
                  }}>Configuration avancée</button>
                </li>
                <li>
                  <button className={pathname === '/direct' ? 'selected' : undefined} onClick={() => {
                    return navigate('/direct');
                  }}> Touches de fonction</button>
                </li>
                {session.godMode && <li>
                  <button className={pathname === '/supervision' ? 'selected' : undefined} onClick={() => {
                    return navigate('/supervision');
                  }}> Supervision</button>
                </li>}
                <li>
                  <button>
                    Masquer numéro                
                    <div className="material-switch pull-right">
                      <input
                        className="form-control"
                        name="shared"
                        type="checkbox"
                        onChange={toggleHideNumber}
                        checked={clipClir}
                        value={clipClir}
                      />
                      <label onClick={() => {
                          toggleHideNumberApi({clipClir: !clipClir, userId: userId});
                          toggleHideNumber();
                        }} htmlFor="someSwitchOptionPrimary" className="label-primary"></label>
                    </div>
                  </button>
                </li>
              </ul>
            </li>
            {!isEmpty(session.user.fax) && <li>
              <button
                className={pathname === '/fax' ? 'selected' : undefined}
                onClick={() => {
                  return navigate('/fax');
                }}
                aria-expanded="false"
              ><i className="fa fa-fax"></i><span className="hide-menu">Fax</span>
              </button>
            </li>}
            <li>
              <button
                className={pathname === '/contacts' ? 'selected' : undefined}
                onClick={() => {
                  return navigate('/contacts')}
                }
                aria-expanded="false"
              ><i className="mdi mdi-account-multiple"></i><span className="hide-menu">Annuaire</span>
              </button>
            </li>
            <li>
              <button
                className={pathname === '/scheduler' ? 'selected' : undefined}
                onClick={() => {
                  return navigate('/scheduler')}
                }
                aria-expanded="false"
              ><i className="mdi mdi-calendar"></i><span className="hide-menu">Planification</span>
              </button>
            </li>
            <li>
              <button className={`${pathname === '/instant-messaging' ? 'selected' : undefined}`}
                  onClick={() => {
                    removeNewMessage();
                    return navigate('/instant-messaging');
                  }}
                ><i className="fa fa-comments-o"></i><span className="hide-menu">Messagerie instantanée</span>
              </button>
            </li>
            <li>
              <button>
                <i className="fa fa-moon-o"></i> Thème sombre                
                <div className="material-switch pull-right">
                  <input
                    className="form-control"
                    name="shared"
                    type="checkbox"
                    onChange={handleSwitchChange}
                    checked={settings.darkMode}
                  />
                  <label onClick={handleSwitchChange} htmlFor="someSwitchOptionPrimary" className="label-primary"></label>
                </div>
              </button>
            </li>
          </ul>  
        </nav>
      </div>
    </aside>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession,
    im: state.im,
    settings: state.settings
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearSession,
    toggleDarkMode,
    toggleHideNumber,
    removeNewMessage,
    clearImSession
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
