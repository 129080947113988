const recordsReducer = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return [];
    case 'LOADING_RECORDS':
      return { ...state, isLoading: true };
    case 'FETCH_RECORDS':
      return { ...state,  datas: action.payload }
    case 'FETCH_COUNT_RECORDS':
      return { ...state,  count: action.payload }
    case 'STOP_LOADING_RECORDS':
      return { ...state, isLoading: false };
    default:
      return state
  }
};

export default recordsReducer;
