import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { fetchMevos } from 'components/commons/helpers/admin/mevoGroupHelper.js';
import { getCompany } from 'components/commons/helpers/admin/companyHelper.js';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { startEditingMevo } from 'actions/admin/mevoGroupActions.js';
import MevoGroupTab from './MevoGroupTab.js';

class MevoGroup extends Component {
  componentDidMount() {
    fetchUsers();
	getCompany();
    fetchMevos();
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Messagerie de groupe"]} title="Messagerie de groupe" addGs={true} />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" role="tab" href="#gi" onClick={fetchMevos}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Messagerie de groupe
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane p-20 active" id="gi" role="tabpanel">
                <MevoGroupTab
                  isLoading={this.props.mevoGroup.isLoading}
                  mevoGroup={this.props.mevoGroup.datas}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      mevoGroup: state.mevoGroup
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingMevo,
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(MevoGroup);
