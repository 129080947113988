import React, { Component } from 'react';
import { showModal } from 'actions/modalActions.js';
import { getPhoneButtons, savePhoneButton } from 'components/commons/api/direct';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DirectModal from 'components/views/modals/DirectModal.js';
import Preloader from 'components/commons/Preloader.js';
import { setEditingButton, removeDirectButtons } from 'actions/directActions.js';
import isEmpty from 'lodash/isEmpty';
import { notifyInfo, notifyWarning } from 'components/commons/Toasts.js';

class Direct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttons: [],
      isLoadingButtons: false,
      type: ""
    }
  }

  async componentDidMount() {
    try {
      this.setState({ isLoadingButtons: true });
      await this.props.removeDirectButtons();
      const buttonsApi = await getPhoneButtons();
      const buttons = buttonsApi.data.buttons;
      if (!isEmpty(buttons)) {
        this.setState({ type: buttonsApi.data.type, buttons: buttons });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoadingButtons: false });
    }
  }

  render() {
    if (this.state.isLoadingButtons) {
      return (
        <div className="text-center">
          <Preloader color="primary" />
        </div>
      );
    }

    if (isEmpty(this.state.buttons)) {
      return (
        <div className="card" id="directKeys">
          <div className="card-body p-b-0">
            <h4 className="card-title">Touches de fonction</h4>
            <div className="row">
              <div className="col-lg-12 text-center">
                <h5 className="text-muted">Vous n'avez pas de poste comportant des boutons à configurer</h5>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div id="directKeys">
        <div className="card-body p-b-0">
          <div className="row">
            <div className='col-lg-6'>
              {this.state.buttons.map((button, index) => {
                return (
                  <div className="col-md-12 col-lg-12 col-xlg-12" key={index}
                    onClick={() => {
                      this.props.setEditingButton(button)
                      return this.props.showModal({
                        title: "Configuration touche directe",
                        component: DirectModal
                      });
                    }}
                  >
                    <div className="card">
                      <div className="row">
                        <div className="col-md-4 col-lg-3 text-left">
                          <span className="btn btn-warning btn-circle btn-xs">{index+1}</span>
                        </div>
                        <div className="col-md-8 col-lg-9">
                          <small>{(isEmpty(button.label) || button.label === "-")  ? <i>Aucune action</i> : button.label}</small>
                        </div>
                      </div>
                    </div>
                </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row justify-content-end form-group">
          {this.props.buttons.buttons.length > 0 && <div className="col-3">
            <button className="btn btn-danger" onClick={(e) => { e.preventDefault(); return this.props.removeDirectButtons(); }}>Annuler le{this.props.buttons.buttons.length > 1 ? 's' : ''} {this.props.buttons.buttons.length > 1 && this.props.buttons.buttons.length} bouton{this.props.buttons.buttons.length > 1 ? 's' : ''}</button>
          </div>}
            <div className="col-3">
              <button className="btn btn-success" onClick={(e) => {
                e.preventDefault();
                this.props.removeDirectButtons();
                return savePhoneButton(this.props.buttons.buttons, this.state.type, this.props.session.datas.data.user_id).then((result) => {
                  notifyInfo("Boutons sauvegardés !");
                  return  this.componentDidMount();
                }).catch((e) => {
                  console.log(e);
                  return notifyWarning("Une erreur est survenue.");
                });
              }}>Enregistrer</button>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    buttons: state.buttons,
    session: state.kertelSession
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal,
    setEditingButton,
    removeDirectButtons
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Direct);
