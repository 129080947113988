import React, { useEffect } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {getUsersSupervisionInformation} from 'components/commons/api/admin/supervision.js';
import { getUsersSupervision as getUsersSupervisionAction } from 'actions/admin/supervisionActions';

function Supervision () {

  const session = useSelector(state => state.kertelSession);
  const supervision = useSelector(state => state.supervision);

  const dispatch = useDispatch();

  async function init() {
    if (isUndefined(supervision.users)) {
      let supervised = await getUsersSupervisionInformation(session.user.user_id);
      supervised = supervised.data;

      if (!isEmpty(session.user.tty)) {
          supervised[session.user.user_id] = {
            "firstname": session.user.firstname,
            "tty": [{ "full_line_id": session.user.tty[0].full_line_id }],
            "status": 'sIdle'
          }
      }

      Object.entries(supervised).forEach(s => s[1].status = 'sIdle');
      dispatch(getUsersSupervisionAction(supervised));
    }
  }

  useEffect(function() {
    init();
  }, [])

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body p-b-0">
          <Breadcrumb title="Supervision"/>
          <div className="row">
            {!isUndefined(supervision.users) && <SupervisionRender users={supervision.users} />}
          </div>
        </div>
      </div>
    </div>
  );
}

const SupervisionRender = (props) => {
  return Object.entries(props.users).map((user, index) => {
    return (
      <div key={index} className="md-chip md-chip-hover col-lg-1">
        <div className={`md-chip-icon md-chip-icon-${user[1].status || "sIdle"}`}><IconRender status={user[1].status} /></div>
        {user[1].firstname} {user[1].lastname}
      </div>
    )
  })
}

const IconRender = (props) => {
  switch (props.status) {
    case 'sRing':
    case 'sRingback':  
      return <div className="pulse pulseRinging"><i className="fa fa-phone fa-2x"></i> </div>

    case 'sConnected':
      return <div className="pulse pulseConnected"><i className="fa fa-phone fa-2x"></i> </div>
    
    case 'sHold':    
      return <div className="pulse pulsePaused"><i className="fa fa-pause fa-2x"></i> </div>

    case 'sBusy':
      return <div className="pulse pulseBusy"><i className="fa fa-hourglass-half fa-2x"></i> </div>
  
    default:
      return <div className="pulseOff"><i className="fa fa-phone fa-2x"></i> </div>
  }
}

export default Supervision;
