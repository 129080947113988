import { POST, PATCH } from './api';

export const getPhoneButtons = (userId) => {
  return POST('/phone/buttons', {
    user_id: userId || ""
  });
}

export const savePhoneButton = (buttons, type, userId) => {
  return PATCH('/phone/buttons', {
    user_id: userId || "",
    type: type,
    buttons: buttons
  });
}
