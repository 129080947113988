import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUser } from 'components/commons/helpers/admin/userHelper.js';
import Preloader from 'components/commons/Preloader.js';
import { stopEditingUser } from 'actions/admin/userActions.js';
import { notifySuccess } from 'components/commons/Toasts.js';
import { updateUser } from 'components/commons/api/admin/user';
import UpdateUserOptionsInfos from './UpdateUserOptionsInfos.js';
import UpdateUserOptionsNumbers from './UpdateUserOptionsNumbers.js';
import UpdateUserOptionProfils from './UpdateUserOptionProfils.js';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

function UpdateUser ({ users, company, stopEditingUser }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [userState, setUserState] = useState(users.editing.datas);

  async function editUser() {
    try {
	  let info = {};
	  if (!isUndefined(userState.civility)) info.civility = userState.civility;
	  if (!isUndefined(userState.firstname)) info.firstname = userState.firstname;
	  if (!isUndefined(userState.lastname)) info.lastname = userState.lastname;
	  if (!isUndefined(userState.login)) info.login = userState.login;
	  if (userState.password !== "********" && userState.password) {
		info.password = userState.password;
		
		// Password should have : at least 8 characters, at least one upper case letter (A – Z), at least one lower case letter(a-z), At least one digit (0 – 9) .
		var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
		
		if (!regExp.test(info.password)) {
		  return swal({
			type: 'error',
			icon: "error",
			title: 'Oops...',
			text: "Le mot de passe est invalide",
			confirmButtonColor: 'LightSeaGreen',
		  })
		}
	  }
	  if (!isUndefined(userState.mail)) info.mail = userState.mail;
	  if (!isUndefined(userState.sso_email)) info.sso_email = userState.sso_email;
	  if (!isUndefined(userState.administrator)) info.administrator = userState.administrator;
	  if (!isUndefined(userState.allow_impersonate)) info.allow_impersonate = userState.allow_impersonate;
	  if (!isUndefined(userState.group_id)) info.group_id = userState.group_id;
	  if (!isUndefined(userState.location_id)) info.location_id = userState.location_id;
	  if (!isUndefined(userState.mobile)) info.mobile = userState.mobile;
    if (!isUndefined(userState.alt_e164)) info.alt_e164 = userState.alt_e164;
	  if (!isUndefined(userState.clip_clir)) info.clip_clir = userState.clip_clir;
	  if (!isUndefined(userState.clip_called)) info.clip_called = userState.clip_called;
	  if (!isUndefined(userState.clip_previous)) info.clip_previous = userState.clip_previous;
	  if (!isUndefined(userState.e164)) info.e164 = userState.e164;
	  if (!isUndefined(userState.fax)) info.fax = userState.fax;
	  if (!isUndefined(userState.s164)) info.s164 = userState.s164;
	  if (!isUndefined(userState.profile_id)) info.profile_id = userState.profile_id;
	  if (!isUndefined(userState.outgoingcall_profile_id)) info.outgoingcall_profile_id = userState.outgoingcall_profile_id;
	  if (!isUndefined(userState.incomingcall_profile_id)) info.incomingcall_profile_id = userState.incomingcall_profile_id;
	  
      await updateUser(users.editing.userId, info).then((result) => {
        notifySuccess("Utilisateur mis à jour avec succès !");
        users.editing.isEditing = false;
        return navigate('/admin/user');
      })
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setUserState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  function handleAutocomplete(data) {
    setUserState(prevState => ({
      ...prevState,
      [data.name]: data.value
    }))
  }

  function handleOptionsNumbers(type, number) {
  	if (type === 'addLong') {
      setUserState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          e164: [
            ...prevState.user.e164, number.long
          ]
        }
      }))
    }

  	else if (type === 'addFax') {
      setUserState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          fax: [
            ...prevState.user.fax, number.fax
          ]
        }
      }))
    }

  	else if (type === 'addShort') {
      setUserState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          s164: [
            ...prevState.user.s164, number.short
          ]
        }
      }))
    }

  	else if (type === 'delLong') {
      let e164 = userState.e164;
  	  forEach(number, function(value, key) {
  	    var index = e164.indexOf(value);
        if (index > -1) e164.splice(index, 1);
  	  })

      setUserState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          e164: e164
        }
      }))
    }

  	else if (type === 'delFax') {
      let faxes = userState.fax;
      forEach(number, function(value, key) {
        var index = faxes.indexOf(value);
        if (index > -1) faxes.splice(index, 1);
      })

      setUserState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          faxes: faxes
        }
      }))
    }

  	else if (type === 'delShort') {
      let s164 = userState.s164;
  	  forEach(number, function(value, key) {
  	    var index = s164.indexOf(value);
  	    if (index > -1) s164.splice(index, 1);
  	  })

      setUserState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          s164: s164
        }
      }))
    }
  }

  function getLocations() {
    let locations = [];

    if(!isUndefined(company.location)) {
	  forEach(company.location, function(value, key) {
		locations.push({name: 'location_id', value: value.id, label: value.name});
	  })
    }

    return locations;
  }

  function getGroups() {
    let groups = [];

    if (!isUndefined(company.group)) {
	  forEach(company.group, function(value, key) {
		groups.push({name: 'group_id', value: value.id, label: value.name});
	  })
    }

    return groups;
  }

  function getProfils() {
	let profile_services = [];
	let profile_calls_in = [];
	let profile_calls_out = [];

    if (!isUndefined(company.profile_services)) {
	  forEach(company.profile_services, function(value, id) {
		profile_services.push({name: 'profile_id', value: id, label: value});
	  })
    }

    if (!isUndefined(company.profil_calls_out)) {
	  forEach(company.profil_calls_out, function(value, key) {
		if (value.id !== 'HIDDEN') profile_calls_out.push({name: 'outgoingcall_profile_id', value: value.id, label: value.name});
	  })
    }

    if (!isUndefined(company.profil_calls_in)) {
	  forEach(company.profil_calls_in, function(value, id) {
		if (value.id !== 'HIDDEN') profile_calls_in.push({name: 'incomingcall_profile_id', value: id, label: value.name});
	  })
    }

    return {
	  profile_services: profile_services,
	  profile_calls_in: profile_calls_in,
	  profile_calls_out: profile_calls_out,
    };
  }

  async function init () {
    if (users.editing.isEditing) {
      try {
        await fetchUser(users.editing.userId).then((result) => {
          let userInfos = users.editing.datas;
          
          if (!isUndefined(userInfos.sso.email)) userInfos.sso_email = userInfos.sso.email;
          if (isNull(userInfos.sso_email))userInfos.sso_email = "";
          
          setUserState(prevState => ({
            ...prevState,
            user: userInfos
          }))
        })
      } catch (e) {
        console.log(e);
      } finally {
	    setIsLoading(false);
	  }
    }
  }

  useEffect(function() {
    init();
  }, []);
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
	
    if (isEmpty(userState)) return navigate('/admin/user');
	
	let numbers = {
	  'long' : [],
	  'fax' : [],
	  'short' : [],
	  'lenghShort' : company.short_length
	};

	forEach(company.long, function(value, number) {
	  if (isEmpty(value)) numbers.long.push(number);
	})

	forEach(company.fax, function(value, number) {
	  if (isEmpty(value)) numbers.fax.push(number);
	})

	forEach(company.short, function(value, number) {
	  numbers.short.push(number);
	})

    if (users.editing.isEditing) {
      return (
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body p-b-0">
              <Breadcrumb routes={["Utilisateurs", "Edition de l'utilisateur"]} url="/admin/user" title="Edition de l'utilisateur" />
              <ul className="nav nav-tabs customtab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
                    <span className="hidden-sm-up">
                      <i className="ti-home"></i>
                    </span>
                    <span className="hidden-xs-down">
                      Informations générales
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#numbers" role="tab">
                    <span className="hidden-sm-up">
                      <i className="ti-home"></i>
                    </span>
                    <span className="hidden-xs-down">
                      Configurations des numéros
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#profils" role="tab">
                    <span className="hidden-sm-up">
                      <i className="ti-home"></i>
                    </span>
                    <span className="hidden-xs-down">
                      Configurations des profils
                    </span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active p-20" role="tabpanel" id="infos">
                  <UpdateUserOptionsInfos
                    options={userState}
                    handleOptions={handleOptions}
                    groups={getGroups}
                    locations={getLocations}
                  />
                </div>
                <div className="tab-pane p-20" role="tabpanel" id="numbers">
                  <UpdateUserOptionsNumbers
                    options={userState}
                    handleOptions={handleOptions}
					handleOptionsNumbers={handleOptionsNumbers}
                    handleAutocomplete={handleAutocomplete}
                    groups={getGroups}
                    locations={getLocations}
                    profils={getProfils}
					numbers={numbers}
					company={company}
                  />
                </div>
                <div className="tab-pane p-20" role="tabpanel" id="profils">
                  <UpdateUserOptionProfils
                    options={userState}
                    handleOptions={handleOptions}
					handleOptionsNumbers={handleOptionsNumbers}
					handleAutocomplete={handleAutocomplete}
                    groups={getGroups}
                    locations={getLocations}
                    profils={getProfils}
					numbers={numbers}
                  />
                </div>
                <div className="form-actions m-4">
                  <div className="row">
                    <div className="col-md-12 ">
                      <span className="float-right">
                        <button
                          type="button"
                          className="btn btn-inverse mr-2"
                          onClick={() => {
                            stopEditingUser();
                            return navigate('/admin/user');
                          }}
                        >
                          Annuler
                        </button>
                        <button onClick={editUser} type="submit" className="btn btn-success">Enregistrer</button>
                      </span>
                    </div>
                    <div className="col-md-6"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

const mapStateToProps = (state) => {
  return {
	company: state.company,
	users: state.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingUser
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
