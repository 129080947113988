const initialState = {
  activated: false,
  forwards: []
}

const forwardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'ACTIVATE_CUSTOM_FORWARDS':
      return {
        activated: true
      };

    case 'DEACTIVATE_CUSTOM_FORWARDS':
      return {
        activated: false
      };

    case 'ADD_FORWARDS':
      return {
        forwards: action.payload
      };

    default:
      return state
  }
}

export default forwardReducer;
