
import { store } from 'store/index.js';

const companyReducer = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return [];
    case 'LOADING_COMPANY':
      return { ...state, isLoading: true };
    case 'GET_COMPANY':
      localStorage.setItem('company', store.kertelSession.user.company);
      localStorage.setItem('provider', store.kertelSession.user.provider);
      return state;
    case 'STOP_LOADING_COMPANY':
      return { ...state, isLoading: false };
    case 'START_EDITING_COMPANY':
      return { ...state, editing: { ...state.editing, isEditing: true, companyId: action.payload } }
    case 'STOP_EDITING_COMPANY':
      return { ...state, editing: { ...state.editing, isEditing: false } }
    default:
      return state
  }
}

export default companyReducer;
