import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { clearSession } from 'actions/authActions.js';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router';

function Logout ({ clearSession }) {

  const navigate = useNavigate();
  
  useEffect(() => {
    clearSession();    
	  navigate('/');

    return;
  }, [])
  
	return (
	  <div className="loader loader-default is-active" />
	);
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
	clearSession,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Logout);
