export const showModal = (component) => {
  return {
    type: 'SHOW_MODAL',
    payload: component
  }
};

export const hideModal = () => {
  return {
    type: 'HIDE_MODAL'
  }
};
