import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getForwardInformation } from 'components/commons/api/admin/forward';
import Preloader from 'components/commons/Preloader.js';
import { saveForwardConfig } from 'components/commons/api/admin/forward.js';
import { notifyInfo } from 'components/commons/Toasts.js';
import { getUsers } from 'components/commons/api/user';
import { getAlphabeticallyOrderedUsers } from 'components/commons/helpers/contactHelper.js';

class ForwardLayout extends Component {

  usrForwards   = {entity: {type: "NONE"}} ;
  busyForwards  = {entity: {type: "NONE"}} ;
  naForwards    = {entity: {type: "NONE"}} ;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      myForwards: [],
      incomingSecondSelect: false,
      incomingSecondInput: false,
      incomingValue: '',
      incomingUserValue: '',
      busyUserValue: '',
      naUserValue: '',
      isLoadingAllIncomings: false,
      value: '',
      composantA: false,
      isLoadingOccupation: false,
      isSaving: false,
      users: [],
      incomingAnnounceValue: 1
    }

    this.handleIncomingChange           = this.handleIncomingChange.bind(this);
    this.handleIncommingNumberChange    = this.handleIncommingNumberChange.bind(this);
    this.saveForward                    = this.saveForward.bind(this);
    this.getUsersForwards               = this.getUsersForwards.bind(this);
    this.handleBusyUserChange           = this.handleBusyUserChange.bind(this);
    this.handleNaUserChange             = this.handleNaUserChange.bind(this);
    this.handleIncommingUserChange      = this.handleIncommingUserChange.bind(this);
    this.handleBusyChange               = this.handleBusyChange.bind(this);
    this.handleBusyNumberChange         = this.handleBusyNumberChange.bind(this);
    this.handleNaChange                 = this.handleNaChange.bind(this);
    this.handleNaNumberChange           = this.handleNaNumberChange.bind(this);
    this.handleSecondsDelayInput        = this.handleSecondsDelayInput.bind(this);
    this.handleIncommingAnnounceChange  = this.handleIncommingAnnounceChange.bind(this);
    this.handleBusyAnnounceChange       = this.handleBusyAnnounceChange.bind(this);
    this.handleNaAnnounceChange         = this.handleNaAnnounceChange.bind(this);

  }

  async componentDidMount() {

    this.getUsersForwards('');
    try {
      await getForwardInformation(this.props.userId).then((result) => {
        if(!isEmpty(result.data.usr)){
          this.usrForwards = result.data.usr.forwards[0].dests[0];
          if(this.usrForwards.entity.type === "USER"){
            this.setState({
              incomingSecondSelect: true,
              incomingSecondInput: false,
              isLoadingAllIncomings: false,
              incomingValue: "USER",
              incomingUserValue: this.usrForwards.entity.data.id
            });
          }
        }
        if(!isEmpty(result.data.busy)){
          this.busyForwards = result.data.busy.forwards[0].dests[0];
          if(this.busyForwards.entity.type === "USER"){
            this.setState({
              busySecondSelect: true,
              busySecondInput: false,
              isLoadingAllBusy: false,
              busyValue: "USER",
              busyUserValue: this.busyForwards.entity.data.id
            });
          }
        }
        if(!isEmpty(result.data.na)){
          this.naForwards = result.data.na.forwards[0].dests[0];
          if(this.naForwards.entity.type === "USER"){
            this.setState({
              naSecondSelect: true,
              naSecondInput: false,
              isLoadingAllNa: false,
              naValue: "USER",
              naUserValue: this.naForwards.entity.data.id
            });
          }
        }

      })
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }

  }

  async saveForward() {
    try {
      this.setState({ isSaving: true });

      if(!isEmpty(this.state.myForwards.usr)){
        const usrForwards = this.state.myForwards.usr.forwards;
        await saveForwardConfig({user_id: this.props.userId, forward_type: 'usr', forwards: usrForwards });
      }

      if(!isEmpty(this.state.myForwards.busy)){
        let busyForwards = this.state.myForwards.busy.forwards;
        await saveForwardConfig({ user_id: this.props.userId,forward_type: 'busy', forwards: busyForwards });
      }

      if(!isEmpty(this.state.myForwards.na)){
        let naForwards = this.state.myForwards.na.forwards;
        console.log(naForwards);
        await saveForwardConfig({user_id: this.props.userId, forward_type: 'na', forwards: naForwards });
      }

      return notifyInfo("Renvois d'appels sauvegardés !");
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isSaving: false });
    }
  }


  render() {

    if (this.state.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

      if(!isEmpty(this.state.myForwards.usr))
          this.usrForwards = this.state.myForwards.usr.forwards[0].dests[0];

      if(!isEmpty(this.state.myForwards.busy))
          this.busyForwards =  this.state.myForwards.busy.forwards[0].dests[0];

      if(!isEmpty(this.state.myForwards.na))
          this.naForwards = this.state.myForwards.na.forwards[0].dests[0];

    return (
      <div className="card">
        <div className="card-body p-b-0">
          <h4 className="card-title m-b-2">Renvois d'appels</h4>
          <form onSubmit={this.handleSubmit} id="">
            <table className='table'>
              <tbody>
                <tr>
                  <td>Pour tous les appels entrants, renvoyer vers</td>
                  <td>
                    <select value={this.usrForwards.entity.type || "NONE"} onChange={this.handleIncomingChange} className="custom-select col-12">
                      <option value="NONE">Rejeter l'appel</option>
                      <option value="PHONE">Mon poste</option>
                      <option value="NUMBER">Mon mobile</option>
                      <option value="E164">Numéro</option>
                      <option value="MEVO">Ma messagerie vocale</option>
                      {/*  <option value="SVI_ANNOUNCE">Mon annonce</option>*/}
                      <option value="USER">Annuaire</option>
                      <option value="CONFIG">Configuration avancée</option>
                    </select>
                  </td>
                  <td>
                    {(this.state.incomingSecondSelect  && !this.state.incomingSecondInput) &&
                      <IncomingSecondSelect
                        users={this.state.users}
                        incomingValue={this.state.incomingValue}
                        incomingUserValue={this.state.incomingUserValue}
                        handleIncommingUserChange={this.handleIncommingUserChange}
                        handleIncommingAnnounceChange={this.handleIncommingAnnounceChange}
                        incomingAnnounceValue={this.state.incomingAnnounceValue}
                      />
                    }
                    { ((this.state.incomingSecondInput && !this.state.incomingSecondSelect) || this.usrForwards.entity.type === "E164") &&
                      <input
                        type="tel"
                        autoFocus={true}
                        className="form-control"
                        placeholder="Numéro de téléphone"
                        value={this.usrForwards.entity.data}
                        onChange={this.handleIncommingNumberChange} />
                  }
                  </td>
                  <td>
                    { this.state.isLoadingAllIncomings && <div className="col-1" style={loaderStyles}>
                      <i className="fa fa-2x fa-circle-o-notch fa-spin text-primary"></i>
                    </div>}
                  </td>
                  <td>{''}</td>
                </tr>
                <tr>
                  <td>En cas d'occupation, renvoyer vers</td>
                  <td>
                    <select value={this.busyForwards.entity.type || "NONE"} onChange={this.handleBusyChange} className="custom-select col-12">
                      <option value="NONE">Rejeter l'appel</option>
                      <option value="PHONE">Mon poste</option>
                      <option value="NUMBER">Mon mobile</option>
                      <option value="E164">Numéro</option>
                      <option value="MEVO">Ma messagerie vocale</option>
                    {/*  <option value="SVI_ANNOUNCE">Mon annonce</option>*/}
                      <option value="USER">Annuaire</option>
                      <option value="CONFIG">Configuration avancée</option>
                    </select>
                  </td>
                  <td>
                    {(this.state.busySecondSelect && !this.state.busySecondInput) &&
                      <BusySecondSelect
                        users={this.state.users}
                        busyValue={this.state.busyValue}
                        busyUserValue={this.state.busyUserValue}
                        handleBusyUserChange={this.handleBusyUserChange}
                        handleBusyAnnounceChange={this.handleBusyAnnounceChange}
                        busyAnnounceValue={this.state.busyAnnounceValue}
                      />
                    }
                    {((this.state.busySecondInput && !this.state.busySecondSelect) || this.busyForwards.entity.type === "E164") &&
                      <input
                        type="tel"
                        autoFocus={true}
                        className="form-control"
                        placeholder="Numéro de téléphone"
                        value={this.busyForwards.entity.data}
                        onChange={this.handleBusyNumberChange} />
                    }
                  </td>
                  <td>
                    {this.state.isLoadingAllBusy && <div className="col-1" style={loaderStyles}>
                      <i className="fa fa-2x fa-circle-o-notch fa-spin text-primary"></i>
                    </div>}
                  </td>
                  <td>{''}</td>
                </tr>
                <tr>
                  <td>En cas de non-réponse, renvoyer vers</td>
                  <td>
                    <select value={this.naForwards.entity.type || "NONE"} onChange={this.handleNaChange} className="custom-select col-12">
                      <option value="NONE">Ne rien faire</option>
                      <option value="PHONE">Mon poste</option>
                      <option value="NUMBER">Mon mobile</option>
                      <option value="E164">Numéro</option>
                      <option value="MEVO">Ma messagerie vocale</option>
                      {/*  <option value="SVI_ANNOUNCE">Mon annonce</option>*/}
                      <option value="USER">Annuaire</option>
                      <option value="CONFIG">Configuration avancée</option>
                    </select>
                  </td>
                  <td>
                    {(this.state.naSecondSelect && !this.state.naSecondInput) &&
                      <NaSecondSelect
                        users={this.state.users}
                        naValue={this.state.naValue}
                        naUserValue={this.state.naUserValue}
                        handleNaUserChange={this.handleNaUserChange}
                        handleNaAnnounceChange={this.handleNaAnnounceChange}
                        naAnnounceValue={this.state.naAnnounceValue}
                      />
                    }
                    {((this.state.naSecondInput && !this.state.naSecondSelect) || this.naForwards.entity.type === "E164") &&
                      <input
                        type="tel"
                        autoFocus={true}
                        className="form-control"
                        placeholder="Numéro de téléphone"
                        value={this.naForwards.entity.data}
                        onChange={this.handleNaNumberChange} />
                    }
                  </td>
                  <td>
                    {((this.state.naSecondInput || this.state.naSecondSelect) || this.naForwards.entity.type !== "NONE") && <div className="row">
                      <div className="col-4 text-center my-auto">
                        après
                      </div>
                      <div className="col-4">
                        <input
                          min={5}
                          max={59}
                          step={5}
                          type="number"
                          className="form-control"
                          value={this.naForwards.timeout}
                          onChange={this.handleSecondsDelayInput}
                        />
                      </div>
                      <div className="col-4 my-auto">
                        secondes
                      </div>
                    </div>}
                  </td>
                  <td>
                    {this.state.isLoadingAllNa && <div className="col-1" style={loaderStyles}>
                      <i className="fa fa-2x fa-circle-o-notch fa-spin text-primary"></i>
                    </div>}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} className="text-right">
                    <button
                      disabled={this.props.isSaving}
                      type="submit"
                      className="btn btn-success btn-sm"
                      onClick={(e) => { e.preventDefault(); return this.saveForward()}}
                    >{this.props.isSaving ? 'Enregistrement...' : 'Enregistrer'}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    );
  }


    async handleIncomingChange(event) {
      try {
        const incomingValue       = event.target.value;
        let incomingSecondSelect  = false;
        let incomingSecondInput   = false;
        let isLoadingAllIncomings = false;
        let forward               = {};

        switch (incomingValue) {
          case "NONE":
            incomingSecondSelect  = false;
            incomingSecondInput   = false;
            isLoadingAllIncomings = false;
            forward               = {forwards: [{dests: [{entity: {type: "NONE"}}]}]};
            break;
          case "PHONE":
            incomingSecondSelect  = false;
            incomingSecondInput   = false;
            isLoadingAllIncomings = false;
            forward               = {forwards: [{dests: [{entity: {type: "PHONE", data: ""}, timeout: -1}]}]};
            break;
          case "NUMBER":
            incomingSecondSelect  = false;
            incomingSecondInput   = false;
            isLoadingAllIncomings = false;
            forward               = {forwards: [{dests: [{entity: {type: "NUMBER", data: { dest: "GSM", id: "" }}, timeout: -1}]}]};
            break;
          case "E164":
            incomingSecondSelect  = false;
            incomingSecondInput   = true;
            isLoadingAllIncomings = false;
            forward               = {forwards: [{dests: [{entity: {type: "E164", data: ""}, timeout: -1}]}]};
            break;
          case "MEVO":
            incomingSecondSelect  = false;
            incomingSecondInput   = false;
            isLoadingAllIncomings = false;
            forward               = {forwards: [{dests: [{entity: {type: "MEVO", data: "DEFAULT"}, timeout: -1}]}]};
            break;
          case "SVI_ANNOUNCE":
            incomingSecondSelect  = true;
            incomingSecondInput   = false;
            forward               = {forwards: [{dests: [{entity: {type: "SVI_ANNOUNCE", data: "1"}, timeout: -1}]}]};
            break;
          case "USER":
            this.getUsersForwards('incomings');
            incomingSecondSelect  = true;
            incomingSecondInput   = false;
            isLoadingAllIncomings = true;
            forward               = {forwards: [{dests: [{entity: {type: "USER", data: {dest: "S164", id: this.props.session.id_user}}, timeout: -1}]}]};
            break;
          default:
            incomingSecondSelect  = false;
            incomingSecondInput   = false;
            isLoadingAllIncomings = false;
            forward               = {forwards: [{dests: [{entity: {type: "NONE"}}]}]};
            break;
        }

        return await this.setState(prevState => {
          return {
            incomingSecondSelect,
            incomingSecondInput,
            incomingValue,
            isLoadingAllIncomings,
            myForwards: {
              ...prevState.myForwards,
              usr: forward
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    async handleBusyChange(event) {
      try {
        const busyValue       = event.target.value;
        let busySecondSelect  = false;
        let busySecondInput   = false;
        let isLoadingAllBusy  = false;
        let forward           = {};

        switch (busyValue) {
          case "NONE":
            busySecondSelect  = false;
            busySecondInput   = false;
            isLoadingAllBusy  = false;
            forward           = {forwards: [{dests: [{entity: {type: "NONE"}}]}]};
            break;
          case "PHONE":
            busySecondSelect  = false;
            busySecondInput   = false;
            isLoadingAllBusy  = false;
            forward           = {forwards: [{dests: [{entity: {type: "PHONE", data: ""}, timeout: -1}]}]};
            break;
          case "NUMBER":
            busySecondSelect  = false;
            busySecondInput   = false;
            isLoadingAllBusy  = false;
            forward           = {forwards: [{dests: [{entity: {type: "NUMBER", data: { dest: "GSM", id: "" }}, timeout: -1}]}]};
            break;
          case "E164":
            busySecondSelect  = false;
            busySecondInput   = true;
            isLoadingAllBusy  = false;
            forward           = {forwards: [{dests: [{entity: {type: "E164", data: ""}, timeout: -1}]}]};
            break;
          case "MEVO":
            busySecondSelect  = false;
            busySecondInput   = false;
            isLoadingAllBusy  = false;
            forward           = {forwards: [{dests: [{entity: {type: "MEVO", data: "DEFAULT"}, timeout: -1}]}]};
            break;
          case "SVI_ANNOUNCE":
            busySecondSelect  = true;
            busySecondInput   = false;
            forward           = {forwards: [{dests: [{entity: {type: "SVI_ANNOUNCE", data: "1"}, timeout: -1}]}]};
            break;
          case "USER":
            this.getUsersForwards('busy');
            busySecondSelect  = true;
            busySecondInput   = false;
            isLoadingAllBusy  = true;
            forward           = {forwards: [{dests: [{entity: {type: "USER", data: {dest: "S164", id:"id_user"}}, timeout: -1}]}]};
            break;
          default:
            busySecondSelect  = false;
            busySecondInput   = false;
            isLoadingAllBusy  = false;
            forward           = {forwards: [{dests: [{entity: {type: "NONE"}}]}]};
            break;
        }

        return await this.setState(prevState => {
          return {
            busySecondSelect,
            busySecondInput,
            busyValue,
            isLoadingAllBusy,
            myForwards: {
              ...prevState.myForwards,
              busy: forward
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    async handleNaChange(event) {
      try {
        const naValue       = event.target.value;
        let naSecondSelect  = false;
        let naSecondInput   = false;
        let isLoadingAllNa  = false;
        let forward         = {};

        switch (naValue) {
          case "NONE":
            naSecondSelect  = false;
            naSecondInput   = false;
            isLoadingAllNa  = false;
            forward         = {forwards: [{dests: [{entity: {type: "NONE"}}]}]};
            break;
          case "PHONE":
            naSecondSelect  = false;
            naSecondInput   = false;
            isLoadingAllNa  = false;
            forward         = {forwards: [{dests: [{entity: {type: "PHONE", data: ""}, timeout: -1}]}]};
            break;
          case "NUMBER":
            naSecondSelect  = false;
            naSecondInput   = false;
            isLoadingAllNa  = false;
            forward         = {forwards: [{dests: [{entity: {type: "NUMBER", data: { dest: "GSM", id: "" }}, timeout: -1}]}]};
            break;
          case "E164":
            naSecondSelect  = false;
            naSecondInput   = true;
            isLoadingAllNa  = false;
            forward         = {forwards: [{dests: [{entity: {type: "E164", data: ""}, timeout: -1}]}]};
            break;
          case "MEVO":
            naSecondSelect  = false;
            naSecondInput   = false;
            isLoadingAllNa  = false;
            forward         = {forwards: [{dests: [{entity: {type: "MEVO", data: "DEFAULT"}, timeout: -1}]}]};
            break;
          case "SVI_ANNOUNCE":
            naSecondSelect  = true;
            naSecondInput   = false;
            forward         = {forwards: [{dests: [{entity: {type: "SVI_ANNOUNCE", data: "1"}, timeout: -1}]}]};
            break;
          case "USER":
            this.getUsersForwards('na');
            naSecondSelect  = true;
            naSecondInput   = false;
            isLoadingAllNa  = true;
            forward         = {forwards: [{dests: [{entity: {type: "USER", data: {dest: "S164", id:"id_user"}}, timeout: -1}]}]};
            break;
          default:
            naSecondSelect  = false;
            naSecondInput   = false;
            isLoadingAllNa  = false;
            forward         = {forwards: [{dests: [{entity: {type: "NONE"}}]}]};
            break;
        }

        return await this.setState(prevState => {
          return {
            naSecondSelect,
            naSecondInput,
            naValue,
            isLoadingAllNa,
            myForwards: {
              ...prevState.myForwards,
              na: forward
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    async handleIncommingNumberChange(event) {
      try {
        const number = event.target.value;

        return await this.setState(prevState => {
          return {
            myForwards: {
              ...prevState.myForwards,
              usr: {
                ...prevState.usr,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: number,
                      type: "E164"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ isLoadingForwards: false });
      }
    }

    async handleBusyNumberChange(event) {
      try {
        const number = event.target.value;

        return await this.setState(prevState => {
          return {
            myForwards: {
              ...prevState.myForwards,
              busy: {
                ...prevState.busy,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: number,
                      type: "E164"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ isLoadingForwards: false });
      }
    }

    async handleNaNumberChange(event) {
      try {
        const number = event.target.value;

        return await this.setState(prevState => {
          return {
            myForwards: {
              ...prevState.myForwards,
              na: {
                ...prevState.na,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: number,
                      type: "E164"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ isLoadingForwards: false });
      }
    }

    async handleSecondsDelayInput(event) {
      try {
        const timeout = event.target.value;
        var newNa = this.state.myForwards.na;
        if(newNa != null) {
          newNa.forwards[0].dests[0].timeout = timeout;

          return await this.setState(prevState => {
            return {
              myForwards: {
                ...prevState.myForwards,
                na: newNa
              }
            }
          });
        }
        else {


          return await this.setState(prevState => {
            return {
              myForwards: {
                ...prevState.myForwards,
                na: {
                  ...prevState.na,
                  forwards: [{
                    ...prevState.forwards,
                    dests: [{
                      ...prevState.dests,
                      entity: this.naForwards.entity,
                      timeout: timeout
                    }]
                  }]
                }
              }
            }
          });
        }


      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ isLoadingForwards: false });
      }
    }

    async handleIncommingUserChange(event) {
      try {
        const user_id = event.target.value;

        return await this.setState(prevState => {
          return {
            incomingUserValue: user_id,
            myForwards: {
              ...prevState.myForwards,
              usr: {
                ...prevState.usr,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: { dest: "S164", id: user_id },
                      type: "USER"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ isLoadingForwards: false });
      }
    }

    async handleBusyUserChange(event) {
      try {
        const user_id = event.target.value;

        return await this.setState(prevState => {
          return {
            busyUserValue: user_id,
            myForwards: {
              ...prevState.myForwards,
              busy: {
                ...prevState.busy,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: { dest: "S164", id: user_id },
                      type: "USER"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ isLoadingForwards: false });
      }
    }

    async handleNaUserChange(event) {
      try {
        const user_id = event.target.value;

        return await this.setState(prevState => {
          return {
            naUserValue: user_id,
            myForwards: {
              ...prevState.myForwards,
              na: {
                ...prevState.na,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: { dest: "S164", id: user_id },
                      type: "USER"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({ isLoadingForwards: false });
      }
    }

    async handleIncommingAnnounceChange(event) {
      try {
        const announce_id = event.target.value;

        this.setState({ incomingAnnounceValue: announce_id });

        return await this.setState(prevState => {
          return {
            myForwards: {
              ...prevState.myForwards,
              usr: {
                ...prevState.usr,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: { dest: "SVI_ANNOUNCE", id: announce_id },
                      type: "SVI_ANNOUNCE"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    async handleBusyAnnounceChange(event) {
      try {
        const announce_id = event.target.value;
        this.setState({ busyAnnounceValue: announce_id });

        return await this.setState(prevState => {
          return {
            myForwards: {
              ...prevState.myForwards,
              busy: {
                ...prevState.busy,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: { dest: "SVI_ANNOUNCE", id: announce_id },
                      type: "SVI_ANNOUNCE"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    async handleNaAnnounceChange(event) {
      try {
        const announce_id = event.target.value;
        this.setState({ naAnnounceValue: announce_id });

        return await this.setState(prevState => {
          return {
            myForwards: {
              ...prevState.myForwards,
              na: {
                ...prevState.na,
                forwards: [{
                  ...prevState.forwards,
                  dests: [{
                    ...prevState.dests,
                    entity: {
                      ...prevState.entity,
                      data: { dest: "SVI_ANNOUNCE", id: announce_id },
                      type: "SVI_ANNOUNCE"
                    }
                  }]
                }]
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    async getUsersForwards(context) {
      switch (context) {
        case 'incomings':
          this.setState({ isLoadingAllIncomings: true });
          break;
        case 'busy':
          this.setState({ isLoadingAllBusy: true });
          break;
        case 'na':
          this.setState({ isLoadingAllNa: true });
          break;
        default:
          this.setState({ isLoadingAllIncomings: true });
      }

      try {
        const users = await getUsers();
        let filteredUsers = await getAlphabeticallyOrderedUsers(users.data);
        this.setState({ users: filteredUsers });
      } catch (e) {
        console.log(e);
      } finally {
        switch (context) {
          case 'incomings':
            this.setState({ isLoadingAllIncomings: false });
            break;
          case 'busy':
            this.setState({ isLoadingAllBusy: false });
            break;
          case 'na':
            this.setState({ isLoadingAllNa: false });
            break;
          default:
            this.setState({ isLoadingAllIncomings: false });
        }
      }
    }

}


const IncomingSecondSelect = (props) => {
  if (props.incomingValue === "USER") {

    return (
      <select value={props.incomingUserValue} onChange={props.handleIncommingUserChange} className="custom-select col-12">
        <option disabled={true}>Destinataire</option>$
        {!isEmpty(props.users.callCenter) && <optgroup label="Centre d'appels">
          {
            props.users.callCenter.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.commonRings) && <optgroup label='Sonneries générales'>
          {
            props.users.commonRings.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.usersList) && <optgroup label='Utilisateurs'>
          {
            props.users.usersList.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.waitingQueues) && <optgroup label="Files d'attente">
          {
            props.users.waitingQueues.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
      </select>
    );
  }

  return (
    <select value={props.incomingAnnounceValue} onChange={props.handleIncommingAnnounceChange} className="custom-select col-12">
      <option value="1">Message d'absence</option>
      <option value="2">Message de non-disponibilité</option>
    </select>
  );
}

const BusySecondSelect = (props) => {
  if (props.busyValue === "USER") {
    return (
      <select value={props.busyUserValue} onChange={props.handleBusyUserChange} className="custom-select col-12">
        <option disabled={true}>Destinataire</option>
        {!isEmpty(props.users.callCenter) && <optgroup label="Centre d'appels">
          {
            props.users.callCenter.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.commonRings) && <optgroup label='Sonneries générales'>
          {
            props.users.commonRings.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.usersList) && <optgroup label='Utilisateurs'>
          {
            props.users.usersList.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.waitingQueues) && <optgroup label="Files d'attente">
          {
            props.users.waitingQueues.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
      </select>
    );
  }

  return (
    <select value={props.busyAnnounceValue} onChange={props.handleBusyAnnounceChange} className="custom-select col-12">
      <option value="1">Message d'absence</option>
      <option value="2">Message de non-disponibilité</option>
    </select>
  );
}

const NaSecondSelect = (props) => {
  if (props.naValue === "USER") {
    return (
      <select value={props.naUserValue} onChange={props.handleNaUserChange} className="custom-select col-12">
        <option disabled={true}>Destinataire</option>
        {!isEmpty(props.users.callCenter) && <optgroup label="Centre d'appels">
          {
            props.users.callCenter.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.commonRings) && <optgroup label='Sonneries générales'>
          {
            props.users.commonRings.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.usersList) && <optgroup label='Utilisateurs'>
          {
            props.users.usersList.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.waitingQueues) && <optgroup label="Files d'attente">
          {
            props.users.waitingQueues.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
      </select>
    );
  }

  return (
    <select value={props.naAnnounceValue} onChange={props.handleNaAnnounceChange} className="custom-select col-12">
      <option value="1">Message d'absence</option>
      <option value="2">Message de non-disponibilité</option>
    </select>
  );
}




const loaderStyles = {
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  display: "flex"
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    session: state.kertelSession.datas.data
  }
}

export default connect(mapStateToProps, null)(ForwardLayout);
