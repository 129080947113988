import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import {getUser} from 'components/commons/api/user.js';
import { updateUser } from 'components/commons/api/admin/user';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Preloader from 'components/commons/Preloader.js';
import { notifySuccess } from 'components/commons/Toasts.js';
import swal from 'sweetalert';
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      user: {
        allow_impersonate : false,
        sso: {
          sso_email: ""
        }
      }
    };
    
    this.handleOptions = this.handleOptions.bind(this);
    this.loadInfosUser = this.loadInfosUser.bind(this);
    this.editUser = this.editUser.bind(this);
  }
  
  async componentDidMount() {
    await this.loadInfosUser();
  }
  
  handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
	
    this.setState(state => ({
      user: {
        ...state.user,
        [name]: value
      }
    }));
  }
  
  async loadInfosUser() {
    this.setState({ isLoading: true })
    try {
      const userInfos = await getUser();
      if (!isUndefined(userInfos.data)) {
        let infos = userInfos.data;
        if (!isUndefined(infos.sso.email)) infos.sso_email = infos.sso.email;
        if (isNull(infos.sso_email))infos.sso_email = "";
        this.setState(state => ({
          ...state.user,
          user: infos
        }));
      }
      
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false })
    }
  }
  
  async editUser() {
    this.setState({ isLoading: true })
    try {
      
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      
	  let info = {};
	  
      if (!isUndefined(this.state.user.allow_impersonate)) info.allow_impersonate = this.state.user.allow_impersonate;
      
      if (!isUndefined(this.state.user.sso_email)) info.sso_email = this.state.user.sso_email;
      if (isEmpty(info.sso_email)) {
        return swal({
          type: 'error',
          icon: "error",
          title: 'Oops...',
          text: "L'adresse mail sso est invalide",
          confirmButtonColor: 'LightSeaGreen',
        })
      }
      else if (!pattern.test(info.sso_email)) {
        return swal({
          type: 'error',
          icon: "error",
          title: 'Oops...',
          text: "L'adresse mail sso est invalide",
          confirmButtonColor: 'LightSeaGreen',
        })
      }
	  
      await updateUser(this.props.user.user_id, info).then((result) => {
        notifySuccess("Utilisateur mis à jour avec succès !");
        return;
      })
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false })
    }
  }
  
  render() {
    if (this.state.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb title='Mes informations' />
            <table className='table table-bordered'>
                <tbody>
                    <tr>
                        <td>Nom complet</td>
                        <td>{this.props.session.user_display_name}</td>
                    </tr>
                    <tr>
                        <td>Adresse mail</td>
                        <td>{this.props.session.user_email}</td>
                    </tr>
                    <tr>
                        <td>Compte mail de connexion rapide</td>
                        <td>
                          <TextField
                            name="sso_email"
                            //label="SSO email"
                            multiline
                            fullWidth
                            value={this.state.user.sso_email}
                            onChange={this.handleOptions}
                          />
                        </td>
                    </tr>
                    <tr>
                        <td>Entreprise</td>
                        <td>{this.props.session.company}</td>
                    </tr>
                    <tr>
                        <td>Téléphone</td>
                        <td>{!isEmpty(this.props.user.e164) && this.props.user.e164[0]}</td>
                    </tr>
                    <tr>
                        <td>Numéro(s) court(s)</td>
                        <td>
                          <ul>
                            {!isEmpty(this.props.user.s164) && this.props.user.s164.map((short) => {
                            return (
                            <li>{short}</li>
                            );
                          })}  
                          </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Autoriser un administrateur à accéder à votre compte</td>
                        <td>
                          <FormControlLabel
                            style={{ paddingTop: '20px'}}
                            control={
                              <Switch
                                name="allow_impersonate"
                                checked={this.state.user.allow_impersonate}
                                style={{ float: "left" }}
                                onChange={this.handleOptions}
                              />
                            }
                          />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="form-actions m-4">
              <div className="row">
                <div className="col-md-12 ">
                  <span className="float-right">
                    <button
                      type="button"
                      className="btn btn-inverse mr-2"
                      onClick={() => {
                        this.loadInfosUser();
                      }}
                    >
                      Annuler
                    </button>
                    <button onClick={this.editUser} type="submit" className="btn btn-success">Enregistrer</button>
                  </span>
                </div>
                <div className="col-md-6"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession.datas.data,
    user: state.kertelSession.user
  };
}

export default connect(mapStateToProps, null)(Account);
