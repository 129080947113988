import { POST, PUT, PATCH } from './../api';

export const getUsers = () => {
  return POST('/user', {
    user_id: "*"
  });
}

export const getUser = (userId) => {
  return POST('/user', {
    user_id: userId
  });
}

export const addUser = (informations) => {
  return PUT('/user', {
    data: informations
  });
}

export const updateUser = (userId,informations) => {
  return PATCH('/user', {
    user_id: userId,
    data: informations
  });
}