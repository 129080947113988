import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { fetchUsersSupervision, addUsersSupervision, deleteUsersSupervision } from 'components/commons/helpers/admin/supervisionHelper.js';
import { stopLoadingSupervision, stopEditingSupervision } from 'actions/admin/supervisionActions.js';
import Preloader from 'components/commons/Preloader.js';
import EditSupervisionUsers from './EditSupervisionUsers.js';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import {notifySuccess} from 'components/commons/Toasts.js';
import { useNavigate } from 'react-router';

function EditSupervision ({ supervision, users, stopEditingSupervision, company }) {

	const navigate = useNavigate();

	let idSupervisor = supervision.supervisor;
	let superv = supervision.supervisor;
	if(users.datas != null) {
	  users.datas.forEach(function(element) {
		if (element.user_id === idSupervisor) {
		  if (!isUndefined(element.firstname)) superv = element.firstname + " " + element.lastname;
		  else superv = element.lastname;
		}
	  });
	}

	const [isLoading, setIsLoading] = useState(true);
	const [supervisor, setSupervisor] = useState(superv);
	const [supervisionState, setSupervisionState] = useState({
        "users": supervision.users,
		"dataMembers": [],
      });

  async function init() {
	try {
	  await fetchUsers();
	  await fetchUsersSupervision(supervision.supervisor);
	  
	  let members = supervision.users;
	  let supervisor = supervision.supervisor;
	  var dataMembers = [];
	  forEach(users.datas, function(details) {
		if ((details.user_type === "1" || details.user_type === "2" || details.user_type === "7") && details.user_id !== supervisor) {
		  let user = {
			"user_id": details.user_id,
			"mail": !isUndefined(details.mail) ? details.mail : '',
			"firstname": !isUndefined(details.firstname) ? details.firstname : '',
			"lastname": !isUndefined(details.lastname) ? details.lastname : '',
			"group_id": details.group_id,
			"location_id": details.location_id,
			"user_type" : details.user_type,
			tableData: { checked: members.includes(details.user_id) ? true : false}
		  };
		  
		  dataMembers.push(user);
		}
	  })

	  setSupervisionState(prevState => ({
		...prevState.supervision,
		"dataMembers":dataMembers,
	  }));
	} catch (e) {
	  console.log(e);
	} finally {
	  setIsLoading(false);
	}
  }

  useEffect(function() {
	init();
  }, []);

  async function updateSupervision(){
    try {
	  let info = {
		members :  {
          "add": [],
          "delete": [],
        },
	  };
	  
	  forEach(supervision.dataMembers, function(details) {
		if (details.tableData.checked === true) {
		  info.members.add.push(details.user_id);
		}
	  })
	  
	  forEach(supervisionState.users, function(user_id) {
		  if (!info.members.add.includes(user_id)) {
			info.members.delete.push(user_id);
		  }
	  })
	  
      if (!isEmpty(info.members.delete)) {
		deleteUsersSupervision(supervision.supervisor, info.members.delete);
	  }
	  
      if (!isEmpty(info.members.add)) {
		addUsersSupervision(supervision.supervisor, info.members.add);
	  }
    } catch (e) {
      console.log(e);
    } finally {
	  notifySuccess("La mise à jour a été effectuée avec succès !");
	  await fetchUsers();
	  stopEditingSupervision();
      return navigate('/admin/supervision');
    }
  }
  
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Supervision", "Utilisateurs supervisés"]} url="/admin/supervision" title="Utilisateurs supervisés" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Liste des utilisateurs supervisés par :
					<span className="btn btn-warning react-bs-table-del-btn" style={{
					  padding: "2px",
					  marginLeft: "5px"
					}}>{supervisor}</span>
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="users">
			  {
				isLoading && <div className="col-lg-12 text-center"><Preloader /></div>
			  }
			  {
				!isLoading &&
				<EditSupervisionUsers
                  dataMembers={supervisionState.dataMembers}
                  company={company}
                />
			  }
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
							stopEditingSupervision();
                            return navigate('/admin/supervision');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={updateSupervision} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
	supervision: state.supervision
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopLoadingSupervision,
	stopEditingSupervision
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSupervision);
