import React, { Component } from 'react';
import { createUserContact, updateUserContact } from 'components/commons/api/contacts.js';
import { hideModal } from 'actions/modalActions.js';
import { stopEditingContact } from 'actions/contactsActions.js';
import { fetchUserContacts, fetchUserContact } from 'components/commons/helpers/contactHelper.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Preloader from 'components/commons/Preloader.js';
import { toast } from 'react-toastify';

class CreateContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      contact: {
        contacts_info:
        {
          civility : "0",
          shared: false
        }
      }
    }

    this.handleInputChange  = this.handleInputChange.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);
    this.handleSharedChange = this.handleSharedChange.bind(this);
  }

  async componentDidMount() {
    if (this.props.contacts.editing.isEditing) {
      try {
        await await fetchUserContact(this.props.contacts.editing.contactId).then((result) => {
          this.setState((prevState) => ({
            contact: {
              ...prevState.contact,
              contacts_info: this.props.contacts.editing.datas
            }
          }));
        })
      } catch (e) {
        console.log(e);
      }
    }
  }

  handleSharedChange() {
    this.setState(prevState => {
      return {
        contact: {
          contacts_info: {
            ...prevState.contact.contacts_info,
            shared: !prevState.contact.contacts_info.shared
          }
        }
      }
    })
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.props.contacts.editing.isEditing) {
      try {
        this.setState({ isCreating: true });
        return await updateUserContact(this.state.contact.contacts_info).then((result) => {
          return fetchUserContacts();
        })
      } catch (e) {
        console.log(e);
      } finally {
        return this.setState({ isCreating: false }, function() { this.props.hideModal();});
      }
    }

    try {
      this.setState({ isCreating: true });
      return await createUserContact(this.state.contact.contacts_info).then((result) => {
        toast.success("Contact créé avec succès !", {
          hideProgressBar: true,
          autoClose: 5000,
          onClose: () => {
            fetchUserContacts();
            return this.props.hideModal();         
          }
        });
        
      })
    } catch (e) {
      console.log(e);
    } finally {
      return this.setState({ isCreating: false });
    }
  }

  handleInputChange(event) {
    const target  = event.target;
    const value   = target.type === 'checkbox' ? target.checked : target.value;
    const name    = target.name;

    return this.setState(state => ({
      contact: {
        contacts_info: {
          ...state.contact.contacts_info,
          [name]: value
        }
      }
    }));
  }

  render() {
    if (this.props.contacts.editing.isLoading) {
      return (<Preloader />);
    }

    const editingContact = this.state.contact.contacts_info;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <div className='row'>
            <div className="col-md-6">
              <div className="form-group">
                <span>Contact partagé</span>
                <div className="material-switch pull-right">
                  <input
                    className="form-control"
                    name="shared"
                    type="checkbox"
                    onChange={this.handleInputChange}
                    checked={editingContact.shared || this.state.contact.contacts_info.shared}
                  />
                  <label onClick={this.handleSharedChange} htmlFor="someSwitchOptionPrimary" className="label-primary"></label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <select name="civility" value={this.state.contact.contacts_info.civility} className="form-control custom-select" onChange={this.handleInputChange}>
                  <option value={0}>Indéfini</option>
                  <option value={1}>Madame</option>
                  <option value={3}>Monsieur</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="8"
                  className="form-control"
                  name="telephone"
                  type="tel"
                  placeholder="N° fixe"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.telephone || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="1"
                  className="form-control"
                  name="lastname"
                  type="text"
                  placeholder="Nom"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  required={true}
                  value={editingContact.lastname || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="9"
                  className="form-control"
                  name="mobile"
                  type="tel"
                  placeholder="N° mobile"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.mobile || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="2"
                  className="form-control"
                  name="firstname"
                  type="text"
                  placeholder="Prénom"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.firstname || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="10"
                  className="form-control"
                  name="fax"
                  type="tel"
                  placeholder="N° fax"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.fax || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="3"
                  className="form-control"
                  name="mail"
                  type="email"
                  placeholder="Adresse email"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.mail || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="11"
                  className="form-control"
                  type="tel"
                  placeholder="Autre n° de téléphone"
                  id="example-text-input"
                  name='other'
                  onChange={this.handleInputChange}
                  value={editingContact.other || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="4"
                  className="form-control"
                  name="company"
                  type="text"
                  placeholder="Entreprise"
                  id="example-text-input"
                  onChange={this.handleInputChange}
                  value={editingContact.company || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="12"
                  className="form-control"
                  type="text"
                  placeholder="Site internet"
                  id="example-text-input"
                  name='website'
                  onChange={this.handleInputChange}
                  value={editingContact.website || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="5"
                  className="form-control"
                  type="text"
                  placeholder="Adresse"
                  id="example-text-input"
                  name='address'
                  onChange={this.handleInputChange}
                  value={editingContact.address || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="6"
                  className="form-control"
                  type="number"
                  placeholder="Code postal"
                  id="example-text-input"
                  name='cp'
                  onChange={this.handleInputChange}
                  value={editingContact.cp || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="7"
                  className="form-control"
                  type="text"
                  placeholder="Ville"
                  id="example-text-input"
                  name='city'
                  onChange={this.handleInputChange}
                  value={editingContact.city || ''}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              this.props.stopEditingContact();
              return this.props.hideModal()
            }}
          >Annuler</button>
          <input
            disabled={this.state.isCreating}
            type="submit"
            className="btn btn-success waves-effect text-left right-side-toggle"
            value={this.state.isCreating ? 'Création...' : 'Confirmer'}
            />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal,
    stopEditingContact
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateContact);
