import { store } from 'store/index.js';
import { getWaitingQueuesInformation , getWaitingQueueInformation, deleteWaitingQueueInformation, updateWaitingQueueInformation } from 'components/commons/api/admin/waitingQueue';
import {
  loadingWaitingQueues,
  getWaitingQueues as getWaitingQueuesAction,
  stopLoadingWaitingQueues,
  loadingWaitingQueue,
  getWaitingQueue as getWaitingQueueAction,
  stopLoadingWaitingQueue,
} from 'actions/admin/waitingQueueActions';

export const fetchWaitingQueues = async (props) => {

  store.dispatch(loadingWaitingQueues());
  try {
    await getWaitingQueuesInformation().then((result) => {
      return store.dispatch(getWaitingQueuesAction(Object.values(result.data)));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingWaitingQueues());
  }
}

export const fetchWaitingQueue = async (waitingQueueId) => {

  store.dispatch(loadingWaitingQueue());
  try {
    await getWaitingQueueInformation(waitingQueueId).then((result) => {
      return store.dispatch(getWaitingQueueAction(Object.values(result.data)[0]));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingWaitingQueue());
  }
}

export const deleteWaitingQueue = async (waitingQueueId) => {

  store.dispatch(loadingWaitingQueue());
  try {
    await deleteWaitingQueueInformation(waitingQueueId).then((result) => {
      return fetchWaitingQueues();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingWaitingQueue());
  }
}

export const updateWaitingQueue = async (queueId, informations) => {
  store.dispatch(loadingWaitingQueue());
  try {
    await updateWaitingQueueInformation(queueId, informations).then((result) => {
      return fetchWaitingQueues();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingWaitingQueue());
  }
}
