const userReducer = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return [];
    case 'LOADING_USERS':
      return { ...state, isLoading: true };
    case 'FETCH_USERS':
      return { ...state,  datas: action.payload }
      case 'STOP_LOADING_USERS':
        return { ...state, isLoading: false };
      case 'START_EDITING_USER':
        return { ...state, editing: { ...state.editing, isEditing: true, userId: action.payload } }
      case 'STOP_EDITING_USER':
        return { ...state, editing: { ...state.editing, isEditing: false } }
      case 'FETCH_USER':
        return { ...state, editing: { ...state.editing, datas: action.payload } }
      case 'LOADING_USER':
        return { ...state, editing: { ...state.editing, isLoading: true } };
      case 'STOP_LOADING_USER':
        return { ...state, editing: { ...state.editing, isLoading: false } };
    default:
      return state
  }
}

export default userReducer;
