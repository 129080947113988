import React, { Component } from 'react';
import { createCompanyBill, updateCompanyBill } from 'components/commons/api/bills.js';
import { hideModal } from 'actions/modalActions.js';
import { stopEditingCompanyBill } from 'actions/billActions.js';
import { fetchUserContacts, fetchUserContact } from 'components/commons/helpers/contactHelper.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select'
import FileBase64 from 'react-file-base64';
import moment from "moment";
import Preloader from 'components/commons/Preloader.js';
import { toast } from 'react-toastify';

class CreateCompanyBill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      date: {
        day:
        {
          today : moment().format("YYYY-MM-DD")
        }
      },
      company_bill: {
        company_bill_info:
        {
          name : "",
          address : "",
          zipcode : "",
          city : "",
          country : "",
          logo : "",
          footer1 : "",
          footer2 : ""
        }
      }
    }

    this.handleInputChange  = this.handleInputChange.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (this.props.contacts.editing.isEditing) {
      try {
        await await fetchUserContact(this.props.contacts.editing.contactId).then((result) => {
          this.setState((prevState) => ({
            contact: {
              ...prevState.contact,
              company_bill_info: this.props.contacts.editing.datas
            }
          }));
        })
      } catch (e) {
        console.log(e);
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.props.contacts.editing.isEditing) {
      try {
        this.setState({ isCreating: true });
        return await updateCompanyBill(this.state.company_bill.company_bill_info).then((result) => {
          return fetchUserContacts();
        })
      } catch (e) {
        console.log(e);
      } finally {
        return this.setState({ isCreating: false }, function() { this.props.hideModal();});
      }
    }

    try {
      this.setState({ isCreating: true });
      return await createCompanyBill(this.state.company_bill.company_bill_info).then((result) => {
        toast.success("Entitée créée avec succès !", {
          hideProgressBar: true,
          autoClose: 5000,
          onClose: () => {
            fetchUserContacts();
            return this.props.hideModal();
          }
        });

      })
    } catch (e) {
      console.log(e);
    } finally {
      return this.setState({ isCreating: false });
    }
  }

  handleInputChange(event) {
    const target  = event.target;
    const value   = target.type === 'checkbox' ? target.checked : target.value;
    const name    = target.name;

    return this.setState(state => ({
      company_bill: {
        company_bill_info: {
          ...state.company_bill.company_bill_info,
          [name]: value
        }
      }
    }));
  }

  getFiles(files){
    this.setState(state => ({
      company_bill: {
        company_bill_info: {
          ...state.company_bill.company_bill_info,
          ['logo']: files.base64
        }
      }
    }));
  }




  render() {
    if (this.props.contacts.editing.isLoading) {
      return (<Preloader />);
    }

    const editingCompanyBill = this.state.company_bill.company_bill_info;
    const today = this.state.date.day.today;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="1"
                  className="form-control"
                  name="name"
                  type="text"
                  placeholder="Nom"
                  id="name-input"
                  onChange={this.handleInputChange}
                  required={true}
                  value={editingCompanyBill.name || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="2"
                  className="form-control"
                  name="address"
                  type="text"
                  placeholder="Adresse"
                  id="address-input"
                  onChange={this.handleInputChange}
                  value={editingCompanyBill.address || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="3"
                  className="form-control"
                  name="zipcode"
                  type="text"
                  placeholder="Code postal"
                  id="zipcode-input"
                  onChange={this.handleInputChange}
                  value={editingCompanyBill.zipcode || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="4"
                  className="form-control"
                  name="city"
                  type="text"
                  placeholder="Ville"
                  id="city-input"
                  onChange={this.handleInputChange}
                  value={editingCompanyBill.city || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="5"
                  className="form-control"
                  name="country"
                  type="text"
                  placeholder="Pays"
                  id="country-input"
                  onChange={this.handleInputChange}
                  value={editingCompanyBill.country || ''}
                />
              </div>
            </div>
          </div>
          <hr class="my-4" />
          <div className="row">
            <div className="col-md-12">
              <div className="file-uploader">
                <FileBase64
                  tabIndex="6"
                  className="form-control"
                  name="logo"
                  placeholder="Logo"
                  id="logo-input"
                  multiple={ false }
                  onDone={ this.getFiles.bind(this)}
                  value={editingCompanyBill.logo || ''}
                />
              </div>
            </div>
          </div>
          <hr class="my-4" />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  tabIndex="7"
                  className="form-control"
                  name="footer1"
                  type="text"
                  placeholder="Pied de page ligne 1"
                  id="footer1-input"
                  onChange={this.handleInputChange}
                  value={editingCompanyBill.footer1 || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  tabIndex="8"
                  className="form-control"
                  name="footer2"
                  type="text"
                  placeholder="Pied de page ligne 2"
                  id="footer2-input"
                  onChange={this.handleInputChange}
                  value={editingCompanyBill.footer2 || ''}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              this.props.stopEditingCompanyBill();
              return this.props.hideModal()
            }}
          >Annuler</button>
          <input
            disabled={this.state.isCreating}
            type="submit"
            className="btn btn-success waves-effect text-left right-side-toggle"
            value={this.state.isCreating ? 'Création...' : 'Confirmer'}
            />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal,
    stopEditingCompanyBill
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompanyBill);
