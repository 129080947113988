import React, { Component } from 'react';
import { createSchedulerDay } from 'components/commons/api/scheduler.js';
import { notifySuccess } from 'components/commons/Toasts.js';
import { hideModal } from 'actions/modalActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewForward from 'components/views/forwards/NewForwardContainer.js';

class CreateDay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      getForwards: false,
      dayLabel: '',
      forwards: [],
      color: 'dddddd'
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.retrieveForwards = this.retrieveForwards.bind(this);
    this.updateColor = this.updateColor.bind(this);
  }

  retrieveForwards(forwards) {
    return this.setState({ forwards, getForwards: false });
  }

  updateColor(event) {
    event.preventDefault();
    this.setState({ color: event.target.name });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const that = this;

    try {
      await this.setState({ isCreating: true, getForwards: true }, async function() {

        const day = {
          user_id: that.props.session.user_id,
          label: that.state.dayLabel,
          forwards: that.state.forwards,
          color: that.state.color
        };

        console.log(that.state.forwards);

        await createSchedulerDay(day);
        this.props.props.callback();
        notifySuccess('Journée créée avec succès !');
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isCreating: false, getForwards: false });
      return this.props.hideModal();
    }
  }

  handleInputChange(event) {
    this.setState({ dayLabel: event.target.value });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="pt-1">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  className="form-control"
                  name="dayName"
                  type="text"
                  placeholder="Nom de la journée"
                  id="dayName"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <div className="dropdown show">
                  <button className="btn btn-light dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Couleur de la journée
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <button className="dropdown-item createDayColorItem" name='81ecec' style={{ background: '#81ecec' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='74b9ff' style={{ background: '#74b9ff' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='a29bfe' style={{ background: '#a29bfe' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='0984e3' style={{ background: '#0984e3' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='fdcb6e' style={{ background: '#fdcb6e' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='ff7675' style={{ background: '#ff7675' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='e17055' style={{ background: '#e17055' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='e84393' style={{ background: '#e84393' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='b2bec3' style={{ background: '#b2bec3' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='f6e58d' style={{ background: '#f6e58d' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='ffbe76' style={{ background: '#ffbe76' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='ff7979' style={{ background: '#ff7979' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='badc58' style={{ background: '#badc58' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='dff9fb' style={{ background: '#dff9fb' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='f9ca24' style={{ background: '#f9ca24' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='f0932b' style={{ background: '#f0932b' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='eb4d4b' style={{ background: '#eb4d4b' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='6ab04c' style={{ background: '#6ab04c' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='7ed6df' style={{ background: '#7ed6df' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='e056fd' style={{ background: '#e056fd' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='686de0' style={{ background: '#686de0' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='30336b' style={{ background: '#30336b' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='22a6b3' style={{ background: '#22a6b3' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='be2edd' style={{ background: '#be2edd' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='4834d4' style={{ background: '#4834d4' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem" name='130f40' style={{ background: '#130f40' }} onClick={this.updateColor}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className='alert' style={{ background: `#${this.state.color}`, height: '36px' }}></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <NewForward
                retrieveForwards={this.retrieveForwards}
                onSaveDay={() => {console.log(this.state)}}
                getForwards={this.state.getForwards}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              return this.props.hideModal()
            }}
          >Annuler</button>
          <input
            disabled={this.state.isCreating}
            type="submit"
            className="btn btn-success waves-effect text-left right-side-toggle"
            value={this.state.isCreating ? 'Création...' : 'Confirmer'}
            />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession.datas.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDay);
