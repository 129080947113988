import { GET, PATCH, POST, PUT, DELETE } from './api';

export const getForwardConfig = () => {
  return GET('/forward');
}

export const getForwardsSet = () => {
  return GET('/forward/set');
}

export const saveForwardConfig = ({forward_type, forwards}) => {
  return PATCH('/forward', {
    user_id: "",
    forward_type: forward_type,
    forwards: forwards
  });
}

export const saveDayForwardsConfig = (userId, dayId, forwards, start) => {
  let forwardsObj = {};
  forwardsObj.start = start;
  forwardsObj.forward = forwards;

  let forwardsArray = [];
  forwardsArray[0] = forwardsObj;

  return PATCH('/scheduler/set/days', {
    user_id: userId,
    day_id: dayId,
    forwards: forwardsArray
  });
}

export const getForwardsByDayId = (dayId, userId) => {
  return POST('/scheduler/set/days', {
    user_id: userId,
    day_id: dayId
  });
}

export const createForwardsSet = (forwardsSet) => {
  return PUT('/forward/set', forwardsSet);
}

export const deleteForwardRule = (userId, forwardId) => {
  return DELETE('/forward/set', {
    user_id: userId,
    forward_id: forwardId
  });
}
