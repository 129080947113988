export const loadingBills = () => {
  return { type: 'LOADING_BILLS' }
};

export const fetchBills = (Bills) => {
  return {
    type: 'FETCH_BILLS',
    payload: Bills
  }
};

export const stopLoadingBills = () => {
  return { type: 'STOP_LOADING_BILLS' }
};