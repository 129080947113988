import React, { Component } from 'react';
import {connect} from 'react-redux';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';
import { fetchCountRecords } from 'components/commons/helpers/admin/recordsHelper.js';
import Moment from 'react-moment';
import swal from 'sweetalert';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
library.add(faCheck, faTimes);

class RecordsTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nbrRecords: this.props.records.count,
    };
  }

  async componentDidMount() {
    fetchCountRecords();

    var player = window.$('.player');

    /**
      * Have to add media event listeners here.
      *
    */
    player.on('play', (e) => {
      e.preventDefault();
      this.playLocation();
    });

    player.on('pause', (e) => {
      e.preventDefault();
      this.pause();
    });

    player.on('ended', (e) => {
      e.preventDefault();
      this.ended();
    });

    window.$(document).on('keydown', (e) => {
      // Move currentTime forward and backward via arrow keys and play/pause via spacebar.
      if (e.keyCode === 39) {
        this.state.player.currentTime += 1;
      } else if (e.keyCode === 37) {
        this.state.player.currentTime -= 1;
      } else if (e.keyCode === 32 && this.state.player.paused === true) {
        e.preventDefault();
        this.state.player.play();
      }  else if (e.keyCode === 32 && this.state.player.paused === false) {
        e.preventDefault();
        this.state.player.pause()
      }
    });

    player.on('wheel', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.originalEvent.wheelDelta > 0) {
        this.state.player.currentTime += 1;
      } else {
        this.state.player.currentTime -= 1;
      }
    });
  }

  getInitialState() {
    return {
      player: false
    }
  }

  componentWillUnmount() {
    var player = window.$('#' + this.props.id);
    player.off('play');
    player.off('pause');
    window.$(document).off('keydown')
    player.off('wheel')
  }

  getPlaybackTime(time) {
    var minutes = Math.floor(time / 60);
    if (minutes > 59) {
      minutes = Math.floor(time / 60) - 60;
    }

    var seconds = Math.round(time - minutes * 60);
    if (seconds > 3599) {
      seconds = Math.round(time - minutes * 60) - 3600;
    }

    return time;
  }

  playLocation() {
    this.setState({player: window.$('.player')[0]}, function() {
      var playbackTime = this.getPlaybackTime(this.state.player.currentTime);
      playbackTime = localStorage.getItem('codepenReactPlayer');

      if (playbackTime !== null) {
        this.setState(prevState => {
          return {
            ...prevState,
            player: {
              ...prevState.player,
              currentTime: playbackTime
            }
          }
        })
      }
      
      this.state.player.play();
    })
  }

  pause() {
    var playbackTime = this.getPlaybackTime(this.state.player.currentTime);
    localStorage.setItem('codepenReactPlayer', playbackTime);
  }

  ended() {
    // Set playback_time to 0.
    var playbackTime = 0;
    localStorage.setItem('codepenReactPlayer', playbackTime);
  }

  render() {

    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MaterialTable
        columns={[
          { title: 'ID', field: 'recordId'},
          { title: 'Date', field: 'date', render: rowData => <Moment format="DD/MM/YYYY HH:mm" date={rowData.date} />},
          { title: 'Source', field: 'source'},
          { title: 'Destination', field: 'destination'},
          { title: 'Lecture', field: 'mp3Filename', filtering: false,
            render: rowData => <audio id='player_records' controls src={rowData.mp3Filename + '&token=' + localStorage.getItem('_k')} type="audio/*" style={{backgroundColor: '#f1f3f4', border: "1px solid #ccc"}}></audio>
          },
        ]}
        data={query =>
          new Promise((resolve, reject) => {
            fetch(
              this.props.urlRecords + '/records', {
                method: 'post',
                headers: {
                  'Content-Type':'application/json',
                  'auth-token': `${localStorage.getItem('_k')}`
                },
                body: JSON.stringify({
                  "domaine": this.props.urlRecords,
                  "page": query.page + 1,
                  "limit": query.pageSize
                })
              }
            )
              .then(response => response.json())
              .then(result => {
                resolve({
                  data: result.data,
                  page:  query.page,
                  totalCount: this.state.nbrRecords,
                })
              })
          })
        }
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Enregistrements par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          exportButton: true,
          search: true,
          pageSize: 10,
          pageSizeOptions: [5,10,20],
          actionsColumnIndex: -1
        }}
        actions={[
          {
            icon: 'save',
            tooltip: 'Télécharger',
            onClick: (event, rowData) => {
              fetch(rowData.mp3Filename + '&token=' + localStorage.getItem('_k'))
                .then(resp => resp.blob())
                .then(blob => {
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  // the filename you want
                  a.download = rowData.name + '.wav';
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
              })
              .catch(() => 
                swal({
                  type: 'error',
                  icon: "error",
                  title: 'Oops...',
                  text: "Une erreur est survenue lors du téléchargement du fichier.",
                  confirmButtonColor: 'LightSeaGreen',
                })
              );
            },
          }
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    records: state.records
  })
}

export default connect(mapStateToProps, null)(RecordsTab);
