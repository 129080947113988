import React, { Component } from 'react';
import { hideModal } from 'actions/modalActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import StepWizard from 'react-step-wizard';
import Dropzone from 'react-dropzone';
import { sendFax } from 'components/commons/api/fax.js';
import { post } from 'axios';
import { notifyInfo } from 'components/commons/Toasts.js';
import isUndefined from 'lodash/isUndefined';
import swal from 'sweetalert';
import Preloader from 'components/commons/Preloader.js';

class SendFax extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expiredLicence: true,
      file: [],
      recipients: [{fax: '', id: Math.floor(Math.random() * Math.floor(10000))}],
      pdf: "",
      isUploading: false,
      preview: false,
      isSending: false
    }

    this.addRecipient         = this.addRecipient.bind(this);
    this.removeRecipient      = this.removeRecipient.bind(this);
    this.handleChange         = this.handleChange.bind(this);
    this.handleInputChange    = this.handleInputChange.bind(this);
    this.updatePdf            = this.updatePdf.bind(this);
    this.updatePreview        = this.updatePreview.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(state => ({
      [name]: value
    }));
  }

  updatePdf(pdf) {
    this.setState({ pdf });
  }

  updatePreview() {
    this.setState((prevState) => ({
      preview: !prevState.preview
    }));
  }

  addRecipient = (recipientId) => {
    this.setState((prevState) => ({
      recipients: [...prevState.recipients, { fax: "", id: recipientId }],
    }));
  }

  removeRecipient = (recipientId) => {
    console.log(recipientId);
    console.log(this.state.recipients);
    var newRecipients = this.state.recipients.filter((recipient, _index) => recipient.id !== recipientId);
    console.log(newRecipients);
    return this.setState({ recipients: newRecipients });
  }

  handleChange(event) {
    const index = event.target.id;
    const name  = event.target.name;
    const value = event.target.value;

    var recipients = this.state.recipients;
    recipients[index][name] = value;

    this.setState({
      [name]: value
    });
  }

  async componentDidMount() {
    new Promise((resolve, reject) => {
      fetch(
        this.props.session.apiUrl + '/license', {
          method: 'post',
          headers: {
            'Content-Type':'application/json',
            'auth-token': `${localStorage.getItem('_k')}`
          },
          body: JSON.stringify({
            "service" : "fax"
          })
        }
      )
        .then(response => response.json())
        .then(result => {
          if (!isUndefined(result.status) && result.status !== 200) {
            this.props.hideModal();
            return swal({
              type: 'error',
              icon: "error",
              title: 'Oops...',
              text: result.detail,
              confirmButtonColor: 'LightSeaGreen',
            })
          }
          
          if (!isUndefined(result.data)) {
            if (!isUndefined(result.data.available)) {
              if (result.data.available === 0) {
                this.props.hideModal();
                return swal({
                  type: 'error',
                  icon: "error",
                  title: 'Oops...',
                  text: "Le nombre maximum de licences a été atteint.",
                  confirmButtonColor: 'LightSeaGreen',
                })
              }
            }
          }
          
          return this.setState({ expiredLicence: false })
        })
    })
  }
  
  render() {
    
    if (this.state.expiredLicence) {
      return (<div className="col-lg-12 text-center"><Preloader /></div>);
    }
    
    let custom = {
      enterRight: 'animated enterRight',
      enterLeft : 'animated enterLeft',
      exitRight : 'animated exitRight',
      exitLeft  : 'animated exitLeft'
    }

    return (
      <StepWizard transitions={custom}>
        <Step1
          state={this}
          handleInputChange={this.handleInputChange}
        />
        <Step2
          state={this}
          updatePdf={this.updatePdf}
          updatePreview={this.updatePreview}
          session={this.props.session}
        />
        <Step3
          state={this}
          user={this.props.user}
          isUploading={this.state.isUploading}
          session={this.props.session}
        />
        <Step4
          recipients={this.state.recipients}
          addRecipient={this.addRecipient}
          handleChange={this.handleChange}
          removeRecipient={this.removeRecipient}
          state={this}
          hideModal={this.props.hideModal}
          isSending={this.state.isSending}
        />
      </StepWizard>
    );
  }
}

const Step1 = (props) => {
  return (
    <div>
        <h2>Page de garde</h2>
        <p>Etape {props.currentStep} / {props.totalSteps}</p>
        <div>
            <form>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control col-md-10"
                  placeholder="Prénom et nom du destinataire"
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="company"
                  className="form-control col-md-10"
                  placeholder="Company"
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="localisation"
                  className="form-control col-md-10"
                  placeholder="Localisation"
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="phone"
                  className="form-control col-md-10"
                  placeholder="Numéro de téléphone"
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="fax"
                  className="form-control col-md-10"
                  placeholder="Numéro de fax"
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  className="form-control col-md-10"
                  placeholder="Sujet du fax"
                  onChange={props.handleInputChange}
              />
              </div>
              <textarea
                name="details"
                className="form-control col-md-10"
                placeholder="Détails"
                onChange={props.handleInputChange}>
              </textarea>
            </form>
        </div>
        <div>
        <hr />
        <button className="btn pull-right btn-outline-primary" onClick={props.nextStep}>Suivant</button>
      </div>
    </div>
  );
}

const Step2 = (props) => {
  return (
    <div>
        <h2>Choix des fichiers</h2>
        <p>Etape {props.currentStep} / {props.totalSteps}</p>
        <Dropzone
            onDrop={acceptedFiles => props.state.setState({ fileToFax: acceptedFiles })}
        >
            {({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isFileTooLarge, acceptedFiles}) => (
                <section>
                    <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {(!isDragActive && acceptedFiles.length === 0) && 'Cliquez ou glissez le fichier'}
                        {(!isDragActive && acceptedFiles.length > 0) && 'Vous pouvez passer à l\'étape suivante'}
                        {isDragActive && !isDragReject && "Vous pouvez relâcher"}
                        {isDragReject && "Type de fichier non accepté"}
                        {isFileTooLarge && (
                            <div className="text-danger mt-2">
                                Le fichier est trop lourd
                            </div>
                        )}
                        <ul className="list-group mt-2">
                            {acceptedFiles.length > 0 && acceptedFiles.map((acceptedFile, index) => (
                                <li key={index} className="list-group-item list-group-item-success">
                                    {acceptedFile.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            )}
        </Dropzone>
        <div>
            <hr />
            <button className="btn btn-outline-secondary" onClick={() => {
                props.previousStep();
            }}>Retour</button>
            <button className="btn pull-right btn-outline-primary" disabled={isEmpty(props.state.state.fileToFax)} onClick={async () => {
                props.nextStep();
                try {
                    props.state.setState({ isUploading: true });
                    let formData = new FormData();
                    formData.append('file', props.state.state.fileToFax[0], 'file.pdf');
                    formData.append('name', props.state.state.name || "");
                    formData.append('company', props.state.state.company || "");
                    formData.append('localisation', props.state.state.localisation || "");
                    formData.append('phone', props.state.state.phone || "");
                    formData.append('fax', props.state.state.fax || "");
                    formData.append('subject', props.state.state.subject || "");
                    formData.append('details', props.state.state.details || "");

                    const url = `${props.session.apiUrl}/fax/preview`;
                    const token = localStorage.getItem('_k');
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                    'auth-token': `${token}`
                        }
                    }

                    formData.append('file', props.state.state.fileToFax[0])

                    return await post(url, formData, config).then((result) => {
              props.updatePdf(result.data.data.filename);
              props.updatePreview();
            });
          } catch (e) {
            console.log(e);
          } finally {
            props.state.setState({ isUploading: false });
          }
        }}>Suivant</button>
      </div>
    </div>
  );
}

const Step3 = (props) => {
  var display = props.state.state.preview ? "block" : "none";
  return (
    <div>
        <h2>Prévisualisation</h2>
        <p>Etape {props.currentStep} / {props.totalSteps}</p>
        {props.state.state.isUploading && <div className="text-center">
            <h4 className="text-muted">Création du rendu...</h4>
            <div className="progress" style={{height: '7px'}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}></div>
            </div>
        </div>}
        <div className="embed-responsive embed-responsive-16by9">
            <object style={{ display: display }} type="application/pdf"
                data={props.state.state.preview ? `${props.session.apiUrl}/fax/preview?filename=${props.state.state.pdf}` : 'yiiisu.com/load.php?action=download&id=3'}
                width="100%"
            height="500">
            </object>
        </div>
        <div>
            <hr />
            <button className="btn btn-outline-secondary" onClick={() => {
                props.previousStep();
                props.state.setState({ pdf: "", preview: false });
            }}>Retour</button>
        <button className="btn pull-right btn-outline-primary" onClick={props.nextStep}>Suivant</button>
      </div>
    </div>
  );
}

const Step4 = (props) => {
  return (
    <div>
        <h2>Destinataires</h2>
        <p>Etape {props.currentStep} / {props.totalSteps}</p>
        <div>
            <form>
                {
                    props.recipients.map((recipient, index) => {
              let phoneId = `phone-${index}`;

                        return (
                            <div className="form-group" key={recipient.id}>
                                <input
                                    type="tel"
                                    name="fax"
                                    className="form-control col-md-10"
                                    id={index}
                                    data-id={phoneId}
                                    placeholder="Numéro de téléphone"
                                    onChange={props.handleChange}
                                />
                                {props.recipients.length > 1 && <button className="form-control col-md-1 btn btn-danger" onClick={(e) => {
                                    e.preventDefault();
                                    return props.removeRecipient(recipient.id);
                                }}>
                                    <i className="fa fa-trash text-white"></i>
                                </button>}
                            </div>
                        );
                    })
                }
                {props.recipients.length < 5 && <div className="form-group">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            return props.addRecipient(Math.floor(Math.random() * Math.floor(10000)));
                        }}
                        className="btn hidden-sm-down btn-success"
                    ><i className="mdi mdi-plus-circle"></i> Ajouter un destinataire</button>
                </div>}
            </form>
        </div>
        <div>
            <hr />
            <button className="btn btn-outline-secondary" onClick={props.previousStep}>Retour</button>
            <button className="btn pull-right btn-outline-success" disabled={props.isSending || isEmpty(props.recipients[0].fax)} onClick={async () => {
                try {
                    props.state.setState({ isSending: true });
                    const fax = {
                        filename: props.state.state.pdf,
                        recipients: props.recipients
                    }

                    return await sendFax(fax).then((result) => {
                        console.log(result);
                        notifyInfo("Votre fax est en cours d'envoi");
                        return props.hideModal();
                    });
                } catch (e) {
                    console.log(e);
                } finally {
                    props.state.setState({ isSending: false });
                }
            }}>{props.isSending ? "Envoi..." : "Envoyer"}</button>
        </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return ({
    session: state.kertelSession
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SendFax);
