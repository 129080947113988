
export const loadingMevos = () => {
  return { type: 'LOADING_MEVOS' }
};

export const getMevos = (informations) => {
  return {
    type: 'GET_MEVOS',
    payload: informations
  }
};

export const stopLoadingMevo = () => {
  return { type: 'STOP_LOADING_MEVO' }
};

export const loadingMevo = () => {
  return { type: 'LOADING_MEVO' }
};

export const getMevo = (informations) => {
  return {
    type: 'GET_MEVO',
    payload: informations
  }
};

export const stopLoadingMevos = () => {
  return { type: 'STOP_LOADING_MEVOS' }
};

export const startEditingMevo = (mevoId) => {
  return {
    type: 'START_EDITING_MEVO',
    payload: mevoId
  }
};

export const stopEditingMevo = () => {
  return { type: 'STOP_EDITING_MEVO' }
};


export const startConfigMevo = (mevoId) => {
  return {
    type: 'START_CONFIG_MEVO',
    payload: mevoId
  }
};

export const stopConfigMevo = () => {
  return { type: 'STOP_CONFIG_MEVO' }
};