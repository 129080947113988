import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import { connect } from 'react-redux';
import { deleteInterception } from 'components/commons/helpers/admin/interceptionGroupHelper.js';
import MaterialTable from 'material-table';
import { startEditingInterception } from 'actions/admin/interceptionGroupActions.js';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router';

function InterceptionGroupTab({ isLoading, interception, startEditingInterception }) {

  const navigate = useNavigate();
    
    if (isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MaterialTable
        columns={[
          { title: 'id', field: 'id', hidden: true },
          { title: 'Nom', field: 'name' },
          { title: 'Nombre de membres', sorting: false,
            render: rowData => (
                <div>{(rowData.members != null)?rowData.members.length:"null"}</div>
            )
          },
        ]}
        data={interception}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement � afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Groupe d'interceprion par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
              labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Modifier',
            onClick: (event, rowData) => {
              startEditingInterception(rowData.id);
              return navigate('/admin/editgroupinterception');
            },
          },
          {
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
              return deleteInterception(rowData.id);
            },
          }
        ]}
      />
    );
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingInterception,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InterceptionGroupTab);
