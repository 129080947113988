import React, { Component } from 'react';
import MessagesMevoGroupTab from './MessagesMevoGroupTab.js';
import { connect } from 'react-redux';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';

class MessagesMevoGroup extends Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
  }

  render() {
    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <MaterialTable
        columns={[
          { title: 'Nom', field: 'name'},
          { title: 'Nombre de messages', field: 'messages'},
          { title: 'N° Court', field: 's164', render: rowData => (
              <div>{(rowData.s164 != null)? rowData.s164.join(', '):""}</div>
            )
          },
          { title: 'Nombre de membres', field: 'members'},
        ]}
        data={this.props.messages}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Messagerie de groupe par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
              labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
        }}
        detailPanel={[
          {
            tooltip: "Plus d'information",
            render: rowData => {
              console.log(this.props.mevoGroup);
              return(
              <MessagesMevoGroupTab
                messages={this.props.mevoGroup[rowData.name]}
                isLoading={this.props.isLoading}
                refreshData={() => this.componentDidMount()}
              />
            )}
          },
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messagesMevoGroup: state.messagesMevoGroup
  };
}

export default connect(mapStateToProps, null)(MessagesMevoGroup);
