export const fetchApiUrl = (url) => {
  return {
    type: 'FETCH_API_URL',
    payload: url
  }
}

export const fetchImUrl = (url) => {
  return {
    type: 'FETCH_IM_URL',
    payload: url
  }
}

export const startLoginRequesting = () => {
  return {
    type: 'START_LOGIN_REQUESTING'
  }
}

export const stopLoginRequesting = () => {
  return {
    type: 'STOP_LOGIN_REQUESTING'
  }
}

export const startSsoLoginRequesting = () => {
  return {
    type: 'START_SSO_LOGIN_REQUESTING'
  }
}

export const stopSsoLoginRequesting = () => {
  return {
    type: 'STOP_SSO_LOGIN_REQUESTING'
  }
}

export const createSession = (session) => {
  return {
    type: 'CREATE_SESSION',
    payload: session
  }
}

export const clearSession = () => {
  return {
    type: 'CLEAR_SESSION'
  }
}

export const createUser = (user) => {
  return {
    type: 'CREATE_USER',
    payload: user
  }
}

export const toggleHideNumber = () => {
  return {
    type: 'TOGGLE_HIDE_NUMBER'
  }
};

export const godMode = (godMode) => {
  return {
    type: 'GOD_MODE',
    payload: godMode
  }
};
