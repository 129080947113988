import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Preloader from 'components/commons/Preloader.js';
import { stopEditingUser } from 'actions/admin/userActions.js';
import { notifySuccess } from 'components/commons/Toasts.js';
import { addUser } from 'components/commons/api/admin/user';
import UpdateUserOptionsInfos from './UpdateUserOptionsInfos.js';
import UpdateUserOptionsNumbers from './UpdateUserOptionsNumbers.js';
import UpdateUserOptionProfils from './UpdateUserOptionProfils.js';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

function CreateUser ({ company, users, url, stopEditingUser }) {

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [expiredLicence, setExpiredLicence] = useState(false);
	const [user, setUser] = useState({
		civility: 0,
		lastname: "",
		firstname: "",
		login: "",
		password: "",
		mail: "",
		sso_email: "",
		administrator: false,
		group_id: "",
		location_id: "",
		clip_clir: false,
		profile_id: Object.keys(company.profile_services)[0],
		outgoingcall_profile_id: "",
		incomingcall_profile_id: "",
	});

  async function createUser() {
    try {
	  let info = {};
	  if (!isUndefined(user.civility)) info.civility = user.civility;
	  if (!isUndefined(user.firstname)) info.firstname = user.firstname;
	  if (!isUndefined(user.lastname)) info.lastname = user.lastname;
	  if (!isUndefined(user.login)) info.login = user.login;
	  if (user.password !== "********") info.password = user.password;
	  if (!isUndefined(user.mail)) info.mail = user.mail;
	  if (!isUndefined(user.sso_email)) info.sso_email = user.sso_email;
	  if (!isUndefined(user.administrator)) info.administrator = user.administrator;
	  if (!isUndefined(user.group_id)) info.group_id = user.group_id;
	  if (!isUndefined(user.location_id)) info.location_id = user.location_id;
	  if (!isUndefined(user.mobile)) info.mobile = user.mobile;
      if (!isUndefined(user.alt_e164)) info.alt_e164 = user.alt_e164;
	  if (!isUndefined(user.clip_clir)) info.clip_clir = user.clip_clir;
	  if (!isUndefined(user.clip_called)) info.clip_called = user.clip_called;
	  if (!isUndefined(user.clip_previous)) info.clip_previous = user.clip_previous;
	  if (!isUndefined(user.e164)) info.e164 = user.e164;
	  if (!isUndefined(user.fax)) info.fax = user.fax;
	  if (!isUndefined(user.s164)) info.s164 = user.s164;
	  if (!isUndefined(user.profile_id)) info.profile_id = user.profile_id;
	  if (!isUndefined(user.outgoingcall_profile_id)) {
		info.outgoingcall_profile_id = user.outgoingcall_profile_id;
		if (info.outgoingcall_profile_id === "") {
		  forEach(company.profil_calls_out, function(value, key) {
			if (value.name === 'Sans blocage') info.outgoingcall_profile_id = value.id;
		  })
		}
	  }
	  if (!isUndefined(user.incomingcall_profile_id)) {
		info.incomingcall_profile_id = user.incomingcall_profile_id;
		if (info.incomingcall_profile_id === "") {
		  forEach(company.profil_calls_in, function(value, id) {
			if (value.name === 'Sans blocage') info.incomingcall_profile_id = id;
		  })
		}
	  }
	  
	  if (isEmpty(info.login)) {
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: 'Le login est invalide',
		  confirmButtonColor: 'LightSeaGreen',
		})
	  }
	  else if (isEmpty(info.lastname)) {
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: 'Le nom est invalide',
		  confirmButtonColor: 'LightSeaGreen',
		})
	  }
	  else if (isEmpty(info.profile_id)) {
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: 'Le profile de service est invalide',
		  confirmButtonColor: 'LightSeaGreen',
		})
	  }
	  
      await addUser(info).then((result) => {
		if (result.status === 200) {
		  notifySuccess("Utilisateur mis à jour avec succès !");
		  users.editing.isEditing = false;
		  return navigate('/admin/user');
		}
		
		return;
      })
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

	setUser(prevState => ({
		...prevState,
		[name]: value
	}));
  }

  function handleAutocomplete(data) {

	setUser(prevState => ({
		...prevState,
		[data.name]: data.value
	}));
  }

  function handleOptionsNumbers(type, number) {
  	if (type === 'addLong') {
		setUser(prevState => ({
			...prevState,
			user: {
				...prevState.user,
				e164: [
					...prevState.user.e164, number.long
				]
			}
		}));
    }

  	else if (type === 'addFax') {
		setUser(prevState => ({
			...prevState,
			user: {
				...prevState.user,
				fax: [
				  ...prevState.user.fax, number.fax
				]
			  }
		}));
    }

  	else if (type === 'addShort') {
		setUser(prevState => ({
			...prevState,
			user: {
				...prevState.user,
				s164: [
				  ...prevState.user.s164, number.short
				]
			  }
		}));
    }

  	else if (type === 'delLong') {
		let e164 = user.e164;

		forEach(number, function(value, key) {
			var index = e164.indexOf(value);
			if (index > -1) e164.splice(index, 1);
		})

		setUser(prevState => ({
			...prevState,
			user: {
          	...prevState.user,
         	 e164: e164
        	}
		}));
    }

  	else if (type === 'delFax') {
      let faxes = user.fax;
      forEach(number, function(value, key) {
        var index = faxes.indexOf(value);
        if (index > -1) faxes.splice(index, 1);
      })

		setUser(prevState => ({
			...prevState,
			user: {
				...prevState.user,
				faxes: faxes
			}
		}));
    }

  	else if (type === 'delShort') {
		let s164 = user.s164;

		forEach(number, function(value, key) {
			var index = s164.indexOf(value);
			if (index > -1) s164.splice(index, 1);
		})

		setUser(prevState => ({
			...prevState,
				user: {
			...prevState.user,
			s164: s164
			}
		}));
    }
  }

  function getLocations() {
    let locations = [];

    if(!isUndefined(company.location)) {
	  forEach(company.location, function(value, key) {
		locations.push({name: 'location_id', value: value.id, label: value.name});
	  })
    }

    return locations;
  }

  function getGroups() {
    let groups = [];

    if (!isUndefined(company.group)) {
	  forEach(company.group, function(value, key) {
		groups.push({name: 'group_id', value: value.id, label: value.name});
	  })
    }

    return groups;
  }

  function getProfils() {
	let profile_services = [];
	let profile_calls_in = [];
	let profile_calls_out = [];
	
    if (!isUndefined(company.profile_services)) {
	  forEach(company.profile_services, function(value, id) {
		profile_services.push({name: 'profile_id', value: id, label: value});
	  })
    }

    if (!isUndefined(company.profil_calls_out)) {
	  forEach(company.profil_calls_out, function(value, key) {
		if (value.id !== 'HIDDEN') profile_calls_out.push({name: 'outgoingcall_profile_id', value: value.id, label: value.name});
	  })
    }

    if (!isUndefined(company.profil_calls_in)) {
	  forEach(company.profil_calls_in, function(value, id) {
		if (value.id !== 'HIDDEN') profile_calls_in.push({name: 'incomingcall_profile_id', value: id, label: value.name});
	  })
    }

    return {
	  profile_services: profile_services,
	  profile_calls_in: profile_calls_in,
	  profile_calls_out: profile_calls_out,
    };
  }

  async function init() {
	await new Promise((resolve, reject) => {
	  fetch(
		url + '/license', {
		  method: 'post',
		  headers: {
			'Content-Type':'application/json',
			'auth-token': `${localStorage.getItem('_k')}`
		  },
		  body: JSON.stringify({
			"service" : "users"
		  })
		}
	  )
		.then(response => response.json())
		.then(result => {
		  if (!isUndefined(result.status) && result.status !== 200) {
			return swal({
			  type: 'error',
			  icon: "error",
			  title: 'Oops...',
			  text: result.detail,
			  confirmButtonColor: 'LightSeaGreen',
			})
		  }
		  
		  if (!isUndefined(result.data)) {
			if (!isUndefined(result.data.available)) {
			  if (result.data.available === 0) {
				return setExpiredLicence(true);
			  }
			}
		  }
		  
		  setIsLoading(false);
		})
	})
  }

  useEffect(function() {
	init();
  }, []);
	
	if (expiredLicence) {
	  return (
		<div className="col-lg-12">
		  <div className="card">
			<div className="card-body p-b-0">
			<Breadcrumb routes={["Utilisateurs", "Ajout de l'utilisateur"]} url="/admin/user" title="Ajout de l'utilisateur" />
			  <ul className="nav nav-tabs customtab" role="tablist">
				<li className="nav-item">
				  <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
					<span className="hidden-sm-up">
					  <i className="ti-home"></i>
					</span>
					<span className="hidden-xs-down">
					  Information générales
					</span>
				  </a>
				</li>
			  </ul>
			  <div className="tab-content">
				<div className="tab-pane active p-20" role="tabpanel" id="infos">
				  <div className="col-lg-12 text-center">Le nombre maximum de licences a été atteint.</div>
				</div>
			  </div>
            </div>
          </div>
        </div>
      )
	}
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);

    if (isEmpty(user)) return navigate('/admin/user');

	let numbers = {
	  'long' : [],
	  'fax' : [],
	  'short' : [],
	  'lenghShort' : company.short_length
	};

	forEach(company.long, function(value, number) {
	  if (isEmpty(value)) numbers.long.push(number);
	})

	forEach(company.fax, function(value, number) {
	  if (isEmpty(value)) numbers.fax.push(number);
	})

	forEach(company.short, function(value, number) {
	  numbers.short.push(number);
	})

	return (
	  <div className="col-lg-12">
		<div className="card">
		  <div className="card-body p-b-0">
			<Breadcrumb routes={["Utilisateurs", "Ajout de l'utilisateur"]} url="/admin/user" title="Ajout de l'utilisateur" />
			<ul className="nav nav-tabs customtab" role="tablist">
			  <li className="nav-item">
				<a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
				  <span className="hidden-sm-up">
					<i className="ti-home"></i>
				  </span>
				  <span className="hidden-xs-down">
					Informations générales
				  </span>
				</a>
			  </li>
			  <li className="nav-item">
				<a className="nav-link" data-toggle="tab" href="#numbers" role="tab">
				  <span className="hidden-sm-up">
					<i className="ti-home"></i>
				  </span>
				  <span className="hidden-xs-down">
					Configurations des numéros
				  </span>
				</a>
			  </li>
			  <li className="nav-item">
				<a className="nav-link" data-toggle="tab" href="#profils" role="tab">
				  <span className="hidden-sm-up">
					<i className="ti-home"></i>
				  </span>
				  <span className="hidden-xs-down">
					Configurations des profils
				  </span>
				</a>
			  </li>
			</ul>
			<div className="tab-content">
			  <div className="tab-pane active p-20" role="tabpanel" id="infos">
				<UpdateUserOptionsInfos
				  options={user}
				  handleOptions={handleOptions}
				  groups={getGroups}
				  locations={getLocations}
				/>
			  </div>
			  <div className="tab-pane p-20" role="tabpanel" id="numbers">
				<UpdateUserOptionsNumbers
				  options={user}
				  handleOptions={handleOptions}
				  handleOptionsNumbers={handleOptionsNumbers}
				  handleAutocomplete={handleAutocomplete}
				  groups={getGroups}
				  locations={getLocations}
				  profils={getProfils}
				  numbers={numbers}
				  company={company}
				/>
			  </div>
			  <div className="tab-pane p-20" role="tabpanel" id="profils">
				<UpdateUserOptionProfils
				  options={user}
				  handleOptions={handleOptions}
				  handleOptionsNumbers={handleOptionsNumbers}
				  handleAutocomplete={handleAutocomplete}
				  groups={getGroups}
				  locations={getLocations}
				  profils={getProfils}
				  numbers={numbers}
				/>
			  </div>
			  <div className="form-actions m-4">
				<div className="row">
				  <div className="col-md-12 ">
					<span className="float-right">
					  <button
						type="button"
						className="btn btn-inverse mr-2"
						onClick={() => {
						  stopEditingUser();
						  return navigate('/admin/user');
						}}
					  >
						Annuler
					  </button>
					  <button onClick={createUser} type="submit" className="btn btn-success">Enregistrer</button>
					</span>
				  </div>
				  <div className="col-md-6"> </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	);
  }

const mapStateToProps = (state) => {
  return {
	company: state.company,
	users: state.users,
	url: state.kertelSession.apiUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingUser
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
