import React, { Component } from "react";
import { connect } from 'react-redux';
import ShortNumberModal from '../../modals/ShortNumberModal.js'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import forEach from 'lodash/forEach';
import IconButton from '@material-ui/core/IconButton';
import { blue, green, red } from '@material-ui/core/colors';
// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
library.add(faSync);

class EditMevoGroupInfos extends Component
{
  customNoConfirm(next) {
	next();
  }

  selectRowProp = {
	mode: 'checkbox'
  };

  createCustomModalHeader = (closeModal) => {
	const headerStyle = {
	  fontWeight: 'bold',
	  fontSize: 'large',
	  textAlign: 'center',
	  backgroundColor: '#eeeeee',
	  padding:'50px'
	};

	return (
	  <div className='modal-header' style={headerStyle} />
	);
  }

  createCustomModalFooter = (onClose, onSave) => {
    return (
      <div className='modal-footer' style={{ backgroundColor: '#ffffff' }}>
        <button className='btn btn-inverse mr-2' onClick={ onClose }>Annuler</button>
        <button className='btn btn-success' onClick={ onSave }>Enregistrer</button>
      </div>
    );
  }

  createCustomModal = (onModalClose, onSave, columns, validateState, ignoreEditable) => {
    const attr = {
      onModalClose, onSave, columns, validateState, ignoreEditable
    };

    return (
      <ShortNumberModal
        { ... attr }
        numbers={this.props.numbers}
        options={this.props.options}
      />
    );
  }

  render() {

	var longNumbers = [];
	var listLongNumbers = this.props.numbers.long;
	forEach(this.props.options.e164, function(value) {
	  var index = listLongNumbers.indexOf(value);
	  if (index > -1) listLongNumbers.splice(index, 1);
	  longNumbers.push({long: value});
	})

	var shortNumbers = [];
	forEach(this.props.options.s164, function(value) {
	  shortNumbers.push({short: value});
	})
	
	return (
	  <Container component="main">
		<Grid container spacing={2}>

		  <Grid item xs={12} sm={4}>
			<TextField
			  name="name"
			  label="Nom"
			  fullWidth
			  value={this.props.options.name}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={2}>
			{
			  this.props.form === "create" && <TextField
				id="tty_id"
				name="tty_id"
				label="Numéro de poste"
				type="number"
				min= "1"
				value={this.props.options.tty_id}
				onChange={this.props.handleOptions}
				InputProps={{
				  step: "1",
				  startAdornment: (
					<TextField disabled defaultValue={this.props.options.company_id} style={{width: '50px' }} />
				  ),
				}}
			  />
			}
			{
			  this.props.form === "update" && <TextField
				id="tty_id"
				name="tty_id"
				label="Numéro de poste"
				type="number"
				disabled
				value={this.props.options.tty_id}
			  />
			}
		  </Grid>
		  <Grid item xs={2} sm={1} style={{paddingTop: '20px',float: 'right' }}>
			{
			  this.props.form === "create" && this.props.options.btLoad && <IconButton aria-label="load" onClick={this.props.handleCheckTttId}>
				<FontAwesomeIcon icon="sync" color={blue[300]} size="sm" />
			  </IconButton>
			}
  	        {
			  this.props.form === "create" && this.props.options.btAllow && <IconButton aria-label="allow">
				<FontAwesomeIcon icon="check" color={green[300]} size="sm" />
			  </IconButton>
			}
  	        {
			  this.props.form === "create" && this.props.options.btForbidden && <IconButton aria-label="forbidden">
				<FontAwesomeIcon icon="times" color={red[300]} size="sm" />
			  </IconButton>
			}
		  </Grid>
		  <Grid item xs={12} sm={3} />

		  <Grid item xs={12} sm={4}>
			<BootstrapTable
			  data={ longNumbers }
			  deleteRow
			  insertRow
			  selectRow={this.selectRowProp}
			  renderAlert={false}
			  options={{
				handleConfirmDeleteRow: this.customNoConfirm,
				afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addLong'),
				afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delLong'),
				insertModalHeader: this.createCustomModalHeader,
				insertModalFooter: this.createCustomModalFooter,
				noDataText: 'Aucun numéro disponible',
				insertText: 'Ajouter',
				deleteText: 'Supprimer',
			  }}
			>
			  <TableHeaderColumn dataField='long' editable={{type: 'select', options: {values: listLongNumbers}}} isKey >Numéros de téléphone</TableHeaderColumn>
			</BootstrapTable>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<BootstrapTable
			  data={ shortNumbers }
			  deleteRow
			  insertRow
			  selectRow={this.selectRowProp}
			  renderAlert={false}
			  options={{
                handleConfirmDeleteRow: this.customNoConfirm,
				afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addShort'),
				afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delShort'),
                insertModal: this.createCustomModal,
				noDataText: 'Aucun numéro disponible',
				insertText: 'Ajouter',
				deleteText: 'Supprimer',
			  }}
			>
			  <TableHeaderColumn dataField='short' isKey >Numéro(s) court(s)</TableHeaderColumn>
			</BootstrapTable>
		  </Grid>
		  <Grid item xs={12} sm={4} />
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(EditMevoGroupInfos);
