import React from 'react';
import Preloader from 'components/commons/Preloader.js';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { useSelector } from "react-redux";
import Table from 'components/commons/tables/Table.js'
import { clickCall } from 'components/commons/helpers/phoneHelper';

function RecentsTab ({ recents, hideSearch, isLoading, sizePerPage }) {

  const phone = useSelector(state => state.kertelphone);
   
  if (isLoading) {
    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={10}><Preloader /></td>
          </tr>
        </tbody>
      </table>
    );
  }

  const columns = [
    { title: 'Date et heure', text: 'Date et heure', dataField: 'date',
      formatter: (cell, rowData, rowIndex) => {
        return (<React.Fragment>Le <Moment locale='fr' unix format="DD MMMM YYYY à HH:mm:ss">{rowData.date || "-"}</Moment></React.Fragment>)
      }
    },
    { title: 'Contact ou numéro', text: 'Contact ou numéro', dataField: 'number',
      formatter: (cell, rowData) => {
        return rowData.src_name || rowData.src_number || rowData.dst_name || rowData.dst_number
      }
    },
    { title: 'Statut', text: 'Statut', dataField: 'statut',
      formatter: (cell, rowData, rowIndex) => {
        if (rowData.state === 'connected') {
          return <React.Fragment>Conversation</React.Fragment>
        }
      
        if (rowData.state === 'noanswer') {
          return <React.Fragment>Non répondu</React.Fragment>
        }
        
        return "-";
      }
    },
    { title: 'Durée', text: 'Durée', dataField: 'duration',
      formatter: (cell, rowData, rowIndex) => {
        let minutes = Math.floor(rowData.duration / 60);
        let secondes = Math.ceil(rowData.duration % 60);

        return <React.Fragment>{minutes} mn {secondes < 10 ? "0" + secondes : secondes} s</React.Fragment>
      }
    },
    { title: 'Action', text: 'Action', dataField: 'action',
      formatter: (cell, rowData, rowIndex) => {
        return (
          <div>
            {
              phone.hasWebPhone &&
                <div className="btn btn-success btn-circle" onClick={() => {
                    clickCall(rowData.dst_number || rowData.src_number)
                  }}>
                  <i className="fa fa-phone"></i>
                </div>
            }
          </div>
        )
      }
    },
  ];

  return (
    <Table
      datas={recents}
      columns={columns}
      searchPlaceholder={!hideSearch && "Chercher un appel"}
      sizePerPage={sizePerPage}
    />

  );
}

export default RecentsTab;
