import React from 'react';
import Select from 'react-select';
import forEach from 'lodash/forEach';

export default function CustomSelect(props) {

  const handleOptionsSelect = (props) => {
	let options = [];
    forEach(props.options, function(key, items) {
	  options.push({name:props.name, value: items, label: items});
	})
    
    return options;
  }
  
  return (
    <div className="section">
      <Select
        placeholder="Rechercher un numéro"
        options={handleOptionsSelect(props)}
        value={props.value}
        onChange={props.handleAutocomplete}
      />
    </div>
  );
};

