import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchSviMenus } from 'components/commons/helpers/admin/sviHelper.js';
import SviMenus from './SviMenus.js';

class Svi extends Component {
  
  async componentDidMount() {
    await fetchSviMenus();
  }
  
  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={['Menus SVI']} title='Menus SVI' addSviMenu={true} />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#users" role="tab" onClick={fetchSviMenus}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Liste des menus svi
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="users" role="tabpanel">
                <SviMenus
                  isLoading={this.props.svi.isLoading}
                  menusSvi={this.props.svi.menus}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    svi: state.svi
  }
}

export default connect(mapStateToProps, null)(Svi);
