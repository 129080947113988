export const addDirectButton = (button) => {
  return {
    type: 'ADD_DIRECT_BUTTON',
    payload: button
  }
};

export const removeDirectButtons = () => {
  return {
    type: 'REMOVE_DIRECT_BUTTONS'
  }
};

export const setEditingButton = (button) => {
  return {
    type: 'SET_EDITING_DIRECT_BUTTON',
    payload: button
  }
};
