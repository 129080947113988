
export const loadingCompanyContact = () => {
  return { type: 'LOADING_COMPANY_CONTACTS' }
};

export const fetchCompanyContacts = (informations) => {
  return {
    type: 'FETCH_COMPANY_CONTACTS',
    payload: informations
  }
};

export const fetchCompanyContact = (informations) => {
  return {
    type: 'FETCH_COMPANY_CONTACT',
    payload: informations
  }
};

export const stopLoadingCompanyContact = () => {
  return { type: 'STOP_LOADING_COMPANY_CONTACTS' }
};

export const startEditingCompanyContact = (companyId) => {
  return {
    type: 'START_EDITING_COMPANY_CONTACT',
    payload: companyId
  }
};

export const stopEditingCompanyContact = () => {
  return { type: 'STOP_EDITING_COMPANY_CONTACT' }
};
