import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router';

function AdminBar() {

  const navigate = useNavigate();

  return (
    <header id="admin-header" className="topbar">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button className="navbar-brand" >Administrateur</button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item dropdown mr-2">
              <button className="nav-link" id="navbarDropdownMenuLink"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="mdi mdi-account-multiple mr-1"></i><span className="mr-1">Utilisateurs</span>  <div className="has-arrow"></div>
              </button>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/user');
                }}>Liste</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/forward');
                }}>Renvois d'appel</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/mevomessages');
                }}>Messagerie vocale</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/fax');
                }}>WebFax</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/groupinterception');
                }}>Interception</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/supervision');
                }}>Supervision</button>
              </div>
            </li>
            <li className="nav-item dropdown mr-2">
              <button className="nav-link"  id="navbarDropdownMenuLink"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="mdi mdi-widgets mr-1"></i><span className="mr-1">Numérotation</span> <div className="has-arrow"></div>
              </button>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/numbers');
                }}>Plage de numéros</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/companycontact');
                }}>Annuaire société</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/settings');
                }}>Profils d'appels</button>
              </div>
          </li>
          <li className="nav-item dropdown mr-2">
              <button className="nav-link"  id="navbarDropdownMenuLink"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="mdi mdi-phone mr-1"></i><span className="mr-1">Appels</span></button><div className="has-arrow"></div>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/records');
                }}>Enregistrement</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/stats');
                }}>Statistiques</button>
        <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/cdr');
                }}>CDR</button>
              </div>
          </li>
          <li className="nav-item dropdown mr-2">
              <button className="nav-link"  id="navbarDropdownMenuLink"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="mdi mdi-phone mr-1"></i><span className="mr-1">Postes</span></button><div className="has-arrow"></div>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/mevogroup');
                }}>Messagerie de groupe</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/ringtonegeneral');
                }}>Sonnerie générale</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/waitingqueue');
                }}>File d'attente</button>
                <button className="dropdown-item" onClick={() => {
                  return navigate('/admin/svi');
                }}>SVI</button>
              </div>
          </li>
          <li className="nav-item dropdown mr-2">
              <button className="nav-link"  id="navbarDropdownMenuLink"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="mdi mdi-credit-card-multiple mr-1"></i><span className="mr-1">Facturation</span><div className="has-arrow"></div></button>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <button className="dropdown-item" onClick={() => {
                return navigate('/admin/bills');
              }}>Factures</button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminBar);
