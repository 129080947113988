import React, { Component } from "react";
import { connect } from 'react-redux';
import ShortNumberModal from '../../modals/ShortNumberModal.js'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import forEach from 'lodash/forEach';

class EditMevoGroupInfos extends Component {
  
  customNoConfirm(next) {
	next();
  }
  
  selectRowProp = {
	mode: 'checkbox'
  };
  
  createCustomModal = (onModalClose, onSave, columns, validateState, ignoreEditable) => {
    const attr = {
      onModalClose, onSave, columns, validateState, ignoreEditable
    };
    
    return (
      <ShortNumberModal
        { ... attr }
        numbers={this.props.numbers}
        options={this.props.options}
      />
    );
  }
  
  render() {
	
	var shortNumbers = [];
	forEach(this.props.options.s164, function(value) {
	  shortNumbers.push({short: value});
	})

	return (
	  <Container component="main">
		<Grid container spacing={2}>
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="name"
			  label="Nom"
			  fullWidth
			  value={this.props.options.name}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="password"
			  label="Mot de passe"
			  type="password"
			  fullWidth
			  helperText="6 chiffres minimum."
			  value={this.props.options.password}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={2} />
		  <Grid item xs={12} sm={4}>
			<BootstrapTable
			  data={ shortNumbers }
			  deleteRow
			  insertRow
			  selectRow={this.selectRowProp}
			  renderAlert={false}
			  options={{
                handleConfirmDeleteRow: this.customNoConfirm,
				afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addShort'),
				afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delShort'),
                insertModal: this.createCustomModal,
				noDataText: 'Aucun numéro disponible',
				insertText: 'Ajouter',
				deleteText: 'Supprimer',
			  }}
			>
			  <TableHeaderColumn dataField='short' isKey >Numéro(s) court(s)</TableHeaderColumn>
			</BootstrapTable>
		  </Grid>
		  <Grid item xs={12} sm={8} />
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(EditMevoGroupInfos);
