import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { isUndefined } from 'lodash';
import { getCdrInformation } from 'components/commons/api/admin/cdr';
import { notifyWarning } from 'components/commons/Toasts.js';
import { fetchUsers } from '../../../commons/helpers/admin/userHelper';
import Table from 'components/commons/tables/Table.js'
import Moment from 'react-moment';

class Cdr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateStart: new Date().getDate(),
      hourStart: '',
      dateEnd: '',
      hourEnd: '',
      user: "utilisateurs",
      cdr: []
    }

    this.getCdr = this.getCdr.bind(this);
  }

  async getCdr(e) {
    e.preventDefault();

    if (isEmpty(this.state.dateStart) || isEmpty(this.state.dateEnd)) {
      return notifyWarning('Merci de remplir tous les champs');
    }

    const { company, provider } = this.props.session.user;

    try {
      const cdr = await getCdrInformation({
        user_id: this.state.user,
        company: company,
        provider: provider,
        startdate: this.state.dateStart + " " + this.state.hourStart + ":00",
        enddate: this.state.dateEnd + " " + this.state.hourEnd + ":00",
      });
  
      this.setState({ cdr: cdr.data });
    } catch (error) {
      console.log("error CDR");
    }
  }

  async componentDidMount() {
    await fetchUsers();
  }

  render() {

    if (isUndefined(this.props.users.datas)) {
      return (<div>Chargement...</div>)
    }

    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb title='CDR' />
            <div className="row">
              <div className="col-lg-2">
                <label className="small text-muted">Date de début</label>
                <input
                  type="date"
                  className="form-control"
                  value={this.state.dateStart}
                  name="dateStart" onChange={e => {
                  this.setState({ dateStart: e.target.value })
                }} />
              </div>

              <div className="col-lg-1">
                <label className="small text-muted">Heure de début</label>
                <input
                  type="time"
                  className="form-control"
                  value={this.state.hourStart}
                  name="hourStart" onChange={e => {
                  this.setState({ hourStart: e.target.value })
                }} />
              </div>

              <div className="col-lg-2">
                <label className="small text-muted">Date de fin</label>
                <input
                  type="date"
                  className="form-control"
                  value={this.state.dateEnd}
                  name="dateEnd"
                  onChange={e => {
                  this.setState({ dateEnd: e.target.value })
                }} />
              </div>

              <div className="col-lg-1">
                <label className="small text-muted">Heure de fin</label>
                <input
                  type="time"
                  className="form-control"
                  value={this.state.hourEnd}
                  name="hourEnd" onChange={e => {
                  this.setState({ hourEnd: e.target.value })
                }} />
              </div>

              <div className="col-lg-3">
                <label className="small text-muted">Utilisateur</label>
                <select
                  value={this.state.user}
                  name="user"
                  className="form-control"
                  onChange={e => this.setState({ user: e.target.value })}
                  >
                  <option value="utilisateurs" disabled={true}>Utilisateurs</option>
                  {
                    !isEmpty(this.props.users) && this.props.users.datas.map((user, index) => {
                      return (
                        <option key={index} value={user.user_id}>{user.firstname} {user.lastname}</option>
                      )
                    })
                  }
                </select>
              </div>

              <div className="col-lg-3">
              <label className="small text-muted">Résultat</label><br />
                <button
                  className="btn btn-primary"
                  onClick={this.getCdr}
                >Chercher</button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <hr />
                <CDRRender cdr={this.state.cdr} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CDRRender = (props) => {
  const { cdr } = props;

  const columns = [
    { title: 'Destinataire', text: 'Destinataire', dataField: 'dst_display'},
    { title: 'Numéro', text: 'Numéro', dataField: 'dst_number'},
    { title: 'Durée', text: 'Durée', dataField: 'duration',
      formatter: (cell, rowData, rowIndex) => {
        let minutes = Math.floor(rowData.duration / 60);
        let secondes = Math.ceil(rowData.duration % 60);

        return <React.Fragment>{minutes} mn {secondes < 10 ? "0" + secondes : secondes} s</React.Fragment>
      }
    },
    { title: 'Durée de sonnerie', text: 'Durée de sonnerie', dataField: 'ring_duration',
      formatter: (cell, rowData, rowIndex) => {
        let minutes = Math.floor(rowData.duration / 60);
        let secondes = Math.ceil(rowData.duration % 60);

        return <React.Fragment>{minutes} mn {secondes < 10 ? "0" + secondes : secondes} s</React.Fragment>
      }
    },
    { title: 'Appelant', text: 'Appelant', dataField: 'src_display'},
    { title: 'Numéro', text: 'Numéro', dataField: 'src_number'},
    { title: 'Date et heure', text: 'Date et heure', dataField: 'start_timestamp',
      formatter: (cell, rowData, rowIndex) => {
        return (<React.Fragment>Le <Moment locale='fr' format="DD MMMM YYYY à HH:mm:ss">{rowData.start_timestamp || "-"}</Moment></React.Fragment>)
      }
    },
    { title: 'Transfert', text: 'Transfert', dataField: 'trf_display'},
    { title: 'N° Transfert', text: 'N° Transfert', dataField: 'trf_number'},
    { title: 'Uuid', text: 'Uuid', dataField: 'uuid'},
    { title: 'Id', text: 'Id', dataField: 'id'},
  ];

  if (isEmpty(cdr)) {
    return (<div className="col-lg-12 text-center"><hr /><h4 className="text-muted">Aucun enregistrement</h4></div>)
  }

  return <Table
    datas={cdr}
    keyField="uuid"
    columns={columns}
    searchPlaceholder={"Chercher un appel"}
    sizePerPage={25}
    CSVExport={"cdr.csv"}
  />
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession,
    users: state.users
  }
}

export default connect(mapStateToProps, null)(Cdr);
