import React, { useEffect, useState } from 'react';
import kertelLogo from '../../assets/images/logo-big.png';
import { connect } from 'react-redux';
import { getTokenWithCallbackAuth } from 'components/commons/api/auth.js';
import jwt_decode from 'jwt-decode';
import { createSession } from 'actions/authActions.js';
import { bindActionCreators } from 'redux';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';

function CallbackAuth({ createSession }) {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  function getParams(param) {
	  var vars = {};
	  window.location.href.replace( window.location.hash, '' ).replace( 
      /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
      function( m, key, value ) { // callback
          vars[key] = value !== undefined ? value : '';
      }
	);
    
	if (param) {
	  return vars[param] ? vars[param] : null;
	}
    
	return vars;
  }

  useEffect(() => {
    async function fetchData() {
      let params = getParams();
    
      if (isUndefined(params.token)) {
        setIsLoading(false);
        setError("Échec de l’authentification.")
        
        return;
      }
      
      if (params.token === "error") {
        if (!isUndefined(params.mail)) {
          setIsLoading(false);
          setError("Aucun compte n'est associé à ce login sso : " + params.mail + ".")
          
          return;
        }

        setIsLoading(false);
        setError("Échec de l’authentification.")
        
        return;
      }
          
      await getTokenWithCallbackAuth(params.token).then(async (result) => {
        if (result.status !== 200 || isEmpty(result.data.token_auth) ) {
          setIsLoading(false);
          setError("Échec de l’authentification.")
          
          return;
        }
    
        const decoded = jwt_decode(result.data.token_auth);
    
        if (isUndefined(decoded.lvl)) {
          setIsLoading(false);
          setError("Échec de l’authentification.")

          return;
        }
    
        await createSession({
          token_auth: result.data.token_auth,
          token_min: result.data.mini_token_auth,
          decoded: decoded,
          state: {
            company: result.data.company,
            password: result.data._p,
          },
          admin: decoded.lvl === "admin"
        });

        return;
      })
    }
	
    fetchData();

    navigate('/dashboard');
  }, [])
  
  if (isLoading) {
    return (
      <div className="loader loader-default is-active" />
    );
  }
  
  return (
    <div>
        <div id="leftHomeContainer">
            <div id="firstTitle">
                <h1>Votre bureau.<br />partout</h1>
            </div>
            <div id="secondTitle">
                <h2>KERTEL.<span>ONE</span></h2>
                <h3>La solution de communications unifiées de Kertel</h3>
            </div>
        </div>
        <div id="rightHomeContainer">
    <div id="homeBrand">
      <img src={kertelLogo} alt="Logo KERTEL" />
    </div>
    <div id="homeFormContainer">
      <div className="circleIcone"><div className="notfoundIcone" /></div>
      <h2>Oops!</h2>
      <div className="alert alert-warning">Une erreur technique est survenue</div>
      <p>{error}</p>
      <button className="btn btn-outline-secondary"
      onClick={() => navigate('/')}
      >
      Revenir à l'écran de connexion
      </button>
    </div>
      </div>
    </div>
  );    
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createSession,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(CallbackAuth);
