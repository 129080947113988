import React from 'react';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { fetchWaitingQueue, deleteWaitingQueue } from 'components/commons/helpers/admin/waitingQueueHelper.js';
import { startEditingWaitingQueue } from 'actions/admin/waitingQueueActions.js';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import isUndefined from 'lodash/isUndefined';
import { useNavigate } from 'react-router';

function WaitingQueueTab ({ startEditingWaitingQueue, usersList, isLoading, listWaitingQueue }) {

  const navigate = useNavigate();

  function format_number(numbers) {
    forEach(numbers, function(value, index, numbers) {
        var first = value.substring(0, 2);
            if (first === "+3") {
              numbers[index] = ("0" + value.slice(3, value.length));
            } else if (first === "33") {
              numbers[index] = ("0" + value.slice(2, value.length));
        }
      })

    if (typeof numbers === "object") {
      return numbers.join(', ');
    } else {
      return numbers;
    }
  }

  function find_user_element(id,element){
    var users = usersList.datas;
    if(users != null){
      for (var user in users) {
        if(users[user].user_id === id){
          if(users[user][element] != null){
            return users[user][element];
          } else {
            return null;
          }
        }
      }
    }
  }

    if (isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MaterialTable
        columns={[
          { title: 'Nom', field: 'name' },
          { title: 'Numéro', field: 'e164',
            render: rowData => (
               <div>
                {
                  (find_user_element(rowData.owner,'e164')!= null) ? format_number(find_user_element(rowData.owner,'e164')) : find_user_element(rowData.owner,'e164')
                }
              </div>
            )
          },
          { title: 'N° court', field: 'short',
            render: rowData => (
              <div>
                {
                  (find_user_element(rowData.owner,'s164')!= null) ? find_user_element(rowData.owner,'s164').join(', ') : ""
                }
              </div>
            )
          },
          { title: 'Max', field: 'max_size'},
          { title: 'Type', field: 'type',
            render: rowData => (
                <div>{ parseInt(rowData.func) === 1 ? 'Ring' : 'Parking' }</div>
            )
          },
        ]}
        data={filter(listWaitingQueue, item => !isUndefined(item.owner))}
        title=""
        localization={{
          toolbar: { searchPlaceholder: 'Rechercher' } ,
          pagination: {
            labelRowsPerPage: "Contacts par page",
            labelDisplayedRows: "{from}-{to} sur {count}",
            labelRowsSelect: "lignes",
          }
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Modifier',
            onClick: (event, rowData) => {
              startEditingWaitingQueue(rowData.id);
              fetchWaitingQueue(rowData.id);
              return navigate('/admin/editwaitingqueue');
            },
          },
          {
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
              deleteWaitingQueue(rowData.id);
            },
          }
        ]}
      />
    );
}

const mapStateToProps = (state) => {
  return ({usersList: state.users})
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingWaitingQueue
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingQueueTab);
