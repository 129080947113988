import React, { Component } from 'react';
import { hideModal } from 'actions/modalActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Dropzone from 'react-dropzone';
import { post } from 'axios';
import { notifyInfo } from 'components/commons/Toasts.js';

class MusicModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileToSend: [],
      isUploading: false,
      isLoading: true,
      url: '',
      params: {}
    }
  }

  componentDidMount() {
    let url = '';
    let params = {};

    switch (this.props.props.context) {
      case 'waiting':
        url = `${this.props.session.apiUrl}/waiting/music`;
        break;
      case 'away00':
        url = `${this.props.session.apiUrl}/away/message`;
        params['message_type'] = '00';
        break;
      case 'away01':
        url = `${this.props.session.apiUrl}/away/message`;
        params['message_type'] = '01';
        break;
      case 'announce':
        url = `${this.props.session.apiUrl}/mevo/announce`;
        break;

      default:
        url = '';
    }

    this.setState({ url, params });
  }

  render() {
    if (this.state.isUploading) {
      return (
        <div>
          <h2>Envoi du fichier...</h2>
          <div className="progress" style={{ height: "10px"}}>
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%"}}></div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <h2>Choix du fichier</h2>
        <h4>Types de fichiers acceptés : Wav et MP3 / Taille maximale : 1 Mo </h4>
        <Dropzone
          maxSize={1048576}
          accept="audio/*"
          onDrop={acceptedFiles => {
            if (!isEmpty(acceptedFiles) && acceptedFiles.length === 1) {

              return this.setState({ fileToSend: acceptedFiles });
            }

            return null;
          }}
        >
          {({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isFileTooLarge, acceptedFiles}) => {
            isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > 1048576;
            
            return (
              (
                <section>
                  
                  <div className={`dropzone ${isDragReject ? 'wrongFormat' : ''} ${isDragActive && !isDragReject ? 'rightFormat' : ''}`} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {(!isDragActive && acceptedFiles.length === 0) && 'Cliquez ou glissez le fichier'}
                    {(!isDragActive && acceptedFiles.length > 0) && 'Votre fichier est prêt à l\'envoi'}
                    {isDragActive && !isDragReject && "Vous pouvez relâcher"}
                    {isDragReject && "Type de fichier non accepté"}
                    {isFileTooLarge && (
                        <div className="text-danger mt-2">
                            Le fichier est trop lourd
                        </div>
                    )}
                    <ul className="list-group mt-2">
                        {acceptedFiles.length > 0 && acceptedFiles.map((acceptedFile, index) => (
                            <li key={index} className="list-group-item list-group-item-success">
                                {acceptedFile.name}
                            </li>
                        ))}
                    </ul>
                  </div>
                </section>
              )
            );
          }}
        </Dropzone>
        <div>
          <hr />
          <button className="btn btn-outline-secondary" onClick={this.props.hideModal}>Annuler</button>
          { this.props.props.context !== "mevoGroup" && (
              <button className="btn pull-right btn-success" disabled={isEmpty(this.state.fileToSend) || this.state.isUploading} onClick={async () => {
                try {
                  this.setState({ isUploading: true });
                  let formData = new FormData();
                  Object.entries(this.state.params).forEach(([key, value]) => {
                    formData.append(key, value);
                  });
                  formData.append('file', this.state.fileToSend[0]);
                  
                  const url = this.state.url;
                  const token = localStorage.getItem('_k');
                  const config = {
                    headers: {
                      'content-type': 'multipart/form-data',
                      'auth-token': `${token}`
                    }
                  };
                  
                  return await post(url, formData, config).then((result) => {
                    console.log(result);
                    this.props.props.callback();
                    notifyInfo('Musique sauvegardée avec succès !');
                    return this.props.hideModal();
                  });
                } catch (e) {
                  console.log(e);
                } finally {
                  this.setState({ isUploading: false });
                }
              }}>{this.state.isUploading ? 'Envoi...' : 'Enregistrer'}</button>
            )
          }
          { this.props.props.context === "mevoGroup" && (
              <button className="btn pull-right btn-success" disabled={isEmpty(this.state.fileToSend) || this.state.isUploading} onClick={async () => {
                try {
                  this.setState({ isUploading: true });
                  
                  this.props.props.file(this.state.fileToSend[0]);
                  
                  return this.props.hideModal();
                } catch (e) {
                  console.log(e);
                } finally {
                  this.setState({ isUploading: false });
                }
              }}>{this.state.isUploading ? 'Envoi...' : 'Télécharger'}</button>
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    session: state.kertelSession
  });
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicModal);
