import React, { useEffect, useState } from 'react';
import { getVoiceMails } from 'components/commons/helpers/voiceMailHelper';
import { getMessagesMevoGroup } from 'components/commons/helpers/messagesMevoGroupHelper';
import MailsTab from './MailsTab.js';
import ConfigMevo from './ConfigMevo.js';
import MessagesMevoGroup from './MessagesMevoGroup.js';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { useSelector } from 'react-redux';
import Preloader from 'components/commons/Preloader.js';
import isEmpty from 'lodash/isEmpty';

function VoiceMails () {

  const messagesMevoGroup = useSelector(state => state.messagesMevoGroup);
  const voiceMails = useSelector(state => state.voiceMail);

  const [isLoading, setIsLoading] = useState(false);

  async function init() {
    setIsLoading(true);
    await getVoiceMails();
    await getMessagesMevoGroup();
    setIsLoading(false);
  }

  useEffect(function() {
    init();
  }, []);

  if (isLoading) {
    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={10}><Preloader /></td>
          </tr>
        </tbody>
      </table>
    );
  }
  
  const minitoken = localStorage.getItem('_mt');
  let messages = [];
  let data = messagesMevoGroup.datas;
  Object.keys(data).forEach(function(key) {
    messages.push({"name": key, "messages": Object.keys(data[key]).length, "s164": data[key][0]["s164"],"members": Object.keys(data[key][0]["member"]).length});
  });
  
  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body p-b-0">
          <Breadcrumb title='Messagerie vocale' />
          
          <ul className="nav nav-tabs customtab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#company" role="tab">
                <span className="hidden-sm-up">
                  <i className="ti-home"></i>
                </span>
                <span className="hidden-xs-down">
                  Mes messages vocaux
                </span>
              </a>
            </li>
            {
              !isEmpty(data) &&
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Mes messages de groupe
                  </span>
                </a>
              </li>
            }
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#infos" role="tab">
                <span className="hidden-sm-up">
                  <i className="ti-home"></i>
                </span>
                <span className="hidden-xs-down">
                  Configuration
                </span>
              </a>
            </li>              
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="company" role="tabpanel">
              <MailsTab
                mails={voiceMails.datas}
                isLoading={voiceMails.isLoading}
                refreshData={() => init()}
                search={true}
                rowsPag={10}
                minitoken={minitoken}
              />
            </div>
            {
              !isEmpty(data) &&
              <div className="tab-pane" id="users" role="tabpanel">
                <MessagesMevoGroup
                  messages={messages}
                  mevoGroup={data}
                  isLoading={messagesMevoGroup.isLoading}
                />
              </div>
            }
            <div className="tab-pane" id="infos" role="tabpanel">
              <ConfigMevo
                mails={voiceMails.datas}
                isLoading={voiceMails.isLoading}
                refreshData={() => init()}
                search={true}
                rowsPag={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoiceMails;
