import { store } from 'store/index.js';
import {
  getProfilesCallsInformation,
  getProfileCallsInformation,
  deleteProfileCallsInformation,
  updateProfileCallsInformation,
  createProfileCallsInformation
} from 'components/commons/api/admin/profileCalls';
import {
  loadingProfileCalls,
  getProfilesCalls as getProfilesCallsAction,
  getProfileCalls as getProfileCallsAction,
  stopLoadingProfileCalls,
} from 'actions/admin/profileCallsActions';

export const fetchProfilesCalls = async (props) => {
  store.dispatch(loadingProfileCalls());
  try {
    await getProfilesCallsInformation().then((result) => {
      return store.dispatch(getProfilesCallsAction(Object.values(result.data)));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingProfileCalls());
  }
}

export const fetchProfileCalls = async (profileId) => {
  store.dispatch(loadingProfileCalls());
  try {
    await getProfileCallsInformation(profileId).then((result) => {
      var profile;
      Object.values(result.data).forEach(function(element) {
        if(element.id === profileId ){
          profile = element;
        }
      });
      return store.dispatch(getProfileCallsAction(profile));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingProfileCalls());
  }
}

export const createProfileCalls = async (informations) => {
  store.dispatch(loadingProfileCalls());
  try {
    await createProfileCallsInformation(informations).then((result) => {
      return fetchProfilesCalls();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingProfileCalls());
  }
}

export const updateProfileCalls = async (mevoId, informations) => {
  store.dispatch(loadingProfileCalls());
  try {
    await updateProfileCallsInformation(mevoId, informations).then((result) => {
      return fetchProfilesCalls();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingProfileCalls());
  }
}


export const deleteProfileCalls = async (profileId) => {
  store.dispatch(loadingProfileCalls());
  try {
    await deleteProfileCallsInformation(profileId).then((result) => {
      return fetchProfilesCalls();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingProfileCalls());
  }
}
