import { POST, PUT, DELETE } from './../api';

export const getUsersSupervisionInformation = (user_id) => {
  return POST('/supervision', {
    user_id: user_id
  });
}

export const addUsersSupervisionInformation = (user_id, informations) => {
  return PUT('/supervision', {
    user_id: user_id,
    users: informations
  });
}

export const deleteUsersSupervisionInformation = (user_id, informations) => {
  return DELETE('/supervision', {
    user_id: user_id,
    users: informations
  });
}