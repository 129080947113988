import React, { Component } from 'react';
import BillsTab from './BillsTab.js';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchBills } from 'components/commons/helpers/admin/billsHelper.js';

class Bills extends Component {

  componentDidMount() {
    fetchBills();
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={['Factures']} title='Factures' addBill={true} />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#users" role="tab" onClick={fetchBills}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Liste des factures
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="users" role="tabpanel">
                <BillsTab
                  bills={this.props.bills.datas}
                  isLoading={this.props.bills.isLoading}
                  url={this.props.url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bills: state.bills,
    url: state.kertelSession.apiUrl
  }
}

export default connect(mapStateToProps, null)(Bills);
