import { POST, GET, PUT, DELETE, PATCH } from './api';

export const toggleScheduler = (userId, bool) => {
  return PATCH('/user', {
    user_id: userId, // Obligatoire
    data: {
      scheduler: bool
    }
  });
}

export const getYearScheduler = (userId) => {
  return POST('/scheduler', {
    user_id: userId,
    view: 'year',
    year: '2019'
  });
}

export const getMonthScheduler = (userId, month, year) => {
  return POST('/scheduler', {
    user_id: userId,
    view: 'month',
    year: year,
    month: month
  });
}

export const addDayScheduler = (day) => {
  return PUT('/scheduler', day);
}

export const addWeekScheduler = (week) => {
  return PUT('/scheduler', week);
}


export const deleteDayScheduler = (day) => {
  return DELETE('/scheduler', day);
}

export const getSchedulerDays = (userId) => {
  return GET('/scheduler/set/days', {
    user_id: userId
  });
}

export const getSchedulerWeeks = (userId) => {
  return GET('/scheduler/set/weeks', {
    user_id: userId
  });
}

export const createSchedulerWeek = (week) => {
  return PUT('/scheduler/set/weeks', week);
}

export const updateSchedulerWeek = (week) => {
  return PATCH('/scheduler/set/weeks', week);
}

export const createSchedulerDay = (day) => {
  return PUT('/scheduler/set/days', day);
}

export const createDayRange = (day) => {
  return PATCH('/scheduler/set/days', day);
}

export const deleteSchedulerWeek = ({weekId, userId}) => {
  return DELETE('/scheduler/set/weeks', {
    week_id: weekId,
    user_id: userId
  });
}

export const deleteSchedulerDay = ({dayId, userId}) => {
  return DELETE('/scheduler/set/days', {
    day_id: dayId,
    user_id: userId
  });
}

export const deleteDayRange = (range) => {
  return PATCH('/scheduler/set/days', range);
}
