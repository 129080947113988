import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import MaterialTable from 'material-table';
import filter from 'lodash/filter';
import MevoConfig from './MevoConfig.js';
import forEach from 'lodash/forEach';

class Mevo extends Component {
  componentDidMount() {
    fetchUsers();
  }

  find_location(id){
    if(this.props.company.location != null) {
      for (var location in this.props.company.location) {
        if(this.props.company.location[location].id === id){
          return this.props.company.location[location].name;
        }
      }
    }
    return id;
  }

  format_number(numbers) {
    if (typeof numbers === "object") {
      forEach(numbers, function(value, index, numbers) {
        var first = value.substring(0, 2);
        if (first === "+3") {
          //.match(/.{1,2}/g).join(' ')
          numbers[index] = ("0" + value.slice(3, value.length));
        } else if (first === "33") {
          numbers[index] = ("0" + value.slice(2, value.length));
        }
      })
      return numbers.join(', ');
    } else {
      var first = numbers.substring(0, 2);
      if (first === "+3") {
        //.match(/.{1,2}/g).join(' ')
        numbers = ("0" + numbers.slice(3, numbers.length));
      } else if (first === "33") {
        numbers = ("0" + numbers.slice(2, numbers.length));
      }
      return numbers;
    }
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={['Configuration de la messagerie vocale']} title='Messagerie vocale'/>
              <ul className="nav nav-tabs customtab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#users" role="tab" onClick={fetchUsers}>
                    <span className="hidden-sm-up">
                      <i className="ti-home"></i>
                    </span>
                    <span className="hidden-xs-down">
                      Utilisateurs
                    </span>
                  </a>
                </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" id="users" role="tabpanel">
                <MaterialTable
                  columns={[
                    { title: 'Prénom', field: 'firstname', grouping: false},
                    { title: 'Nom', field: 'lastname', grouping: false},
                    { title: 'N° Long', field: 'e164', render: rowData => (
                          <div>{(rowData.e164 != null)?this.format_number(rowData.e164):""}</div>
                    )},
                    { title: 'N° Court', field: 's164', render: rowData => (
                        <div>{(rowData.s164 != null)?rowData.s164.join(', '):""}</div>
                    )},
                    { title: 'Mobile', field: 'mobile', render: rowData => (
                          <div>{(rowData.mobile != null)?this.format_number(rowData.mobile):""}</div>
                    )},
                    { title: 'Département', field: 'location_id', grouping: false,
                    render: rowData => (
                      <div>{(rowData.location_id != null)?this.find_location(rowData.location_id):"Pas de département"}</div>
                    )}
                  ]}
                  data={filter(this.props.users.datas, item => (item.user_type === "1" || item.user_type === "2" || item.user_type === "4") && (item.e164 != null || item.s164 != null || item.mobile != null))}
                  detailPanel={rowData => {
                    return (
                      <MevoConfig
                        userId={rowData.user_id}
                      />
                    )
                  }}
                  title=""
                  localization={{
                    toolbar: { searchPlaceholder: 'Rechercher' } ,
                    pagination: {
                      labelRowsPerPage: "Utilisateurs par page",
                      labelDisplayedRows: "{from}-{to} sur {count}"
                    }
                  }}
                  options={{
                    pageSize: 10,
                    detailPanelType: "single"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company
  }
}

export default connect(mapStateToProps, null)(Mevo);
