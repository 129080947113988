import React, { Component } from 'react';
import { notifySuccess, notifyWarning } from 'components/commons/Toasts.js';
import { hideModal } from 'actions/modalActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createDayRange } from 'components/commons/api/scheduler.js';
import isUndefined from 'lodash/isUndefined';

class SchedulerEditDayModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      getForwards: false,
      color: 'dddddd',
      label: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateColor = this.updateColor.bind(this);
  }

  componentDidMount() {
    this.setState({
      color: !isUndefined(this.props) ? this.props.color : 'dddddd',
      label: !isUndefined(this.props) ? this.props.label : ''
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      await this.setState({ isCreating: true });
      const range = {
        user_id: this.props.session.user_id,
        day_id: this.props.dayId,
        color: this.state.color,
        label: this.state.label,
        action: 'edit'
      };

      await createDayRange(range);
      this.props.callback();
      notifySuccess('Journée mise à jour avec succès !');
    } catch (e) {
      notifyWarning('Une erreur est survenue lors de la mise à jour de la journée.');
      console.log(e);
    } finally {
      this.setState({ isCreating: false, getForwards: false });
      return this.props.hideModal();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    return this.setState({ [name]: value });
  }

  updateColor(event) {
    event.preventDefault();
    this.setState({ color: event.target.name });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <div className='row'>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  className="form-control"
                  name="label"
                  type="text"
                  placeholder="Nom de la journée"
                  id="dayName"
                  value={this.state.label}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <div className="dropdown show">
                  <button className="btn btn-light dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Couleur de la journée
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <button className="dropdown-item createDayColorItem dayColorRender" name='81ecec' style={{ background: '#81ecec' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='74b9ff' style={{ background: '#74b9ff' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='a29bfe' style={{ background: '#a29bfe' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='0984e3' style={{ background: '#0984e3' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='fdcb6e' style={{ background: '#fdcb6e' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='ff7675' style={{ background: '#ff7675' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='e17055' style={{ background: '#e17055' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='e84393' style={{ background: '#e84393' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='b2bec3' style={{ background: '#b2bec3' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='f6e58d' style={{ background: '#f6e58d' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='ffbe76' style={{ background: '#ffbe76' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='ff7979' style={{ background: '#ff7979' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='badc58' style={{ background: '#badc58' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='dff9fb' style={{ background: '#dff9fb' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='f9ca24' style={{ background: '#f9ca24' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='f0932b' style={{ background: '#f0932b' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='eb4d4b' style={{ background: '#eb4d4b' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='6ab04c' style={{ background: '#6ab04c' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='7ed6df' style={{ background: '#7ed6df' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='e056fd' style={{ background: '#e056fd' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='686de0' style={{ background: '#686de0' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='30336b' style={{ background: '#30336b' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='22a6b3' style={{ background: '#22a6b3' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='be2edd' style={{ background: '#be2edd' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='4834d4' style={{ background: '#4834d4' }} onClick={this.updateColor}></button>
                    <button className="dropdown-item createDayColorItem dayColorRender" name='130f40' style={{ background: '#130f40' }} onClick={this.updateColor}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1">
              <div className='alert dayColorRender' style={{ background: `#${this.state.color}` }}></div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <input
            disabled={this.state.isCreating}
            type="submit"
            className="btn btn-success waves-effect text-left right-side-toggle"
            value={this.state.isCreating ? 'Mise à jour...' : 'Mettre à jour'}
            />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession.datas.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerEditDayModal);
