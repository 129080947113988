import React, { Component } from 'react';
import { getListIncomingFaxes, getListOutgoingFaxes } from 'components/commons/helpers/faxHelper';
import FaxListTab from './FaxListTab.js';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

class FaxList extends Component {
  componentDidMount() {
    getListIncomingFaxes();
    getListOutgoingFaxes();
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb title='Journal des fax' addFax={true} />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#incomingFax" role="tab" onClick={getListIncomingFaxes}>
                  <i className="mdi mdi-arrow-left-box text-success"></i>
                  <span className="hidden-xs-down">
                    Fax reçus <span className="badge badge-pill badge-light">{!isEmpty(this.props.fax.incomings.datas) ? this.props.fax.incomings.datas.length : 0}</span>
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#outgoingFax" role="tab" onClick={getListOutgoingFaxes}>
                <i className="mdi mdi-arrow-right-box text-info"></i>
                  <span className="hidden-xs-down">
                    Fax envoyés <span className="badge badge-pill badge-light">{!isEmpty(this.props.fax.outgoings.datas) ? this.props.fax.outgoings.datas.length : 0}</span>
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="incomingFax" role="tabpanel">
                <FaxListTab
                  faxs={this.props.fax.incomings.datas}
                  isLoading={this.props.fax.incomings.isLoading}
                  webFax={false}
                  type={"incomings"}
                  selection={false}
                  search={true}
                  rowsPag={10}
                />
              </div>
              <div className="tab-pane" id="outgoingFax" role="tabpanel">
                <FaxListTab
                  faxs={this.props.fax.outgoings.datas}
                  isLoading={this.props.fax.outgoings.isLoading}
                  webFax={false}
                  type={"outgoings"}
                  selection={false}
                  search={true}
                  rowsPag={10}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fax: state.fax
  };
}

export default connect(mapStateToProps, null)(FaxList);
