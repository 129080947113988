import { store } from 'store/index.js';
import { getIncomingCalls, getOutgoingCalls, setCallsAsSeen, deleteIncomingCalls, deleteOutgoingCalls } from 'components/commons/api/phone.js';
import {
  loadingCallLog,
  stopLoadingCallLog,
  fetchIncomingCalls,
  fetchOutgoingCalls,
  fetchCallLog,
  fetchUnseenCalls,
  ringback,
  hasReadUnseenCalls,
  addSelectedContact} from 'actions/phoneActions.js';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { notifyWarning } from 'components/commons/Toasts.js';
import { fetchCompanyContacts, fetchUserContacts } from 'components/commons/helpers/contactHelper.js';

export const clickCall = async (number, displayName = '') => {

  const contactSearch = await getContactAccordingToNumber(number);

  if (!isEmpty(contactSearch)) {
    displayName = isUndefined(contactSearch.firstname) && isUndefined(contactSearch.lastname) ? number : `${contactSearch.firstname || ""} ${contactSearch.lastname || ""}`
  }

  const numberWithoutBlank = number.replace(/\s/g, '');
  navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(function(result) {
    store.dispatch(ringback(numberWithoutBlank, displayName))
    store.dispatch(addSelectedContact(contactSearch))
    //store.getState().kertelphone.webPhone.call("sip:" + numberWithoutBlank + "@" + store.getState().kertelSession.user.servers.sip.domain);
  }).catch(function(err) {
    console.log(err);
    notifyWarning("Votre micro n'est pas connecté");
  });
}

export const getCallLog = async (props) => {
  store.dispatch(loadingCallLog());

  try {
    let incomings = await getIncomingCalls();
    let outgoings = await getOutgoingCalls();

    incomings = incomings.data;
    outgoings = outgoings.data;
    
    var calls = (incomings).concat(outgoings);
    calls.sort(compare);

    store.dispatch(fetchIncomingCalls(incomings));
    store.dispatch(fetchOutgoingCalls(outgoings));
    
    const unseen  = (calls).filter(call => call.type === "incoming" && call.seen === false);
    store.dispatch(fetchUnseenCalls(unseen));
    
    return store.dispatch(fetchCallLog(calls));
  } catch (error) {
    console.log(error);
  } finally {
    return store.dispatch(stopLoadingCallLog());
  }
};

export const setIncomingCallsAsSeen = async () => {
  store.dispatch(loadingCallLog());
  
  try {
    await setCallsAsSeen().then((result) => {
      return getCallLog();
    })
    
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingCallLog());
    store.dispatch(hasReadUnseenCalls());
  }
}

export const deleteCalls = async (callIds) => {
  store.dispatch(loadingCallLog());
  
  try {
    if (!isEmpty(callIds.incoming)) {
      await deleteIncomingCalls(callIds.incoming).then((result) => {
        return;
      })
    }
    
    if (!isEmpty(callIds.outgoing)) {
      await deleteOutgoingCalls(callIds.outgoing).then((result) => {
        return;
      })
    }
    
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingCallLog());
    return getCallLog();
  }
}

function compare(a, b) {
  const dateA = a.date;
  const dateB = b.date;

  let comparison = 0;
  if (dateA > dateB) {
    comparison = -1;
  } else if (dateA < dateB) {
    comparison = 1;
  }
  return comparison;
}

export const getContactAccordingToNumber = async (number) => {
  if (isEmpty(store.getState().contacts.company.datas) || isEmpty(store.getState().contacts.user.datas)) {
    await fetchCompanyContacts();
    await fetchUserContacts();
  }

  const userContact = store.getState().contacts.user.datas.filter(contact => contact.telephone === number || contact.mobile === number);
  const companyContact = store.getState().contacts.company.datas.filter(contact => contact.telephone === number || contact.mobile === number);
  
  if (!isEmpty(userContact)) {
    return userContact[0];
  }

  if (!isEmpty(companyContact)) {
    return companyContact[0];
  }

  return { displayName: '', number: '' };
}
