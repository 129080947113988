
export const loadingInterceptions = () => {
  return { type: 'LOADING_INTERCEPTIONS' }
};

export const getInterceptions = (informations) => {
  return {
    type: 'GET_INTERCEPTIONS',
    payload: informations
  }
};

export const stopLoadingInterceptions = () => {
  return { type: 'STOP_LOADING_INTERCEPTIONS' }
};

export const loadingInterception = () => {
  return { type: 'LOADING_INTERCEPTION' }
};

export const getInterception = (informations) => {
  return {
    type: 'GET_INTERCEPTION',
    payload: informations
  }
};

export const stopLoadingInterception = () => {
  return { type: 'STOP_LOADING_INTERCEPTION' }
};

export const startEditingInterception = (interceptionId) => {
  return {
    type: 'START_EDITING_INTERCEPTION',
    payload: interceptionId
  }
};

export const stopEditingInterception = () => {
  return { type: 'STOP_EDITING_INTERCEPTION' }
};
