import React, { Component } from 'react';
import CompanyTab from './CompanyTab.js';
import ColleaguesTab from './ColleaguesTab.js';
import UserTab from './UserTab.js';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchCompanyContacts, fetchUserContacts } from 'components/commons/helpers/contactHelper.js';
import { clickCall } from 'components/commons/helpers/phoneHelper';
import { isEmpty } from 'lodash';

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberModal: false,
      telephone: '',
      mobile: '',
      displayName: ''
    }
  }
  
  componentDidMount() {
    fetchCompanyContacts();
  }

  render() {

    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb title='Annuaire' add={true} />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#colleagues" role="tab" onClick={fetchCompanyContacts}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Mes collègues
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#user" role="tab" onClick={fetchUserContacts}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Mon carnet d'adresse
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#company" role="tab" onClick={fetchUserContacts}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Contacts partagés
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" id="colleagues" role="tabpanel">
                <ColleaguesTab
                  contacts={this.props.contacts.company.datas}
                  isLoading={this.props.contacts.company.isLoading}
                  fetchCompanyContacts={fetchCompanyContacts}
                  handleNumberModal={(bool, telephone, mobile, displayName) => this.setState({ numberModal: bool, telephone: telephone, mobile: mobile, displayName: displayName })}
                />
              </div>
              <div className="tab-pane p-20" id="user" role="tabpanel">
                <UserTab
                  contacts={this.props.contacts.user.datas}
                  isLoading={this.props.contacts.user.isLoading}
                  fetchUserContacts={fetchUserContacts}
                  handleNumberModal={(bool, telephone, mobile, displayName) => this.setState({ numberModal: bool, telephone: telephone, mobile: mobile, displayName: displayName })}
                />
              </div>
              <div className="tab-pane p-20" id="company" role="tabpanel">
                <CompanyTab
                  contacts={this.props.contacts.company.datas}
                  isLoading={this.props.contacts.company.isLoading}
                  fetchCompanyContacts={fetchCompanyContacts}
                  handleNumberModal={(bool, telephone, mobile, displayName) => this.setState({ numberModal: bool, telephone: telephone, mobile: mobile, displayName: displayName })}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.numberModal  && <div
          style={{
            position:'fixed',
            top: '0',
            left:'0',
            width:'100vw',
            height:'100vh',
            background: 'rgba(0,0,0,0.5)',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            zIndex: 10000,
          }}
        >
          <div style={{
          zIndex: 10001,
          width: "40%",
          height: "300px",
          background: "#FFF",
          margin: "auto",
          color: "#000000",
          border: "1px solid #CCC",
          borderRadius: '4px',
          boxShadow: "0px 0px 6px #535353",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}><SelectNumber
          telephone={this.state.telephone}
          mobile={this.state.mobile}
          displayName={this.state.displayName}
          clickCall={clickCall}
          closeWindow={() => this.setState({
            telephone: '',
            mobile: '',
            numberModal: false,
            displayName: ''
          })} /></div>
        </div>}
      </div>
    );
  }
}

const SelectNumber = (props) => {
  return (
    <div className='text-center'>
      <h3>Cliquez sur le numéro à appeler</h3>
      <div>
        {!isEmpty(props.telephone) && <li className='btn btn-link' onClick={() => props.clickCall(props.telephone, props.displayName)}>{props.telephone}</li>}
      </div>
      <div>
        {!isEmpty(props.mobile) && <li className='btn btn-link' onClick={() => props.clickCall(props.mobile, props.displayName)}>{props.mobile}</li>}
      </div>
      <hr />
      <div className="text-center">
        <button onClick={() => { return props.closeWindow() }} className='btn btn-primary'>Fermer</button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts
  }
}

export default connect(mapStateToProps, null)(Company);
