import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull'
import { connect } from 'react-redux';

class ForwardLayout extends Component {
  render() {
    if (this.props.isLoadingForwards) {
      return (
        <div className="card" style={{ minHeight: '436px' }}>
          <div className="card-body p-b-2">
            <h4 className="card-title m-b-2">Renvois d'appels</h4>
            <div className='row'>
              <div className='col-lg-12 text-center'>
              <Preloader color="primary" />
              </div>
            </div> 
          </div>  
        </div>
      );
    }

    if (isEmpty(this.props.forwards)) {
      return (
        <div className="card" style={{ minHeight: '436px' }}>
          <div className="card-body p-b-2">
            <h4 className="card-title m-b-2">Renvois d'appels</h4>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <h5>Aucun renvoi d'appels</h5>
              </div>
            </div> 
          </div>  
        </div>  
      )
    }

    let usrForwards   = this.props.forwards.usr.forwards[0];
    let busyForwards  = this.props.forwards.busy.forwards[0];
    let naForwards    = this.props.forwards.na.forwards[0];    

    const { type: usrFwrdType, data: usrFwrdData } = usrForwards.dests[0].entity;
    const { type: busyFwrdType, data: busyFwrdData } = busyForwards.dests[0].entity;
    const { type: naFwrdType, data: naFwrdData } = naForwards.dests[0].entity;

    return (
      <div className="card" style={{ minHeight: '436px' }}>
        <div className="card-body p-b-2">
          <h4 className="card-title m-b-2">Renvois d'appels</h4>
          <form onSubmit={this.handleSubmit} id="">
            <div className='row'>
              <div className='col-lg-12'>
                Pour tous les appels entrants, renvoyer vers
              </div>
            </div>  
            <div className='row'>
              <div className='col-lg-5'>
                <select value={usrFwrdType} onChange={(event) => this.props.handleLeftSelect(event, 'usr')} className="custom-select col-12">
                  <option value="PHONE">Mon poste</option>
                  {!isNull(this.props.session.user_mobile) && <option value="USER_MOBILE">Mon mobile</option>}
                  <option value="E164">Numéro</option>
                  <option value="MEVO">Ma messagerie vocale</option>
                  <option value="USER_DIRECTORY">Annuaire</option>
                  <option value="MESSAGE">Annonce</option>
                  <option value="CONFIG">Configuration avancée</option>
                  <option value="NONE">Rejeter l'appel</option>
                </select>
              </div>
              <div className={`col-lg-${this.props.isLoadingAllUsr ? "5" : "7"}`}>
                {(this.props.usrSecondSelect && !this.props.usrSecondInput) &&
                  <SecondSelect
                    context='usr'
                    users={this.props.users}
                    usrValue={this.props.usrValue}
                    complexValue={this.props.usrComplexValue}
                    userValue={this.props.userValue}
                    handleRightInput={this.props.handleRightInput}
                    announceValue={this.props.usrAnnounceValue}
                    isLoadingAll={this.props.isLoadingAll}
                    complexDatas={this.props.complexDatas}
                  />
                }
                {((this.props.usrSecondInput && !this.props.usrSecondSelect) || usrFwrdType === "E164") &&
                  <input
                    type="tel"
                    autoFocus={true}
                    className="form-control"
                    placeholder="Numéro de téléphone"
                    value={`${usrFwrdData}`}
                    onChange={(e) => this.props.handleRightInput(e, 'usr', 'number')} />
                }
              </div>
              <div className='col-lg-2'>
                {this.props.isLoadingAllUsr && <div className="col-1" style={loaderStyles}>
                    <i className="fa fa-2x fa-circle-o-notch fa-spin text-primary"></i>
                  </div>}
              </div>
            </div>
            
            <div className='row'>
              <div className='col-lg-12'>
                <hr />
                En cas d'occupation, renvoyer vers
              </div>
            </div>  
            <div className='row'>
              <div className='col-lg-5'>
                <select value={busyFwrdType} onChange={(event) => this.props.handleLeftSelect(event, 'busy')} className="custom-select col-12">
                  <option value="PHONE">Mon poste</option>
                  {!isNull(this.props.session.user_mobile) && <option value="USER_MOBILE">Mon mobile</option>}
                  <option value="E164">Numéro</option>
                  <option value="MEVO">Ma messagerie vocale</option>
                  <option value="USER_DIRECTORY">Annuaire</option>
                  <option value="MESSAGE">Annonce</option>
                  <option value="NONE">Rejeter l'appel</option>
                </select>
              </div>
              <div className='col-lg-7'>
                {(this.props.busySecondSelect && !this.props.busySecondInput) &&
                <SecondSelect
                  context='busy'
                  users={this.props.users}
                  busyValue={this.props.busyValue}
                  complexValue={this.props.busyComplexValue}
                  userValue={this.props.userValue}
                  handleRightInput={this.props.handleRightInput}
                  announceValue={this.props.busyAnnounceValue}
                  isLoadingAll={this.props.isLoadingAll}
                  complexDatas={this.props.complexDatas}
                />
                }
                {((this.props.busySecondInput && !this.props.busySecondSelect) || busyFwrdType === "E164") &&
                  <input
                    type="tel"
                    autoFocus={true}
                    className="form-control"
                    placeholder="Numéro de téléphone"
                    value={`${busyFwrdData}`}
                    onChange={(e) => this.props.handleRightInput(e, 'busy', 'number')} />
                }
              </div>
              <div className='col-lg-2'>
                {this.props.isLoadingAllBusy && <div className="col-1" style={loaderStyles}>
                  <i className="fa fa-2x fa-circle-o-notch fa-spin text-primary"></i>
                </div>}
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-12'>
                <hr />
                En cas de non-réponse, renvoyer vers
              </div>
            </div>  
            <div className='row'>
              <div className='col-lg-5'>
                <select value={naFwrdType} onChange={(event) => this.props.handleLeftSelect(event, 'na')} className="custom-select col-12">
                  <option value="PHONE">Mon poste</option>
                  {!isNull(this.props.session.user_mobile) && <option value="USER_MOBILE">Mon mobile</option>}
                  <option value="E164">Numéro</option>
                  <option value="MEVO">Ma messagerie vocale</option>
                  <option value="USER_DIRECTORY">Annuaire</option>
                  <option value="MESSAGE">Annonce</option>
                  <option value="NONE">Ne rien faire</option>
                </select>
              </div>
              <div className='col-lg-7'>
                {(this.props.naSecondSelect && !this.props.naSecondInput) &&
                  <SecondSelect
                  context='na'
                  users={this.props.users}
                  naValue={this.props.naValue}
                  userValue={this.props.userValue}
                  complexValue={this.props.naComplexValue}
                  handleRightInput={this.props.handleRightInput}
                  announceValue={this.props.naAnnounceValue}
                  isLoadingAll={this.props.isLoadingAll}
                  complexDatas={this.props.complexDatas}
                />
                }
                {((this.props.naSecondInput && !this.props.naSecondSelect) || naFwrdType === "E164") &&
                  <input
                    type="tel"
                    autoFocus={true}
                    className="form-control"
                    placeholder="Numéro de téléphone"
                    value={`${naFwrdData}`}
                    onChange={(e) => this.props.handleRightInput(e, 'na', 'number')} />
                }
              </div>
              <div className='col-lg-2'>
                {this.props.isLoadingAllNa && <div className="col-1" style={loaderStyles}>
                  <i className="fa fa-2x fa-circle-o-notch fa-spin text-primary"></i>
                </div>}
              </div>
              <p>&nbsp;</p>
            </div>

            {((this.props.naSecondInput || this.props.naSecondSelect) || naFwrdType !== "NONE") && <div className="row">
              <div className="col-2 text-center my-auto">
                après
              </div>
              <div className="col-2">
                <input
                  min={5}
                  max={59}
                  step={5}
                  type="number"
                  className="form-control"
                  value={naForwards.dests[0].timeout}
                  onChange={this.props.handleSecondsDelayInput}
                />
              </div>
              <div className="col-4 my-auto">
                secondes
              </div>
            </div>}

            <div className='row'>
              <div className='col-lg-12 text-right'>
                <button
                  disabled={this.props.isSaving}
                  type="submit"
                  className="btn btn-success btn-sm"
                  onClick={(e) => { e.preventDefault(); return this.props.saveForward()}}
                >{this.props.isSaving ? 'Enregistrement...' : 'Enregistrer'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const SecondSelect = (props) => {
  if (props.isLoadingAll) {
    return (<select disabled={true} className="custom-select col-12">
      <option>Chargement...</option>
    </select>
    );
  }

  if ([props.usrValue, props.busyValue, props.naValue].includes("USER_DIRECTORY")) {
    return (
      <select value={props.incomingUserValue} onChange={(e) => props.handleRightInput(e, props.context, 'user')} className="custom-select col-12">
        <option disabled={true}>Destinataire</option>
        {!isEmpty(props.users.callCenter) && <optgroup label="Centre d'appels">
          {
            props.users.callCenter.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.commonRings) && <optgroup label='Sonneries générales'>
          {
            props.users.commonRings.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.usersList) && <optgroup label='Utilisateurs'>
          {
            props.users.usersList.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
        {!isEmpty(props.users.waitingQueues) && <optgroup label="Files d'attente">
          {
            props.users.waitingQueues.map((data, index) => {
              return (
                <option value={data.user_id} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
              );
            })
          }
        </optgroup>}
      </select>
    );
  }

  if ([props.usrValue, props.busyValue, props.naValue].includes("CONFIG")) {
        
    return (
      <select value={props.complexValue} onChange={(e) => props.handleRightInput(e, props.context, 'config')} className="custom-select col-12">
        {
          props.complexDatas.map((data, index) => {
            return (
              <option value={data.id} key={index}>{data.name}</option>
            );
          })
        }
      </select>
    )
  }

  return (
    <select value={props.announceValue} onChange={(e) => props.handleRightInput(e, props.context, 'message')} className="custom-select col-12">
      <option value="0">Message d'absence</option>
      <option value="1">Message de non-disponibilité</option>
    </select>
  );
}

const loaderStyles = {
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  display: "flex"
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession.datas.data
  }
}

export default connect(mapStateToProps, null)(ForwardLayout);
