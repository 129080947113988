const initialState = {
  isLoading: false,
  datas: [],
  unseen: { datas : [] }
}

const voiceMailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'LOADING_VOICE_MAILS':
      return { ...state, isLoading: true };

    case 'STOP_LOADING_VOICE_MAILS':
      return { ...state, isLoading: false };

    case 'FETCH_VOICE_MAILS':
      return { ...state, datas: action.payload };

    case 'FETCH_UNSEEN_VOICE_MAILS':
      return { ...state, unseen: { datas: action.payload} };

    case 'HAS_READ_UNSEEN_VOICE_MAILS':
      return { ...state, unseen: { datas: []} };

    default:
      return state
  }
}

export default voiceMailReducer;
