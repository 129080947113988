import React, { Component } from "react";
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import isEmpty from 'lodash/isEmpty';

class EditWaitingQueueInfos extends Component {
  
  render() {
	return (
	  <Container component="main">
		<Grid container spacing={2}>
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend">Nom</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="name"
			  fullWidth
			  value={this.props.options.name}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={6} />
		  
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend">Type de file d'attente</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="func"
			  disabled
			  value={this.props.options.func === 1 ? "Ring" : "Parking"}
			/>
		  </Grid>
		  <Grid item xs={12} sm={6} />
		  
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend">N° long</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="e164"
			  disabled
			  value={!isEmpty(this.props.options.e164) ? this.props.options.e164.match(/.{1,2}/g).join(' ') : ""}
			/>
		  </Grid>
          <Grid item xs={12} sm={6} />
		  
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend">N° court</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="s164"
			  disabled
			  value={this.props.options.s164}
			/>
		  </Grid>
          <Grid item xs={12} sm={6} />
		  
		  <Grid item xs={12} sm={2}>
			<FormLabel component="legend">Nombre max d'appel</FormLabel>
		  </Grid>
		  <Grid item xs={12} sm={1}>
			<TextField
			  name="max_size"
			  fullWidth
			  type="number"
			  value={this.props.options.max_size}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={9} />
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(EditWaitingQueueInfos);
