import React, { Component } from 'react';
import { connect } from 'react-redux';
import StatsCallsOptions from './StatsCallsOptions.js';
import StatsCallsUsers from './StatsCallsUsers.js';
import StatsCallsGraph from './StatsCallsGraph.js';
import StatsCallsCsv from './StatsCallsCsv.js';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { createMuiTheme , withStyles } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import { bindActionCreators } from 'redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

const useStyles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

function getSteps() {
  return ['Options', 'Utilisateurs'];
}

function checkOptions(fields) {
  let error = {
	status: false,
	detail: '',
  };
  
  if (fields.activeStep === 0) {
	
	const day = new Date();
	const minDate = day.setDate(day.getDate() - 62);
	
	if (isNull(fields.startDate)) {
	  error.status = true;
	  error.detail = "La date de début ne peut pas être vide";
	}
	else if (isNull(fields.endDate)) {
	  error.status = true;
	  error.detail = "La date de fin ne peut pas être vide";
	}
	else if (fields.typeCalls !== 'IN' && fields.typeCalls !== 'OUT') {
	  error.status = true;
	  error.detail = "Le type d'appels ne peut pas être vide";
	}
	else if (fields.startDate === 'Invalid Date') {
	  error.status = true;
	  error.detail = "La date de début est invalide";
	}
	else if (fields.endDate === 'Invalid Date') {
	  error.status = true;
	  error.detail = "La date de fin est invalide";
	}
	else if (Math.floor(new Date(minDate).getTime() - new Date(fields.startDate).getTime()) > 0) {
	  error.status = true;
	  error.detail = "La date de début ne devrait pas être avant 62 jours de la date actuelle";
	}
	else if (Math.floor(new Date(minDate).getTime() - new Date(fields.endDate).getTime()) > 0) {
	  error.status = true;
	  error.detail = "La date de fin ne devrait pas être avant 62 jours de la date actuelle";
	}
	else if (Math.floor(new Date().getTime() - new Date(fields.startDate).getTime()) < 0) {
	  error.status = true;
	  error.detail = "La date de début ne peut pas être supérieur à la date actuelle";
	}
	else if (Math.floor(new Date().getTime() - new Date(fields.endDate).getTime()) < 0) {
	  error.status = true;
	  error.detail = "La date de fin ne peut pas être supérieur à la date actuelle";
	}
	else if (Math.floor(new Date(fields.endDate).getTime() - new Date(fields.startDate).getTime()) < 0) {
	  error.status = true;
	  error.detail = "La date de début ne peut pas être supérieur à la date de fin";
	}
  }
  
  if (fields.activeStep === 1) {
	if (isNull(fields.users)) {
	  error.status = true;
	  error.detail = "Aucun utilisateur est sélectionné";
	}
	else if (isEmpty(fields.users)) {
	  error.status = true;
	  error.detail = "Aucun utilisateur est sélectionné";
	}
  }
  
  return error;
}

class StatsCalls extends Component {
  
  constructor(props) {
    super(props);
	
    this.state = {
	  activeStep: 0,
      users: [],
	  numbers: [{ name: "" }],
	  typeCalls : '',
	  startDate: null,
	  endDate: null,
	  internalCalls: false,
	  grain: '1440',
	  rendering: 'graph',
    };
	
	this.theme = createMuiTheme({
	  palette: {
		  primary: {
			  main: green[600],
		  },
		  secondary: {
			  main: green[200],
		  },
	  },
    });
	
	this.handleOptions = this.handleOptions.bind(this);
	this.handleOptionsDate = this.handleOptionsDate.bind(this);
	this.handleNumberNameChange = this.handleNumberNameChange.bind(this);
	this.handleAddNumber = this.handleAddNumber.bind(this);
	this.handleRemoveNumber = this.handleRemoveNumber.bind(this);
	this.handleOptionsUsers = this.handleOptionsUsers.bind(this);
  }
  
  static propTypes = {
    classes: PropTypes.object,
  };
  
  handleOptions = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    this.setState({ [name]: value });
  }
  
  handleOptionsDate = (date, name) => {
    this.setState({ [name]: date})
  }
  
  handleOptionsUsers = (newUsers) => {
    this.setState({ users: newUsers });
  };
  
  handleNumberNameChange = idx => evt => {
    const newNumbers = this.state.numbers.map((number, sidx) => {
      if (idx !== sidx) return number;
      return { name: evt.target.value };
    });
	
    this.setState({ numbers: newNumbers });
  };

  handleAddNumber = () => {
    this.setState({
      numbers: this.state.numbers.concat([{ name: "" }])
    });
  };

  handleRemoveNumber = idx => () => {
    this.setState({
      numbers: this.state.numbers.filter((s, sidx) => idx !== sidx)
    });
  };
  
  handleNext = () => {
    const { activeStep } = this.state;
    const { status, detail } = checkOptions(this.state);
	
	if (status === true) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: detail,
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	
    this.setState({
      activeStep: activeStep + 1,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      //users: [], //@TODO
	  numbers: [{ name: "" }],
	  typeCalls : '',
	  startDate: null,
	  endDate: null,
	  internalCalls: false,
	  grain: '1440',
	  rendering: 'graph',
    });
  };
  
  getStepContent = (step) => {
	switch (step) {
	  case 0:
		return 	<StatsCallsOptions
				  options={this.state}
				  handleOptions={this.handleOptions.bind(this)}
				  handleOptionsDate={this.handleOptionsDate.bind(this)}
				  handleNumberNameChange={this.handleNumberNameChange.bind(this)}
				  handleAddNumber={this.handleAddNumber.bind(this)}
				  handleRemoveNumber={this.handleRemoveNumber.bind(this)}
				  usersSelected={this.usersSelected}
				/>;
	  case 1:
		return	<div className="col-lg-12">
				  <div className="card">
					<div className="card-body p-b-0">
					  <div className="tab-content">
						<div className="tab-pane active" id="company" role="tabpanel">
						  <StatsCallsUsers
							options={this.state}
							users={this.props.users.datas}
							isLoading={this.props.users.isLoading}
							handleOptionsUsers={this.handleOptionsUsers.bind(this)}
							ref={this.childUsers}
						  />
						</div>
					  </div>
					</div>
				  </div>
				</div>;
	  default:
		return 'Unknown step';
	}
  }
  
  getRendering = () => {
	switch (this.state.rendering) {
	  case 'csv':
		return <StatsCallsCsv options={this.state} users={this.props.users.datas} />;
	  case 'graph':
	  default:
		return <StatsCallsGraph options={this.state} users={this.props.users.datas}/>;
	}
  }
  
  async componentDidMount() {
    fetchUsers();
  }
  
  render() {
	
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
	
	return (
	  <div className="col-lg-12">
		<div className="card">
		  <div className="card-body p-b-0">
			<Breadcrumb routes={['Statistique']} title='Statistiques' getUserStats={false} />
			<div className="tab-content">
			  <div className={classes.root}>
				<Stepper activeStep={activeStep} orientation="vertical">
				  {steps.map((label, index) => (
					<Step key={label}>
					  <StepLabel>{label}</StepLabel>
					  <StepContent>
						<Typography component={'span'}>{this.getStepContent(index)}</Typography>
						<MuiThemeProvider theme={this.theme}>
						  <div style={{ float: 'right'}}>
							<Button
							  variant="outlined"
							  disabled={activeStep === 0}
							  onClick={this.handleBack}
							  className={classes.button}
							  style={{marginRight: '20px'}}
							>
							  Précédent
							</Button>
							<Button
							  variant="contained"
							  color="primary"
							  onClick={this.handleNext}
							  className={classes.button}
							>
							  {activeStep === steps.length - 1 ? 'Lancer' : 'Suivant'}
							</Button>
						  </div>
						</MuiThemeProvider>
					  </StepContent>
					</Step>
				  ))}
				</Stepper>
				{activeStep === steps.length && (
				  <Paper square elevation={0} className={classes.resetContainer}>
					{this.getRendering()}
					<div style={{ float: 'right'}}>
					  <Button
						variant="outlined"
						disabled={activeStep === 0}
						onClick={this.handleBack}
						className={classes.button}
						style={{marginRight: '20px'}}
					  >
						Précédent
					  </Button>
					  <Button
						variant="contained"
						onClick={this.handleReset}
						className={classes.button}
					  >
						Annuler
					  </Button>
					</div>
				  </Paper>
				)}
			  </div>
			  <div className="card"></div>
			</div>
		  </div>
		</div>
	  </div>
	);
  }
}

const mapStateToProps = (state) => {
  return ({
	users: state.users,
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(StatsCalls));