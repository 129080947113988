import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumbersLong from './NumbersLong.js';
import NumbersShort from './NumbersShort.js';
import NumbersFax from './NumbersFax.js';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { getCompany } from 'components/commons/helpers/admin/companyHelper.js';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { updateUser } from 'components/commons/api/admin/user';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

class Number extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      numbers: {
        "long": [],
        "short": [],
        "fax": [],
      },
    }
    
    this.removeNumberUser = this.removeNumberUser.bind(this);
  }
  
  static numberFormer(number) {
    var newnumber = number;
    var first = number.substring(0,2);
    if (first === "+3") {
      newnumber = "0" + number.slice(3, number.length);
    } else if(first === "33") {
      newnumber = "0" + number.slice(2, number.length);
    }
    
    return newnumber.match(/.{1,2}/g).join(' ');
  }
  
  static findUserName(id, users) {
    if (users != null) {
      for (var user in users) {
        if(users[user].user_id === id){
          return ((users[user].firstname != null )? users[user].firstname : "")+" "+(users[user].lastname != null ? users[user].lastname : "");
        }
      }
    }
  }

  static findUserType(id, users) {
    if (users != null) {
      for (var user in users) {
        if(users[user].user_id === id){
          return users[user].user_type;
        }
      }
    }
  }
  
  async componentDidMount() {
    await getCompany();
    await fetchUsers();
  }

  getNumbers (type) {
    let numbers = [];
    let list = [];
    
    if (type === "long") {
      list = this.props.company.long;
    } else if (type === "short") {
      list = this.props.company.short;
    } else if (type === "fax") {
      list = this.props.company.fax;
    }
    
    var users = this.props.users.datas;
    
	if (!isUndefined(list)) {
	  Object.keys(list).map(function(number) {
		numbers.push({
		  "number": type === "short" ? number : Number.numberFormer(number),
		  "user_id": list[number],
		  "user_type":  Number.findUserType(list[number], users),
		  "user": Number.findUserName(list[number], users),
		  "type": Number.findUserType(list[number], users),
		});
		return true;
	  })
	}
    
    return numbers;
  }
  
  async removeNumberUser(type, idUser, number) {
    try {
      // Delete the e164 number of the user
      if (!isEmpty(idUser)) {
        let info = {};
        let index = -1;
		switch (type) {
		  case 'long' :
            var user_e164 = [];
            forEach(this.props.users.datas, function(elements) {
              user_e164[elements.user_id] = elements.e164;
            })
            info.e164 = user_e164[idUser];
            index = info.e164.indexOf(number);
            if (index > -1) info.e164.splice(index, 1);
			break;
		  case 'short' :
            var user_s164 = [];
            forEach(this.props.users.datas, function(elements) {
              user_s164[elements.user_id] = elements.s164;
            })
            info.s164 = user_s164[idUser];
            index = info.s164.indexOf(number);
            if (index > -1) info.s164.splice(index, 1);
			break;
		  case 'fax' :
            var user_fax = [];
            forEach(this.props.users.datas, function(elements) {
              user_fax[elements.user_id] = elements.fax;
            })
            info.fax = user_fax[idUser];
            index = info.fax.indexOf(number);
            if (index > -1) info.fax.splice(index, 1);
			break;
          default:
        }
        
        await updateUser(idUser, info);
      }
    } catch (e) {
      console.log(e);
    } finally {
      getCompany();
      fetchUsers();
    }
  }
  
  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={['Plages de numéros']} title='Plage de numéros' />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#company" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Numéros longs
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Numéros courts
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#gi" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Numéros fax
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="company">
                  <NumbersLong
                    isLoading={this.props.company.isLoading}
                    numbers={this.getNumbers("long")}
                    removeNumberUser={this.removeNumberUser.bind(this)}
                  />
              </div>
              <div className="tab-pane p-20" role="tabpanel" id="users">
                  <NumbersShort
                    isLoading={this.props.company.isLoading}
                    numbers={this.getNumbers("short")}
                    removeNumberUser={this.removeNumberUser.bind(this)}
                  />
              </div>
              <div className="tab-pane p-20" role="tabpanel" id="gi">
                  <NumbersFax
                    isLoading={this.props.company.isLoading}
                    numbers={this.getNumbers("fax")}
                    removeNumberUser={this.removeNumberUser.bind(this)}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
  }
}

export default connect(mapStateToProps, null)(Number);
