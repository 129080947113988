import React, { Component } from 'react';
import { createBill, updateBill } from 'components/commons/api/bills.js';
import { hideModal } from 'actions/modalActions.js';
import { stopEditingBill } from 'actions/contactsActions.js';
import { fetchUserContacts, fetchUserContact } from 'components/commons/helpers/contactHelper.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select'
import moment from "moment";
import Preloader from 'components/commons/Preloader.js';
import { toast } from 'react-toastify';

class CreateBill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      date: {
        day:
        {
          today : moment().format("YYYY-MM-DD")
        }
      },
      contact: {
        contacts_info:
        {
          civility : "0"
        }
      }
    }

    this.handleInputChange  = this.handleInputChange.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (this.props.contacts.editing.isEditing) {
      try {
        await await fetchUserContact(this.props.contacts.editing.contactId).then((result) => {
          this.setState((prevState) => ({
            contact: {
              ...prevState.contact,
              contacts_info: this.props.contacts.editing.datas
            }
          }));
        })
      } catch (e) {
        console.log(e);
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.props.contacts.editing.isEditing) {
      try {
        this.setState({ isCreating: true });
        return await updateBill(this.state.contact.contacts_info).then((result) => {
          return fetchUserContacts();
        })
      } catch (e) {
        console.log(e);
      } finally {
        return this.setState({ isCreating: false }, function() { this.props.hideModal();});
      }
    }

    try {
      this.setState({ isCreating: true });
      return await createBill(this.state.contact.contacts_info).then((result) => {
        toast.success("Facture créée avec succès !", {
          hideProgressBar: true,
          autoClose: 5000,
          onClose: () => {
            fetchUserContacts();
            return this.props.hideModal();
          }
        });

      })
    } catch (e) {
      console.log(e);
    } finally {
      return this.setState({ isCreating: false });
    }
  }

  handleInputChange(event) {
    const target  = event.target;
    const value   = target.type === 'checkbox' ? target.checked : target.value;
    const name    = target.name;

    return this.setState(state => ({
      contact: {
        contacts_info: {
          ...state.contact.contacts_info,
          [name]: value
        }
      }
    }));
  }




  render() {
    if (this.props.contacts.editing.isLoading) {
      return (<Preloader />);
    }

    const editingBill = this.state.contact.contacts_info;
    const today = this.state.date.day.today;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Select
                  id="bill-to-select"
                  tabIndex="1"
                  placeholder="Entitée de facturation"
                  options={[
                    { value: 'company1', label: 'Hotel Relais Bosquet' },
                    { value: 'company2', label: 'Entitée 2' },
                    { value: 'company3', label: 'Entitée 3' }
                  ]}
                  />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Select
                  id="ship-to-select"
                  tabIndex="2"
                  placeholder="Poste"
                  isMulti
                  options={[
                    { value: 'poste1', label: 'Chambre 1' },
                    { value: 'poste2', label: 'Chambre 2' },
                    { value: 'poste3', label: 'Chambre 3' }
                  ]}
                  />
              </div>
            </div>
          </div>
          <hr class="my-4" />
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="3"
                  className="form-control"
                  name="name"
                  type="text"
                  placeholder="Nom"
                  id="name-input"
                  onChange={this.handleInputChange}
                  required={true}
                  value={editingBill.lastname || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="4"
                  className="form-control"
                  name="address"
                  type="text"
                  placeholder="Adresse"
                  id="address-input"
                  onChange={this.handleInputChange}
                  value={editingBill.mobile || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="5"
                  className="form-control"
                  name="zipcode"
                  type="text"
                  placeholder="Code postal"
                  id="zipcode-input"
                  onChange={this.handleInputChange}
                  value={editingBill.firstname || ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="6"
                  className="form-control"
                  name="city"
                  type="text"
                  placeholder="Ville"
                  id="city-input"
                  onChange={this.handleInputChange}
                  value={editingBill.fax || ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  tabIndex="7"
                  className="form-control"
                  name="country"
                  type="text"
                  placeholder="Pays"
                  id="country-input"
                  onChange={this.handleInputChange}
                  value={editingBill.country || ''}
                />
              </div>
            </div>
          </div>
          <hr class="my-4" />
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  readOnly
                  tabIndex="8"
                  className="form-control"
                  name="datein"
                  type="date"
                  placeholder="Date"
                  id="datein-input"
                  onChange={this.handleInputChange}
                  value={editingBill.mail || today}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Select
                  id="delay-select"
                  tabIndex="9"
                  placeholder="Délai de paiement"
                  options={[
                    { value: '0', label: 'Immédiat' },
                    { value: '15', label: '15 jours' },
                    { value: '30', label: '30 jours' },
                    { value: '45', label: '45 jours' }
                  ]}
                  />
              </div>
            </div>
          </div>
          <hr class="my-4" />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  tabIndex="9"
                  name="comment"
                  placeholder="Texte complémentaire..."
                  id="comment-textarea"
                  rows={5}
                  onChange={this.handleInputChange}
                  value={editingBill.company || ''}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary waves-effect text-left"
            data-dismiss="modal"
            onClick={() => {
              this.props.stopEditingBill();
              return this.props.hideModal()
            }}
          >Annuler</button>
          <input
            disabled={this.state.isCreating}
            type="submit"
            className="btn btn-success waves-effect text-left right-side-toggle"
            value={this.state.isCreating ? 'Création...' : 'Confirmer'}
            />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal,
    stopEditingBill
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBill);
