import React, { Component } from 'react';
import { createSchedulerWeek } from 'components/commons/api/scheduler.js';
import { notifySuccess } from 'components/commons/Toasts.js';
import { hideModal } from 'actions/modalActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

class ScheduleSetWeekModalNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      monday: {},
      tuesday: {},
      wednesday: {},
      thursday: {},
      friday: {},
      saturday: {},
      sunday: {},
      label: '',
      isSaving: false,
      days: {},
      default: false
    }

    this.handleDayChange    = this.handleDayChange.bind(this);
    this.handleSaveWeek     = this.handleSaveWeek.bind(this);
    this.handleLabelChange  = this.handleLabelChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  componentDidMount() {
    const color = this.props.props.days[Object.keys(this.props.props.days)[0]].color;
    const value = this.props.props.days[Object.keys(this.props.props.days)[0]].day_id;

    this.setState({
      monday: {color: color, value: value},
      tuesday: {color: color, value: value},
      wednesday: {color: color, value: value},
      thursday: {color: color, value: value},
      friday: {color: color, value: value},
      saturday: {color: color, value: value},
      sunday: {color: color, value: value},
      days: this.props.props.days
    });
  }

  handleDayChange(event) {
    this.setState({
      [event.target.name]: { color: event.target.options[event.target.selectedIndex].dataset.color, value: parseInt(event.target.options[event.target.selectedIndex].value) }
    })
  }

  async handleSwitchChange() {
    try {
      return this.setState(prevState => {
        return {
          default: !prevState.default
        }
      })
    } catch (e) {
      console.log(e);
    }
  }  

  handleLabelChange(event) {
    this.setState({
      label: event.target.value
    })
  }

  async handleSaveWeek(e) {
    e.preventDefault();

    if (isEmpty(this.state.label)) {
      return null;
    }

    try {
      this.setState({ isSaving: true });
      const week = {
        user_id: this.props.session.user_id,
        label: this.state.label,
        default: this.state.default,
        week: {
          monday: this.state.monday.value,
          tuesday: this.state.tuesday.value,
          wednesday: this.state.wednesday.value,
          thursday: this.state.thursday.value,
          friday: this.state.friday.value,
          saturday: this.state.saturday.value,
          sunday: this.state.sunday.value,
        }
      }

      await createSchedulerWeek(week);
      notifySuccess('Semaine sauvegardée avec succès');
      this.props.props.callback();
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isSaving: false });
      this.props.hideModal();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className='col-lg-12'>
            <input
              type='text'
              className='form-control'
              placeholder='Nom de la semaine'
              onChange={this.handleLabelChange}
              value={this.state.label}
              required={true}
            />
            <hr />
          </div>
        </div>
        <div className='row well'>
          <div className='col-lg-5'>
            Semaine par défaut
          </div>
          <div className='col-lg-7 text-left'>
            <div className="material-switch pull-right">
              <input
                className="form-control"
                name="shared"
                type="checkbox"
                checked={this.state.default}
              />
              <label onClick={this.handleSwitchChange} htmlFor="someSwitchOptionPrimary" className="label-primary"></label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className='col-lg-12'>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className='col-lg-12'>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Jour de la semaine</th>
                  <th>Configuration associée</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lundi</td>
                  <td style={{ backgroundColor: `#${this.state.monday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='monday' value={this.state.monday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.state.days).map((key, index) => {
                        return <option key={index} data-color={this.state.days[key]['color']} value={key}>{ this.state.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Mardi</td>
                  <td style={{ backgroundColor: `#${this.state.tuesday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='tuesday' value={this.state.tuesday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.state.days).map((key, index) => {
                        return <option key={index} data-color={this.state.days[key]['color']} value={key}>{ this.state.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Mercredi</td>
                  <td style={{ backgroundColor: `#${this.state.wednesday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='wednesday' value={this.state.wednesday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.state.days).map((key, index) => {
                        return <option key={index} data-color={this.state.days[key]['color']} value={key}>{ this.state.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Jeudi</td>
                  <td style={{ backgroundColor: `#${this.state.thursday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='thursday' value={this.state.thursday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.state.days).map((key, index) => {
                        return <option key={index} data-color={this.state.days[key]['color']} value={key}>{ this.state.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Vendredi</td>
                  <td style={{ backgroundColor: `#${this.state.friday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='friday' value={this.state.friday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.state.days).map((key, index) => {
                        return <option key={index} data-color={this.state.days[key]['color']} value={key}>{ this.state.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Samedi</td>
                  <td style={{ backgroundColor: `#${this.state.saturday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='saturday' value={this.state.saturday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.state.days).map((key, index) => {
                        return <option key={index} data-color={this.state.days[key]['color']} value={key}>{ this.state.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Dimanche</td>
                  <td style={{ backgroundColor: `#${this.state.sunday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='sunday' value={this.state.sunday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.state.days).map((key, index) => {
                        return <option key={index} data-color={this.state.days[key]['color']} value={key}>{ this.state.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 text-right'>
            <button className='btn btn-light' onClick={this.props.hideModal}>Annuler</button>{' '}
            <button className='btn btn-success' disabled={this.state.isSaving || isEmpty(this.state.label)} onClick={this.handleSaveWeek}>{this.state.isSaving ? 'Sauvegarde...' : 'Sauvegarder'}</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    scheduler: state.scheduler,
    session: state.kertelSession.datas.data
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSetWeekModalNew);
