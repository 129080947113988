import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { notifySuccess } from 'components/commons/Toasts.js';
import { bindActionCreators } from 'redux';
import { createWaitingQueue } from 'components/commons/api/admin/waitingQueue';
import UpdateWaitingQueueInfos from './UpdateWaitingQueueInfos.js';
import Preloader from 'components/commons/Preloader.js';
import isUndefined from 'lodash/isUndefined';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

function CreateWaitingQueue ({ users, url }) {

	const navigate = useNavigate();

	const [isLoading, setIsloading] = useState(true);
	const [expiredLicence, setExpiredLicence] = useState(false);
	const [waitingQueues, setWaitingQueues] = useState({
        name: "",
		func: 1
      });
  
  async function createWaitingQueue() {
    try {
	  let info = {};
	  if (!isUndefined(waitingQueues.name)) info.name = waitingQueues.name;
	  info.func = waitingQueues.func;
	  
      await createWaitingQueue(info).then((result) => {
		if (result.status === 200) {
          notifySuccess("File d'attente crée avec succès !");
		  return navigate('/admin/waitingqueue');
		}
		
		return;
      })
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }
  
  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

	setWaitingQueues(
		...waitingQueues,
		{
			[name]: value
		}
	)
  }

  async function init () {
	await new Promise((resolve, reject) => {
		fetch(
		  url + '/license', {
			method: 'post',
			headers: {
			  'Content-Type':'application/json',
			  'auth-token': `${localStorage.getItem('_k')}`
			},
			body: JSON.stringify({
			  "service" : "waitingQueue"
			})
		  }
		)
		  .then(response => response.json())
		  .then(result => {
			if (!isUndefined(result.status) && result.status !== 200) {
			  return swal({
				type: 'error',
				icon: "error",
				title: 'Oops...',
				text: result.detail,
				confirmButtonColor: 'LightSeaGreen',
			  })
			}
			
			if (!isUndefined(result.data)) {
			  if (!isUndefined(result.data.available)) {
				if (result.data.available === 0) {
				  return setExpiredLicence(true);
				}
			  }
			}
			
			setIsloading(false);
		  })
	  })
  }

  useEffect(function() {
	init();
  }, [])

	if (expiredLicence) {
	  return (
		<div className="col-lg-12">
		  <div className="card">
			<div className="card-body p-b-0">
            <Breadcrumb routes={["File d'attente", "Création de file d'attente"]} url="/admin/waitingqueue" title="Création de file d'attente" />
			  <ul className="nav nav-tabs customtab" role="tablist">
				<li className="nav-item">
				  <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
					<span className="hidden-sm-up">
					  <i className="ti-home"></i>
					</span>
					<span className="hidden-xs-down">
					  Information générales
					</span>
				  </a>
				</li>
			  </ul>
			  <div className="tab-content">
				<div className="tab-pane active p-20" role="tabpanel" id="infos">
				  <div className="col-lg-12 text-center">Le nombre maximum de licences a été atteint.</div>
				</div>
			  </div>
            </div>
          </div>
        </div>
      )
	}
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
    
	return (
	  <div className="col-lg-12">
		<div className="card">
		  <div className="card-body p-b-0">
            <Breadcrumb routes={["File d'attente", "Création de file d'attente"]} url="/admin/waitingqueue" title="Création de file d'attente" />
			<ul className="nav nav-tabs customtab" role="tablist">
			  <li className="nav-item">
				<a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
				  <span className="hidden-sm-up">
					<i className="ti-home"></i>
				  </span>
				  <span className="hidden-xs-down">
					Informations générales
				  </span>
				</a>
			  </li>
			</ul>
			<div className="tab-content">
			  <div className="tab-pane active p-20" role="tabpanel" id="infos">
				<UpdateWaitingQueueInfos
				  options={waitingQueues}
				  handleOptions={handleOptions}
				/>
			  </div>
			  <div className="form-actions m-4">
				<div className="row">
				  <div className="col-md-12 ">
					<span className="float-right">
					  <button
						type="button"
						className="btn btn-inverse mr-2"
						onClick={() => {
						  return navigate('/admin/waitingqueue');
						}}
					  >
						Annuler
					  </button>
					  <button onClick={createWaitingQueue} type="submit" className="btn btn-success">Enregistrer</button>
					</span>
				  </div>
				  <div className="col-md-6"> </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	);
}

const mapStateToProps = (state) => {
  return {
	users: state.users,
	url: state.kertelSession.apiUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWaitingQueue);
