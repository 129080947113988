import React, { useEffect, useState } from 'react';
import { getSchedulerDays, getSchedulerWeeks, deleteSchedulerDay, deleteDayRange, deleteSchedulerWeek } from 'components/commons/api/scheduler.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal } from 'actions/modalActions.js';
import { addDay, addWeek } from 'actions/schedulerActions.js';
import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';
import Preloader from 'components/commons/Preloader.js';
import SchedulerEditDayModal from 'components/views/modals/SchedulerEditDayModal.js';
import ScheduleSetWeekModal from 'components/views/modals/ScheduleSetWeekModal.js';
import ScheduleSetWeekModalNew from 'components/views/modals/ScheduleSetWeekModalNew.js';
import CreateDay from 'components/views/modals/CreateDay.js';
import SchedulerDayModal from 'components/views/modals/SchedulerDayModal.js';
import { getLiteralForward } from 'components/commons/helpers/forwardsHelper.js';
import { notifyInfo } from 'components/commons/Toasts.js';
import swal from 'sweetalert';
import Calendar from 'components/views/scheduler/Calendar';
import { isUndefined } from 'lodash';

function Scheduler({ session, scheduler, showModal, addDay, addWeek }) {

  const [days, setDays] = useState();
  const [weeks, setWeeks] = useState([]);
  const [isLoadingDays, setIsLoadingDays] = useState(false);
  const [showConfig, setShowConfig] = useState(0);

  useEffect(function() {
    initDaysAndWeeks();
  }, []);

  async function initDaysAndWeeks() {
    setIsLoadingDays(true);
    const weeks = await getSchedulerWeeks(session.user.user_id);
    const d = await getSchedulerDays(session.user.user_id);

    setDays(d.data);
    setWeeks(weeks.data);

    setIsLoadingDays(false);
  }

  function getProgressPercentageArray(forwardsTimesArray) {
    let fullDayInMinutes = 1440;
    let progressArray = [];

    forwardsTimesArray.map((forward, index) => {
      const label = `${forward.start}`;
      let forwardTime = forward.start;
      let startForwardArray = split(forwardTime, ':');
      let forwardInMinutes = (parseInt(startForwardArray[0]) * 60) + parseInt(startForwardArray[1]);
      let startForwardOnProgress = fullDayInMinutes - forwardInMinutes;

      if (index === 0) {
        progressArray.push({width: ((forwardInMinutes * 100) / fullDayInMinutes), color: '#EEEEEE'});
      }

      let startForwardInPercentage = (startForwardOnProgress * 100) / fullDayInMinutes;
      return progressArray.push({width: startForwardInPercentage, color: `#${forward.color}`, label: label});
    });

    return progressArray;
  }

  async function handleDeleteDay(dayId) {
    const willDelete = await swal({
      title: "Confirmation",
      text: `Etes-vous sûr(e) de vouloir supprimer cette journée ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      try {
        await deleteSchedulerDay({ dayId, userId: session.user.user_id });
        notifyInfo('Journée supprimée avec succès !');

        initDaysAndWeeks();
      } catch (e) { console.log(e); }
    }
  }

  async function handleDeleteWeek(weekId) {
    const willDelete = await swal({
      title: "Confirmation",
      text: `Etes-vous sûr(e) de vouloir supprimer la semaine ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      try {
        await deleteSchedulerWeek({ weekId, userId: session.user.user_id });
        notifyInfo('Semaine supprimée avec succès !');
        
        initDaysAndWeeks();

        return;
      } catch (e) { console.log(e); }
    }
  }

  function daysRender() {
    if (isLoadingDays) {
      return <tr><td colSpan={4}><Preloader className="primary" /></td></tr>
    }
  
    if (isEmpty(days)) {
      return <tr><td colSpan={4} className="text-center"><h1 className='text-muted'>Aucune journée</h1></td></tr>
    }
  
    return (
      Object.keys(days).map((key, index) => {
        const forwardsPercentageArray = getProgressPercentageArray(days[key].forwards);
  
        return (
          <React.Fragment key={index}>
            <tr>
              <td style={{ backgroundColor: `#${days[key]['color']}` }} className="dayColorRenderContainer"><span className="dayColorRender"></span></td>
              <td style={{ fontWeight: "bold" }}>{days[key]['label']}</td>
              <td>
                <div className="progress">
                  <ProgressRender forwards={forwardsPercentageArray} />
                </div>
              </td>
              <td>
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={async () => {
                    await addDay(days[key]);
                    setShowConfig(key);
                  }}
                >
                  configurer
                </button>
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => handleDeleteDay(key)}
                >
                  supprimer
                </button>
              </td>
            </tr>
            <tr className={`collapse schedulerSubTable ${key === showConfig && 'show'}`} id={`day_${key}`}>
              <td colSpan={4}>
                <div>
                  <div className="card">
                    <div className="col-lg-12 text-right">
                      <button className="btn btn-success" onClick={() => {
                        return showModal({
                          title: "Ajouter un créneau",
                          component: SchedulerDayModal,
                          callback: () => initDaysAndWeeks(),
                          dayId: key
                        });
                      }}>Ajouter un créneau</button>
                      <hr />
                    </div>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='progress col-lg-12'>
                          <ProgressRender forwards={forwardsPercentageArray} showLabel={true} />
                        </div>
                        <hr />
                      </div>
                    </div>
                    <table className="table">
                      <thead className="bg-light">
                        <tr>
                          <th style={thStyles}>Créneaux</th>
                          <th style={thStyles}>Appels entrants</th>
                          <th style={thStyles}>Occupation</th>
                          <th style={thStyles}>Non-réponse</th>
                          <th style={thStyles}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (days[key].forwards).map((keyMap, index) => {
  
                            let { usr, busy, na } = keyMap.forward;
  
                            //usr.forwards = [{ dests:[{entity:{type:"PHONE", data:""}, timeout: 5}] }]
                            //busy.forwards = [{ dests:[{entity:{type:"PHONE", data:""}, timeout: 5}] }]
                            //na.forwards = [{ dests:[{entity:{type:"PHONE", data:""}, timeout: 5}] }]
                            
  
                            if (!isUndefined(keyMap.forward.usr.forwards)) {
                              if (!isUndefined(keyMap.forward.usr.forwards[0].dests)) {
                                usr = keyMap.forward.usr.forwards[0].dests[0].entity;
                              }                            
                            }
  
                            if (!isUndefined(keyMap.forward.busy.forwards)) {
                              if (!isUndefined(keyMap.forward.busy.forwards[0].dests)) {
                                busy = keyMap.forward.busy.forwards[0].dests[0].entity;
                              }                            
                            }
  
                            if (!isUndefined(keyMap.forward.na.forwards)) {
                              if (!isUndefined(keyMap.forward.na.forwards[0].dests)) {
                                na = keyMap.forward.na.forwards[0].dests[0].entity;
                              }                            
                            }
  
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <span style={{ fontWeight: "bold" }}>
                                      <span className="badge mr-3" style={{ color: `#${keyMap.color}`, backgroundColor: `#${keyMap.color}` }}>color</span> {keyMap.start} - {keyMap.end}</span>
                                  </td>
                                  <td>
                                    {usr.type === "E164" ? usr.data : getLiteralForward(usr.type, usr.data)}
                                  </td>
                                  <td>
                                    {busy.type === "E164" ? busy.data : getLiteralForward(busy.type, busy.data)}
                                  </td>
                                  <td>
                                    {na.type === "E164" ? na.data : getLiteralForward(na.type, na.data)}
                                  </td>
                                  <td className="text-center">
                                    <button className="btn btn-link" onClick={() => {
                                      return showModal({
                                        title: "Modifier un créneau",
                                        component: SchedulerDayModal,
                                        callback: () => initDaysAndWeeks(),
                                        dayId: keyMap.day_id,
                                        forwards: keyMap.forward,
                                        color: keyMap.color,
                                        start: keyMap.start,
                                        end: keyMap.end,
                                      });
                                    }}>modifier</button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <hr />
                    <h4>Nom et couleur de la journée</h4>
                    <h5 className="mt-0 text-muted">Le changement de couleur n'aura aucun effet sur les créneaux</h5>
                    <div className='row'>
                      <div className='col-lg-6'>
                        {!isEmpty(scheduler.day) &&
                          <SchedulerEditDayModal
                            label={days[key].label}
                            dayId={key}
                            color={days[key]['color']}
                            callback={() => initDaysAndWeeks()}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      })
    );
  }
  
  const ProgressRender = (props) => {
    return (
      (props.forwards).map((forward, index) => {
        return (
          <div key={index} className={`progress-bar progress-bar-striped ${props.showLabel ? 'text-left' : 'text-center'}`} role="progressbar" style={{width: `${forward.width}%`, height: `${props.showLabel ? "30px" : "15px"}`, background: forward.color}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"><h3 style={{ borderLeft: '2px solid #000', marginBottom: '0', paddingLeft: '2px' }}>{props.showLabel ? forward.label : ''}</h3></div>
        );
      })
    );
  }
  
  const WeeksRender = props => {
    if (isLoadingDays) {
      return <tr><td colSpan={4}><Preloader className="primary" /></td></tr>
    }
  
    if (isEmpty(weeks)) {
      return <tr><td colSpan={4} className="text-center"><h1>Aucune semaine configurée</h1></td></tr>
    }
  
    return (
      Object.keys(weeks).map((key, index) => {      
        return (
          <tr key={index}>
            <td style={{ fontWeight: "bold" }}>{weeks[key]['label']} {weeks[key]['default'] && <span className='badge badge-pill badge-info'>{' '}Semaine par défaut</span>}</td>
            <td>
              <button className="btn btn-link" onClick={() => {
                addWeek(weeks[key]);
                return showModal({
                  title: "Modifier une semaine",
                  component: ScheduleSetWeekModal,
                  callback: () => initDaysAndWeeks(),
                  days: days,
                  label: weeks[key].label,
                  weekId: key
                })
              }}>Afficher</button>
              {!weeks[key].default && <button
                className='btn btn-link'
                onClick={() => handleDeleteWeek(key)}>Supprimer</button>}
            </td>
          </tr>
        );
      })
    );
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs customtab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#calendar" role="tab">
                <span className="hidden-xs-down">
                  Calendrier
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#days" role="tab">
                <span className="hidden-xs-down">
                  Configuration des journées
                </span>
              </a>
            </li>
            <li className="nav-item disabled">
              <a className={`nav-link ${isEmpty(days) && 'disabled'}`} data-toggle="tab" href="#weeks" role="tab" onClick={() => {
                if (isEmpty(days)) {
                  return notifyInfo('Vous devez créer au moins une journée avant de configurer vos semaines.');
                }
              }}>
                Configuration des semaines
              </a>
            </li>
          </ul>
          <div className="tab-content bg-white">
            <div className="tab-pane active" id="calendar" role="tabpanel">
              <Calendar />
            </div>  
            <div className="tab-pane" id="days" role="tabpanel">
              <div style={{ padding: "15px" }}>
                <div className="row">
                  <div className="col-lg-12 text-right">
                    <button className="btn btn-success" onClick={() => {
                      return showModal({
                        title: "Créer une journée",
                        component: CreateDay,
                        callback: () => initDaysAndWeeks()
                      });
                    }}>Créer une journée</button>
                    <hr />
                  </div>
                </div>
                <table className="table bg-white table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>{''}</th>
                      <th scope="col">Nom de la configuration</th>
                      <th scope="col">Renvois</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    { daysRender() }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane" id="weeks" role="tabpanel">
              <div style={{ padding: "15px" }}>
                <div className="row">
                  <div className="col-lg-12 text-right">
                    <button className="btn btn-success" onClick={() => {
                      return showModal({
                        title: "Créer une semaine par défaut",
                        component: ScheduleSetWeekModalNew,
                        callback: () => initDaysAndWeeks(),
                        days: days
                      });
                    }}>Créer une semaine</button>
                    <hr />
                  </div>
                </div>
                <table className="table bg-white table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Nom de la semaine configurée</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <WeeksRender
                      weeks={weeks}
                      days={days}
                      isLoadingDays={isLoadingDays}
                      showModal={showModal}
                      addWeek={addWeek}
                      handleDeleteWeek={handleDeleteWeek}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const thStyles = {
  fontWeight: 'bold',
  textAlign: 'left'
}

const mapStateToProps = (state) => {
  return ({
    session: state.kertelSession,
    scheduler: state.scheduler
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal,
    addDay,
    addWeek
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
