const initialState = {
  show: false,
  component: null,
  title: "",
  component_dtmf:null,
  show_dtmf: false,
  title_dtmf: ""
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'SHOW_MODAL':
      return {
        ...state,
        show: true,
        component: action.payload.component,
        title: action.payload.title,
        props: action.payload,
        callback: action.payload.callback
      };

    case 'HIDE_MODAL':
      return {
        ...state,
        show: false,
        showDTMF: false
      };

    default:
      return state
  }
}

export default modalReducer;
