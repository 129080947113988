import { GET, POST, PATCH } from './../api';

export const getMevoInformation = () => {
  return GET('/mevo');
}

export const getMevoByUser = (user_id) => {
  return POST('/mevo', {
    user_id: user_id
  });
}

export const getMevoMessages = (user_id) => {
  return POST('/mevo/messages', {
    user_id: user_id
  });
}

export const updateMevoInformation = (userId, informations) => {
  return PATCH('/mevo', {
    company: localStorage.getItem('company'),
    user_id: userId,
    data: informations
  });
}