import React, { Component } from 'react';
import { hideModal } from 'actions/modalActions.js';
import logoIconK from 'assets/images/logo-icon-k.png';
import logoLightIcon from 'assets/images/logo-light-icon.png';
import logoTextK from 'assets/images/logo-text-k.png';
import logoLightText from 'assets/images/logo-text-k.png';
import { loadingNotifications, stopLoadingNotifications } from 'actions/notificationsActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class NavBar extends Component {

  async componentDidMount() {
    try {
      this.props.loadingNotifications();
    } catch (e) {
      console.log(e);
    } finally {
      return this.props.stopLoadingNotifications();
    }
  }

  render() {
    return (
        <header id="user_header" className="topbar">
          <nav className="navbar top-navbar navbar-expand-md navbar-light" style={{ height: "20px" }}>
            <div className="navbar-header">
              <a className="navbar-brand" href="../" style={{ paddingTop: "60px" }}>
                <b>
                  <img src={logoIconK} alt="homepage" className="dark-logo" />
                  <img src={logoLightIcon} alt="homepage" className="light-logo" />
                </b>
                <span>
                 <img src={logoTextK} alt="homepage" className="dark-logo" />
                 <img src={logoLightText} className="light-logo" alt="homepage" /></span>
              </a>
            </div>
            <div className={`navbar-collapse ${window.location.href.indexOf('admin') > -1 && 'adminNavBar' }`} id="mainNavbar"></div>
          </nav>
        </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    phone: state.kertelphone,
    voicemails: state.voiceMail,
    notifications: state.notifications,
    settings: state.settings,
    fax: state.fax
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadingNotifications,
    stopLoadingNotifications,
    hideModal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
