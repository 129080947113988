import { store } from 'store/index.js';
import {
  getMevosGroupInformation,
  getMevoGroupInformation,
  createMevoGroupInformation,
  updateMevoGroupInformation,
  deleteMevoGroupInformation
} from 'components/commons/api/admin/mevoGroup';
import {
  loadingMevos,
  getMevos as getMevosAction,
  stopLoadingMevos,
  loadingMevo,
  getMevo as getMevoAction,
  stopLoadingMevo,

} from 'actions/admin/mevoGroupActions';
import {notifySuccess} from 'components/commons/Toasts.js';

export const fetchMevos = async (props) => {
  store.dispatch(loadingMevos());
  try {
    await getMevosGroupInformation().then((result) => {
      return store.dispatch(getMevosAction(Object.values(result.data)));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingMevos());
  }
}

export const fetchMevoGroup = async (mevoId) => {
  store.dispatch(loadingMevo());
  try {
    await getMevoGroupInformation(mevoId).then((result) => {
      return store.dispatch(getMevoAction(Object.values(result.data)[0]));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingMevo());
  }
}

export const createMevo = async (informations, history) => {
  store.dispatch(loadingMevo());
  try {
    await createMevoGroupInformation(informations).then((result) => {
      if (result.status === 200) {
        notifySuccess("Mevo de groupe ajoutée avec succès !");
        fetchMevos();
        return;
      }

      return fetchMevos();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingMevo());
  }
}

export const updateMevo = async (mevoId, informations) => {
  store.dispatch(loadingMevo());
  try {
    await updateMevoGroupInformation(mevoId, informations).then((result) => {
      if (result.status === 200) {
        notifySuccess("Mevo de groupe mis à jour avec succès !");
        fetchMevos();
        return;
      }

      return fetchMevos();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingMevo());
  }
}

export const deleteMevo = async (mevoId) => {
  store.dispatch(loadingMevo());
  try {
    await deleteMevoGroupInformation(mevoId).then((result) => {
      return fetchMevos();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingMevo());
  }
}
