import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { fetchProfilesCalls } from 'components/commons/helpers/admin/profileCallsHelper.js';
import ProfileCallsTab from './ProfileCallsTab.js';

class ProfileCalls extends Component {
  componentDidMount() {
    fetchProfilesCalls();
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Profils d'appels"]} title="Profils d'appels"  addPc="true"/>
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#gi" role="tab" onClick={fetchProfilesCalls}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Vos profils d'appels
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" id="gi" role="tabpanel" >
                <ProfileCallsTab
                  isLoading={this.props.profileCalls.isLoading}
                  ringtoneGeneral={this.props.profileCalls.datas}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileCalls : state.profileCalls
  }
}

export default connect(mapStateToProps, null)(ProfileCalls);
