import { store } from 'store/index.js';
import {
  getRingtonesGeneralInformation,
  getRingtoneGeneralInformation,
  createRingtoneGeneralInformation,
  updateRingtoneGeneralInformation,
  deleteRingtoneGeneralInformation,
  checkTtyIdRingtoneGeneralInformation
} from 'components/commons/api/admin/ringtoneGeneral';
import {
  loadingRingtonesGeneral,
  getRingtonesGeneral as getRingtonesGeneralAction,
  stopLoadingRingtonesGeneral,
  loadingRingtoneGeneral,
  getRingtoneGeneral as getRingtoneGeneralAction,
  stopLoadingRingtoneGeneral,
  checkTtyIdRingtonesGeneral,
} from 'actions/admin/ringtoneGeneralActions';
import { notifySuccess, notifyError } from 'components/commons/Toasts.js';

export const fetchRingtonesGeneral = async (props) => {

  store.dispatch(loadingRingtonesGeneral());
  try {
    await getRingtonesGeneralInformation().then((result) => {
      return store.dispatch(getRingtonesGeneralAction(Object.values(result.data)));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingRingtonesGeneral());
  }
}

export const fetchRingtoneGeneral = async (ringtoneGeneralId) => {

  store.dispatch(loadingRingtoneGeneral());
  try {
    await getRingtoneGeneralInformation(ringtoneGeneralId).then((result) => {
      return store.dispatch(getRingtoneGeneralAction(Object.values(result.data)[0]));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingRingtoneGeneral());
  }
}

export const createRingtoneGeneral = async (informations, history) => {
  store.dispatch(loadingRingtoneGeneral());
  try {
    await createRingtoneGeneralInformation(informations).then((result) => {
      if (result.status === 200) {
        notifySuccess("Messagerie de groupe ajoutée avec succès !");
        fetchRingtonesGeneral();
      }

      return fetchRingtonesGeneral();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingRingtoneGeneral());
  }
}

export const updateRingtoneGeneral = async (mevoId, informations, history) => {
  store.dispatch(loadingRingtoneGeneral());
  try {
    await updateRingtoneGeneralInformation(mevoId, informations).then((result) => {
      if (result.status === 200) {
        notifySuccess("Messagerie de groupe mis à jour avec succès !");
        fetchRingtonesGeneral();
      }

      return fetchRingtonesGeneral();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingRingtoneGeneral());
  }
}

export const deleteRingtoneGeneral = async (ringId) => {
  store.dispatch(loadingRingtoneGeneral());
  try {
    await deleteRingtoneGeneralInformation(ringId).then((result) => {
      return fetchRingtonesGeneral();
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingRingtoneGeneral());
  }
}

export const checkTtyIdRingtoneGeneral = async (ttyId) => {
  try {
    await checkTtyIdRingtoneGeneralInformation(ttyId).then((result) => {
      if (result.status !== 200) {
        notifyError("Echec lors de la validation du numéro de poste !");
        return false;
      }

      return store.dispatch(checkTtyIdRingtonesGeneral(result.data.check));
    })
  } catch (e) {
    console.log(e);
  }
}
