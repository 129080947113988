import React, { Component } from 'react';
import { updateSchedulerWeek } from 'components/commons/api/scheduler.js';
import { notifySuccess } from 'components/commons/Toasts.js';
import { hideModal } from 'actions/modalActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class ScheduleSetWeekModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      monday: {color: 'FFFFFF', value: 0},
      tuesday: {color: 'FFFFFF', value: 0},
      wednesday: {color: 'FFFFFF', value: 0},
      thursday: {color: 'FFFFFF', value: 0},
      friday: {color: 'FFFFFF', value: 0},
      saturday: {color: 'FFFFFF', value: 0},
      sunday: {color: 'FFFFFF', value: 0},
      label: '',
      isSaving: false,
      weekId: 0
    }

    this.handleDayChange    = this.handleDayChange.bind(this);
    this.handleSaveWeek     = this.handleSaveWeek.bind(this);
    this.handleLabelChange  = this.handleLabelChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      monday: { color: this.props.props.days[this.props.scheduler.week.monday.id].color, value: this.props.scheduler.week.monday.id },
      tuesday: { color: this.props.props.days[this.props.scheduler.week.tuesday.id].color, value: this.props.scheduler.week.tuesday.id },
      wednesday: { color: this.props.props.days[this.props.scheduler.week.wednesday.id].color, value: this.props.scheduler.week.wednesday.id },
      thursday: { color: this.props.props.days[this.props.scheduler.week.thursday.id].color, value: this.props.scheduler.week.thursday.id },
      friday: { color: this.props.props.days[this.props.scheduler.week.friday.id].color, value: this.props.scheduler.week.friday.id },
      saturday: { color: this.props.props.days[this.props.scheduler.week.saturday.id].color, value: this.props.scheduler.week.saturday.id },
      sunday: { color: this.props.props.days[this.props.scheduler.week.sunday.id].color, value: this.props.scheduler.week.sunday.id },
      label: this.props.props.label,
      weekId: this.props.props.weekId
    });
  }

  handleDayChange(event) {
    this.setState({
      [event.target.name]: { color: event.target.options[event.target.selectedIndex].dataset.color, value: parseInt(event.target.options[event.target.selectedIndex].value) }
    })
  }

  handleLabelChange(event) {
    this.setState({
      label: event.target.value
    })
  }

  async handleSaveWeek(e) {
    e.preventDefault();

    try {
      this.setState({ isSaving: true });
      const week = {
        user_id: this.props.session.user_id,
        label: this.state.label,
        week_id: this.state.weekId,
        week: {
          monday: this.state.monday.value,
          tuesday: this.state.tuesday.value,
          wednesday: this.state.wednesday.value,
          thursday: this.state.thursday.value,
          friday: this.state.friday.value,
          saturday: this.state.saturday.value,
          sunday: this.state.sunday.value,
        }
      }

      await updateSchedulerWeek(week);
      notifySuccess('Semaine sauvegardée avec succès');
      this.props.props.callback();
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isSaving: false });
      this.props.hideModal();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className='col-lg-12'>
            <input
              type='text'
              className='form-control'
              placeholder='Nom de la semaine (optionnel)'
              onChange={this.handleLabelChange}
              value={this.state.label}
            />
            <hr />
          </div>
        </div>
        <div className="row">
          <div className='col-lg-12'>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Jour de la semaine</th>
                  <th>Configuration associée</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lundi</td>
                  <td style={{ backgroundColor: `#${this.state.monday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='monday' value={this.state.monday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.props.props.days).map((key, index) => {
                        return <option key={index} data-color={this.props.props.days[key]['color']} value={key}>{ this.props.props.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Mardi</td>
                  <td style={{ backgroundColor: `#${this.state.tuesday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='tuesday' value={this.state.tuesday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.props.props.days).map((key, index) => {
                        return <option key={index} data-color={this.props.props.days[key]['color']} value={key}>{ this.props.props.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Mercredi</td>
                  <td style={{ backgroundColor: `#${this.state.wednesday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='wednesday' value={this.state.wednesday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.props.props.days).map((key, index) => {
                        return <option key={index} data-color={this.props.props.days[key]['color']} value={key}>{ this.props.props.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Jeudi</td>
                  <td style={{ backgroundColor: `#${this.state.thursday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='thursday' value={this.state.thursday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.props.props.days).map((key, index) => {
                        return <option key={index} data-color={this.props.props.days[key]['color']} value={key}>{ this.props.props.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Vendredi</td>
                  <td style={{ backgroundColor: `#${this.state.friday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='friday' value={this.state.friday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.props.props.days).map((key, index) => {
                        return <option key={index} data-color={this.props.props.days[key]['color']} value={key}>{ this.props.props.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Samedi</td>
                  <td style={{ backgroundColor: `#${this.state.saturday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='saturday' value={this.state.saturday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.props.props.days).map((key, index) => {
                        return <option key={index} data-color={this.props.props.days[key]['color']} value={key}>{ this.props.props.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Dimanche</td>
                  <td style={{ backgroundColor: `#${this.state.sunday.color}`, textAlign: 'center' }}>
                    <select className='form-control' name='sunday' value={this.state.sunday.value} onChange={this.handleDayChange}>
                      {Object.keys(this.props.props.days).map((key, index) => {
                        return <option key={index} data-color={this.props.props.days[key]['color']} value={key}>{ this.props.props.days[key]['label'] }</option>
                      })}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 text-right'>
            <button className='btn btn-light' onClick={this.props.hideModal}>Annuler</button>{' '}
            <button className='btn btn-success' disabled={this.state.isSaving} onClick={this.handleSaveWeek}>{this.state.isSaving ? 'Sauvegarde...' : 'Sauvegarder'}</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    scheduler: state.scheduler,
    session: state.kertelSession.datas.data
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSetWeekModal);
