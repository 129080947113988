import React, { Component } from 'react';
import {connect} from 'react-redux';
import Preloader from 'components/commons/Preloader.js';
import MaterialTable from 'material-table';
import Moment from 'react-moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
library.add(faFileExcel);

class BillsTab extends Component
{
  render() {
    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={4}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <MaterialTable
        columns={[
          { title: 'Numéro', field: 'billId'},
          { title: 'Date', field: 'date', render: rowData => <Moment format="DD/MM/YYYY HH:mm" date={rowData.date} />},
          { title: 'Intitulé', field: 'label'},
        ]}
        data={this.props.bills}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucune facture à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            header: {
              actions: ''
            },
            pagination: {
              labelRowsPerPage: "Facture par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
              labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          search: true,
          pageSize: 10,
          pageSizeOptions: [10,25,50],
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => (<PictureAsPdfIcon />),
            tooltip: 'PDF',
            onClick: (event, rowData) => {
              window.open(rowData.filename + '&token=' + localStorage.getItem('_mt'), '_blank');
              return ;
            },
          },
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(BillsTab);
