import { store } from 'store/index.js';
import { getRecords, getCountRecords } from 'components/commons/api/admin/records';
import {
  loadingRecords,
  fetchRecords as fetchRecordsAction,
  fetchCountRecords as fetchCountRecordsAction,
  stopLoadingRecords
} from 'actions/admin/recordsActions';

export const fetchRecords = async (props, page, limit) => {
  store.dispatch(loadingRecords());
  
  try {
    await getRecords(page, limit).then((result) => {
      return store.dispatch(fetchRecordsAction(result.data));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingRecords());
  }
}

export const fetchCountRecords = async (props) => {
  store.dispatch(loadingRecords());
  
  try {
    await getCountRecords().then((result) => {
      return store.dispatch(fetchCountRecordsAction(result.data));
    })
  } catch (e) {
    console.log(e);
  } finally {
    store.dispatch(stopLoadingRecords());
  }
}
