import { store } from 'store/index.js';
import { getMessagesMevoGroup as messagesMevoGroup } from 'components/commons/api/phone.js';
import { loadingMessagesMevoGroup, stopLoadingMessagesMevoGroup, fetchMessagesMevoGroup, fetchUnseenMessagesMevoGroup } from 'actions/messagesMevoGroupActions.js';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

export const getMessagesMevoGroup = async (props) => {
  store.dispatch(loadingMessagesMevoGroup());

  try {
    const messages = await messagesMevoGroup();
    let unseen  = [];

    if (!isEmpty(messages.data)) {
      unseen = (messages.data).filter(message => message.seen === false);
    }
    
    var data = {};
    messages.data.forEach(function(elements) {
      if (isUndefined(data[elements.mevo])) data[elements.mevo] = [];
      data[elements.mevo].push(elements);
    });
    
    store.dispatch(fetchMessagesMevoGroup(data));
    store.dispatch(fetchUnseenMessagesMevoGroup(unseen));
  } catch (error) {
    console.log(error);
  } finally {
    return store.dispatch(stopLoadingMessagesMevoGroup());
  }
};
