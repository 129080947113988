import { GET, PUT, DELETE, POST, PATCH } from './api';

export const getFrequentContacts = () => {
  return POST('/contacts/frequent');
}

export const getCompanyContacts = () => {
  return GET('/contact/company');
}

export const getUserContacts = () => {
  return GET('/contact/user');
}

export const getUserContact = (contactId) => {
  return POST('/contact/user', {
    contact_id: contactId
  });
}

export const createUserContact = (contact) => {
  return PUT('/contact/user', {
    contacts_info: [contact]
  });
}

export const updateUserContact = (contact) => {
  return PATCH('/contact/user', {
    contacts_info: [contact]
  });
}

export const deleteUserContact = (contactId) => {
  return DELETE('/contact/user', {
    contact_ids: [contactId]
  });
}

export const searchContact = (query) => {
  return POST('/contact/company', {
    search: query,
    limit: 10
  });
}
