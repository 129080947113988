import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal } from 'actions/modalActions';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import MusicModal from 'components/views/modals/MusicModal.js';
import Preloader from 'components/commons/Preloader.js';

class Music extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      waitingMusicUrl: '',
      awayMessage00Url: '',
      awayMessage01Url: '',
      away00Playing: false,
      away01Playing: false,
      waitingPlaying: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.playMusic = this.playMusic.bind(this);
  }

  playMusic(audioId) {
    var myMusic = document.getElementById(audioId);

    if (!isNull(myMusic)) {
      let that = this;
      myMusic.addEventListener("ended", function() {
        that.setState({ away00Playing: false, away01Playing: false, waitingPlaying: false });
        myMusic.pause();
        const reset = myMusic.src;
        return myMusic.src = reset;
      }, true);

      switch (audioId) {
        case 'player_away00':
          if (this.state.away00Playing) {
            this.setState({ away00Playing: false, away01Playing: false, waitingPlaying: false });
            myMusic.pause();
            const reset = myMusic.src;
            return myMusic.src = reset;
          }

          this.setState({ away00Playing: true, away01Playing: false, waitingPlaying: false });
          return myMusic.play();
        case 'player_away01':
          if (this.state.away01Playing) {
            this.setState({ away00Playing: false, away01Playing: false, waitingPlaying: false });
            myMusic.pause();
            const reset = myMusic.src;
            return myMusic.src = reset;
          }

          this.setState({ away00Playing: false, away01Playing: true, waitingPlaying: false });
          return myMusic.play();
        case 'player_waiting':
          if (this.state.waitingPlaying) {
            this.setState({ away00Playing: false, away01Playing: false, waitingPlaying: false });
            myMusic.pause();
            const reset = myMusic.src;
            return myMusic.src = reset;
          }

          this.setState({ away00Playing: false, away01Playing: false, waitingPlaying: true });
          return myMusic.play();
        default:
          this.setState({ away00Playing: false, away01Playing: false, waitingPlaying: false });
      }
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      
      const waitingMusicUrl = `${this.props.session.apiUrl}/waiting/music?r=${(new Date()).getTime()}&token=${localStorage.getItem('_mt')}`;
      const away00MessageUrl = `${this.props.session.apiUrl}/away/message?message_type=00&r=${(new Date()).getTime()}&token=${localStorage.getItem('_mt')}`;
      const away01MessageUrl = `${this.props.session.apiUrl}/away/message?message_type=01&r=${(new Date()).getTime()}&token=${localStorage.getItem('_mt')}`;

      let hasWaitingMusic = false;
      let hasAway00Message = false;
      let hasAway01Message = false;
      
      this.setState({ isLoading: true })
      
      // Check waiting music
      await fetch(waitingMusicUrl)
        .then(response => {
          if (response.status === 200) hasWaitingMusic = true;
        })
        .catch(() => {
          console.log("hasWaitingMusic : Missing sound.");
        });
        
      // Check away01 message
      await fetch(away00MessageUrl)
        .then(response => {
          if (response.status === 200) hasAway00Message = true;
        })
        .catch(() => {
          console.log("hasAwayMessage : Missing sound.");
        });
        
      // Check mevo announce
      await fetch(away01MessageUrl)
        .then(response => {
          if (response.status === 200) hasAway01Message = true;
        })
        .catch(() => {
          console.log("hasMevoAnnounce : Missing sound.");
        });
        
      this.setState({
        isLoading: false,
        waitingMusicUrl: hasWaitingMusic ? waitingMusicUrl : '',
        away00MessageUrl: hasAway00Message ? away00MessageUrl : '',
        away01MessageUrl: hasAway01Message ? away01MessageUrl : ''
      });
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  render() {

    return (
      <div className="card" id="musicCard">
        <div className="card-body p-b-0">
          <div className="row">
            {this._isMounted && <MusicsRender
              state={this.state}
              showModal={this.props.showModal}
              session={this.props.session.datas.data}
              componentDidMount={this.componentDidMount}
              playMusic={this.playMusic}
            />}
          </div>
        </div>
      </div>
    );
  }
}

const MusicsRender = (props) => {
  const {
    isLoading,
    waitingMusicUrl,
    away00MessageUrl,
    away01MessageUrl,
    away00Playing,
    away01Playing,
    waitingPlaying } = props.state;
  const { playMusic } = props;

  if (isLoading) {
    return (<div className="col-lg-12 text-center"><Preloader /></div>);
  }

  return (
    <table className="table" id="musicTable">
      <tbody>
        <tr>
          <td>Message d'absence</td>
          <td className="musicActionsButtons">
            {away00MessageUrl && <React.Fragment>
              <audio style={musicStyle} id='player_away00' controls src={away00MessageUrl} type="audio/*"></audio>
              <button
                className="btn btn-warning btn-circle"
                onClick={(e) => { e.preventDefault(); return playMusic('player_away00'); }}
              >{away00Playing ? <i className="fa fa-stop"></i> : <i className="fa fa-play"></i>}</button>
            </React.Fragment>}
            <button
              className="btn btn-danger btn-circle"
              onClick={() => {
                return props.showModal({
                  title: "Envoyer un fichier audio",
                  component: MusicModal,
                  context: 'away00',
                  callback: () => props.componentDidMount()
                })
              }}
              ><i className="fa fa-edit"></i> </button>
            <button disabled={isEmpty(away00MessageUrl)} className="btn btn-info btn-circle"><i className="fa fa-download" download={true} onClick={() => {
              if (isEmpty(away00MessageUrl)) return;
              var xhr = new XMLHttpRequest();
              xhr.open('GET', encodeURI(away00MessageUrl), true);
              xhr.responseType = 'blob';
              xhr.onload = function(evt) {
                var blob = new Blob([xhr.response], {type: 'audio/mp3'});
                var objectUrl = URL.createObjectURL(blob);
                var element = document.createElement('a');
                element.setAttribute('href', objectUrl);
                element.setAttribute('download', 'away_message.mp3');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
              };

              var data = JSON.stringify({text: ""});
              xhr.send(data);
              return null;
            }
            }></i> </button>
          </td>
        </tr>
        <tr>
          <td>Message de non-disponibilité</td>
          <td className="musicActionsButtons">
            {away01MessageUrl &&
              <React.Fragment>
                <audio style={musicStyle} id='player_away01' controls src={away01MessageUrl} type="audio/*"></audio>
                <button
                  className="btn btn-warning btn-circle"
                  onClick={(e) => { e.preventDefault(); return playMusic('player_away01'); }}
                >{away01Playing ? <i className="fa fa-stop"></i> : <i className="fa fa-play"></i>}</button>
              </React.Fragment>}
            <button
              className="btn btn-danger btn-circle"
              onClick={() => {
                return props.showModal({
                  title: "Envoyer un fichier audio",
                  component: MusicModal,
                  context: 'away01',
                  callback: () => props.componentDidMount()
                })
              }}
              ><i className="fa fa-edit"></i> </button>
            <button disabled={isEmpty(away01MessageUrl)} className="btn btn-info btn-circle"><i className="fa fa-download" download={true} onClick={() => {
              if (isEmpty(away01MessageUrl)) return;
              var xhr = new XMLHttpRequest();
              xhr.open('GET', encodeURI(away01MessageUrl), true);
              xhr.responseType = 'blob';
              xhr.onload = function(evt) {
                var blob = new Blob([xhr.response], {type: 'audio/mp3'});
                var objectUrl = URL.createObjectURL(blob);
                var element = document.createElement('a');
                element.setAttribute('href', objectUrl);
                element.setAttribute('download', 'unavailable_message.mp3');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
              };

              var data = JSON.stringify({text: ""});
              xhr.send(data);
              return null;
            }
            }></i> </button>
          </td>
        </tr>
        <tr>
          <td>Musique d'attente</td>
          <td className="musicActionsButtons">
            {waitingMusicUrl && <React.Fragment>
              <audio style={musicStyle} id='player_waiting' controls src={waitingMusicUrl} type="audio/*"></audio>
              <button
                className="btn btn-warning btn-circle"
                onClick={(e) => { e.preventDefault(); return playMusic('player_waiting'); }}
              >{waitingPlaying ? <i className="fa fa-stop"></i> : <i className="fa fa-play"></i>}</button>
            </React.Fragment>}
            <button
              className="btn btn-danger btn-circle"
              onClick={() => {
                return props.showModal({
                  title: "Envoyer un fichier audio",
                  component: MusicModal,
                  context: 'waiting',
                  callback: () => props.componentDidMount()
                })
              }}
              ><i className="fa fa-edit"></i> </button>
            <button disabled={isEmpty(waitingMusicUrl)} className="btn btn-info btn-circle"><i className="fa fa-download" download={true} onClick={() => {
              if (isEmpty(waitingMusicUrl)) return;

              var xhr = new XMLHttpRequest();
              xhr.open('GET', encodeURI(waitingMusicUrl), true);
              xhr.responseType = 'blob';
              xhr.onload = function(evt) {
                var blob = new Blob([xhr.response], {type: 'audio/mp3'});
                var objectUrl = URL.createObjectURL(blob);
                var element = document.createElement('a');
                element.setAttribute('href', objectUrl);
                element.setAttribute('download', 'waiting_music.mp3');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
              };

              var data = JSON.stringify({text: ""});
              xhr.send(data);
              return null;
            }
            }></i> </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const musicStyle = {
  width: '1px',
  opacity: 0
}

const mapStateToProps = (state) => {
  return ({
    music: state.music,
    session: state.kertelSession
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Music);
