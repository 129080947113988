import React, { Component } from 'react';
import 'rc-calendar/assets/index.css';
import FullCalendar from 'rc-calendar/lib/FullCalendar';
import 'rc-select/assets/index.css';
import Select from 'rc-select';
import frFR from 'rc-calendar/lib/locale/fr_FR';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getMonthScheduler,
  getSchedulerWeeks,
  addDayScheduler,
  addWeekScheduler,
  deleteDayScheduler,
  getSchedulerDays,
  toggleScheduler as toggleSchedulerApi } from 'components/commons/api/scheduler.js';
import isUndefined from 'lodash/isUndefined';
import { toggleScheduler } from 'actions/schedulerActions.js';

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      year: new Date().getFullYear(),
      isLoading: true,
      month: new Date().getMonth() + 1,
      type: 'date',
      days: [],
      weeks: [],
      cellOverId: null,
      dragType: 'day',
      dragColor: '#FFFFFF'
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.getEnglishDay = this.getEnglishDay.bind(this);
    this.handleDeleteDayScheduler = this.handleDeleteDayScheduler.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  async handleSwitchChange() {
    try {
      await toggleSchedulerApi(this.props.session.user_id, !this.props.scheduler.active);
      return this.props.toggleScheduler();
    } catch (e) {
      console.log(e);
    }
  }

  onSelect(value) {
    //console.log('select', value.format('YYYY-MM-DD'));
  }

  getEnglishDay(frenchDay) {
    switch (frenchDay.toLowerCase()) {
      case 'lundi': return 'monday';
      case 'mardi': return 'tuesday';
      case 'mercredi': return 'wednesday';
      case 'jeudi': return 'thursday';
      case 'vendredi': return 'friday';
      case 'samedi': return 'saturday';
      case 'dimanche': return 'sunday';
      default: return frenchDay;
    }
  }

  async handleDeleteDayScheduler({year, numWeek, day}) {
    try {
      await deleteDayScheduler({
        user_id: this.props.session.user_id,
        year: year,
        num_week: numWeek,
        day: day
      });
      this.componentDidMount();
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    let allDays = [];
    let weeks   = [];
    let days    = [];

    try {
      this.setState({ isLoading: true });
      const month = await getMonthScheduler(this.props.session.user_id, this.state.month, this.state.year);
      weeks       = await getSchedulerWeeks(this.props.session.user_id);
      days        = await getSchedulerDays(this.props.session.user_id);

      Object.keys(month.data).map((week) => {
        Object.keys(month.data[week]).map((day) => {
          return allDays.push({
            scheduleDate: `${day}`,
            label: `${month.data[week][day]['label']}`,
            dayId: `${month.data[week][day]['id']}`,
            color: `${month.data[week][day]['color']}`,
          });
        });

        return allDays;
      });
    } catch (e) {

    } finally {
      this.setState({ isLoading: false, data: allDays, weeks: weeks.data, days: days.data });
    }
  }

  onDragEnd = (event) => {
    console.log('Drag end : ', event.target.id);
  }

  onDragStart = (event) => {
    const dataSet = event.target.dataset;
    this.setState({
      dragType: dataSet.type,
      dragColor: dataSet.color,
      dragDayId: dataSet.refid,
    });
    console.log(this.state);
    event.dataTransfer.setData("text", event.target.id);
    event.dataTransfer.setData("content", event.target.textContent);
    event.dataTransfer.effectAllowed = "copy";
  }

  onDrop = async (event) => {
    event.preventDefault();
    if ( event.target.className === "droptarget" ) {

      const cell = document.getElementById(event.target.id);
      const isDayCellEmpty = isUndefined((cell.getElementsByClassName('dayCell'))[0]);
      const isWeekCellEmpty = isUndefined((cell.getElementsByClassName('weekCell'))[0]);

      if (!isDayCellEmpty) {
        //cell.getElementsByClassName('dayCell')[0].remove();
      }

      if (!isWeekCellEmpty) {
        cell.getElementsByClassName('weekCell')[0].remove();
      }

      var newDiv = document.createElement("p");
      newDiv.className = `badge btn col-12 text-white text-left ${this.state.dragType === 'day' ? 'dayCell' : 'weekCell'}`;
      newDiv.style.background = this.state.dragColor;
      var newContent = document.createTextNode(event.dataTransfer.getData("content"));
      newDiv.appendChild(newContent);

      if (this.state.dragType === 'day') {
        try {
          const day = {
            user_id: this.props.session.user_id,
            year: event.target.dataset.year,
            num_week: event.target.dataset.week,
            day: this.getEnglishDay(event.target.dataset.dayname),
            ref_id: this.state.dragDayId
          };

          event.target.style.background = '#FFFFFF';
          //event.target.appendChild(newDiv);
          event.stopPropagation();
          await addDayScheduler(day);
          this.componentDidMount();
        } catch (e) {
          console.log(e);
        }
      }

      if (this.state.dragType === 'week') {
        try {
          const week = {
            user_id: this.props.session.user_id,
            year: event.target.dataset.year,
            num_week: event.target.dataset.week,
            ref_id: this.state.dragDayId
          };

          await addWeekScheduler(week);
          this.componentDidMount();
        } catch (e) {
          console.log(e);
        }
      }
      
      return false;
    }
  }

  onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  }

  onDragEnter = (event) => {
    if (event.target.className === 'droptarget' && this.state.dragType === 'week') {
      const weekId = event.target.dataset.week;
      let weekCells = document.querySelectorAll(`[data-week='${weekId}']`);
      weekCells.forEach((cell) => {
        cell.style.background = '#EEEEEE';
      });

      return event.dataTransfer.dropEffect = "copy"
    }

    if (event.target.className === 'droptarget' && this.state.dragType === 'day') {
      event.target.style.background = '#EEEEEE';
      return event.dataTransfer.dropEffect = "copy";
    }
  }

  onDragLeave = (event) => {
    event.preventDefault();
    if (event.target.className === 'droptarget') {
      const weekId = event.target.dataset.week;
      let weekCells = document.querySelectorAll(`[data-week='${weekId}']`);
      weekCells.forEach((cell) => {
        cell.style.background = '#FFFFFF';
      });
    }
  }

  render() {
    const now = moment();
    const defaultCalendarValue = now.clone();
    defaultCalendarValue.add(-1, 'month');

    return (
      <div className="row">
        <div className='col-lg-2 mt-4'>
          <div className="row">
            <div className="col-lg-12 ml-3">{!this.props.scheduler.active && <div className='alert alert-danger text-center'>La planification est désactivée</div>}</div>
            <div className='col-lg-9 pl-5'>
              { this.props.scheduler.active ? 'Désactiver' : 'Activer'}
            </div>
            <div className='col-lg-3'>
              <div className="material-switch pull-right">
                <input
                  className="form-control"
                  name="shared"
                  type="checkbox"
                  checked={this.props.scheduler.active}
                />
                <label onClick={this.handleSwitchChange} htmlFor="someSwitchOptionPrimary" className="label-primary"></label>
              </div>
            </div>
          </div>
          <hr />
          <ul className="list-group ml-4">
            <WeeksRender
              days={this.state.days}
              weeks={this.state.weeks}
              onDragStart={this.onDragStart}
              onDragEnter={this.onDragEnter}
              onDragEnd={this.onDragEnd}
              onDrop={this.onDrop}
              onDragOver={this.onDragOver}
              isLoading={this.state.isLoading}
            />
          </ul>
        </div>
        <div className="col-lg-10">
          <div className="card">
            <div className="card-body">
              <FullCalendar
                onChange={async (date) => {
                  await this.setState({ month: date.format('M'), year: date.format('Y'), data: [] })
                  this.componentDidMount();
                }}
                Select={Select}
                fullscreen
                defaultValue={now}
                onSelect={this.onSelect}
                type={'date'}
                locale={frFR}
                dateCellContentRender={(date) => <DaysRender
                  date={date}
                  data={this.state.data}
                  onDrop={this.onDrop}
                  onDragEnter={this.onDragEnter}
                  onDragLeave={this.onDragLeave}
                  onDragOver={this.onDragOver}
                  handleDeleteDayScheduler={this.handleDeleteDayScheduler}
                  getEnglishDay={this.getEnglishDay}
                />}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DaysRender = (props) => {
  let found = false;
  const { date, onDrop, onDragEnter, onDragLeave, onDragOver, data, handleDeleteDayScheduler, getEnglishDay } = props;
  return (
    <div
      className='droptarget'
      id={`cell_${date.format('x')}`}
      data-week={moment(date).isoWeek()}
      data-year={moment(date).year()}
      data-dayname={date.format('dddd')}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      >
      <h3 className='text-center calendarCell'>{date.format('D')}</h3>
      {(data).map((day, index) => {
        if (found) {
          return null;
        }

        const day1 = day.scheduleDate;
        const day2 = date.format('YYYY-MM-DD');

        if (day1 === day2) {
          found = true;

          return (
            <p
              key={index}
              className='badge btn col-12 text-white text-left dayCell' style={{ background: `#${day.color}` }}
            >{day.label}
            <button
              type="button"
              className="close calendarDayClose"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => handleDeleteDayScheduler({
                year: moment(date).year(),
                numWeek: moment(date).isoWeek(),
                day: getEnglishDay(date.format('dddd'))
              })}
              >
              <span aria-hidden="true">&times;</span>
            </button></p>
          )
        }

        return null;
      })}
    </div>
  )
}

const WeeksRender = (props) => {
  if (props.isLoading) {
    return (<h6>Chargement...</h6>)
  }

  if (isUndefined(props.days) || isUndefined(props.weeks)) {
    return null;
  }

  return (
    <React.Fragment>
      <h5>Mes semaines</h5>
      <ul className="list-group">
        {Object.keys(props.weeks).map((week, index) => {
          return (
            <li
              id={`week_drag_${index}`}
              draggable="true"
              data-type='week'
              data-refid={props.weeks[week].week_id}
              onDragStart={props.onDragStart}
              onDragEnd={props.onDragEnd}
              onDrop={props.onDrop}
              onDragOver={props.onDragOver}
              className="droptarget"
              style={{ listStyle: 'none' }}
              key={index}>
              <span className='badge btn col-12 text-left badge-light'>
          {props.weeks[week].label} {props.weeks[week].default && <span className='badge badge-pill badge-info'>Par défaut</span>}
              </span><br />
          </li>
          )
        })}
      </ul>
      <hr />
      <h5>Mes journées</h5>
      <ul className="list-group">
        {Object.keys(props.days).map((day, index) => {
          return (
            <li
              id={`drag_${index}`}
              draggable="true"
              data-type='day'
              data-refid={props.days[day].day_id}
              data-color={`#${props.days[day].color}`}
              onDragStart={props.onDragStart}
              onDragEnd={props.onDragEnd}
              onDrop={props.onDrop}
              onDragOver={props.onDragOver}
              className="droptarget mb-1"
              style={{ listStyle: 'none' }}
              key={index}>
              <span
                className='badge btn col-12 text-white text-left'
                style={{ background: `#${props.days[day].color}` }}>{props.days[day].label}
              </span>
            </li>
            )
          })}
      </ul>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return ({
    session: state.kertelSession.datas.data,
    scheduler: state.scheduler
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    toggleScheduler
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
