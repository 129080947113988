import React, {Component, useEffect, useState} from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchUsers} from 'components/commons/helpers/admin/userHelper.js';
import {getConfigProfileCalls} from 'components/commons/api/admin/profileCalls';
import {fetchProfilesCalls, createProfileCalls} from 'components/commons/helpers/admin/profileCallsHelper.js';
import {stopEditingProfileCalls} from 'actions/admin/profileCallsActions.js';
import {notifySuccess} from 'components/commons/Toasts.js';
import EditProfileCallsInfos from './EditProfileCallsInfos.js';
import EditProfileCallsMembers from './EditProfileCallsMembers.js';
import EditProfileCallsZones from './EditProfileCallsZones.js';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

function CreateProfileCalls ({ profileCalls, users, company }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [zones, setZones] = useState({});
  const [profileCallsState, setProfileCallsState] = useState({
    name: '',
    members: [],
    updateMembers: {
      add: [],
      delete: []
    },
    calls_off:{
      international:[],
      local:[]
    },
    calls_on:{
      international:[],
      local:[]
    }
  });

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;

    setProfileCallsState(prevState => ({
        ...prevState,
        [name]: value
    }));
  }

  function handleOptionsNumbers(type, number) {
    if (type === 'addLongOn') profileCallsState.calls_on.international.push(number.long);
    else if (type === 'addLongOff') profileCallsState.calls_off.international.push(number.long);
    else if (type === 'addShortOn') profileCallsState.calls_on.local.push(number.short);
    else if (type === 'addShortOff') profileCallsState.calls_off.local.push(number.short);

    else if (type === 'delLongOn') {
      let e164 = profileCallsState.calls_on.international;
      forEach(number, function(value, key) {
        var index = e164.indexOf(value);
        if (index > -1) e164.splice(index, 1);
      });

      setProfileCallsState(prevState => ({
        ...prevState,
        calls_on: {
          ...prevState.calls_on,
          international: e164
        }
      }));
    }

    else if (type === 'delLongOff') {
      let e164 = profileCallsState.calls_off.international;
      forEach(number, function(value, key) {
      var index = e164.indexOf(value);
      if (index > -1) e164.splice(index, 1);
      })

      setProfileCallsState(prevState => ({
        ...prevState,
        calls_off: {
          ...prevState.calls_off,
          international: e164
        }
      }));
    }

    else if (type === 'delShortOff') {
      let s164 = profileCallsState.calls_off.local;
      forEach(number, function(value, key) {
      var index = s164.indexOf(value);
      if (index > -1) s164.splice(index, 1);
      })

      setProfileCallsState(prevState => ({
        ...prevState,
        calls_off: {
          ...prevState.calls_off,
          local: s164
        }
      }));
    }

    else if (type === 'delShortOn') {
      let s164 = profileCallsState.calls_on.local;
      forEach(number, function(value, key) {
      var index = s164.indexOf(value);
      if (index > -1) s164.splice(index, 1);
      })

      setProfileCallsState(prevState => ({
        ...prevState,
        calls_on: {
          ...prevState.calls_on,
          local: s164
        }
      }));
    }
  }

  function handleMembers(members) {
    setProfileCallsState(prevState => ({
      ...prevState,
      updateMembers: members
    }));
  }

  function format_number(numbers) {
    if (typeof numbers === "object") {
      forEach(numbers, function(value, index, numbers) {
        var first = value.substring(0, 2);
        if (first === "+3") {
          //.match(/.{1,2}/g).join(' ')
          numbers[index] = ("0" + value.slice(3, value.length));
        } else if (first === "33") {
          numbers[index] = ("0" + value.slice(2, value.length));
        }
      })
      return numbers.join(', ');
    } else {
      var first = numbers.substring(0, 2);
      if (first === "+3") {
        //.match(/.{1,2}/g).join(' ')
        numbers = ("0" + numbers.slice(3, numbers.length));
      } else if (first === "33") {
        numbers = ("0" + numbers.slice(2, numbers.length));
      }
      return numbers;
    }
  }

  function handleAllChecked (event) {
	  let prefixes = zones;
	
    forEach(prefixes[event.target.getAttribute("datazone")], function(element, key) {
      prefixes[event.target.getAttribute("datazone")][key]['isChecked'] = event.target.checked;
    })
	
    setZones(prefixes);
  }

  function handleCheckChieldElement(event) {
	
	  let prefixes = zones;
	
	  prefixes[event.target.getAttribute("datazone")][event.target.getAttribute("datalocation")]['isChecked'] = event.target.checked;
	
	  setZones(prefixes);
  }
  
  async function init() {
    fetchUsers();
    try {
      await getConfigProfileCalls().then((result) => {
        if (!isUndefined(result.data)) {
          let prefixes = {};
          
          if (!isUndefined(result.data.prefix_international)) {
            let prefix = result.data.prefix_international;
            forEach(prefix, function(value, key) {
              if (isUndefined(prefixes[value.group])) prefixes[value.group] = {};
              
              if (isUndefined(prefixes[value.group][value.name])) {
                prefixes[value.group][value.name] = {};
                prefixes[value.group][value.name]['id'] = key;
                prefixes[value.group][value.name]['isChecked'] = false;
              }
              else {
                prefixes[value.group][value.name]['id'] += ", " + key;
              }
            })
          }
          
          if (!isUndefined(result.data.prefix_local)) {
            let prefix = result.data.prefix_local;
            forEach(prefix, function(value, key) {
              if (isUndefined(prefixes[value.group])) prefixes[value.group] = {};
              
              if (isUndefined(prefixes[value.group][value.name])) {
                prefixes[value.group][value.name] = {};
                prefixes[value.group][value.name]['id'] = key;
                prefixes[value.group][value.name]['isChecked'] = false;
              }
              else {
                prefixes[value.group][value.name]['id'] += ", " + key;
              }
            })
          }
          
          setZones(prefixes);
        }
      })
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }    
  }

  useEffect(function() {
    init();
  }, [])

  async function createProfileCalls() {
    try {
      let info = profileCallsState;
      
	  if (isEmpty(info.name)) {
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: "Le nom du profil d'appels est invalide.",
		  confirmButtonColor: 'LightSeaGreen',
		})
	  }
      
      forEach(zones, function(elements, key1) {
        forEach(elements, function(details, key2) {
          if (details.isChecked) {
            if (isUndefined(info.calls_on.prefix_international)) info["calls_on"]["prefix_international"] = [];
            info.calls_on.prefix_international.push(details.id);
          }
        })
      })
      
      await createProfileCalls(info).then((result) => {
        notifySuccess("Profil d'appel ajouté avec succès !");
        fetchProfilesCalls();
        return navigate('/admin/settings');
      })
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }

  function checkUserData() {
    var dataBefore = users.datas;
    if (profileCalls.data != null && dataBefore != null) {
      if(profileCalls.data.members != null){
        profileCalls.data.members.forEach(function(element) {
          dataBefore.forEach(function(element2) {
            if (element === element2.user_id && profileCallsState.updateMembers.delete.indexOf(element) === -1) {
              element2.tableData = {
                checked: true
              };
            }
          });
        });
      }
    }
  }

    if (isEmpty(profileCallsState))
      return navigate('/admin/groupinterception');

      checkUserData()

    let numbers = {
      'long': [],
      'short': [],
      'lenghShort': company.short_length
    };

    forEach(company.long, function(value, number) {
      if (isEmpty(value) && !isEmpty(number) )
        numbers.long.push(format_number(number));
      });

    forEach(company.short, function(value, number) {
      numbers.short.push(number);
    });

    return (<div className="col-lg-12">
      <div className="card">
        <div className="card-body p-b-0">
          <Breadcrumb routes={["Paramètres", "Création d'un profil d'appels"]} url="/admin/settings" title="Création d'un profil d'appels"/>
          <ul className="nav nav-tabs customtab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#infos" role="tab" onClick={fetchUsers}>
                <span className="hidden-sm-up">
                  <i className="ti-home"></i>
                </span>
                <span className="hidden-xs-down">
                  Information générales
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                <span className="hidden-sm-up">
                  <i className="ti-home"></i>
                </span>
                <span className="hidden-xs-down">
                  Zones
                </span>
              </a>
            </li>
            {/*
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                <span className="hidden-sm-up">
                  <i className="ti-home"></i>
                </span>
                <span className="hidden-xs-down">
                  Gestion des membres
                </span>
              </a>
            </li>*/}
          </ul>
          <div className="tab-content">
            <div className="tab-pane active p-20" role="tabpanel" id="infos">
              <EditProfileCallsInfos profileCalls={profileCallsState} company={company} handleOptionsNumbers={handleOptionsNumbers} handleOptions={handleOptions} numbers={numbers}/>
            </div>
            <div className="tab-pane p-20" role="tabpanel" id="users">
              <EditProfileCallsZones users={users} company={company} profileCalls={profileCallsState} zones={zones} handleMembers={handleMembers} handleAllChecked={handleAllChecked} handleCheckChieldElement={handleCheckChieldElement}/>
            </div>
            <div className="tab-pane p-20" role="tabpanel" id="users">
              <EditProfileCallsMembers users={users} company={company} profileCalls={profileCallsState} handleMembers={handleMembers}/>
            </div>
            <div className="form-actions m-4">
              <div className="row">
                <div className="col-md-12 ">
                  <span className="float-right">
                    <button type="button" onClick={() => {
                        return navigate('/admin/settings');
                      }} className="btn btn-inverse mr-2">Annuler</button>
                    <button onClick={createProfileCalls} className="btn btn-success">Enregistrer</button>
                  </span>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }

const mapStateToProps = (state) => {
  return {users: state.users, company: state.company, profileCalls: state.profileCalls}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingProfileCalls
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfileCalls);
