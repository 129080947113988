import { POST, PUT, PATCH, DELETE } from './../api';

export const getSviMenus = (menuId = null) => {
  return POST('/svi/menus', {
    company: localStorage.getItem('company'),
    menu_id: menuId
  });
}

export const createSviMenuInformation = (informations) => {
  return PUT('/svi/menus', {
    company: localStorage.getItem('company'),
    menu_id: informations.menu_id,
    data: informations
  });
}

export const updateSviMenuInformation = (menuId, informations) => {
  return PATCH('/svi/menus', {
    company: localStorage.getItem('company'),
    menu_id: menuId,
    data: informations
  });
}

export const deleteSviMenuInformation = (menuId) => {
  return DELETE('/svi/menus', {
    company: localStorage.getItem('company'),
    menu_id: menuId
  });
}

export const getSviConfig = (menuId) => {
  return POST('/svi/configuration', {
    company: localStorage.getItem('company'),
    menu_id: menuId
  });
}

export const setSviConfig = (menuId, informations) => {
  return PATCH('/svi/configuration', {
    company: localStorage.getItem('company'),
    menu_id: menuId,
    data: informations
  });
}

export const getSviFiles = (menuId) => {
  const session = JSON.parse(localStorage.getItem('persist:root')).session;
  return POST('/svi/files', {
    company: localStorage.getItem('company'),
    menu_id: menuId,
    data: {
      domaine: JSON.parse(session).apiUrl,
      token: localStorage.getItem('_k')
    }
  });
}

export const deleteSviFileInformation = (menuId, name, type) => {
  return DELETE('/svifiles', {
    menu_id: menuId,
    name: name,
    type: type,
    token: localStorage.getItem('_k')
  });
}
