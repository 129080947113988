import React, {Component, useEffect, useState} from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import EditWaitingQueueInfos from './EditWaitingQueueInfos.js';
import { fetchWaitingQueue, updateWaitingQueue } from 'components/commons/helpers/admin/waitingQueueHelper.js';
import Preloader from 'components/commons/Preloader.js';
import forEach from 'lodash/forEach';
import isUndefined from 'lodash/isUndefined';
import { notifySuccess } from 'components/commons/Toasts.js';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

function EditWaitingQueue ({ users, waitingQueue }) {

  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(true);
  const [waitingQueues, setWaitingQueues] = useState({
		name: "",
		func: "2",
		e164: "",
		s164: "",
		max_size: 10,
	  });

  function format_number(numbers) {
    forEach(numbers, function(value, index, numbers) {
        var first = value.substring(0, 2);
            if (first === "+3") {
              numbers[index] = ("0" + value.slice(3, value.length));
            } else if (first === "33") {
              numbers[index] = ("0" + value.slice(2, value.length));
        }
      })

    if (typeof numbers === "object") {
      return numbers.join(', ');
    } else {
      return numbers;
    }
  }
  
  function find_user_element(id,element){
    var users = users.datas;
    if(users != null){
      for (var user in users) {
        if(users[user].user_id === id){
          if(users[user][element] != null){
            return users[user][element];
          } else {
            return null;
          }
        }
      }
    }
  }

  async function init() {
    if (waitingQueue.editing.isEditing) {
      try {
        await fetchWaitingQueue(waitingQueue.editing.queueId).then((result) => {

          setWaitingQueues (
            ...waitingQueue,
            {
              name: waitingQueue.data.name,
              func: parseInt(waitingQueue.data.func) !== 1 ? 2 : 1,
              e164: (find_user_element(waitingQueue.data.owner,'e164')!= null) ? format_number(find_user_element(waitingQueue.data.owner,'e164')) : find_user_element(waitingQueue.data.owner,'e164'),
              s164: (find_user_element(waitingQueue.data.owner,'s164')!= null) ? find_user_element(waitingQueue.data.owner,'s164').join(', ') : "",
              max_size: waitingQueue.data.max_size,
            }
          );
        })
      } catch (e) {
        console.log(e);
      } finally {
	    setIsloading(false);
	  }
    }
  }
  
  useEffect(function() {
    init();
  }, []);

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setWaitingQueues (
      ...waitingQueue,
      {
        [name]: value
      }
    );
  }
  
  async function editWaitingQueue() {
    try {
	  let info = {};
	  if (!isUndefined(waitingQueues.name)) info.name = waitingQueues.name;
	  if (!isUndefined(waitingQueues.func)) info.func = waitingQueues.func;
	  
	  if (!isUndefined(waitingQueues.max_size)) {
		info.max_size = waitingQueue.max_size;
		if (info.max_size < 1 || info.max_size === "e") {
		  return swal({
			type: 'error',
			icon: "error",
			title: 'Oops...',
			text: "Le nombre max d'appel est invalide",
			confirmButtonColor: 'LightSeaGreen',
		  })
		}
	  }
	  
      await updateWaitingQueue(waitingQueue.editing.queueId, info).then((result) => {
        notifySuccess("File d'attente mise à jour avec succès !");
        waitingQueue.editing.isEditing = false;
        return navigate('/admin/waitingqueue');
      })
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }
	
  if (isLoading) return (<div className="col-lg-12 text-center"><Preloader/></div>);
	
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["File d'attente", "Édition de la file d'attente"]} url="/admin/waitingqueue" title="Édition de la file d'attente"/>
              <ul className="nav nav-tabs customtab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
                    <span className="hidden-sm-up">
                      <i className="ti-home"></i>
                    </span>
                    <span className="hidden-xs-down">
                      Informations
                    </span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active p-20" role="tabpanel" id="infos">
                  <EditWaitingQueueInfos
					users={users}
                    options={waitingQueues}
                    handleOptions={handleOptions}
                  />
                </div>
				<div className="form-actions m-4">
				  <div className="row">
					<div className="col-md-12 ">
					  <span className="float-right">
						<button type="button" onClick={() => {
							return navigate('/admin/waitingqueue');
						  }} className="btn btn-inverse mr-2">
						  Annuler
						</button>
						<button onClick={editWaitingQueue} type="submit" className="btn btn-success">Enregistrer</button>
					  </span>
				  </div>
				</div>
			  </div>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = (state) => {
  return {users: state.users, company: state.company, contacts:state.contacts.company.datas, waitingQueue: state.waitingQueue, session: state.kertelSession.datas.data}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWaitingQueue);
