import { PURGE, REHYDRATE } from 'redux-persist';

const initialState = {
  requesting: false,
  ssoRequesting: false,
  googleRequesting: false,
  facebookRequesting: false,
  datas: null,
  admin: false,
  apiUrl: '',
  user: {},
  godMode: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SESSION':
      return {
        ...state,
        session: action.payload
      }

    case 'START_LOGIN_REQUESTING':
      return {
        ...state,
        requesting: true
      }

    case 'STOP_LOGIN_REQUESTING':
      return {
        ...state,
        requesting: false
      }

    case 'START_SSO_LOGIN_REQUESTING':
      return {
        ...state,
        ssoRequesting: true
      }

    case 'STOP_SSO_LOGIN_REQUESTING':
      return {
        ...state,
        ssoRequesting: false
      }
      
    case 'CREATE_SESSION':
      localStorage.setItem('_k', action.payload.token_auth);
      localStorage.setItem('_mt', action.payload.token_min);
      return {
        ...state,
        datas: action.payload.decoded,
        admin: action.payload.admin
      }

    case 'CLEAR_SESSION':
      localStorage.removeItem('_k');
      return {
        ...state,
        requesting: false,
        datas: null,
        admin: false,
        user: {}
      }

    case 'CREATE_USER':
      return {
        ...state,
        user: action.payload
      }

    case 'FETCH_API_URL':
      return {
        ...state,
        apiUrl: action.payload
      }
      
    case 'FETCH_IM_URL':
      return {
        ...state,
        imUrl: action.payload
      }

    case 'GOD_MODE':
      return {
        ...state,
        godMode: action.payload
      }  

      case 'TOGGLE_HIDE_NUMBER':
        return {
          ...state,
          user: {
            ...state.user,
            clip_clir: !state.user.clip_clir
          }
        }  

      case REHYDRATE:    
        return state;
      case PURGE:
        return {};  

    default:
      return state
  }
}

export default authReducer;
