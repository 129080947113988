import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Preloader from 'components/commons/Preloader.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import swal from 'sweetalert';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import { post } from 'axios';
import { notifySuccess, notifyWarning } from 'components/commons/Toasts.js';
import { useNavigate } from 'react-router';

function CreateSviFiles ({ svi, url }) {

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [sviFile, setSviFile] = useState({
		"type": 'file',
        "name": '',
		fileToSend : []
	})

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

	setSviFile(prevState => ({
		...prevState,
		[name]: value
	}));
  }

  function getElementsValue(type) {
	
	if (type === "waitingMusic") {
	  return {name:"type", value: "waitingMusic", label: "Type : Musique d'attente"};
	}
	
	return {name:"type", value: "file", label: "Type : Fichier"};
  }
  
  function handleOptionsSelect(data) {

	setSviFile(prevState => ({
		...prevState,
		[data.name]: data.value
	}));
  }
  
  async function addSviFile(){
	let info = {
	  menu_id: svi.configuring.menuId,
	  type: 'file',
	  name: '',
	  file: []
	};
	
	if (!isUndefined(sviFile.type)) info.type = sviFile.type;
	if (!isUndefined(sviFile.name)) info.name = sviFile.name;
	if (!isUndefined(sviFile.fileToSend)) info.file = sviFile.fileToSend[0];
	
	var format = /[ `!@#$%^&*()+=[]{};':"\\|,<>?~]/;
	
	if (isEmpty(info.menu_id)|| info.menu_id.length > 4 ) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: "L'identifiant du menu est invalide.",
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	else if (isEmpty(info.file)) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: "Le choix d'un fichier est requis.",
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	else if (!isEmpty(info.name) && format.test(info.name)) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: "Le nom du fichier contient des caractères non permis.",
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	
	let formData = new FormData();
	formData.append('menu_id', info.menu_id);
	formData.append('type', info.type);
	formData.append('name', info.name);
	formData.append('file', info.file);
	
	const token = localStorage.getItem('_k');
	const config = {
	  headers: {
		'content-type': 'multipart/form-data',
		'auth-token': `${token}`
	  }
	};
	
	return await post(url + '/svifiles', formData, config).then((result) => {
		notifySuccess('Fichier sauvegardé avec succès !');
		return navigate('/admin/svi/files');
	  })
	  .catch((err) => {
		let { response } = err;
		response = response || {};
		
		let message = "Une erreur est survenue.";
		if (!isUndefined(response.data)) {
		  if (!isUndefined(response.data.validation_messages)) {
			const validationMessage = Object.values(response.data.validation_messages);
			const errDetails = Object.values(validationMessage[0]);
			message = errDetails[0];
		  } else {
			message = response.data.detail;
		  }
		}
		
		notifyWarning(message);
	  });
  }

  useEffect(function() {
	setIsLoading(false);
  }, []);
	
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
    if (isEmpty(sviFile)) return navigate('/admin/svi/files');
	
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={[
              "Fichiers SVI",
              (<span>Création des fichiers du menu svi N° : 
                <span className="btn btn-warning react-bs-table-del-btn" style={{
                  padding: "2px",
                  marginLeft: "5px"
                }}>{svi.configuring.menuId}</span>
              </span>)
            ]} url="/admin/svi/files" title="Création des fichiers svi" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Information générales
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
				<Container component="main">
				  <Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
					  <Dropzone
						accept={"audio/*,video/ogg"}
						onDrop={acceptedFiles => {
						  if (!isEmpty(acceptedFiles) && acceptedFiles.length === 1) {

							setSviFile(prevState => ({
								...prevState,
								fileToSend: acceptedFiles
							}))
						  }
						  
						  return null;
						}}
					  >
						{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, isFileTooLarge, acceptedFiles}) => (
						  <section>
							<div className={`dropzone ${isDragReject ? 'wrongFormat' : ''} ${isDragActive && !isDragReject ? 'rightFormat' : ''}`} {...getRootProps()}>
							  <input {...getInputProps()} />
							  {(!isDragActive && acceptedFiles.length === 0) && 'Cliquez ou glissez le fichier'}
							  {(!isDragActive && acceptedFiles.length > 0) && 'Votre fichier est prêt à l\'envoi'}
							  {isDragActive && !isDragReject && "Vous pouvez relâcher"}
							  {isDragReject && "Type de fichier non accepté"}
							  {isFileTooLarge && (
								  <div className="text-danger mt-2">
									  Le fichier est trop lourd
								  </div>
							  )}
							  <ul className="list-group mt-2">
								  {acceptedFiles.length > 0 && acceptedFiles.map((acceptedFile, index) => (
									  <li key={index} className="list-group-item list-group-item-success">
										  {acceptedFile.name}
									  </li>
								  ))}
							  </ul>
							</div>
						  </section>
						)}
					  </Dropzone>
					</Grid>
					<Grid item xs={12} sm={2}>
					  <TextField
						name="name"
						label="Nom du fichier"
						fullWidth
						value={sviFile.name}
						onChange={handleOptions}
					  />
					</Grid>
					<Grid item xs={12} sm={1} />
					<Grid item xs={12} sm={3} style={{marginTop: "12px"}}>
					  <Select
						name="type"
						options={[
						  {name:"type", value: "file", label: "Type : Fichier"},
						  {name:"type", value: "waitingMusic", label: "Type : Musique d'attente"},
						]}
						value={getElementsValue(sviFile.type)}
						onChange={handleOptionsSelect}
						/>
					</Grid>
					<Grid item xs={12} sm={6} />
				  </Grid>
				</Container>
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/svi/files');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={addSviFile} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => {
  return {
    svi: state.svi,
	url: state.kertelSession.apiUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSviFiles);
