import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import Preloader from 'components/commons/Preloader.js';
import swal from 'sweetalert';
import { deleteSviFile } from 'components/commons/helpers/admin/sviHelper.js';

class SviFilesTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {

    var player = window.$('.player');

    /**
      * Have to add media event listeners here.
      *
    */
    player.on('play', (e) => {
      e.preventDefault();
      this.playLocation();
    });

    player.on('pause', (e) => {
      e.preventDefault();
      this.pause();
    });

    player.on('ended', (e) => {
      e.preventDefault();
      this.ended();
    });

    window.$(document).on('keydown', (e) => {
      // Move currentTime forward and backward via arrow keys and play/pause via spacebar.
      if (e.keyCode === 39) {
        this.state.player.currentTime += 1;
      } else if (e.keyCode === 37) {
        this.state.player.currentTime -= 1;
      } else if (e.keyCode === 32 && this.state.player.paused === true) {
        e.preventDefault();
        this.state.player.play();
      }  else if (e.keyCode === 32 && this.state.player.paused === false) {
        e.preventDefault();
        this.state.player.pause()
      }
    });

    player.on('wheel', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.originalEvent.wheelDelta > 0) {
        this.state.player.currentTime += 1;
      } else {
        this.state.player.currentTime -= 1;
      }
    });
  }

  getInitialState() {
    return {
      player: false
    }
  }

  componentWillUnmount() {
    var player = window.$('#' + this.props.id);
    player.off('play');
    player.off('pause');
    window.$(document).off('keydown')
    player.off('wheel')
  }

  getPlaybackTime(time) {
    var minutes = Math.floor(time / 60);
    if (minutes > 59) {
      minutes = Math.floor(time / 60) - 60;
    }

    var seconds = Math.round(time - minutes * 60);
    if (seconds > 3599) {
      seconds = Math.round(time - minutes * 60) - 3600;
    }

    return time;
  }

  playLocation() {
    this.setState({player: window.$('.player')[0]}, function() {
      var playbackTime = this.getPlaybackTime(this.state.player.currentTime);
      playbackTime = localStorage.getItem('codepenReactPlayer');

      if (playbackTime !== null) {
        this.setState(prevState => {
          return {
            ...prevState,
            player: {
              ...prevState.player,
              currentTime: playbackTime
            }
          }
        })
      }
      
      this.state.player.play();
    })
  }

  pause() {
    var playbackTime = this.getPlaybackTime(this.state.player.currentTime);
    localStorage.setItem('codepenReactPlayer', playbackTime);
  }

  ended() {
    // Set playback_time to 0.
    var playbackTime = 0;
    localStorage.setItem('codepenReactPlayer', playbackTime);
  }
  
  render() {

    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MaterialTable
        columns={[
          { title: 'Nom du fichier', field: 'name'},
          { title: 'Type', field: 'type'},
          { title: 'Dernière modification', field: 'lastupdate'},
          { title: 'Taille', field: 'size',
            render: rowData => {
              var sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To'];
              if (rowData.size === 0) return '0 Octet';
              var i = parseInt(Math.floor(Math.log(rowData.size) / Math.log(1024)));
              return Math.round(rowData.size / Math.pow(1024, i), 2) + ' ' + sizes[i];
            }
          },
          { title: 'Lecture', field: 'file', filtering: false,
            render: rowData => <audio id='player_records' controls src={rowData.file + '&token=' + localStorage.getItem('_k')} type="audio/*" style={{backgroundColor: '#f1f3f4', border: "1px solid #ccc"}}></audio>
          },
        ]}
        data={this.props.files}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Fichier par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          search: true,
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        actions={[
          {
            icon: 'save',
            tooltip: 'Télécharger',
            onClick: (event, rowData) => {
              fetch(rowData.file + '&token=' + localStorage.getItem('_k'))
                .then(resp => resp.blob())
                .then(blob => {
                  if (blob.type !== 'audio/wave') {
                    return swal({
                      type: 'error',
                      icon: "error",
                      title: 'Oops...',
                      text: "Une erreur est survenue lors du téléchargement du fichier.",
                      confirmButtonColor: 'LightSeaGreen',
                    })
                  }
                  
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  // the filename you want
                  a.download = rowData.name + '.wav';
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
              })
              .catch(() => 
                swal({
                  type: 'error',
                  icon: "error",
                  title: 'Oops...',
                  text: "Une erreur est survenue lors du téléchargement du fichier.",
                  confirmButtonColor: 'LightSeaGreen',
                })
              );
            },
          },
          {
            icon: 'delete',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
              swal({
                title: 'Etes-vous sûr de vouloir supprimer ce fichier?',
                text: "Une fois supprimé, vous ne pourrez pas récupérer cet élément !",
                icon: "warning",
                buttons: {
                  check: {
                    text: "Ok",
                    value: "willDelete",
                  }
                },
                dangerMode: true,
              })
              .then((value) => {
                if (value === "willDelete") {
                  return deleteSviFile(this.props.menuId, rowData.name, rowData.type);
                }
              });
            },
          }
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, null)(SviFilesTab);
