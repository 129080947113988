import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { deleteMessagesMevoGroup, setMessagesMevoGroupsAsSeen } from 'components/commons/api/messagesMevoGroup.js';
import { notifyInfo } from 'components/commons/Toasts.js';
import swal from 'sweetalert';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { connect } from 'react-redux';

class MessagesMevoGroupTab extends Component {
  componentDidMount() {
    var audios = document.getElementsByTagName("audio");
    const that = this;
    for (var i = 0; i < audios.length; i++) {
      audios[i].addEventListener('play', async function(audio) {
        if (audio.target.getAttribute('data-seen') === 'false') {
          const audioId = (audio.target.id).substring(7);
          try {
            await setMessagesMevoGroupsAsSeen({ message_ids: [audioId], seen: true, user_id: audio.target.getAttribute('data-user') });
            return that.props.refreshData();
          } catch (e) {
            console.log(e);
          }
        }
      })
    }
  }
  
  render() {
    return (
      <MaterialTable
        columns={[
          { title: '', field: 'seen', cellStyle: { textAlign: 'center', width: '5px' }, headerStyle:{ textAlign: 'center' }, render: rowData => {
            return rowData.seen ? <React.Fragment></React.Fragment> : <React.Fragment><span className='badge badge-pill badge-primary'>Nouveau</span></React.Fragment>
          } },
          { title: 'De', field: 'caller_number' },
          { title: 'Date', field: 'date', render: (rowData) => {
            return <React.Fragment>Le <Moment locale='fr' unix format="DD MMMM YYYY à HH:mm:ss">{rowData.date || "-"}</Moment></React.Fragment>
          } },
          { title: 'Durée', field: 'duration'},
          { title: 'Ecouter', field: 'play', render: (rowData) => {
            return (
              <audio
                id={`player_${rowData.id}`}
                controls
                data-seen={rowData.seen}
                data-user={rowData.idUser}
                src={`${this.props.session.apiUrl}/mevo/message?message_id=${rowData.id}&u=${rowData.idUser}`} type="audio/mp3"></audio>
            );
          }}
        ]}
        data={this.props.messages}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Messages par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
              labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          pageSize: 6,
          actionsColumnIndex: -1,
          rowStyle: rowData => ({
            backgroundColor: !rowData.seen ? '#d1e8d1' : 'auto'
          })
        }}
        actions={[
          {
            icon: 'delete_outline',
            tooltip: 'Supprimer',
            onClick: (event, rowData) => {
              return swal({
                title: "Confirmation",
                text: `Etes-vous sûr(e) de vouloir supprimer ce message vocal ?`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                  return deleteMessagesMevoGroup(rowData.id, rowData.idUser).then((result) => {
                    notifyInfo("Message supprimé avec succès !");
                    return this.props.refreshData();
                  }).catch((e) => {
                    return console.log(e);
                  });
                }
              });
            },
          },
          {
            icon: 'cloud_download',
            tooltip: 'Télécharger',
            onClick: (event, rowData) => {
              const url = `${this.props.session.apiUrl}/mevo/message?message_id=${rowData.id}&u=${rowData.idUser}`;
              console.log(url);
              var element = document.createElement('a');
              element.setAttribute('href', 'data:audio/mpeg;charset=utf-8,'+url);
              element.setAttribute('download', 'message.mp3');
              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
              return null;
            },
          },
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    session: state.kertelSession
  })
}

export default connect(mapStateToProps, null)(MessagesMevoGroupTab);
