import React, { Component } from "react";
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

class EditInterceptionGroupInfos extends Component {
  
  render() {
	return (
	  <Container component="main">
		<Grid container spacing={2}>
		  <Grid item xs={12} sm={4}>
			<TextField
			  name="name"
			  label="Nom"
			  fullWidth
			  value={this.props.options.name}
			  onChange={this.props.handleOptions}
			/>
		  </Grid>
		  <Grid item xs={12} sm={8} />
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(EditInterceptionGroupInfos);
