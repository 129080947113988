export const loadingSupervision = () => {
  return { type: 'LOADING_SUPERVISION' }
};

export const getUsersSupervision = (informations) => {
  return {
    type: 'GET_USERS_SUPERVISION',
    payload: informations
  }
};

export const updateSupervisedUserStatus = (user, status) => {
  return {
    type: 'UPDATE_USER_SUPERVISION_STATUS',
    user: user,
    status: status
  }
};

export const stopLoadingSupervision = () => {
  return { type: 'STOP_LOADING_SUPERVISION' }
};

export const startEditingSupervision = (user_id) => {
  return {
    type: 'START_EDITING_SUPERVISION',
    payload: user_id
  }
};

export const stopEditingSupervision = () => {
  return { type: 'STOP_EDITING_SUPERVISION' }
};
