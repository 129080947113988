const initialState = {
    datas: [],
    isLoading: false,
    editing: {
      isEditing: false,
      isLoading: false,
      datas: {},
      contactId: null
    }
}
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
    case 'FETCH_COMPANY_CONTACTS':
      return { ...state,  datas: action.payload }
    case 'LOADING_COMPANY_CONTACTS':
      return { ...state, isLoading: true };
    case 'STOP_LOADING_COMPANY_CONTACTS':
      return { ...state, isLoading: false };
    case 'FETCH_COMPANY_CONTACT':
      return { ...state, editing: { ...state.editing, datas: action.payload } }
    case 'START_EDITING_COMPANY_CONTACT':
      return { ...state, editing: { ...state.editing, isEditing: true, contactId: action.payload } }

    case 'STOP_EDITING_COMPANY_CONTACT':
      return { ...state, editing: { ...state.editing, isEditing: false } }
    default:
      return state
  }
}

export default userReducer;
