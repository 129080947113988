import React, { Component, useState } from 'react';
import {connect} from 'react-redux';
import { getAuthAs } from 'components/commons/api/auth.js';
import jwt_decode from 'jwt-decode';
import { createSession } from 'actions/authActions.js';
import { bindActionCreators } from 'redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import swal from 'sweetalert';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';

function UserInfos ({ company, rowData }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  function format_number(numbers) {
    if (typeof numbers === "object") {
      forEach(numbers, function(value, index, numbers) {
        var first = value.substring(0, 2);
        if (first === "+3") {
          //.match(/.{1,2}/g).join(' ')
          numbers[index] = ("0" + value.slice(3, value.length));
        } else if (first === "33") {
          numbers[index] = ("0" + value.slice(2, value.length));
        }
      })
      return numbers.join(', ');
    } else {
      var first = numbers.substring(0, 2);
      if (first === "+3") {
        //.match(/.{1,2}/g).join(' ')
        numbers = ("0" + numbers.slice(3, numbers.length));
      } else if (first === "33") {
        numbers = ("0" + numbers.slice(2, numbers.length));
      }
      return numbers;
    }
  }

  function findLocation(id) {

    if (isUndefined(company.location)) return "Pas de site";

    for (var location in company.location) {
      if (company.location[location].id === id) {
        return company.location[location].name;
      }
    }

    return "Pas de site";
  }

  function findGroup(id) {

    if (isUndefined(company.group)) return "Pas de département";

    for (var group in company.group) {
      if (company.group[group].id === id) {
        return company.group[group].name;
      }
    }

    return "Pas de département";
  }

  function findProfilServices(id) {
    if (!isUndefined(company.profile_services)) {
      if (!isUndefined(company.profile_services[id])) {
        return company.profile_services[id];
      }
    }

    return "Aucun profile";
  }

  function findProfilCallsIn(id) {
    if (!isUndefined(company.profil_calls_in)) {
      if (!isUndefined(company.profil_calls_in[id])) {
        if (!isUndefined(company.profil_calls_in[id].name)) {
          return company.profil_calls_in[id].name;
        }
      }
    }
    
    return "Aucun profile";
  }

  function findProfilCallsOut(id) {
    if (!isUndefined(company.profil_calls_out)) {
      if (!isUndefined(company.profil_calls_out[id])) {
        if (!isUndefined(company.profil_calls_out[id].name)) {
          return company.profil_calls_out[id].name;
        }
      }
    }
    return "Aucun profile";
  }

  function findPhones(tty) {

    var phones = [];
    if (!isUndefined(tty)) {
      for (var i = 0; i < tty.length; i++) {
        phones.push(
          <tr key='{tty[i].line_id}'>
            <td > {tty[i].model}</td>
            <td> {tty[i].line_id}</td>
          </tr>
        );
      }
    }
    else {
      phones.push(
        <tr key='{tty[i].line_id}'>
          <td > Aucune </td>
          <td> Aucune </td>
        </tr>
      );
    }

    return phones;
  }

  async function loadUser(user_id) {
    const userInfos = await getAuthAs(user_id);
    if (!isUndefined(userInfos.data.token_auth)) {
      const decoded = jwt_decode(userInfos.data.token_auth);
      
      if (isUndefined(decoded.lvl)) {
        return swal({
          type: 'error',
          icon: "error",
          title: 'Oops...',
          text: "Échec de l’authentification.",
          confirmButtonColor: 'LightSeaGreen',
        })
      }
      
      createSession({
        token_auth: userInfos.data.token_auth,
        token_min: userInfos.data.mini_token_auth,
        decoded: decoded,
        admin: decoded.lvl === "admin"
      });
      
      return navigate('/dashboard');
    }
  }
  
    return (
      <div className="row">
        <div className="col">
          <h4 className="card-title font-weight-bold">Postes</h4>
          <table className="tablesaw table-bordered table-hover table">
            <thead>
                <tr>
                  <th scope="col">Model</th>
                  <th scope="col">Identifiant</th>
                </tr>
            </thead>
            <tbody>
              { findPhones(rowData.tty) }
            </tbody>
          </table>
          <div className="form-row">
          </div>
        </div>
        <div className="col">
          <h4 className="card-title font-weight-bold">Autres Informations</h4>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Département / Site</label>
                <div> 
                 { findGroup(rowData.group_id) } / { findLocation(rowData.location_id) }
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Autorisation d'accès au compte</label>
                <br />
				<FormControlLabel style={{ paddingTop: '20px'}}
				  control={
					<Switch
					  name="allow_impersonate"
					  checked={rowData.allow_impersonate}
					  style={{ float: "left" }}
                      disabled={rowData.allow_impersonate ? false : true}
					/>
				  }
				/>
                {
                  rowData.allow_impersonate &&
                  <button type="button" onClick={() => {loadUser(rowData.user_id)}} className="btn btn-success">Se connecter</button>
                }
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Login</label>
                  <input type="text" readOnly className="form-control-plaintext" value={ !isUndefined(rowData.login) ? rowData.login : "Aucun login"}/>
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Numéro Masqué</label>
                  <input type="text" readOnly className="form-control-plaintext" value={ rowData.clip_clir === true ? "OUI" : "Non" }/>
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Profil de service</label>
                  <input type="text" readOnly className="form-control-plaintext" value={ findProfilServices(rowData.profile_id) }/>
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Numéro présenté</label>
                  <input type="text" readOnly className="form-control-plaintext" value={ isEmpty(rowData.alt_e164 ) ? "Aucun numéro" : format_number(rowData.alt_e164)}/>
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Profil appel sortant</label>
                  <input type="text" readOnly className="form-control-plaintext" value={ findProfilCallsOut(rowData.outgoingcall_profile_id) }/>
              </div>
              <div className="form-group col-md-6">
                <label className="font-weight-bold">Profil appel entrant</label>
                  <input type="text" readOnly className="form-control-plaintext" value={ findProfilCallsIn(rowData.incomingcall_profile_id) }/>
              </div>
            </div>
        </div>
      </div>
    )
  }

const mapStateToProps = (state) => {
  return ({})
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createSession,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfos);
