
const interceptionReducer = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return [];
    case 'LOADING_INTERCEPTIONS':
      return { ...state, isLoading: true };
    case 'GET_INTERCEPTIONS':
      return { ...state, datas: action.payload }
    case 'STOP_LOADING_INTERCEPTIONS':
      return { ...state, isLoading: false };

    case 'LOADING_INTERCEPTION':
      return { ...state, isLoading: true };
    case 'GET_INTERCEPTION':
      return { ...state,  data: action.payload }
    case 'STOP_LOADING_INTERCEPTION':
      return { ...state, isLoading: false };

    case 'START_EDITING_INTERCEPTION':
      return { ...state, editing: { ...state.editing, isEditing: true, groupId: action.payload } }
    case 'STOP_EDITING_INTERCEPTION':
      return { ...state, editing: { ...state.editing, isEditing: false, groupId: null, datas:{} } }

    default:
      return state
  }
}

export default interceptionReducer;
