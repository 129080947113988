import React, { Component } from 'react';

class InsertLongNumberModal extends Component {

  handleSaveBtnClick = () => {
    const { columns, onSave } = this.props;

    const newRow = {};
    columns.forEach((column, i) => {
      newRow[column.field] = this.refs[column.field].value;
    }, this);

    // You should call onSave function and give the new row
    onSave(newRow);
  }

  render() {
    const {
      onModalClose,
      onSave,
      columns,
      validateState,
    } = this.props;

    const modalStyle = {
      marginTop: '30%'
    };

    const headerStyle = {
      visibility: "none",
    };

    return (
      <div className='modal-content' style={modalStyle}>
        <div className='modal-header' style={headerStyle} />
        <div className="modal-body">
          {
            columns.map((column, i) => {
              const {
                field,
                name,
                hiddenOnInsert
              } = column;

              if (hiddenOnInsert) {
                // when you want same auto generate value
                // and not allow edit, for example ID field
                return null;
              }
              const error = validateState[field] ? (<span className='help-block bg-danger'>{ validateState[field] }</span>) : null;
              return (
                <div className='form-group' key={ field }>
                  <label>{ name } : </label>
                  <input ref={ field } type='number' defaultValue={ '' } className=' form-control editor edit-text' min="1" />
                  { error }
                </div>
              );
            })
          }
        </div>
        <div className="modal-footer">
          <button className='btn btn-inverse mr-2' onClick={ onModalClose }>Annuler</button>
          <button className='btn btn-success' onClick={ () => this.handleSaveBtnClick(columns, onSave) }>Enregistrer</button>
        </div>
      </div>
    );
  }
}

export default InsertLongNumberModal;
