import { store } from 'store/index.js';
import { getCompanyContacts, getCompanyContact } from 'components/commons/api/admin/companyContact';
import {
  loadingCompanyContact,
  fetchCompanyContacts as fetchCompanyContactsAction,
  fetchCompanyContact as fetchCompanyContactAction,
  stopLoadingCompanyContact,
} from 'actions/admin/companyContactActions';

export const fetchCompanyContacts = async (props) => {
    store.dispatch(loadingCompanyContact());
    try {
      await getCompanyContacts().then((result) => {
        return store.dispatch(fetchCompanyContactsAction(result.data));
      })
    } catch (e) {
      console.log(e);
    } finally {
      store.dispatch(stopLoadingCompanyContact());
    }
  }

  export const fetchCompanyContact = async (contactId) => {
      store.dispatch(loadingCompanyContact());
      try {
        await getCompanyContact(contactId).then((result) => {
          return store.dispatch(fetchCompanyContactAction(Object.values(result.data)[0]));
        })
      } catch (e) {
        console.log(e);
      } finally {
        store.dispatch(stopLoadingCompanyContact());
      }
    }
