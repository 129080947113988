import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { fetchInterceptions, fetchInterception, updateInterception  } from 'components/commons/helpers/admin/interceptionGroupHelper.js';
import { stopEditingInterception } from 'actions/admin/interceptionGroupActions.js';
import { notifySuccess } from 'components/commons/Toasts.js';
import Preloader from 'components/commons/Preloader.js';
import EditInterceptionGroupInfos from './EditInterceptionGroupInfos.js';
import EditInterceptionGroupMembers from './EditInterceptionGroupMembers.js';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';

function EditInterceptionGroup({interceptionGroup, users, company}) {

  const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [interceptionGroupState, setInterceptionGroupState] = useState({
        "name": '',
        "members": [],
	    	"dataMembers": [],
  });

  async function initInterception() {
    fetchUsers();
    
    if (interceptionGroup.editing.isEditing) {
      try {
        await fetchInterception(interceptionGroup.editing.groupId).then((result) => {
		    let members = interceptionGroup.data.members;
		    var dataMembers = [];

		    forEach(users.datas, function(details) {
			    if (details.user_type === "2" || details.user_type === "8") {
            let user = {
              "user_id": details.user_id,
              "mail": !isUndefined(details.mail) ? details.mail : '',
              "firstname": !isUndefined(details.firstname) ? details.firstname : '',
              "lastname": !isUndefined(details.lastname) ? details.lastname : '',
              "group_id": details.group_id,
              "location_id": details.location_id,
              "user_type" : details.user_type,
              tableData: { checked: members.includes(details.user_id) ? true : false}
            };
			  
			    dataMembers.push(user);
			  }
		  })

      let interception = interceptionGroup;
      interception.push({
        "name":interceptionGroup.data.name,
        "members":interceptionGroup.data.members,
        "dataMembers":dataMembers,
      })
		  
      setInterceptionGroupState(interception);
        })
      } catch (e) {
        console.log(e);
      } finally {
	      setIsLoading(false);
	  }
    }
  }

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInterceptionGroupState(...interceptionGroupState, { [name] : value })
  }
  
  useEffect(function() {
    initInterception();
  }, [])

  async function updateInterceptionGroup() {
    try {
	  let info = {
		members :  {
          "add": [],
          "delete": [],
        },
	  };
	  
	  if (!isUndefined(interceptionGroupState.name)) info.name = interceptionGroupState.name;
      
	  forEach(interceptionGroupState.dataMembers, function(details) {
		if (details.tableData.checked === true) {
		  info.members.add.push(details.user_id);
		}
	  })
	  
	  forEach(interceptionGroupState.members, function(user_id) {
		  if (!info.members.add.includes(user_id)) {
			info.members.delete.push(user_id);
		  }
	  })
	  
      await updateInterception(interceptionGroup.editing.groupId, info).then((result) => {
        notifySuccess("Groupe d'interception mis à jour avec succès !");
        fetchInterceptions();
        return navigate('/admin/groupinterception');
      })
    } catch (e) {
      console.log(e);
    } finally {
      return;
    }
  }
    
	if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
	
    if (isEmpty(interceptionGroupState)) return navigate('/admin/groupinterception');
	
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Groupe d'interception", "Édition du groupe d'interception"]} url="/admin/groupinterception" title="Édition du groupe d'interception" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Information générales
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Gestion des membres
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
                <EditInterceptionGroupInfos
                  options={interceptionGroupState}
                  handleOptions={handleOptions}
                />
              </div>
              <div className="tab-pane p-20" role="tabpanel" id="users">
                <EditInterceptionGroupMembers
                  dataMembers={interceptionGroupState.dataMembers}
                  company={company}
                />
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/groupinterception');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={updateInterceptionGroup} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    interceptionGroup: state.interception
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingInterception
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInterceptionGroup);
