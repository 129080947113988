import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { startEditingSupervision } from 'actions/admin/supervisionActions.js';
import { bindActionCreators } from 'redux';
import Preloader from 'components/commons/Preloader.js';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import Gravatar from 'react-gravatar';
import { useNavigate } from 'react-router';

function SupervisionTab ({ isLoading, listUsers, startEditingSupervision }) {

  const navigate = useNavigate();

    if (isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }
    
    return (
      <MaterialTable
        columns={[
          { title: '', field: 'avatar', cellStyle: {textAlign: "center"},
            render: rowData => {
              return !isEmpty(rowData.mail) && <Gravatar email={rowData.mail}/>
            }
          },
          { title: 'Prénom', field: 'firstname'},
          { title: 'Nom', field: 'lastname'},
          { title: "Nombre d'utilisateurs supervisés", sorting: false,
            render: rowData => (
              <div>{rowData.supervision.supervised.length}</div>
            )
          },
        ]}
        data={filter(listUsers, item => item.user_type === "1" || item.user_type === "2")}
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun utilisateur à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Utilisateurs de groupe par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
              labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          search: true,
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Modifier',
            onClick: (event, rowData) => {
              startEditingSupervision(rowData.user_id);
              return navigate('/admin/editsupervision');
            },
          },
        ]}
      />
    );
  }

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    startEditingSupervision,
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(SupervisionTab);
