import { GET, PATCH, POST, DELETE} from './../api';

export const getCompanyContacts = () => {
  return GET('/directory/company',{
    provider: localStorage.getItem('provider'),
    company: localStorage.getItem('company')
  });
}

export const getCompanyContact = (contactId) => {
  return GET('/directory/company',{
    provider: localStorage.getItem('provider'),
    company: localStorage.getItem('company'),
    contact_id: contactId
  });
}

export const createCompanyContact = (contact) => {
  return POST('/directory/company',{
    provider: localStorage.getItem('provider'),
    company: localStorage.getItem('company'),
    data: [contact]
  });
}

export const updateCompanyContact = (contact) => {
  return PATCH('/directory/company',{
    provider: localStorage.getItem('provider'),
    company: localStorage.getItem('company'),
    data: [contact]
  });
}

export const deleteCompanyContact = (contactId) => {
  return DELETE('/directory/company',{
    provider: localStorage.getItem('provider'),
    company: localStorage.getItem('company'),
    data:[{
      contact_id: contactId
    }]
  });
}
