import React, { useEffect, useRef, useState } from 'react';
import NavBar from 'components/commons/navbar/NavBar.js';
import AdminBar from 'components/commons/AdminBar.js';
import SideBar from 'components/commons/SideBar.js';
import NotFound from 'components/commons/errors/NotFound.js';
import Home from 'components/views/Home.js';
import CallbackAuth from 'components/views/CallbackAuth.js';
import Logout from 'components/views/Logout.js';
import Dashboard from 'components/views/dashboard/Dashboard.js';
import Recents from 'components/views/phone/Recents.js';
import InboundManagement from 'components/views/phone/InboundManagement.js';
import CreateInboundManagement from 'components/views/phone/CreateInboundManagement.js';
import VoiceMails from 'components/views/phone/VoiceMails.js';
import MessagesMevoGroup from 'components/views/phone/MessagesMevoGroup.js';
import Musics from 'components/views/phone/Musics.js';
import Directs from 'components/views/phone/Directs.js';
import Account from 'components/views/Account.js';
import Contacts from 'components/views/contacts/Contacts.js';
import Downloads from 'components/views/downloads/Downloads.js';
import InstantMessaging from 'components/views/im/InstantMessaging.js';
import FaxList from 'components/views/fax/FaxList.js';
import Scheduler from 'components/views/scheduler/Scheduler.js';
import Calendar from 'components/views/scheduler/Calendar.js';
import KonamiCode from 'components/views/konami/KonamiCode.js';
import AdminUsers from 'components/views/admin/user/User.js';
import AdminCreateUser from 'components/views/admin/user/CreateUser.js';
import AdminUpdateUser from 'components/views/admin/user/UpdateUser.js';
import AdminMevoMessages from 'components/views/admin/mevoMessages/MevoMessages.js';
import AdminFaxs from 'components/views/admin/Fax.js';
import AdminForwards from 'components/views/admin/forward/Forward.js';
import AdminInterceptionGroup from 'components/views/admin/interceptionGroup/InterceptionGroup.js';
import AdminCreateInterceptionGroup from 'components/views/admin/interceptionGroup/CreateInterceptionGroup.js';
import AdminEditInterceptionGroup from 'components/views/admin/interceptionGroup/EditInterceptionGroup.js';
import AdminNumbers from 'components/views/admin/numbers/Numbers.js';
import AdminCompanyContact from 'components/views/admin/CompanyContact.js';
import AdminMevoGroup from 'components/views/admin/mevoGroup/MevoGroup.js';
import AdminCreateMevoGroup from 'components/views/admin/mevoGroup/CreateMevoGroup.js';
import AdminEditMevoGroup from 'components/views/admin/mevoGroup/EditMevoGroup.js';
import AdminEditMevoGroupConfig from 'components/views/admin/mevoGroup/EditMevoGroupConfig.js';
import AdminEditSiteService from 'components/views/admin/mevoGroup/EditSiteService.js';
import AdminWaitingQueue from 'components/views/admin/waitingQueue/WaitingQueue.js';
import AdminEditWaitingQueue from 'components/views/admin/waitingQueue/EditWaitingQueue.js';
import AdminCreateWaitingQueue from 'components/views/admin/waitingQueue/CreateWaitingQueue.js';
import AdminRingtoneGeneral from 'components/views/admin/ringtoneGeneral/RingtoneGeneral.js';
import AdminCreateRingtoneGeneral from 'components/views/admin/ringtoneGeneral/CreateRingtoneGeneral.js';
import AdminEditRingtoneGeneral from 'components/views/admin/ringtoneGeneral/EditRingtoneGeneral.js';
import AdminStatsCalls from 'components/views/admin/statsCalls/StatsCalls.js';
import AdminCdr from 'components/views/admin/cdr/Cdr.js';
import AdminSettings from 'components/views/admin/settings/ProfileCalls.js';
import AdminCreateProfileCalls from 'components/views/admin/settings/CreateProfileCalls.js';
import AdminEditProfileCalls from 'components/views/admin/settings/EditProfileCalls.js';
import AdminRecords from 'components/views/admin/records/Records.js';
import AdminBills from 'components/views/admin/bills/Bills.js';
import AdminSupervision from 'components/views/admin/supervision/Supervision.js';
import AdminEditSupervision from 'components/views/admin/supervision/EditSupervision.js';
import AdminSvi from 'components/views/admin/svi/Svi.js';
import AdminCreateSviMenu from 'components/views/admin/svi/CreateSviMenu.js';
import AdminEditSviMenu from 'components/views/admin/svi/EditSviMenu.js';
import AdminConfigureSvi from 'components/views/admin/svi/SviConfig.js';
import AdminSviFiles from 'components/views/admin/svi/SviFiles.js';
import AdminCreateSviFiles from 'components/views/admin/svi/CreateSviFiles.js';
import Modal from 'components/commons/Modal.js';
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './assets/scss/style.scss';
import { connect } from 'react-redux';
import isNull from 'lodash/isNull';
import { getCompany } from 'components/commons/helpers/admin/companyHelper.js';
import { bindActionCreators } from 'redux';
import { ringing, hangOut, hangUp, ringback, hold, unHold, answered, hasWebPhone, addSelectedContact } from 'actions/phoneActions.js';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import Gravatar from 'react-gravatar';
import { notifyInfo } from 'components/commons/Toasts.js';
import { addRooms, addNewMessage } from 'actions/imActions.js';
import * as sdk from "matrix-js-sdk"
import { Web } from "sip.js";
import Konami from 'react-konami-code';
import ringbackSound from 'assets/sounds/ringback.wav';
import ringSound from 'assets/sounds/classic_cell_ring.mp3';
import Supervision from './components/views/phone/Supervision';
import {getUsersSupervisionInformation} from 'components/commons/api/admin/supervision.js';
import { getUsersSupervision as getUsersSupervisionAction, updateSupervisedUserStatus } from 'actions/admin/supervisionActions';
import { addSidebarNotification } from 'actions/notificationsActions';
import mqtt from 'mqtt';
import { endsWith, startsWith } from 'lodash';
import { godMode } from 'actions/authActions';
import { getContactAccordingToNumber } from './components/commons/helpers/phoneHelper';
import ConnectAs from './components/views/ConnectAs';
import PrivateRoute from './components/commons/helpers/PrivateRoute';
import { hideModal } from './actions/modalActions';

global.Olm = require('olm');

var ringbackAudio = new Audio(ringbackSound);
var ringAudio = new Audio(ringSound);

function App ({
  session,
  phone,
  modal,
  settings,
  im,
  notifications,
  contacts,
  company,
  ringing,
  hangOut,
  hangUp,
  hold,
  unHold,
  answered,
  ringback,
  addRooms,
  addNewMessage,
  addSelectedContact,
  hasWebPhone,
  addSidebarNotification,
  getUsersSupervisionAction,
  updateSupervisedUserStatus,
  godMode
}) {

  const [matrixClient, setMatrixCLient] = useState(null);
  const [ToIP, setToIP] = useState(null);
  const [MQTT, setMQTT] = useState(null);
  const [myRooms, setMyRooms] = useState([]);
  const [isCalling, setIsCalling] = useState(false);

  const sipAvailable = useRef(false);

  const token = localStorage.getItem('_k');
  let isUserLogged = (token || !isNull(session.datas));

  async function initSipJs() {
    let lineId        = null;
    let sipPassword   = null;
    var audioElement  = document.createElement("audio");
    audioElement.id   = 'remoteAudio';
    audioElement.src  = { ringing }

    document.body.appendChild(audioElement);

    session.user.tty.forEach(function(element) {
      if (element.model === "WEB") {
        lineId = element.line_id;
        sipPassword = element.password;
      }
    });

    const options = {      
      aor: "sip:" + lineId + "@" + session.user.servers.sip.domain, // caller
      media: {
        constraints: { audio: true, video: false }, // audio only call
        remote: { audio: getAudioElement("remoteAudio") } // play remote audio
      },
      userAgentOptions: {
        authorizationUsername: lineId,
        authorizationPassword: sipPassword,
        contactName: lineId
      },
    };

    const server        = session.user.servers.sip.wss;
    const ToIPInstance  = new Web.ToIP(server, options, true);
    setToIP(ToIPInstance);

    // Connect to server   
    await connectToIP(ToIPInstance);

    return ToIPInstance;
  }

  async function connectToIP(ToIP) {
    if (isNull(ToIP)) return;

    ToIP.connect()
    .then(() => {
      console.log("SIP CONNECTED")
      hasWebPhone(true);
      sipAvailable.current = true;

      return ToIP;
    })
    .catch((error) => {
      alert(error)
      hasWebPhone(false);
      sipAvailable.current = false;

      return false;
    });

    ToIP.register();
  }

  useEffect(() => {
    async function initMQTT() {
      if (isEmpty(session.user) || !isNull(MQTT)) {
        return null;
      }

      let supervised = [];
  
      if (!isEmpty(session.user)) {
        if (!isUndefined(session.user.sso)) {
          if (!isUndefined(session.user.profil_services)) {
            if (!isEmpty(session.user.profil_services.profile_serv)) {
              if (session.user.profil_services.profile_serv.includes("s_po")) {
                supervised = await getUsersSupervisionInformation(session.user.user_id);
                supervised = supervised.data;
              }
            }
          }          
  
          if (!isEmpty(session.user.tty)) {
            if (!isEmpty(supervised)) {
              supervised[session.user.user_id] = {
                "firstname": session.user.firstname,
                "tty": [{ "full_line_id": session.user.tty[0].full_line_id }],
                "status": 'sIdle'
              }

              Object.entries(supervised).forEach(s => s[1].status = 'sIdle');
              getUsersSupervisionAction(supervised);
            }
          } 
  
          var mqttOptions = {
            protocol: 'mqtts',
            username: session.user.sso.login,
            password: localStorage.getItem('_mt')
          };
      
          var client = mqtt.connect(session.user.servers.mqtt.wss, mqttOptions);
          setMQTT(client);
  
          const topics = [
            `/mcms1/event/${session.user.tty[0].full_line_id}`,
            `/user/${session.user.user_id}/#`
          ]

          if (!isEmpty(supervised)) {
            Object.entries(supervised).forEach(user => {
              if (!isEmpty(user[1].tty[0])) {
                client.subscribe(`/mcms1/event/${user[1].tty[0].full_line_id}`);
              }
            })
          }
  
          topics.forEach(topic => {
            return client.subscribe(topic);
          });
      
          var note;
  
          client.on('message', function (topic, message) {
            note = JSON.parse(message);
  
            switch (true) {
              case endsWith(topic, '/mevo'):
                addSidebarNotification('mevo', note.status.new);
                break;
  
              case endsWith(topic, '/call'):
                addSidebarNotification('call', note.status.nb_missed_unseen);
                break;
                
              case endsWith(topic, '/fax'):
                addSidebarNotification('fax', note.status.new);
                break;
                
              case startsWith(topic, '/mcms1/event/'):
  
                let splittedTopic = topic.split('/');
  
                let supervisedUsers = supervised;

                if (!isEmpty(supervisedUsers)) {
                  Object.entries(supervisedUsers).forEach(user => {
                    if (!isEmpty(user[1].tty[0])) {                
                      if (splittedTopic.at(-1) === user[1].tty[0].full_line_id) {
                        user[1].status = note.parameters.state;
                        
                        return getUsersSupervisionAction(supervisedUsers);
                      }
                    }
                  })
                }          
                break;             
            
              default:
                break;
            }
          });
        }
      }
  
      window.addEventListener('beforeunload', handleUnload);
      if (session.admin && isEmpty(company)) {
        getCompany();
      }
    }

    initMQTT();

    return;
    
  }, [MQTT])

  useEffect(() => {

    function initMatrix() {
      if(isEmpty(session.user)) return;
      if(isUndefined(im)) return;
  
      if (isNull(matrixClient)) {

        const myAccessToken = im.mxToken;
  
        if (!isEmpty(myAccessToken) && !isUndefined(session.user)) {
          const { devideId, mxUserId }  = im;
          const matrixServer            = 'https://' + session.user.servers.matrix.server;
          const webStorageSessionStore  = new sdk.WebStorageSessionStore(window.localStorage);
          
          const matrixClient = sdk.createClient({
              baseUrl: matrixServer,
              accessToken: myAccessToken,
              userId: mxUserId,
              sessionStore: webStorageSessionStore,
              timelineSupport: true,
              deviceId: devideId
          });
  
          setMatrixCLient(matrixClient);
  
          matrixClient.once("sync", function(state) {
            switch (state) {
              case "ERROR":
                // update UI to say "Connection Lost"
                break;
              case "SYNCING":
                break;
              case "PREPARED":
                console.log('PREPARED');
                // the client instance is ready to be queried.
                var rooms = matrixClient.getRooms();
                addRooms(rooms);
                setMyRooms(rooms);
                
                matrixClient.on("Room.timeline", function(event) {
                  if (event.getType() === 'm.room.message' && (event.sender.userId !== im.mxUserId)) {
                    let oldRooms = myRooms;
                    for (let index = 0; index < oldRooms.length; index++) {
                      if(oldRooms[index].roomId === event.sender.roomId) {
                        if (isUndefined(oldRooms[index].messagesNb)) {
                          oldRooms[index].messagesNb = 1;
                        } else {
                          oldRooms[index].messagesNb = oldRooms[index].messagesNb + 1
                        }
                      };                    
                    }

                    setMyRooms(oldRooms);

                    if(!im.newMessage) addNewMessage();

                    notifyInfo(`Message de ${event.sender.name}`);
                  }
                });
                break;
              default:
                break;
              }
            });      
            
          matrixClient.startClient();
        }
      }
    }

    initMatrix();

    return;
  }, [matrixClient]);


  useEffect(function() {
    if (sipAvailable.current === true) return;
    console.log("===========================================> INIT TOIP");

    async function initTOIP() {
      if (!isNull(ToIP)) return;
      
      initSipJs().then(function(result) {
        console.log("===========================================> INIT SIP");  
        result.delegate = {
          onCallReceived: async (number) => {
            console.log("===========================================> ON CALL RECEIVED");  
            const selectedContact = await getContactAccordingToNumber(number);
            await addSelectedContact(selectedContact);

            return ringing();
          },
          onCallHangup: async () => {
            hangUp();
          },
          onCallAnswered: async () => {
            answered()
          }
        };

        sipAvailable.current = true;
      }).catch(function(e) {
        console.log(e);
        sipAvailable.current = false;
      });
    }

    initTOIP();
  });

  useEffect(function() {
    if (!sipAvailable.current) return;
    if (isNull(ToIP)) return;
    if (phone.ringback && isCalling) return;

    if (phone.ringback) {
      ToIP.call("sip:" + phone.num + "@" + session.user.servers.sip.domain);
      setIsCalling(true);
    }
  })

  function handleUnload(e) {
    if(!isUndefined(phone.webPhone) && !isEmpty(phone.webPhone)) {
      return phone.webPhone.unregister();
    }
  }

  function konamiRedirect() {
    godMode(true);

    return document.location = '/konami';
  }
  
  // Helper function to get an HTML audio element
  function getAudioElement(id) {
    const el = document.getElementById(id);
    if (!(el instanceof HTMLAudioElement)) {
      throw new Error(`Element "${id}" not found or not an audio element.`);
    }
    return el;
  }

  if (!isUndefined(phone) && (!phone.ringback || !phone.ringing)) {
    ringbackAudio.pause();
    ringbackAudio.currentTime = 0;

    ringAudio.pause();
    ringAudio.currentTime = 0;
  }

  return (<Router>
    <div id='mainApp' className={`${settings.darkMode && 'darkMode'}`}>
      <Konami action={konamiRedirect}>
        {"Hey, I'm an Easter Egg! Look at me!"}
      </Konami>
      {modal.show && <Modal component={modal.component}/>}
      <div className="App fix-header card-no-border">
        { session.admin && <AdminBar/> }
        { phone.ringing && <RingingModal
            hangOut={hangOut}
            hangUp={hangUp}
            selectedContact={phone.selectedContact}
            ToIP={ToIP}
            phone={phone} />
        }

        {isUserLogged && <NavBar/>}
        {isUserLogged && <SideBar ToIP={ToIP} />}

        <div className="page-wrapper">
          <Routes>
            <Route path="/callbackAuth" element={<CallbackAuth />}/>
            <Route path="/logincallbackAuth" element={<CallbackAuth />}/>
            <Route exact path="/connect-as/:from/:token" element={<ConnectAs />}/>
            <Route exact path="/login" element={<Home />}/>
            <Route exact path="/logout" element={<Logout />}/>
            <Route exact path="/404" element={<NotFound />}/>
            <Route path='/' element={<PrivateRoute/>}>
              <Route exact path='/' element={<Dashboard/>}/>
              <Route exact path="/me" element={<Account />}/>
              <Route exact path="/recents" element={<Recents />}/>
              <Route exact path="/inbound-management" element={<InboundManagement />}/>
              <Route exact path="/inbound-management/create" element={<CreateInboundManagement />}/>
              <Route exact path="/voicemail" element={<VoiceMails />}/>
              <Route exact path="/mevogroup" element={<MessagesMevoGroup />}/>
              <Route exact path="/musics" element={<Musics />}/>
              <Route exact path="/direct" element={<Directs />}/>
              <Route exact path="/supervision" element={<Supervision />}/>
              <Route exact path="/fax" element={<FaxList />}/>
              <Route exact path="/contacts" element={<Contacts />}/>
              <Route exact path="/downloads" element={<Downloads />}/>
              <Route exact path="/konami" element={<KonamiCode />}/>
              <Route exact path="/instant-messaging" element={<InstantMessaging />}/>
              <Route exact path="/admin/user" element={<AdminUsers />}/>
              <Route exact path="/admin/user/create" element={<AdminCreateUser />}/>
              <Route exact path="/admin/updateuser" element={<AdminUpdateUser />}/>
              <Route exact path="/admin/mevomessages" element={<AdminMevoMessages />}/>
              <Route exact path="/admin/fax" element={<AdminFaxs />}/>
              <Route exact path="/admin/forward" element={<AdminForwards />}/>
              <Route exact path="/admin/groupinterception" element={<AdminInterceptionGroup />}/>
              <Route exact path="/admin/creategroupinterception" element={<AdminCreateInterceptionGroup />}/>
              <Route exact path="/admin/editgroupinterception" element={<AdminEditInterceptionGroup />}/>
              <Route exact path="/admin/numbers" element={<AdminNumbers />}/>
              <Route exact path="/admin/companycontact" element={<AdminCompanyContact />}/>
              <Route exact path="/admin/mevogroup" element={<AdminMevoGroup />}/>
              <Route exact path="/admin/createmevogroup" element={<AdminCreateMevoGroup />}/>
              <Route exact path="/admin/editmevogroup" element={<AdminEditMevoGroup />}/>
              <Route exact path="/admin/mevogroup/config" element={<AdminEditMevoGroupConfig />}/>
              <Route exact path="/admin/createsiteservice" element={<AdminEditSiteService />}/>
              <Route exact path="/admin/waitingqueue" element={<AdminWaitingQueue />}/>
              <Route exact path="/admin/createwaitingqueue" element={<AdminCreateWaitingQueue />}/>
              <Route exact path="/admin/editwaitingqueue" element={<AdminEditWaitingQueue />}/>
              <Route exact path="/admin/ringtonegeneral" element={<AdminRingtoneGeneral />}/>
              <Route exact path="/admin/createringtonegeneral" element={<AdminCreateRingtoneGeneral />}/>
              <Route exact path="/admin/editringtonegeneral" element={<AdminEditRingtoneGeneral />}/>
              <Route exact path="/admin/stats" element={<AdminStatsCalls />}/>
              <Route exact path="/admin/cdr" element={<AdminCdr />}/>
              <Route exact path="/admin/settings" element={<AdminSettings />}/>
              <Route exact path="/admin/editprofilecalls" element={<AdminEditProfileCalls />}/>
              <Route exact path="/admin/createprofilecalls" element={<AdminCreateProfileCalls />}/>
              <Route exact path="/admin/records" element={<AdminRecords />}/>
              <Route exact path="/admin/bills" element={<AdminBills />}/>
              <Route exact path="/admin/supervision" element={<AdminSupervision />}/>
              <Route exact path="/admin/editsupervision" element={<AdminEditSupervision />}/>
              <Route exact path="/admin/svi" element={<AdminSvi />}/>
              <Route exact path="/admin/svi/menu/create" element={<AdminCreateSviMenu />}/>
              <Route exact path="/admin/svi/menu/edit" element={<AdminEditSviMenu />}/>
              <Route exact path="/admin/svi/config" element={<AdminConfigureSvi />}/>
              <Route exact path="/admin/svi/files" element={<AdminSviFiles />}/>
              <Route exact path="/admin/svi/files/create" element={<AdminCreateSviFiles />}/>
              <Route exact path="/scheduler" element={<Scheduler />}/>
              <Route exact path="/scheduler/calendar" element={<Calendar />}/>
              <Route exact path="/connect-as/:from/:token" element={<ConnectAs />}/>
              <Route exact path='/dashboard' element={<Dashboard/>}/>
              <Route exact path='/404' element={<NotFound/>}/>
            </Route>
            
            <Route element={<NotFound />}/>
          </Routes>
        </div>
      </div>
    </div>
  </Router>);
}

const RingingModal = (props) => {

  const { phone, selectedContact } = props;

  return (<div className="card card-outline-warning" id="incomingCall">
    <div className="card-header">
      <h4 className="m-b-0 text-white">Appel entrant</h4>
    </div>
    <div className="card-body">
      <ul className="list-unstyled">
        <li className="media">
          <span className="dot-min d-flex mr-3" alt="Profile">
            <div>
              <label>
                {phone.label}
              </label>
              <Gravatar email={phone.email}/>
            </div>

          </span>
          <div className="media-body">
            <h5 className="mt-0 mb-1">{ (isUndefined(selectedContact.firstname) && isUndefined(selectedContact.lastname)) ? selectedContact.number : `${selectedContact.firstname || ""} ${selectedContact.lastname || ""}`}</h5>
            <div>
              <button type="button" className="btn btn-success btn-circle btn_phone" onClick={(e) => {
                  e.preventDefault();
                  props.hangOut();
                  props.ToIP.answer();
                }}>
                <i className="fa fa-phone"></i>
              </button>
              <button type="button" className="btn btn-danger btn-circle btn_phone" onClick={(e) => {
                  e.preventDefault();
                  props.hangUp();

                  props.ToIP.decline();
                }}>
                <i className="fa fa-phone"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>)
};

const mapStateToProps = (state) => {
  return ({
    session: state.kertelSession,
    phone: state.kertelphone,
    modal: state.modal,
    settings: state.settings,
    im: state.im,
    notifications: state.notifications,
    contacts: state.contacts,
    company: state.company
  });
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    ringing,
    hangOut,
    hangUp,
    hold,
    unHold,
    answered,
    ringback,
    addRooms,
    addNewMessage,
    addSelectedContact,
    hasWebPhone,
    addSidebarNotification,
    getUsersSupervisionAction,
    updateSupervisedUserStatus,
    godMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
