/* LOADING */
export const loadingMessagesMevoGroup = () => { return { type: 'LOADING_MESSAGES_MEVO_GROUP' } };

/* STOP LOADING */
export const stopLoadingMessagesMevoGroup = () => { return { type: 'STOP_LOADING_MESSAGES_MEVO_GROUP' } };

/* FETCH */
export const fetchMessagesMevoGroup = (messagesMevoGroup) => { return { type: 'FETCH_MESSAGES_MEVO_GROUP', payload: messagesMevoGroup } };

/* UNSEEN / SEEN  */
export const fetchUnseenMessagesMevoGroup = (messagesMevoGroup) => { return { type: 'FETCH_UNSEEN_MESSAGES_MEVO_GROUP', payload: messagesMevoGroup } };
export const hasReadUnseenMessagesMevoGroup = () => { return { type: 'HAS_READ_UNSEEN_MESSAGES_MEVO_GROUP' } };
