
const groupReducer = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return [];
    case 'LOADING_RINGTONES_GENERAL':
      return { ...state, isLoading: true  };
    case 'GET_RINGTONES_GENERAL':
      return { ...state, datas: action.payload  }
    case 'STOP_LOADING_RINGTONES_GENERAL':
      return { ...state, isLoading: false  };
    
    case 'LOADING_RINGTONE_GENERAL':
      return { ...state, isLoading: true  };
    case 'GET_RINGTONE_GENERAL':
      return { ...state, data: action.payload }
    case 'STOP_LOADING_RINGTONE_GENERAL':
      return { ...state, isLoading: false };

    case 'START_EDITING_RINGTONE_GENERAL':
      return { ...state, editing: { ...state.editing, isEditing: true, ringId: action.payload } }
    case 'STOP_EDITING_RINGTONE_GENERAL':
      return { ...state, editing: { ...state.editing, isEditing: false, ringId: null, datas: {} } }
    
    case 'CHECK_TTY_ID_RINGTONES_GENERAL':
      return { ...state, checkTtyId: action.payload }
    default:
      return state
  }
}

export default groupReducer;
