import React, { Component } from "react";
import { connect } from 'react-redux';
import clsx from 'clsx';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import { KeyboardDateTimePicker , MuiPickersUtilsProvider } from "@material-ui/pickers";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: green[100],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: green[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();
  
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function StyledCheckbox(props) {
  const GreenCheckbox = withStyles({
	root: {
	  color: green[400],
	  '&$checked': {
		color: green[600],
	  },
	},
	checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  
  return (
	<GreenCheckbox
      {...props}
	/>
  );
}

class StatsCallsOptions extends Component {
  
  customTheme = createMuiTheme({
	palette: {
		primary: {
			main: green[600],
		},
		secondary: {
			main: green[400],
		},
	},
  })
  
  maxLengthCheck = (event) => {
	if (event.target.value.length > event.target.maxLength) {
	  event.target.value = event.target.value.slice(0, event.target.maxLength)
	}
  }
 
  render() {
	
	const day = new Date();
	const minDate = day.setDate(day.getDate() - 62);
	
	return (
	  <Container component="main" maxWidth="sm">
		<Grid container spacing={4}>
		  <Grid item xs={12} sm={6}>
			<MuiThemeProvider theme={this.customTheme}>
			  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale} >
				<KeyboardDateTimePicker
				  autoOk
				  ampm={false}
				  disableFuture
				  name="startDate"
				  value={this.props.options.startDate}
				  onChange={(date) => this.props.handleOptionsDate(date, 'startDate')}
				  label="Date de début"
				  format="dd/MM/yyyy HH:mm"
				  minDate={minDate}
				  invalidDateMessage="Date invalide"
				  minDateMessage="La date ne devrait pas être avant 90 jours de la date actuelle"
				/>
			  </MuiPickersUtilsProvider>
			</MuiThemeProvider>
		  </Grid>
		  <Grid item xs={12} sm={6}>
			<MuiThemeProvider theme={this.customTheme}>
			  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
				<KeyboardDateTimePicker
				  autoOk
				  ampm={false}
				  disableFuture
				  name="endDate"
				  value={this.props.options.endDate}
				  onChange={(date) => this.props.handleOptionsDate(date, 'endDate')}
				  label="Date de fin"
				  format="dd/MM/yyyy HH:mm"
				  minDate={minDate}
				  invalidDateMessage="Date invalide"
				  minDateMessage="La date ne devrait pas être avant 90 jours de la date actuelle"
				/>
			  </MuiPickersUtilsProvider>
			</MuiThemeProvider>
		  </Grid>
		  <Grid item xs={12} sm={6}>
			<FormControl component="fieldset">
			  <FormLabel component="legend">Type d'appels</FormLabel>
			  <RadioGroup defaultValue="" aria-label="call_direction" name="customized-radios">
				<FormControlLabel
				  name="typeCalls"
				  value="IN"
				  control={<StyledRadio />}
				  label="Entrants"
				  checked={this.props.options.typeCalls === "IN"}
				  onChange={this.props.handleOptions}
				/>
				<FormControlLabel
				  name="typeCalls"
				  value="OUT"
				  control={<StyledRadio />}
				  label="Sortants"
				  checked={this.props.options.typeCalls === "OUT"}
				  onChange={this.props.handleOptions}
				/>
			  </RadioGroup>
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={6}>
			<FormGroup row>
			  <FormControlLabel
				name="internalCalls"
				checked={this.props.options.internalCalls}
				onChange={this.props.handleOptions}
				control={<StyledCheckbox />}
				label="Avec appels interne"
			  />
			</FormGroup>
		  </Grid>
		  <Grid item xs={12} sm={6}>
			<FormControl component="fieldset">
			  <FormLabel component="legend">Granularité</FormLabel>
				<Select
				  value={this.props.options.grain}
				  onChange={this.props.handleOptions}
				  inputProps={{
					name: 'grain'
				  }}
				>
				  <MenuItem value={1440}>Une journée</MenuItem>
				  <MenuItem value={60}>Une heure</MenuItem>
				  <MenuItem value={30}>Une demi-heure</MenuItem>
				  <MenuItem value={15}>Un quart d'heure</MenuItem>
				</Select>
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={6}>
			<FormControl component="fieldset">
			  <FormLabel component="legend">Format de sortie</FormLabel>
				<Select
				  value={this.props.options.rendering}
				  onChange={this.props.handleOptions}
				  inputProps={{
					name: 'rendering',
					id: 'filled-age-simple',
				  }}
				>
				  <MenuItem value="graph">Graph</MenuItem>
				  <MenuItem value="csv">Csv</MenuItem>
				</Select>
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={6}>
			<FormControl component="fieldset">
			  <FormLabel component="legend">Filtrer sur le numéro</FormLabel>
			  {this.props.options.numbers.map((number, idx) => (
				<div className="number" key={idx}>
				  <input
					type="number"
					value={number.name}
					onChange={this.props.handleNumberNameChange(idx)}
					maxLength="13"
					onInput={this.maxLengthCheck}
				  />
				  { idx !== 0 ? (<DeleteForeverIcon style={{color: red[400], cursor: "pointer"}} aria-label="delete"  onClick={this.props.handleRemoveNumber(idx)} />) : ('')}
				</div>
			  ))}
			</FormControl>
		  </Grid>
		  <Grid item xs={12} sm={6}>
			<Fab
			  size="small"
			  aria-label="Ajouter un numéro"
			  onClick={this.props.handleAddNumber}
			  style={{color: "#fff", backgroundColor: green[500], '&:hover': {backgroundColor: green[600]}}}
			>
			  <AddIcon />
			</Fab>
		  </Grid>
		</Grid>
	  </Container>
	);
  }
}

const mapStateToProps = (state) => {
  return ({
    statscalls: state.statscalls,
  })
}

export default connect(mapStateToProps, null)(StatsCallsOptions);
