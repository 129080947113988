import { POST, PUT, PATCH, DELETE } from './../api';

export const getMevosGroupInformation = () => {
  return POST('/mevo/group',{
    company: localStorage.getItem('company')
  });
}

export const getMevoGroupInformation = (mevoId) => {
  return POST('/mevo/group', {
    company: localStorage.getItem('company'),
    mevo_group_id: mevoId
  });
}

export const createMevoGroupInformation = (informations) => {
  return PUT('/mevo/group', {
    company: localStorage.getItem('company'),
    data: informations
  });
}

export const updateMevoGroupInformation = (mevoId,informations) => {
  return PATCH('/mevo/group', {
    company: localStorage.getItem('company'),
    mevo_group_id: mevoId,
    data: informations
  });
}

export const deleteMevoGroupInformation = (mevoId) => {
  return DELETE('/mevo/group', {
    company: localStorage.getItem('company'),
    mevo_group_id: mevoId
  });
}
