const initialState = {
  buttons: [],
  editing: {}
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
    
    case 'ADD_DIRECT_BUTTON':
      let newState = state.buttons;
      newState.push(action.payload);
      return { ...state, buttons: newState };

    case 'REMOVE_DIRECT_BUTTONS':
      return { editing: {}, buttons: [] };

    case 'SET_EDITING_DIRECT_BUTTON':
      return { ...state, editing: action.payload };

    default:
      return state
  }
}

export default contactsReducer;
