import React, { Component } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { useNavigate } from 'react-router';

function EditSiteService () {

  const navigate = useNavigate();

    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Groupe","Création de service/site"]} title="Création de service/site" />
              <div className="p-3"></div>
              <div className="row">
               <div className="col-md-6">
                   <div className="form-group row">
                       <label className="control-label col-md-4 my-auto font-weight-bold">Nom du groupe d'interception</label>
                       <div className="col-md-6">
                           <input type="text" className="form-control" />
                        </div>
                   </div>
                 </div>
             </div>
             <div className="p-2"></div>
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Selectionner les utilisateurs
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#sg" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Sélectionner les sonneries générales
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" role="tabpanel" id="users">
                <MaterialTable
                columns={[
                  { title: 'Nom', field: 'name' },
                  { title: 'Nombre de membres', field: 'nb', sorting: false},

                ]}
                data={[
                  { name: 'Angers', nb: '5'},
                  { name: 'Paris', nb: '4'},
                ]}
                title=""
                localization={{
                  toolbar: { searchPlaceholder: 'Rechercher' } ,
                  pagination: {
                    labelRowsPerPage: "Contacts par page",
                    labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
                  }
                }}
                options={{
                  pageSize: 10,
                  selection: true
                }}
                components={{
                 Toolbar: props => (
                   <div>
                     <div style={{padding: '0px 10px'}}>
                     </div>
                   </div>
                 ),
               }}
              />
              </div>

              <div className="tab-pane" role="tabpanel" id="sg">
                <MaterialTable
                columns={[
                  { title: 'Nom', field: 'name' }

                ]}
                data={[
                  { name: 'SG Demo'},
                  { name: 'SG Angers'},
                ]}
                title=""
                localization={{
                  toolbar: { searchPlaceholder: 'Rechercher' } ,
                  pagination: {
                    labelRowsPerPage: "Contacts par page",
                    labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
                  }
                }}
                options={{
                  pageSize: 10,
                  selection: true
                }}
                components={{
                 Toolbar: props => (
                   <div>
                     <div style={{padding: '0px 10px'}}>
                     </div>
                   </div>
                 ),
               }}
              />
              </div>

              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/mevogroup');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                          <button type="submit" className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default EditSiteService;
