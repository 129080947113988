import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import filter from 'lodash/filter';
import { getUsers } from 'components/commons/api/user';
import { hideModal } from 'actions/modalActions.js';
import { addDirectButton, removeDirectButtons } from 'actions/directActions.js';
import { notifyInfo } from 'components/commons/Toasts.js';
import Select from 'react-select';
import { getCompany } from 'actions/admin/companyActions';
import { getCompanyInformation } from '../../commons/api/admin/company';

class DirectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      secondInputDatas: [],
      isLoadingSecondInputDatas: false,
      secondInputTel: false,
      secondInputValue: "",
      label: "",
      params: "",
      users: [],
      myShortNumbers: [],
      boutonFunctionName: "",
      boutonParams: ""
    }

    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleSecondSelect = this.handleSecondSelect.bind(this);
    this.handleLabelChange  = this.handleLabelChange.bind(this);
    this.handleTelChange    = this.handleTelChange.bind(this);
    this.handleSecondSelectChange = this.handleSecondSelectChange.bind(this);
  }

  handleSecondSelect(event) {
    const value = event.target.value;
    this.setState({ secondInputValue: value });
  }

  handleSecondSelectChange(selectedOption) {
    this.setState({
      secondInputValue: selectedOption.target.value}, function() {
      console.log(this.state);
    });
  }

  handleLabelChange(event) {
    const label = event.target.value;
    this.setState({ label });
  }

  handleTelChange(event) {
    const secondInputValue = event.target.value;
    this.setState({ secondInputValue });
  }

  async componentDidMount() {
    const { user_id } = this.props.session.datas.data.user_id;
    const { admin } = this.props.session;


    if (isEmpty(this.state.users)) {
      const users = admin ? await getUsers(user_id) : await getUsers();
      this.setState({ users: users.data });
      console.log(users.data);
    }
  }

  async handleActionChange(event) {
    try {
      const value = event.target.value;
      var filteredUsers = [];
      this.setState({ isLoadingSecondInputDatas: true, secondInputDatas: [], secondInputTel: false, actionValue: value });

      switch (value) {
        case "F_NONE":
          this.setState({
            boutonFunctionName: "F_NONE",
            boutonParams: "",
          })

          break;
        case "F_SHORTNUMBER_COMPANY":
          const source = this.state.users;
          let shortNumbers = [];
          source.forEach((number) => {
            if (!isUndefined(number.s164) && !isEmpty(number.s164)) {
              return shortNumbers.push(number.s164);
            }
          });

          this.setState({ secondInputDatas: shortNumbers, secondInputTel: false, boutonFunctionName: "F_SHORTDIAL" });
          break;
        case "F_NUMBER":
          this.setState({ secondInputTel: true, secondInputValue: '', boutonFunctionName: "F_SHORTDIAL" });
          break;
        case "F_SHORTNUMBER":
          this.setState({ isLoadingSecondInputDatas: true });
          const company = await getCompanyInformation();
          this.setState({ isLoadingSecondInputDatas: false });
          this.setState({ secondInputDatas: Object.entries(company.data), secondInputTel: false, boutonFunctionName: "F_SHORTDIAL" });
          break;
        case "F_FORWARD":
          filteredUsers = this.state.users.filter(u => function() {
            return [1,2,4].includes(u.user_type);
          });

          filteredUsers.forEach(u => {
            u.label = (u.firstname || '') + ' ' + (u.lastname || '');
            u.value = u.user_id;
          })

          this.setState({
            secondInputDatas: filteredUsers,
            secondInputTel: false,
            boutonFunctionName: "F_FORWARD"
          });
          break;
        case "F_SUPERVISION_USER":
          filteredUsers = this.state.users.filter(u => function() {
            return [1,2,4].includes(u.user_type);
          });

          filteredUsers.forEach(u => {
            u.label = (u.firstname || '') + ' ' + (u.lastname || '');
            u.value = u.user_id;
          })

          this.setState({
            secondInputDatas: filteredUsers,
            secondInputTel: false,
            boutonFunctionName: "F_SUPERVISION_USER"
          });
          break;
        case "F_DIRECTORY_USER":
          filteredUsers = this.state.users.filter(u => function() {
            return [1,2,4].includes(u.user_type);
          });

          filteredUsers.forEach(u => {
            u.label = (u.firstname || '') + ' ' + (u.lastname || '');
            u.value = u.user_id;
          })

          this.setState({
            secondInputDatas: filteredUsers,
            secondInputTel: false,
            boutonFunctionName: "F_SHORTDIAL"
          });
          break;
        case "F_SUPERVISION_GROUP":
            filteredUsers = this.state.users.filter(u => u.user_type == 6);
  
            this.setState({
              secondInputDatas: filteredUsers,
              secondInputTel: false,
              boutonFunctionName: "F_SUPERVISION_GROUP"
            });
            break;
        case "F_VOICEMAIL":
          filteredUsers = this.state.users.filter(u => function() {
            return [1,2,4].includes(u.user_type);
          });

          filteredUsers.forEach(u => {
            u.label = (u.firstname || '') + ' ' + (u.lastname || '');
            u.value = u.user_id;
          })

          this.setState({
            secondInputDatas: [],
            secondInputTel: false,
            boutonFunctionName: "F_VOICEMAIL"
          });
          break;
        case "F_IVR_GROUP":
          filteredUsers = this.state.users.filter(u => u.user_type == 6);

          this.setState({
            secondInputDatas: filteredUsers,
            secondInputTel: false,
            boutonFunctionName: "F_VOICEMAIL_GROUP"
          });
          break;
        case "F_INTERCEPTION_RING":
        
          filteredUsers = this.state.users.filter(user => {
            if(!isEmpty(user.tty)) {
              if (user.tty[0].type == 1) {
                return true;
              }
            }
          });

          this.setState({
            secondInputDatas: filteredUsers,
            secondInputTel: false,
            boutonFunctionName: "F_INTERCEPTION_RING"
          }, function() { console.log(this.state); });
          break;
        case "F_INTERCEPTION_TRANSFER_PARK":
        case "F_INTERCEPTION_PARK":
          filteredUsers = this.state.users.filter(user => {
            if(!isEmpty(user.tty)) {
              if (user.tty[0].type == 2) {
                return true;
              }
            }
          });

          this.setState({
            secondInputDatas: filteredUsers,
            secondInputTel: false,
            boutonFunctionName: "F_INTERCEPTION_PARK"
          });
          break;
        case "F_TRANSFER_PARK":
          filteredUsers = this.state.users.filter(user => {
            if(!isEmpty(user.tty)) {
              if (user.tty[0].type == 2) {
                return true;
              }
            }
          });

          this.setState({
            secondInputDatas: filteredUsers,
            secondInputTel: false,
            boutonFunctionName: "F_TRANSFER_PARK"
          });
          break;
        case "F_PATRON_SECRETARY":
          break;
        case "F_HOLD":
          this.setState({
            secondInputDatas: [],
            secondInputTel: false,
            boutonFunctionName: "F_HOLD"
          });
          break;
        case "F_TRANSFER":
          this.setState({
            secondInputDatas: this.state.users,
            secondInputTel: false,
            boutonFunctionName: "F_TRANSFER"
          });
          break;
        case "F_CALLCENTER":
          filteredUsers = this.state.users.filter(u => u.user_type == 5);

          this.setState({
            secondInputDatas: filteredUsers,
            secondInputTel: false,
            boutonFunctionName: "F_CALLCENTER"
          });
          break;
        default:
          this.setState({ secondInputTel: false });
          break;

      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoadingSecondInputDatas: false });
    }

  }

  render() {
    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader color="primary" /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <div className="col-12">
        <div className="">
          <form>
            <div className="row">
              <div className="col-lg-12 mb-4">
                <h4 className="text-info">Cette modification ne sera prise en compte qu'au moment où vous appuierez sur "Enregister" en bas du module "Touches de fonction"</h4>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nom de la touche"
                  value={this.state.label}
                  onChange={this.handleLabelChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-5">
                <select value={this.state.actionValue} className="form-control" onChange={this.handleActionChange}>
                  <option>Choisissez une action</option>
                  <option value="F_NONE">Ne rien faire</option>
                  <optgroup label="Annuaire">
                    <option value="F_DIRECTORY_USER">Annuaire des utilisateurs</option>
                    <option value="F_SHORTNUMBER_COMPANY">Numéros courts de l'entreprise</option>
                    <option value="F_NUMBER">Numéro libre</option>
                  </optgroup>
                  <optgroup label="Services">
                    <option value="F_SHORTNUMBER">Mes numéros courts</option>
                    <option value="F_FORWARD">Renvoi</option>
                    <option value="F_VOICEMAIL">Boite vocale</option>
                    <option value="F_IVR_GROUP">Messagerie de groupe</option>
                    <option value="F_HOLD">Mise en attente</option>
                    <option value="F_TRANSFER">Transfert d'appel entre 2 communications</option>
                  </optgroup>
                  <optgroup label="Supervision">
                    <option value="F_SUPERVISION_USER">Supervision Utilisateur</option>
                    <option value="F_SUPERVISION_GROUP">Supervision de groupe</option>
                    <option value="F_CALLCENTER">Centre d'appels</option>
                    <option value="F_INTERCEPTION_RING">Interception Ring</option>
                    <option value="F_INTERCEPTION_TRANSFER_PARK">Interception/Transfert Park</option>
                    <option value="F_INTERCEPTION_PARK">Interception Park</option>
                    <option value="F_TRANSFER_PARK">Transfert Park</option>
                  </optgroup>
                </select>
              </div>
              {(!isEmpty(this.state.secondInputDatas) && this.state.actionValue === "F_SUPERVISION_GROUP") && <div className='form-group col-lg-6'>
                <Select options={this.state.secondInputDatas} isMulti onChange={this.handleSecondSelectChange} value={this.state.secondInputValue} />
              </div>}
              {(!isEmpty(this.state.secondInputDatas) && this.state.actionValue !== "F_SUPERVISION_GROUP") && <div className="form-group col-lg-6">
                <select className="form-control" value={this.state.secondInputValue} onChange={this.handleSecondSelectChange}>
                <option>Destinataire</option>
                {
                      this.state.secondInputDatas.map((data, index) => {
                        return <OptionRender data={data} key={index} actionValue={this.state.actionValue} />
                      })
                }
                </select>
              </div>}
              {this.state.secondInputTel && <div className="form-group col-lg-6">
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Numéro de téléphone"
                  value={this.state.secondInputValue || ""}
                  onChange={this.handleTelChange}
                />
              </div>}
              {this.state.isLoadingSecondInputDatas && <div className="form-group col-lg-1" style={loaderStyles}>
                <Preloader color="primary" />
              </div>}
            </div>
            <div className="row justify-content-end form-group">
              <div className="">
                <button className="btn btn-default mr-2" onClick={(e) => {
                  e.preventDefault();
                  this.props.removeDirectButtons();
                  return this.props.hideModal();
                }}>Annuler</button>{' '}
              </div>
              <div className="">
                <button className="btn btn-success" onClick={async (e) => {
                  e.preventDefault();
                  let button = this.props.buttons.editing;
                  button.params = this.state.secondInputValue;
                  button.function = this.state.boutonFunctionName;
                  button.label = this.state.label;
                  await this.props.addDirectButton(button);
                  notifyInfo("Bouton prêt à être sauvegardé");
                  return this.props.hideModal();
                }}>Ajouter aux boutons à sauvegarder</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const OptionRender = (props) => {
  const { actionValue, data, index } = props;

  if (actionValue === 'F_FORWARD') {
    return (
      <option value={`${data.user_id};${data.user_type}`} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
    );
  }

  if (actionValue === 'F_VOICEMAIL') {
    return (
      <option value={`${data.user_id};VoiceMail`} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
    );
  }

  if (actionValue === 'F_IVR_GROUP') {
    return (
      <option value={`${data.user_id};VoiceMail,${data.user_id}`} key={index}>{`${data.lastname || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
    );
  }

  if (
    actionValue === 'F_SUPERVISION_USER' ||
    actionValue === 'F_INTERCEPTION_RING' ||
    actionValue === 'F_INTERCEPTION_TRANSFER_PARK' ||
    actionValue === 'F_INTERCEPTION_PARK' ||
    actionValue === 'F_TRANSFER_PARK') {
    if (!isEmpty(data)) {
      return (
        <option value={data.tty[0].full_line_id} key={index}>{`${data.lastname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
      );
    }

    if (actionValue === 'F_SUPERVISION_GROUP') {
      return (
        <Select value={data[0] || ''} key={index} />
      );
    }

    return null;
  }

  return (<option value={data.alt_e164 || data.mobile || data[0] || data.user_id || ''} key={index}>{`${data.lastname || data[0] || ''} ${data.firstname || ''}${data.s164 ? ' - ' + data.s164[0] : ''}`}</option>
  );
}

const loaderStyles = {
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  height: "40px"
}

const mapStateToProps = (state) => {
  return ({
    buttons: state.buttons,
    session: state.kertelSession,
    company: state.company
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    hideModal,
    addDirectButton,
    removeDirectButtons,
    getCompany
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DirectModal);
