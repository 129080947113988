import React from 'react';
import { useNavigate } from 'react-router';

export default function NotFound () {
  const navigate = useNavigate();

  return (
    <section id="wrapper" className="error-page">
      <div className="error-box">
        <div className="error-body text-center">
          <h1>404</h1>
          <h3 className="text-uppercase">Page introuvable !</h3>
          <p className="text-muted m-t-30 m-b-30">Il semblerait que la page que vous tentez d'accéder n'existe plus.</p>
          <button
            className="btn btn-info btn-rounded waves-effect waves-light m-b-40"
            onClick={ () => {
                return navigate('/');
              }
            }
            >Revenir à l'accueil</button>
        </div>
      </div>
  </section>
  );
}
