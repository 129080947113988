import React, { Component } from 'react';
import Dialer from 'components/commons/Dialer.js';
import { connect } from 'react-redux';
import { composeNum } from 'actions/phoneActions.js';
import { bindActionCreators } from 'redux';
import trim from 'lodash/trim'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import { clickCall } from 'components/commons/helpers/phoneHelper';

class Phone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listenerAttached: false,
      num: ""
    }
    
    this.doCall = this.doCall.bind(this);
    this.composeNum = this.composeNum.bind(this);
  }

  doCall() {                    
    if (!isEmpty(this.state.num) ) {
      
      clickCall(this.state.num);

      this.setState({ num: "" });

      return;
    }
  }

  componentDidUpdate() {
    if (!this.state.listenerAttached && !isUndefined(this.props.phone)) {    
      const that = this;  
      document.querySelector("#phoneDialInput").addEventListener('keypress', function(e) {
        if (e.key === 'Enter') {
          that.doCall();

          return;
        }
      });

      this.setState({ listenerAttached: true });
    }
  }

  composeNum = (number) => {
    if (new RegExp("^[0-9#*+]*$").test(number) || ['#', '*', '+'].includes(number)) {
      if (number !== null) {
        return this.setState({ num: trim(number)  })
      }
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body p-b-0">
          <h4 className="card-title">Téléphone</h4>
          <div className="form-group row mb-4 mt-2">
            <div className="col-12">
              <input
                id="phoneDialInput"
                className="form-control"
                placeholder="Numéro de téléphone..."
                type="tel"
                value={this.state.num}
                onChange={(event) => {

                  return this.composeNum(event.target.value)
                }}
              />
            </div>
          </div>
          <div className="col-12" id="phonedial">
            <Dialer showSearch num={this.state.num} composeNum={this.composeNum} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    phone: state.kertelphone,
    company: state.company,
    session: state.kertelSession
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    composeNum
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Phone);
