import { updateMevoInformation} from 'components/commons/api/admin/mevo';
import { notifySuccess, notifyError } from 'components/commons/Toasts.js';

export const updateMevo = async (userId, informations, notif = true) => {
  try {
    await updateMevoInformation(userId, informations).then((result) => {
      if (result.status === 200) {
        if (notif) notifySuccess("Mevo mise à jour avec succès !");
        return;
      }
      
      notifyError("Echec lors de la lise à jour de la configuration Mevo !");
      return;
    })
  } catch (e) {
    console.log(e);
  }
}
