import React from 'react';
import CreateContact from 'components/views/modals/CreateContact';
import CreateCompanyContact from 'components/views/modals/CreateCompanyContact';
import CreateCompanyBill from 'components/views/modals/CreateCompanyBill';
import CreateBill from 'components/views/modals/CreateBill';
import SendFax from 'components/views/modals/SendFax';
import { showModal } from 'actions/modalActions';
import { clearCreateContactForm } from 'actions/formsActions';
import { clearCreateCompanyBillForm } from 'actions/formsActions';
import { clearCreateBillForm } from 'actions/formsActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { downloadCallsHist } from 'components/commons/api/phone.js';
import { stopEditingRingtoneGeneral } from 'actions/admin/ringtoneGeneralActions.js';
import { stopEditingWaitingQueue } from 'actions/admin/waitingQueueActions.js';
import { stopEditingCompanyContact } from 'actions/admin/companyContactActions.js';
import isEmpty from 'lodash/isEmpty';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';

function Breadcrumb ({
    showModal,
    clearCreateContactForm,
    stopEditingRingtoneGeneral,
    stopEditingWaitingQueue,
    stopEditingCompanyContact,
    downloadCallsHist,
    title,
    routes,
    url,
    add,
    addCompany,
    downloadCallsHistoric,
    addFax,
    addForward,
    addGi,
    addGs,
    addGr,
    addWq,
    addPc,
    addInboundManagement,
    addSviMenu,
    addSviFile,
    addUser,
    addBill
  }) {

    const navigate = useNavigate();

    return (
      <div className="row page-titles">
        <div className="col-md-6 col-8 align-self-center">
          <h3 className="text-themecolor m-b-0 m-t-0">{title}</h3>
          <ol className="breadcrumb">
          {!isEmpty(routes) && <li className="breadcrumb-item"><button onClick={() => { return navigate('/dashboard') }}>Accueil</button></li>}
            {
              !isEmpty(routes) && routes.map((route, index) => {
                return (<li key={index} className="breadcrumb-item">
                  {(index + 1) !== routes.length ? <button onClick={() => { return navigate(url); }}>{route}</button> : <React.Fragment>{route}</React.Fragment>}
                </li>);
              })
            }
          </ol>
        </div>
        {add && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              clearCreateContactForm();
              return showModal({
                title: "Créer un contact",
                component: CreateContact
              })
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter un contact
          </button>
        </div>}
        {addBill && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              clearCreateBillForm();
              return showModal({
                title: "Créer une facture",
                component: CreateBill
              })
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter une facture
          </button>

          <button
            onClick={() => {
              clearCreateCompanyBillForm();
              return showModal({
                title: "Créer une entité de facturation",
                component: CreateCompanyBill
              })
            }}
            className="btn pull-right hidden-sm-down btn-info mr-4"
          ><i className="mdi mdi-plus-circle"></i> Ajouter une entité de facturation
          </button>
        </div>}
        {addCompany && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              stopEditingCompanyContact();
              clearCreateContactForm();
              return showModal({
                title: "Créer un contact",
                component: CreateCompanyContact
              })
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter un contact entreprise
          </button>
        </div>}
        {downloadCallsHistoric && <div className="col-md-6 col-4 align-self-center"><DropdownButton id="dropdown-basic-button" title="Télécharger" className="pull-right">
            <Dropdown.Item onClick={async (e) => {
              e.preventDefault();

              try {
                const hist = await downloadCallsHist('inbound');
                console.log(hist);
              } catch (error) {
                console.log(error);
              }

              }}>Appels entrants</Dropdown.Item>
            <Dropdown.Item onClick={async (e) => {
              e.preventDefault();

              try {
                const hist = await downloadCallsHist('outbound');
                console.log(hist);
              } catch (error) {
                console.log(error);
              }

              }}>Appels sortants</Dropdown.Item>
          </DropdownButton>
          </div>
          }
        {addFax && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={(e) => {
              e.preventDefault();

              return showModal({
                title: "Envoyer un fax",
                component: SendFax
              })
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Envoyer un fax
          </button>
        </div>}
        {addForward && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              return null;
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter un profil de renvoi
          </button>
        </div>}
        {addGi && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              return navigate('/admin/creategroupinterception');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter un groupe d'interception
          </button>
        </div>}
        {addGs && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              return navigate('/admin/createmevogroup');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter une messagerie de groupe
          </button>
        </div>}
        {addGr && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              stopEditingRingtoneGeneral();
              return navigate('/admin/createringtonegeneral');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter une sonnerie générale
          </button>
        </div>}
        {addWq && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              stopEditingWaitingQueue();
              return navigate('/admin/createwaitingqueue');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter une file d'attente
          </button>
        </div>}
        {addPc && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              stopEditingWaitingQueue();
              return navigate('/admin/createprofilecalls');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter un profil d'appels
          </button>
        </div>}        {addInboundManagement && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              return navigate('/inbound-management/create');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Créer une règle
          </button>
        </div>}
        {addSviMenu && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              return navigate('/admin/svi/menu/create');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter un menu svi
          </button>
        </div>}
        {addSviFile && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              return navigate('/admin/svi/files/create');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter un fichier svi
          </button>
        </div>}
        {addUser && <div className="col-md-6 col-4 align-self-center">
          <button
            onClick={() => {
              return navigate('/admin/user/create');
            }}
            className="btn pull-right hidden-sm-down btn-success"
          ><i className="mdi mdi-plus-circle"></i> Ajouter un utilisateur
          </button>
        </div>}
      </div>
    );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal,
    clearCreateContactForm,
    clearCreateCompanyBillForm,
    clearCreateBillForm,
    stopEditingRingtoneGeneral,
    stopEditingWaitingQueue,
    stopEditingCompanyContact,
    downloadCallsHist
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Breadcrumb);
