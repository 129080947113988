import React, { Component, useEffect, useState } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsers } from 'components/commons/helpers/admin/userHelper.js';
import { fetchRingtonesGeneral, fetchRingtoneGeneral, updateRingtoneGeneral } from 'components/commons/helpers/admin/ringtoneGeneralHelper.js';
import { stopEditingRingtoneGeneral } from 'actions/admin/ringtoneGeneralActions.js';
import Preloader from 'components/commons/Preloader.js';
import EditRingtoneGeneralInfos from './EditRingtoneGeneralInfos.js';
import EditRingtoneGeneralMembers from './EditRingtoneGeneralMembers.js';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';

function EditRingtoneGeneral ({ ringtoneGeneral, company, users }) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [ringtoneGeneralState, setRingtoneGeneralState] = useState({
    "name": '',
    "company_id": ringtoneGeneral.checkTtyId,
    "tty_id": ringtoneGeneral.editing.ringId,
    "e164": [],
    "s164": [],
    "members": [],
    "dataMembers": [],
    "btLoad" : true,
    "btAllow" : false,
    "btForbidden" : false
  });

  function handleOptions(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setRingtoneGeneralState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function handleOptionsNumbers(type, number) {
  	if (type === 'addLong') ringtoneGeneralState.e164.push(number.long);
  	else if (type === 'addShort' && !isUndefined(number)) ringtoneGeneralState.s164.push(number.short);
  	else if (type === 'delLong') {
  	  let e164 = ringtoneGeneralState.e164;
  	  forEach(number, function(value, key) {
    		var index = e164.indexOf(value);
    		if (index > -1) e164.splice(index, 1);
  	  })

      setRingtoneGeneralState(prevState => ({
        ...prevState,
        e164: e164
      }));
  	}

  	else if (type === 'delShort' && !isUndefined(number)) {
  	  let s164 = ringtoneGeneralState.s164;
  	  forEach(number, function(value, key) {
    		var index = s164.indexOf(value);
    		if (index > -1) s164.splice(index, 1);
  	  })

      setRingtoneGeneralState(prevState => ({
        ...prevState,
        s164: s164
      }));
	  }
  }

  async function init() {
    fetchUsers();
    if (ringtoneGeneral.editing.isEditing) {
      try {
        await fetchRingtoneGeneral(ringtoneGeneral.editing.ringId).then((result) => {
		  let members = ringtoneGeneral.data.member;
		  var dataMembers = [];
		  forEach(users.datas, function(details) {
			if (!isUndefined(details.tty) && !isEmpty(details.tty) && (details.user_type === "1" || details.user_type === "2")) {
			  let user = {
				"user_id": details.user_id,
				"mail": !isUndefined(details.mail) ? details.mail : '',
				"firstname": !isUndefined(details.firstname) ? details.firstname : '',
				"lastname": !isUndefined(details.lastname) ? details.lastname : '',
				"group_id": details.group_id,
				"location_id": details.location_id,
				"user_type" : details.user_type,
				tableData: { checked: members.includes(details.user_id) ? true : false}
			  };
			  
			  dataMembers.push(user);
			}
		  })

      setRingtoneGeneralState(prevState => ({
        ...prevState,
        "name":ringtoneGeneral.data.name,
        "id":ringtoneGeneral.data.id,
        "e164": ringtoneGeneral.data.e164,
        "s164": ringtoneGeneral.data.s164,
        "members":ringtoneGeneral.data.member,
        "dataMembers":dataMembers,
      }));
    })
    } catch (e) {
      console.log(e);
    } finally {
	    setIsLoading(false);
	  }
  }
}

  useEffect(function() {
    init();
  }, []);

  async function updateRingtoneGeneral(){
    try {
	  let info = {
		members :  {
          "add": [],
          "delete": [],
        },
	  };
	  
	  if (!isUndefined(ringtoneGeneralState.name)) info.name = ringtoneGeneralState.name;
	  if (!isUndefined(ringtoneGeneralState.s164)) info.s164 = ringtoneGeneralState.s164;
	  if (!isUndefined(ringtoneGeneralState.e164)) info.e164 = ringtoneGeneralState.e164;
	  
	  forEach(ringtoneGeneralState.dataMembers, function(details) {
		if (details.tableData.checked === true) {
		  info.members.add.push(parseInt(details.user_id));
		}
	  })
	  
	  forEach(ringtoneGeneralState.members, function(user_id) {
		  if (!info.members.add.includes(user_id)) {
			info.members.delete.push(parseInt(user_id));
		  }
	  })
	  
      await updateRingtoneGeneral(ringtoneGeneral.editing.ringId, info);
      navigate('/admin/ringtonegeneral')
    } catch (e) {
      console.log(e);
    } finally {
	  await fetchRingtonesGeneral();
      return;
    }
  }

	  if (isLoading) return (<div className="col-lg-12 text-center"><Preloader /></div>);
    if (isEmpty(ringtoneGeneralState)) return navigate('/admin/ringtonegeneral');

    let numbers = {
      'long' : [],
      'short' : [],
      'lenghShort' : company.short_length
    };

    forEach(company.long, function(value, number) {
      if (isEmpty(value)) numbers.long.push(number);
    })

    forEach(company.short, function(value, number) {
      numbers.short.push(number);
    })
    
	  return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body p-b-0">
            <Breadcrumb routes={["Sonnerie générale", "Édition de Sonnerie générale"]} url="/admin/ringtonegeneral" title="Édition de Sonnerie générale" />
            <ul className="nav nav-tabs customtab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#infos" role="tab" onClick={fetchUsers}>
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Information générales
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#users" role="tab">
                  <span className="hidden-sm-up">
                    <i className="ti-home"></i>
                  </span>
                  <span className="hidden-xs-down">
                    Gestion des membres
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active p-20" role="tabpanel" id="infos">
                <EditRingtoneGeneralInfos
                  options={ringtoneGeneralState}
                  handleOptions={handleOptions}
                  handleOptionsNumbers={handleOptionsNumbers}
				  form="update"
                  numbers={numbers}
                />
              </div>
              <div className="tab-pane p-20" role="tabpanel" id="users">
                <EditRingtoneGeneralMembers
                  dataMembers={ringtoneGeneralState.dataMembers}
                  company={company}
                />
              </div>
              <div className="form-actions m-4">
                  <div className="row">
                      <div className="col-md-12 ">
                        <span className="float-right">
                          <button type="button" onClick={() => {
                            return navigate('/admin/ringtonegeneral');
                          }} className="btn btn-inverse mr-2">Annuler</button>
                        <button onClick={updateRingtoneGeneral} className="btn btn-success">Enregistrer</button>
                        </span>
                      </div>
                      <div className="col-md-6"> </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => {
  return {
    users: state.users,
    company: state.company,
    ringtoneGeneral: state.ringtoneGeneral
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopEditingRingtoneGeneral
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRingtoneGeneral);
