import React, { useEffect, useState } from "react";
import { isEmpty, isUndefined } from "lodash";
import { getTokenConnectAs } from "../commons/api/auth";
import { createSession } from "../../actions/authActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useNavigate, useParams } from "react-router";

function ConnectAs({ createSession }) {
    const { token, from } = useParams();

    const [setIsLoading] = useState(true);
    const [setError] = useState("");

    const navigate = useNavigate();

    useEffect(() => {

        async function getToken() {
            
            if (!isEmpty(token)) {
                const t = await getTokenConnectAs(token, from);
                localStorage.setItem("_k", t.data); 

                const decoded = jwt_decode(t.data);
                
                if (isUndefined(decoded.lvl)) {
                    setIsLoading(false);
                    setError("Échec de l’authentification.")
        
                    return;
                }
            
                createSession({
                    token_auth: t.data,
                    token_min: token,
                    decoded: decoded,
                    state: {
                    company: decoded.data.company,
                    password: decoded.data._p,
                    },
                    admin: decoded.lvl === "admin"
                });
            
                return navigate('/dashboard');        
            }
        }

        getToken();
    }, []);

    return (
        <div>Chargement...</div>
    )
}
  
const mapDispatchToProps = (dispatch) => {
return bindActionCreators({
    createSession,
}, dispatch);
}
  
  export default connect(null, mapDispatchToProps)(ConnectAs);