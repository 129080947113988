/* LOADING */

export const loadingFrequentContacts = () => {
  return { type: 'LOADING_FREQUENT_CONTACTS' }
};

export const loadingSearchContacts = () => {
  return { type: 'LOADING_SEARCH_CONTACTS' }
};

export const loadingCompanyContacts = () => {
  return { type: 'LOADING_COMPANY_CONTACTS' }
};

export const loadingUserContacts = () => {
  return { type: 'LOADING_USER_CONTACTS' }
};

export const loadingUserContact = () => {
  return { type: 'LOADING_USER_CONTACT' }
};

/* STOP LOADING */

export const stopLoadingFrequentContacts = () => {
  return { type: 'STOP_LOADING_FREQUENT_CONTACTS' }
};

export const stopLoadingSearchContacts = () => {
  return { type: 'STOP_LOADING_SEARCH_CONTACTS' }
};

export const stopLoadingCompanyContacts = () => {
  return { type: 'STOP_LOADING_COMPANY_CONTACTS' }
};

export const stopLoadingUserContacts = () => {
  return { type: 'STOP_LOADING_USER_CONTACTS' }
};

export const stopLoadingUserContact = () => {
  return { type: 'STOP_LOADING_USER_CONTACT' }
};


export const startEditingContact = (contactId) => {
  return {
    type: 'START_EDITING_CONTACT',
    payload: contactId
  }
};

export const stopEditingContact = () => {
  return { type: 'STOP_EDITING_CONTACT' }
};


export const startEditingBill = (contactId) => {
  return {
    type: 'START_EDITING_BILL',
    payload: contactId
  }
};

export const stopEditingBill = () => {
  return { type: 'STOP_EDITING_BILL' }
};


export const startEditingCompanyBill = (contactId) => {
  return {
    type: 'START_EDITING_COMPANY_BILL',
    payload: contactId
  }
};

export const stopEditingCompanyBill = () => {
  return { type: 'STOP_EDITING_COMPANY_BILL' }
};

/* FETCH */

export const fetchFrequentContacts = (contacts) => {
  return {
    type: 'FETCH_FREQUENT_CONTACTS',
    payload: contacts
  }
};

export const fetchSearchContacts = (contacts) => {
  return {
    type: 'FETCH_SEARCH_CONTACTS',
    payload: contacts
  }
};

export const fetchCompanyContacts = (contacts) => {
  return {
    type: 'FETCH_COMPANY_CONTACTS',
    payload: contacts
  }
};

export const fetchUserContacts = (contacts) => {
  return {
    type: 'FETCH_USER_CONTACTS',
    payload: contacts
  }
};

export const fetchUserContact = (contactId) => {
  return {
    type: 'FETCH_USER_CONTACT',
    payload: contactId
  }
};
