import React, { Component } from 'react';
import { ringback } from '../../actions/phoneActions.js';
import { showModal, hideModal } from '../../actions/modalActions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clickCall } from 'components/commons/helpers/phoneHelper';
import trim from 'lodash/trim'

class Dialer extends Component {

  render() {
    const keys = [
      { number: "1", letters: "" },
      { number: "2", letters: "ABC" },
      { number: "3", letters: "DEF" },
      { number: "4", letters: "GHI" },
      { number: "5", letters: "JKL" },
      { number: "6", letters: "MNO" },
      { number: "7", letters: "PQRS" },
      { number: "8", letters: "TUV" },
      { number: "9", letters: "WXYZ" },
      { number: "*", letters: "" },
      { number: "0", letters: "" },
      { number: "#", letters: "" },
    ];

    return (
      <div className="row m-b-20" id="phonedial">
        {this.props.white && <div className="row m-b-20" id="phonedialTwiceOptions">
          <button
            type="button"
            className="btn btn-circle btn-lg btn-success"
            onClick={() => clickCall(this.props.num)}>
            <i className="fa fa-phone"></i>
          </button>
          <div className="col-lg-1">&nbsp;</div>
          <button type="button" className="btn btn-circle btn-lg btn-success">
            <i className="fa fa-address-card"></i>
          </button>
        </div>}
        <div className="row">
          {keys.map((keyTouch, index) => {
            return (
              <div className="col-lg-4" key={index}>
                <button type="button" className={`col-12 btn ${this.props.white ? 'text-success bg-white' : 'btn-default'}`} onClick={() => {
                  const number = trim(this.props.num + keyTouch.number);

                  return this.props.composeNum(number);
                }}>
                  <span className="dialNumber">{keyTouch.number}</span><br />{keyTouch.letters}
                </button>
              </div>);
          })}
          {this.props.showSearch &&
            <div className="col-lg-4">
              <button
                type="button"
                className={`col-12 btn ${this.props.white ? 'text-success bg-white' : 'btn-default'}`}
                onClick={() => {

                  //return this.props.showModal({
                  //  title: "Nouvel appel",
                  //  component: NewCall
                  //});
                }}
              >
            </button>
          </div>}
          <div className="col-lg-4">
          <button type="button" className={`col-12 btn ${this.props.white
              ? 'text-success bg-white'
              : 'btn-default'} ${!this.props.phone.hasWebPhone && "disabled"}`} onClick={(e) => {
                e.preventDefault();
                
                return clickCall(this.props.num)
              }}>
            <i className="fa fa-phone fa-3x"></i>
          </button>
        </div>
          <div className="col-lg-4">
            <button
              type="button"
              className="col-12 btn btn-default"
              onClick={() => {
                const number = this.props.num.slice(0, -1);
                return this.props.composeNum(number);
              }}
              >
              <i className="mdi mdi-backspace fa-3x"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    phone: state.kertelphone
  });
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    ringback,
    showModal,
    hideModal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dialer);
