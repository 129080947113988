import React, { Component } from 'react';
import { notifyInfo } from 'components/commons/Toasts.js';
import { connect } from 'react-redux';
import { getForwardConfig, saveForwardConfig, saveDayForwardsConfig, getForwardsSet } from 'components/commons/api/forward.js';
import { activateCustomForwards, deactivateCustomForwards, addForwards } from 'actions/forwardActions.js';
import { bindActionCreators } from 'redux';
import ForwardLayout from './ForwardLayout';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import { getUsers } from 'components/commons/api/user';
import { getForwardsByDayId } from 'components/commons/api/forward.js';
import { getAlphabeticallyOrderedUsers } from 'components/commons/helpers/contactHelper.js';
import { clearSession } from '../../../../actions/authActions';

class ForwardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingForwards: true,
      usrValue: '',
      usrUserValue: '',
      busyUserValue: '',
      naUserValue: '',
      composantA: false,
      usrSecondSelect: false,
      complexDatas: [],
      usrComplexValue: null,
      busyComplexValue: null,
      naComplexValue: null,
      usrSecondInput: false,
      isLoadingAllUsr: false,
      isLoadingOccupation: false,
      myForwards: [],
      isSaving: false,
      users: [],
      usrAnnounceValue: 1,
      busyAnnounceValue: 1,
      naAnnounceValue: 1,
      timeout: 5
    };

    this.initState                      = this.initState.bind(this);
    this.handleRightInput               = this.handleRightInput.bind(this);
    this.saveForward                    = this.saveForward.bind(this);
    this.getUsersForwards               = this.getUsersForwards.bind(this);
    this.handleSecondsDelayInput        = this.handleSecondsDelayInput.bind(this);
    this.getComplexForwards             = this.getComplexForwards.bind(this);
    this.handleLeftSelect               = this.handleLeftSelect.bind(this);

    if (isUndefined(this.props.session)) this.props.clearSession();
  }

  async componentDidMount() {
    const config = {
      user_id: this.props.session.user_id
    }

    try {
      this.setState({ isLoadingForwards: true });
      let forwards = [];

      if (!isUndefined(this.props.dayId)) {
        config.day_id = this.props.dayId;
        const forw = await getForwardsByDayId(this.props.dayId, this.props.session.user_id);
        forwards = forw.data[this.props.dayId].forwards[0].forward;
        this.setState({ start: forw.data[this.props.dayId].forwards[0].start });
      } else {
        const forw  = await getForwardConfig(config);
        forwards    = forw.data;
        
        if (isEmpty(forwards.usr.forwards)) {
          forwards.usr.forwards = [{ dests:[{entity:{type:"PHONE", data:""}, timeout: this.state.timeout}] }]
        }

        if (isEmpty(forwards.busy.forwards)) {
          forwards.busy.forwards = [{ dests:[{entity:{type:"PHONE", data:""}, timeout: this.state.timeout}] }]
        } 

        if (isEmpty(forwards.na.forwards)) {
          forwards.na.forwards = [{ dests:[{entity:{type:"PHONE", data:""}, timeout: this.state.timeout}] }]
        }
        
        if (!isUndefined(forwards.usr.id) && forwards.usr.id > 0) {
          forwards.usr.forwards[0].dests[0].entity.type = "CONFIG";
          forwards.usr.forwards[0].dests[0].entity.data = { id: forwards.usr.id };
        }
      }

      this.initState('usr', forwards);    
      this.initState('busy', forwards);    
      this.initState('na', forwards);    

      this.props.addForwards(forwards);
      this.setState({ myForwards : forwards });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoadingForwards: false });
    }
  }

  initState(context, forwards) {
    if (forwards[context].forwards[0].dests[0].entity.type === "USER") {
      if (forwards[context].forwards[0].dests[0].entity.data.dest === "GSM") {        
        forwards[context].forwards[0].dests[0].entity.type = "USER_MOBILE";
        this.setState({ myForwards: forwards });
      } else {
        forwards[context].forwards[0].dests[0].entity.type = "USER_DIRECTORY";
        this.getUsersForwards(context);

        this.setState({
          [context + 'SecondSelect']: true,
          [context + 'SecondInput']: false,
          [context + 'Value']: "USER_DIRECTORY",
        });
      }
    }

    if (forwards[context].forwards[0].dests[0].entity.type === "CONFIG") {      
      this.getComplexForwards(context);

      this.setState({
        [context + 'SecondSelect']: true,
        [context + 'SecondInput']: false,
        [context + 'Value']: "CONFIG",
        [context + 'ComplexValue']: forwards[context].forwards[0].dests[0].entity.data.id,
      });
    }

    if (forwards[context].forwards[0].dests[0].entity.type === "MESSAGE") {      
      this.setState({
        [context + 'SecondSelect']: true,
        [context + 'SecondInput']: false,
        [context + 'Value']: "MESSAGE",
        [context + 'AnnounceValue']: forwards[context].forwards[0].dests[0].entity.data
      });
    }
  }

  async getComplexForwards(context) {
    this.setState({ ['isLoadingAll' + startCase(context)]: true });

    let rulesApi = [];
    let rules = [];
    try {
      this.setState({ isLoading: true });
      rulesApi = await getForwardsSet();
      rulesApi = Object.entries(rulesApi.data);
      rulesApi.forEach((rule, i) => {
        rules.push(rule[1]);
      });

      this.setState({ complexDatas: rules });

    } catch (e) {

    } finally {
      this.setState({ ['isLoadingAll' + startCase(context)]: false });
    }
  }

  async getUsersForwards(context) {
    if (!isEmpty(this.state.users)) {
      return null;
    }

    this.setState({ ['isLoadingAll' + startCase(context)]: true });

    try {
      const users = await getUsers();
      let filteredUsers = await getAlphabeticallyOrderedUsers(users.data);
      this.setState({ users: filteredUsers });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ ['isLoadingAll' + startCase(context)]: false });
    }
  }

  async handleLeftSelect (event, context) {
    try {
      if (context === "usr") {
        this.setState({ isLoadingAllUsr: true });
      }

      const { value } = event.target;
      let forward     = {forwards: [{dests: [{entity: {type: "NONE"}, timeout: this.state.timeout}]}]};
      let fwrdData    = forward.forwards[0].dests[0].entity;

      forward.forwards[0].dests[0].entity.type = value;

      switch (value) {
        case "PHONE": case "E164" : fwrdData.data = ""; break;
        case "USER_MOBILE":         fwrdData.data = { dest: "GSM", id: "" }; break;
        case "MEVO":                fwrdData.data = "DEFAULT"; break;
        case "MESSAGE":             fwrdData.data = "1"; break;
        case "USER_DIRECTORY":      this.getUsersForwards(context); fwrdData.data = {dest: "S164", id: this.props.session.id_user}; break;
        case "CONFIG":
          await this.getComplexForwards(context);
          let {complexDatas} = this.state;
          const targetValue = {value: !isEmpty(complexDatas) ? complexDatas[0].id : 0};
          
          fwrdData.data = forward.forwards[0].dests[0].entity.data = { id: !isEmpty(complexDatas) ? complexDatas[0].id : 0};
          this.handleRightInput({target: targetValue}, context, 'config'); break;
        default:
          return forward;
      }

      return this.setState(prevState => {
        return {
          [context + 'SecondSelect']: ["MESSAGE", "USER_DIRECTORY", "CONFIG"].includes(value),
          [context + 'SecondInput']: ["E164"].includes(value),
          [context + 'Value']: value,
          //['isLoadingAll' + startCase(context)]: ["USER_DIRECTORY", "CONFIG"].includes(value),
          myForwards: {
            ...prevState.myForwards,
            [context]: forward
          }
        }
      });
      
    } catch (error) {
      console.log(error);      
    } finally {
      this.setState({ isLoadingAllUsr: false });
    }
  }

  handleRightInput(event, context, typeOfInput) {
    
    try {
      let data      = null;
      let type      = '';
      let { value } = event.target;

      switch (typeOfInput) {
        case 'number':
          data = value;
          type = "E164"
          break;
        case 'user':
          data = { dest: "S164", id: value };
          type = 'USER_DIRECTORY';
          break;
        case 'message':
          data = value;
          type = 'MESSAGE';
          this.setState({ [context + 'AnnounceValue']: value });
          break;
        case 'config':
          data = { id: value };          
          type = 'CONFIG';
          this.setState({ [context + 'ComplexValue']: value, [context + 'Value']: 'CONFIG' });
          break;

        default:
          break;
      }

      return this.setState(prevState => {
        return {
          myForwards: {
            ...prevState.myForwards,
            [context]: {
              ...prevState[context],
              forwards: [{
                ...prevState.forwards,
                dests: [{
                  ...prevState.dests,
                  entity: {
                    ...prevState.entity,
                    data: data,
                    type: type
                  }
                }]
              }]
            }
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleSecondsDelayInput(event) {
    try {
      const timeout = event.target.value;
      let newNa     = this.state.myForwards.na;
      newNa.forwards[0].dests[0].timeout = timeout;

      return this.setState(prevState => {
        return {
          timeout,
          myForwards: {
            ...prevState.myForwards,
            na: newNa
          }
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoadingForwards: false });
    }
  }

  async saveForward() {
    try {
      this.setState({ isSaving: true });
      let forwardsClone = cloneDeep(this.state.myForwards);

      let usrForwards = forwardsClone.usr.forwards;
      if (usrForwards[0].dests[0].entity.type === "USER_MOBILE" || usrForwards[0].dests[0].entity.type === "USER_DIRECTORY" ) {
        usrForwards[0].dests[0].entity.type = "USER";
      }

      let busyForwards = forwardsClone.busy.forwards;
      if (busyForwards[0].dests[0].entity.type === "USER_MOBILE" || busyForwards[0].dests[0].entity.type === "USER_DIRECTORY" ) {
        busyForwards[0].dests[0].entity.type = "USER";
      }

      let naForwards = forwardsClone.na.forwards;
      if (naForwards[0].dests[0].entity.type === "USER_MOBILE" || naForwards[0].dests[0].entity.type === "USER_DIRECTORY" ) {
        naForwards[0].dests[0].entity.type = "USER";
      }

      if (usrForwards[0].dests[0].entity.type === "CONFIG") {          
        usrForwards = {id: usrForwards[0].dests[0].entity.data.id};
      }

      if (!isUndefined(this.props.dayId)) {
        await saveDayForwardsConfig(this.props.session.user_id, this.props.dayId, this.state.myForwards, this.state.start);
      } else {
        await saveForwardConfig({ forward_type: 'usr', forwards: usrForwards });
        await saveForwardConfig({ forward_type: 'busy', forwards: busyForwards });
        await saveForwardConfig({ forward_type: 'na', forwards: naForwards });
      }

      return notifyInfo("Renvois d'appels sauvegardés !");
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isSaving: false });
    }
  }

  render() {
    return <ForwardLayout
      activated={this.props.forward.activated}
      isLoadingAll={this.state.isLoadingAll}
      forwards={this.state.myForwards}
      complexDatas={this.state.complexDatas}     
      isLoadingForwards={this.state.isLoadingForwards}
      isSaving={this.state.isSaving}

      users={this.state.users}
      userValue={this.state.userValue}

      usrAnnounceValue={this.state.usrAnnounceValue}
      busyAnnounceValue={this.state.busyAnnounceValue}
      naAnnounceValue={this.state.naAnnounceValue}

      usrValue={this.state.usrValue}
      busyValue={this.state.busyValue}
      naValue={this.state.naValue}

      usrComplexValue={this.state.usrComplexValue}
      busyComplexValue={this.state.busyComplexValue}
      naComplexValue={this.state.naComplexValue}

      usrSecondSelect={this.state.usrSecondSelect}
      busySecondSelect={this.state.busySecondSelect}
      naSecondSelect={this.state.naSecondSelect}

      usrSecondInput={this.state.usrSecondInput}
      busySecondInput={this.state.busySecondInput}
      naSecondInput={this.state.naSecondInput}
      
      handleSecondsDelayInput={this.handleSecondsDelayInput}
      handleRightInput={this.handleRightInput}
      handleLeftSelect={this.handleLeftSelect}
      saveForward={this.saveForward}

      isLoadingAllUsr={this.state.isLoadingAllUsr}
      isLoadingAllNa={this.state.isLoadingAllNa}
      isLoadingAllBusy={this.state.isLoadingAllBusy}
    />
  }
}

const mapStateToProps = (state) => {
  return ({
    forward: state.forward,
    session: state.kertelSession.datas.data
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    activateCustomForwards,
    deactivateCustomForwards,
    addForwards,
    clearSession
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForwardContainer);
