import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import MaterialTable from 'material-table';
import Preloader from 'components/commons/Preloader.js';
import { showModal, hideModal } from 'actions/modalActions';
import { createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import UpdateFaxNumber from 'components/views/modals/UpdateFaxNumber';
import isEmpty from 'lodash/isEmpty';

class NumbersFax extends Component {

  constructor(props) {
    super(props);
    
    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: green[400],
        },
        secondary: {
          main: green[600],
        },
      },
    });
  }
  
  render() {
    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <MaterialTable
        columns={[
          { title: 'N°', field: 'number' },
          { title: '', field: 'user_id', hidden: true},
          { title: 'Affecté à', field: 'user'},
          {
            title: 'Description', field: 'user_type',
            lookup: {
              '1': 'Utilisateur',
              '2': 'Utilisateur',
              '3': "Centre d'appel",
              '4': "Utilisateur",
              '5': "Centre d'appel",
              '6': "Messagerie de groupe",
              '7': "File d'attente",
              '8': "Sonnerie générale",
              '9': "Ipbx",
            }
          },
        ]}
        data={ this.props.numbers }
        title=""
        localization={
          {
            toolbar: {
              searchPlaceholder: 'Rechercher',
            },
            body: {
              emptyDataSourceMessage: 'Aucun enregistrement à afficher',
              filterRow: {
                  filterTooltip: 'Filtre'
              }
            },
            pagination: {
              labelRowsPerPage: "Numéro par page",
              labelDisplayedRows: "{from}-{to} sur {count}",
labelRowsSelect: "lignes",
            },
          }
        }
        options={{
          pageSize: 10,
          actionsColumnIndex: -1
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Modifier',
            onClick: (event, rowData) => {
              return this.props.showModal({
                title: "Assignation du numéro",
                component: UpdateFaxNumber,
                numbers: rowData,
              });
            },
          },
          rowData => ({
            icon: 'delete',
            /*tooltip: 'Supprimer',*/
            disabled: isEmpty(rowData.user_id),
            onClick: (event, rowData) => {
              this.props.removeNumberUser('fax', rowData.user_id, '+33' + rowData.number.replace(/\s/g, '').substr(1));
            },
          })
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    users: state.users,
    company: state.company
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal,
    hideModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NumbersFax);
