import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSviConfig, updateSviConfig } from 'components/commons/helpers/admin/sviHelper.js';
import * as go from 'gojs';
import isUndefined from 'lodash/isUndefined';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import swal from 'sweetalert';
import Popup from "reactjs-popup";
import SviConfigDiagramInfos from './SviConfigDiagramInfos';
import { getConfigAuth } from 'components/commons/api/auth.js';

class SviConfig extends Component
{  
  constructor(props) {
    super(props);
    
	this.state = {
      isLoading: true,
      nodeSvi: {
        tree_id : "",
        id: "",
        name: "",
        parent: "",
        details: "",
        param1: "",
        param2: "",
        param3: "",
        comment: "",
        tts: false,
      },
      infos: {
        open: false,
		link: false,
        node: {}
      },
      actions: {
        'SAY': "Joue le fichier & va au prochain élément ID.",
        'SAY_ONE_DIGIT': "Joue le fichier & va à la touche tapée.",
        'SAY_MULTI_DIGIT': "Joue le fichier & va à la suite de touches tapées finies par #.",
        'LUA': "Execute le LUA & va au résultat.",
        'CALL': "Appelle le numéro & choisis l'id du poste de l'appelant.",
        'CALL_MOH': "Appelle le numéro (avec un ringback personnalisé) & choisis l'id du poste de l'appelant & joue le fichier ringback.",
        'HTTP_REQUEST_MULTI_DIGIT': "Joue le fichier & attend la saisi de digit et appelle l'url.",
        'HTTP_REQUEST': "Joue le fichier & appelle l'url.",
        'SAY_DATE': "Énonce la date contenue dans le buffer et va au prochain élément ID.",
        'SAY_DATETIME': "Énonce la date/heure contenue dans le buffer et va au prochain élément ID.",
        'SAY_TIME': "Énonce l'heure contenue dans le buffer et va au prochain élément ID.",
        'SAY_NUMBER': "Énonce le nombre contenu dans le buffer et va au prochain élément ID.",
        'SAY_DIGITS': "Énonce le chiffre contenu dans le buffer et va au prochain élément ID.",
        'SAY_PHONETIC': "Énonce le caractère contenu dans le buffer et va au prochain élément ID.",
        'SET_BUFFER': "Insère le contenu dans le buffer et va au prochain élément ID.",
        'JUMP': "Va directement au prochain élément ID.",
        'EXIT': 'Quitte le menu',
      },
	  actionsWithTouch: ['SAY', 'SAY_ONE_DIGIT', 'SAY_MULTI_DIGIT', 'LUA']
    }
    
    this.initDiagram = this.initDiagram.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addNode = this.addNode.bind(this);
    this.updateParentNode = this.updateParentNode.bind(this);
	this.deleteNode = this.deleteNode.bind(this);
  }
  
  async openModal(e, obj) {
    
    await fetchSviConfig(this.props.svi.configuring.menuId, false);
    
    var node = obj.part;
    
    if (node !== null) {
      
      let infos = {
        open: true,
		link: this.state.infos.link,
        node: node.data
      };
      
      if (!isUndefined(this.props.svi.configuring.data.nodeDataArray)) {
        forEach(this.props.svi.configuring.data.nodeDataArray, function(details) {
          if (details.id === node.data.id) {
            infos.node = details;
          }
        })
      }
      
      this.setState(prevState => {
        return {
          ...prevState,
          infos: infos
        }
      });
    }
  }
  
  closeModal() {
    this.setState(prevState => {
      return {
        ...prevState,
        infos: {
          ...prevState.infos,
          open: false,
          node: {}
        }
      }
    });
  }
  
  /**
   * Diagram initialization method, which is passed to the ReactDiagram component.
   * This method is responsible for making the diagram and initializing the model and any templates.
   * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
   */
  initDiagram(menu_id, resources) {
    
	let setting = {
	  'node' : true,
	  'lvlColor' : true,
	  'links' : true,
	  'contextMenu' : true,
	};
	
    var $ = go.GraphObject.make;  // for conciseness in defining templates
    
    var projectDiagramDiv = document.getElementById("myDiagramDiv");
    var projectDiagram = go.Diagram.fromDiv(projectDiagramDiv);
    if (projectDiagram) {
      projectDiagram.div = null;
    }
    
	const that=this;
	
    let myDiagram =
      $(go.Diagram, "myDiagramDiv",  // must name or refer to the DIV HTML element
        {
          grid: $(go.Panel, "Grid",
            $(go.Shape, "LineH", { stroke: "lightgray", strokeWidth: 0.5 }),
            $(go.Shape, "LineH", { stroke: "gray", strokeWidth: 0.5, interval: 10 }),
            $(go.Shape, "LineV", { stroke: "lightgray", strokeWidth: 0.5 }),
            $(go.Shape, "LineV", { stroke: "gray", strokeWidth: 0.5, interval: 10 })
          ),
		  maxSelectionCount: 1,
          "animationManager.initialAnimationStyle": go.AnimationManager.None,
          "InitialAnimationStarting": function(e) {
              var animation = e.subject.defaultAnimation;
              animation.easing = go.Animation.EaseOutExpo;
              animation.duration = 900;
              animation.add(e.diagram, 'scale', 0.1, 1);
              animation.add(e.diagram, 'opacity', 0, 1);
          },
          // have mouse wheel events zoom in and out instead of scroll up and down
          "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
		  // Allow remove node but not of link
		  "InitialLayoutCompleted": function(e) {
			e.diagram.nodes.each(function(n) { n.deletable = true; });
			e.diagram.links.each(function(l) { l.deletable = false; });
		  },
          // enable undo
          "undoManager.isEnabled": true,
          // Scheme : Tree 
          layout:
            $(go.TreeLayout,
              {
                treeStyle: go.TreeLayout.StyleLastParents,
                arrangement: go.TreeLayout.ArrangementHorizontal,
                // properties for most of the tree:
                angle: 90,
                layerSpacing: 75,
                // properties for the "last parents":
                alternateAngle: 90,
                alternateLayerSpacing: 75,
                alternateAlignment: go.TreeLayout.AlignmentBus,
                alternateNodeSpacing: 200
              }),
          positionComputation: function (diagram, pt) {
            return new go.Point(Math.floor(pt.x), Math.floor(pt.y));
          }
        });
    
    // define the Node template
    if (setting.node) {
      myDiagram.nodeTemplate =
        $(go.Node, "Auto",
          {
			doubleClick: function(e, obj) {
			  var node = obj.part;
			  if (node !== null) {
				
				that.setState(prevState => {
				  return {
					...prevState,
					infos: {
					  ...prevState.infos,
					  link: false,
					}
				  }
				});
				
				that.openModal(e, obj);
			  }
			},
			cursor: "pointer"},
          { // handle dragging a Node onto a Node to (maybe) change the reporting relationship
            mouseDragEnter: function(e, node, prev) {
              var diagram = node.diagram;
              var selnode = diagram.selection.first();
              if (!that.mayWorkFor(selnode, node)) return;
              var shape = node.findObject("SHAPE");
              if (shape) {
                shape._prevFill = shape.fill;  // remember the original brush
                shape.fill = "darkred";
              }
            },
            mouseDragLeave: function(e, node, next) {
              var shape = node.findObject("SHAPE");
              if (shape && shape._prevFill) {
                shape.fill = shape._prevFill;  // restore the original brush
              }
            },
            mouseDrop: function(e, node) {
              var diagram = node.diagram;
              var selnode = diagram.selection.first();  // assume just one Node in selection
              if (that.mayWorkFor(selnode, node)) {
                // find any existing link into the selected node
                var link = selnode.findTreeParentLink();
				var thisemp = selnode.data;
				
				if (selnode !== null) {
				  let infos = {
					id: thisemp.id,
					parent: node.key,
					name: thisemp.name,
					"type": 'update',
					"onlyParent": true
				  }
				  
				  if (link !== null) {  // reconnect any existing link
				    if (infos.parent !== link.fromNode.data.id) that.updateParentNode(menu_id, infos);
				  } else {  // else create a new link
					that.updateParentNode(menu_id, infos);
				  }
				}
              }
            }
          },
          // for sorting, have the Node.text be the data.name
          new go.Binding("text", "name"),
          // bind the Part.layerName to control the Node's layer depending on whether it isSelected
          new go.Binding("layerName", "isSelected", function(sel) { return sel ? "Foreground" : ""; }).ofObject(),
          // define the node's outer shape
          $(go.Shape, "Rectangle",
            {
              name: "SHAPE", fill: "#333333", stroke: 'white', strokeWidth: 3.5,
              // set the port properties:
              portId: "", fromLinkable: false, toLinkable: false, cursor: "pointer"
            }),
          $(go.Panel, "Horizontal",
            $(go.Picture,
              {
                name: "Picture",
                desiredSize: new go.Size(50, 50),
                margin: 1.5,
              },
              new go.Binding("source", "name", this.findHeadShot)),
            // define the panel where the text will appear
            $(go.Panel, "Table",
              {
                minSize: new go.Size(130, NaN),
                maxSize: new go.Size(180, NaN),
                margin: new go.Margin(6, 10, 0, 6),
                defaultAlignment: go.Spot.Left
              },
              $(go.RowColumnDefinition, { column: 2, width: 4 }),
              $(go.TextBlock,
                {
                  font: "9pt Segoe UI,sans-serif",
                  stroke: "white",
                  row: 0, column: 0, columnSpan: 5,
                  editable: false, isMultiline: false,
                  minSize: new go.Size(10, 16)
                },
                new go.Binding("text", "name", function(name) { return that.state.actions[name]; }).makeTwoWay()),
              $(go.TextBlock, 
                {
                  font: "9pt  Segoe UI,sans-serif",
                  stroke: "white",
                  row: 2, column: 0,
                  margin: new go.Margin(6, 0, 0, 0)
                },
                new go.Binding("text", "id", function(id) { return "ID: " + id; })),
              $(go.TextBlock, 
                {
                  font: "9pt Segoe UI,sans-serif",
                  stroke: "white",
                  name: "parent",
                  row: 2, column: 3,
                  margin: new go.Margin(6, 0, 0, 0)
                },
                new go.Binding("text", "parent", function(v) { return !isNil(v) ? "Père: " + v : "Père: ??? "; })),
            )  // end Table Panel
          ) // end Horizontal Panel
        );  // end Node
    }
    
    // color lvl
    if (setting.lvlColor) {
      var levelColors = ["#AC193D", "#8C0095", "#5133AB", "#008299", "#D24726", "#008A00", "#094AB2"];
      // override TreeLayout.commitNodes to also modify the background brush based on the tree depth level
      myDiagram.layout.commitNodes = function() {
        go.TreeLayout.prototype.commitNodes.call(myDiagram.layout);  // do the standard behavior
        // then go through all of the vertexes and set their corresponding node's Shape.fill
        // to a brush dependent on the TreeVertex.level value
        myDiagram.layout.network.vertexes.each(function(v) {
          if (v.node) {
            var level = v.level % (levelColors.length);
            var color = levelColors[level];
            var shape = v.node.findObject("SHAPE");
            if (shape) shape.stroke = $(go.Brush, "Linear", { 0: color, 1: go.Brush.lightenBy(color, 0.05), start: go.Spot.Left, end: go.Spot.Right });
          }
        });
      };
    }
    
    // replace the default Link template in the linkTemplateMap
    if (setting.links) {
      myDiagram.linkTemplate =
        $(go.Link,  // the whole link panel
          {
			doubleClick: function(e, obj) {
			  e.diagram.select(obj.part);
			  
			  var node = obj.part;
			  if (node !== null) {
				
				let infos = {};
				infos["id"] = node.data.to;
				if (!isUndefined(that.props.svi.configuring.data.nodeDataArray)) {
				  forEach(that.props.svi.configuring.data.nodeDataArray, function(details) {
					if (details.id === infos.id) {
					  infos["name"] = details.name;
					}
				  })
				}
				
				if (that.state.actionsWithTouch.indexOf(infos["name"]) === -1) return;
				
				that.setState(prevState => {
				  return {
					...prevState,
					infos: {
					  ...prevState.infos,
					  link: true,
					}
				  }
				});
				
				that.openModal(e, obj);
			  }
			},
            routing: go.Link.AvoidsNodes,
            curve: go.Link.JumpOver,
            corner: 1, toShortLength: 4,
            relinkableFrom: false,
            relinkableTo: false,
            reshapable: true,
            resegmentable: true,
            // mouse-overs subtly highlight links:
            mouseEnter: function(e, link) { link.findObject("HIGHLIGHT").stroke = "rgba(30,144,255,0.2)"; },
            mouseLeave: function(e, link) { link.findObject("HIGHLIGHT").stroke = "transparent"; },
            selectionAdorned: false
          },
          new go.Binding("points").makeTwoWay(),
          $(go.Shape,  // the highlight shape, normally transparent
            { isPanelMain: true, strokeWidth: 2, stroke: "gray" }),
          $(go.Shape,  // the highlight shape, normally transparent
            { isPanelMain: true, strokeWidth: 8, stroke: "transparent", name: "HIGHLIGHT" }),
          $(go.Shape,  // the link path shape(the regular link template => gray color)
            { isPanelMain: true, strokeWidth: 2 },
            new go.Binding("stroke", "color"),
            new go.Binding("strokeDashArray", 'color', 'dash'),
          ),
          $(go.Shape,  // the arrowhead
            { toArrow: "standard", strokeWidth: 0, fill: "gray" }),
          $(go.TextBlock,
            {
              background: "white",
              visible: false,  // unless the binding sets it to true for a non-empty string
              segmentIndex: -2,
              segmentOffset: new go.Point(NaN, NaN),
              segmentOrientation: go.Link.None
            },
            new go.Binding("text", "userKey"),
            // hide empty string: if the "userKey" property is undefined, visible is false due to above default setting
            new go.Binding("visible", "userKey", function(a) { return (a ? true : false); })
          )
        );
    }
    
    // the context menu allows users to create action svi
    if (setting.contextMenu) {
	  // also define a context menu for the diagram's background
	  myDiagram.contextMenu =
		$("ContextMenu",
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY),
            {
              name: "SAY",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_ONE_DIGIT),
            {
              name: "SAY_ONE_DIGIT",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_MULTI_DIGIT),
            {
              name: "SAY_MULTI_DIGIT",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.LUA),
            {
              name: "LUA",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.CALL),
            {
              name: "CALL",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.CALL_MOH),
            {
              name: "CALL_MOH",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.HTTP_REQUEST_MULTI_DIGIT),
            {
              name: "HTTP_REQUEST_MULTI_DIGIT",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.HTTP_REQUEST),
            {
              name: "HTTP_REQUEST",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_DATE),
            {
              name: "SAY_DATE",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_DATETIME),
            {
              name: "SAY_DATETIME",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_TIME),
            {
              name: "SAY_TIME",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_NUMBER),
            {
              name: "SAY_NUMBER",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_DIGITS),
            {
              name: "SAY_DIGITS",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_PHONETIC),
            {
              name: "SAY_PHONETIC",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SET_BUFFER),
            {
              name: "SET_BUFFER",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.JUMP),
            {
              name: "JUMP",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.EXIT),
            {
              name: "EXIT",
              click: this.addNode
            }
          ),
		);
	
      myDiagram.nodeTemplate.contextMenu =
        $("ContextMenu",
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY),
            {
              name: "SAY",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_ONE_DIGIT),
            {
              name: "SAY_ONE_DIGIT",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_MULTI_DIGIT),
            {
              name: "SAY_MULTI_DIGIT",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.LUA),
            {
              name: "LUA",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.CALL),
            {
              name: "CALL",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.CALL_MOH),
            {
              name: "CALL_MOH",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.HTTP_REQUEST_MULTI_DIGIT),
            {
              name: "HTTP_REQUEST_MULTI_DIGIT",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.HTTP_REQUEST),
            {
              name: "HTTP_REQUEST",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_DATE),
            {
              name: "SAY_DATE",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_DATETIME),
            {
              name: "SAY_DATETIME",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_TIME),
            {
              name: "SAY_TIME",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_NUMBER),
            {
              name: "SAY_NUMBER",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_DIGITS),
            {
              name: "SAY_DIGITS",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SAY_PHONETIC),
            {
              name: "SAY_PHONETIC",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.SET_BUFFER),
            {
              name: "SET_BUFFER",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.JUMP),
            {
              name: "JUMP",
              click: this.addNode
            }
          ),
          $("ContextMenuButton",
            $(go.TextBlock, this.state.actions.EXIT),
            {
              name: "EXIT",
              click: this.addNode
            }
          ),
        );
    }
    
	// Manage  key combination
	myDiagram.commandHandler.doKeyDown = function() {
	  var e = myDiagram.lastInput;
	  // The meta (Command) key substitutes for "control" for Mac commands
	  var control = e.control || e.meta;
	  var key = e.key;
	  
	  // Quit any not allowed key combination:
	  if (key === 'Backspace') return;
	  else if (control && (key === 'Y' || key === 'A' || key === 'X' || key === 'C' || key === 'V')) return;
	  
	  // Confirm deleted Node
	  var data = [];
	  e.diagram.selection.each(function(n) {
		if (!n instanceof go.Node) return;
		data = n.data;
	  })
	  
	  if (key === "Del" && !isEmpty(data)) {
		that.deleteNode(data);
		return;
	  }
	  
	  // call base method with no arguments (default functionality)
	  go.CommandHandler.prototype.doKeyDown.call(this);
	};
  
    // load an initial diagram from some JSON text
    myDiagram.model = go.Model.fromJson(resources);
    
    // Setup zoom to fit button
    document.getElementById('zoomToFit').addEventListener('click', function() {
      myDiagram.commandHandler.scrollToPart(myDiagram.findNodeForKey(0));
      myDiagram.commandHandler.zoomToFit();
    });
    
    // Setup delete node button
    document.getElementById('deleteNode').addEventListener('click', function() {
	  myDiagram.selection.each(function(part) {
		if (part instanceof go.Link) {
		  return swal({
			type: 'error',
			icon: "error",
			title: 'Oops...',
			text: "[Suppression de lien impossible] veuillez supprimer directement l'élément associé.",
			confirmButtonColor: 'LightSeaGreen',
		  })
		}
		else if (part instanceof go.Node) {
		  that.deleteNode(part.data);
		}
	  })
    });
	
    if (!projectDiagram) {
      // Overview
      $(go.Overview, "myOverview",  // the HTML DIV element for the Overview
        { observed: myDiagram, contentAlignment: go.Spot.Center });   // tell it which Diagram to show and pan
    }
  }
  
  // This converter is used by the Picture.
  findHeadShot(key) {
    return "/images/" + key + ".png";
  }
  
  // clicking the button inserts a new node to the right of the selected node or background (direct database insertion)
  async addNode(e, obj) {
	
	let parent = null;
	
	// get the node parent
	if (!isUndefined(obj.part.adornedPart) && !isNil(obj.part.adornedPart)) {
	  if (!isUndefined(obj.part.adornedPart.data) && !isNil(obj.part.adornedPart.data)) {
		if (!isUndefined(obj.part.adornedPart.data.id)) {
		  parent = obj.part.adornedPart.data.id;
		}
	  }
	}
	
    try {
	  let infos = {
		"name": obj.name,
		"parent": parent,
		"type": 'add',
	  };
	  
	  await updateSviConfig(this.props.svi.configuring.menuId, infos);
    } catch (e) {
      console.log(e);
    } finally {
      await fetchSviConfig(this.props.svi.configuring.menuId);
    }
  }
  
  // drag/drop parent node (direct database update)
  async updateParentNode(menu_id, infos) {
	try {
	  await updateSviConfig(menu_id, infos);
	  await fetchSviConfig(menu_id);
	} catch (e) {
	  console.log(e);
	} finally {
	}
  }
  
  // Delete node
  async deleteNode(data) {
	
    if (isUndefined(data.id)) return;
	
	if (!isUndefined(this.props.svi.configuring.data.nodeDataArray)) {
	  if (this.props.svi.configuring.data.nodeDataArray.length > 1 && data.id === 0) {
		return swal({
		  type: 'error',
		  icon: "error",
		  title: 'Oops...',
		  text: "[Suppression impossible] veuillez supprimer les autres éléménts avant l'élément père.",
		  confirmButtonColor: 'LightSeaGreen',
		})
	  }
	}
	
	let check = false;
	if (!isUndefined(this.props.svi.configuring.data.linkDataArray)) {
	  forEach(this.props.svi.configuring.data.linkDataArray, function(node) {
		if (data.id === node.from && node.color === "gray") check = true;
	  })
	}
	
	if (check) {
	  return swal({
		type: 'error',
		icon: "error",
		title: 'Oops...',
		text: '[Suppression impossible] existence des éléments fils.',
		confirmButtonColor: 'LightSeaGreen',
	  })
	}
	
	swal({
	  title: 'Etes-vous sûr de vouloir supprimer cet élément?',
	  text: "Une fois supprimé, vous ne pourrez pas récupérer cet élément !",
	  icon: "warning",
	  buttons: {
		check: {
		  text: "Ok",
		  value: "willDelete",
		}
	  },
	  dangerMode: true,
	})
	.then((value) => {
	  if (value === "willDelete") {
		let infos = {
		  "id": data.id,
		  "type": 'delete',
		};
		
		try {
		  updateSviConfig(this.props.svi.configuring.menuId, infos);
		} catch (e) {
		  console.log(e);
		} finally {
		  fetchSviConfig(this.props.svi.configuring.menuId);
		}
	  }
	});
  }
  
  // this is used to determine feedback during drags
  mayWorkFor(node1, node2) {
	if (!(node1 instanceof go.Node)) return false;  // must be a Node
	if (node1 === node2) return false;  // cannot work for yourself
	if (node2.isInTreeOf(node1)) return false;  // cannot work for someone who works for you
	return true;
  }
  
  async componentDidMount() {
	this.props.handleOptions(true, true, []);
	await fetchSviConfig(this.props.svi.configuring.menuId, false);
	this.props.handleOptions(false, this.props.svi.configuring.data.consistency.status, this.props.svi.configuring.data.consistency.messages);
	
	await getConfigAuth('svi').then((result) => {
	  if (result.status === 200) {
		this.setState(prevState => {
		  return {
			...prevState,
			actions: result.data
		  }
		});
	  }
    });
	
    this.initDiagram(this.props.svi.configuring.menuId, this.props.svi.configuring.data);
  }
  
  render() {
    return (
	  <React.Fragment>
		<div id="myOverview" className="overView-component"></div>
		<div id="myDiagramDiv" className="diagram-component"></div>
        <Popup
          open={this.state.infos.open}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div className="modalPopup">
            <span className="close" onClick={this.closeModal}>
              &times;
            </span>
            <div className="header"> Informations </div>
            <div className="content" style={{padding: "2%", maxHeight: "600px",overflowY: "scroll",overflowX: "hidden"}}>
              <SviConfigDiagramInfos
                menuId={this.props.svi.configuring.menuId}
                node={this.state.infos.node}
                closeModal={this.closeModal.bind(this)}
                nodes={this.props.svi.configuring.data}
				infos={this.state.infos}
				actions={this.state.actions}
              />
            </div>
          </div>
        </Popup>
	  </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SviConfig);
