import { createStore, combineReducers } from 'redux';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import kertelSession from 'reducers/authReducer.js';
import inbox from '../reducers/inboxReducer.js';
import users from '../reducers/admin/userReducer.js';
import company from '../reducers/admin/companyReducer.js';
import interception from '../reducers/admin/interceptionGroupReducer.js';
import mevoGroup from '../reducers/admin/mevoGroupReducer.js';
import ringtoneGeneral from '../reducers/admin/ringtoneGeneralReducer.js';
import waitingQueue from '../reducers/admin/waitingQueueReducer.js';
import companyContact from '../reducers/admin/companyContactReducer.js';
import profileCalls from '../reducers/admin/profileCallsReducer.js';
import contacts from '../reducers/contactsReducer.js';
import kertelphone from '../reducers/phoneReducer.js';
import fax from '../reducers/faxReducer.js';
import modal from '../reducers/modalReducer.js';
import scheduler from '../reducers/schedulerReducer.js';
import notifications from '../reducers/notificationsReducer.js';
import forward from '../reducers/forwardReducer.js';
import music from '../reducers/musicReducer.js';
import settings from 'reducers/settingsReducer.js';
import voiceMail from 'reducers/voiceMailReducer.js';
import messagesMevoGroup from 'reducers/messagesMevoGroupReducer.js';
import buttons from 'reducers/directReducer.js';
import records from 'reducers/admin/recordsReducer.js';
import bills from 'reducers/admin/billsReducer.js';
import supervision from 'reducers/admin/supervisionReducer.js';
import svi from 'reducers/admin/sviReducer.js';
import im from 'reducers/imReducer.js';
import { persistStore, persistReducer  } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

const reducers = combineReducers({
  kertelSession,
  users,
  company,
  interception,
  mevoGroup,
  ringtoneGeneral,
  waitingQueue,
  inbox,
  contacts,
  companyContact,
  profileCalls,
  kertelphone,
  fax,
  modal,
  notifications,
  forward,
  music,
  settings,
  voiceMail,
  messagesMevoGroup,
  buttons,
  scheduler,
  records,
  bills,
  supervision,
  svi,
  im
});

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet
}

const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
  let store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  let persistor = persistStore(store)
  return { store, persistor }
}

export const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
