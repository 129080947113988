import React, {Component} from 'react';
import Timer from 'react-compound-timer';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { setCallTimer } from 'actions/phoneActions';

class CallTimer extends Component {

    render() {  
        return (
            <Timer startImmediately={true}>
                {
                  ({start}) => (<React.Fragment>
                    <div>
                      <Timer.Hours formatValue={(value) => `${ (
                          (value !== 0)
                          ? value < 10
                            ? `0${value}`
                            : value
                          : ``)}`}/>
                      <Timer.Minutes formatValue={(value) => `${ (
                          value < 10
                          ? `0${value}`
                          : value)} : `}/>
                      <Timer.Seconds formatValue={(value) => `${ (
                          value < 10
                          ? `0${value}`
                          : value)}`}/>
                    </div>
                    <div>
                      <button className="d-none" id="start-timer" onClick={start}>Start</button>
                    </div>
                  </React.Fragment>)
                }
              </Timer>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        phone: state.kertelphone
    });
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
      setCallTimer
    }, dispatch);
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CallTimer);