import React, { useEffect, useState } from 'react';
import kertelLogo from '../../assets/images/logo-big.png';
import { connect } from 'react-redux';
import { getConfigAuth } from 'components/commons/api/auth.js';
import {
  stopLoginRequesting,
  startSsoLoginRequesting,
  stopSsoLoginRequesting,
} from 'actions/authActions.js';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import Popup from "reactjs-popup";
import InfosLogin from './InfosLogin';
import { useNavigate } from 'react-router';
import { getApiUrl } from '../commons/helpers/apiHelper';

function Home ({
  session,
  stopLoginRequesting,
  startSsoLoginRequesting,
  stopSsoLoginRequesting
}) {

  const [modal, setModal] = useState(false);

  const navigate = useNavigate();

  function openModal() {
    setModal(true);
  }
  
  function closeModal() {
    setModal(false);
  }

  async function ssoAuth() {
    
    const currentUrl  = window.location.href;
    
    try {
      
      startSsoLoginRequesting();
        
      await getConfigAuth('auth').then((result) => {
        
        if (result.status !== 200) {
          return swal({
            type: 'error',
            icon: "error",
            title: 'Oops...',
            text: "Échec de l’authentification avec le SSO, le client_id est introuvable.",
            confirmButtonColor: 'LightSeaGreen',
          })
        }
        
        let keycloak_url = "";
        if (!isUndefined(result.data)) {
          if (!isUndefined(result.data.AUTH_KEYCLOAK_URL)) {
            keycloak_url = result.data.AUTH_KEYCLOAK_URL;
          }
        }
        
        let client_id = "";
        if (!isUndefined(result.data)) {
          if (!isUndefined(result.data.AUTH_KEYCLOAK_CLIENT_ID)) {
            client_id = result.data.AUTH_KEYCLOAK_CLIENT_ID;
          }
        }
        
        let client_scope = "";
        if (!isUndefined(result.data)) {
          if (!isUndefined(result.data.AUTH_KEYCLOAK_CLIENT_SCOPE)) {
            client_scope = result.data.AUTH_KEYCLOAK_CLIENT_SCOPE;
          }
        }

        getApiUrl().then(function(result) {
          let apiUrl = result.apiUrl;
          let redirect_uri = apiUrl + '/ssoAuth/' + window.btoa(currentUrl);      
          let url = "";
          
          url += keycloak_url + "/protocol/openid-connect/auth?";
          url +="client_id=" + client_id;
          url += "&response_type=code";
          url += "&scope=" + client_scope;
          url += "&state=75fba1808d5e6caf31da18360f485824c31e73ee";
          url += "&redirect_uri=" + redirect_uri;

          window.location.href = url;
        })
        
        
      })      
    } catch (e) {
      console.log(e);
    } finally {
      stopSsoLoginRequesting();
    }
  }

  useEffect(() => {
    if (!isNull(session.datas)) {
      navigate('/dashboard');

      return;
    }
  })

  useEffect(() => {

    stopLoginRequesting();
    stopSsoLoginRequesting();
  }, [])
  
  return (
    <div id="loginContainer">
        <div id="leftHomeContainer">
          <div id="titlesContainer">
            <div id="firstTitle">
              <h1>KERTEL <span>ONE</span></h1>
            </div>
            <div id="secondTitle">                  
              <h2>Votre ligne pro partout, tout le temps.</h2>
            </div>
          </div>
          <div id="slogan"><h3>La solution pour unifier vos outils de communications internes.</h3></div>
        </div>
        <div id="rightHomeContainer">
          <div id="homeBrand">
              <img src={kertelLogo} alt="Logo KERTEL" />
          </div>
          <div id="homeFormContainer">
            <button
              className="btn btn-outline-secondary"
              disabled={session.requesting}
              onClick={openModal}
            >
              {session.requesting ? 'Récupérer le login utilisateur...' : 'Récupérer le login utilisateur'}
            </button>
            
            <br />
            
            <button
              className="btn btn-success"
              disabled={session.ssoRequesting}
              onClick={ssoAuth}
            >
              {session.ssoRequesting ? 'CONNEXION...' : 'SE CONNECTER'}
            </button>
          </div>
          <footer id='homeFooter'>
            Kertel &copy; {new Date().getFullYear()} - V1.0
          </footer>
        </div>
      <Popup
        open={modal}
        onClose={closeModal}
      >
        <div className="modalPopup">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="header"> Récupération du login utilisateur </div>
          <div className="content" style={{padding: "2%", maxHeight: "600px",overflowY: "scroll",overflowX: "hidden"}}>
            <InfosLogin
              closeModal={closeModal}
              modale={modal}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.kertelSession
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    stopLoginRequesting,
    startSsoLoginRequesting,
    stopSsoLoginRequesting,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
