const initialState = {
  loadingNewMessages: false
}

const inboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
      
    case 'LOADING_NEW_MESSAGES':
      return {
        ...state,
        loadingNewMessages: true
      };

    case 'STOP_LOADING_NEW_MESSAGES':
      return {
        ...state,
        loadingNewMessages: false
      };

    case 'FETCH_NEW_MESSAGES':
      return [action.payload]

    default:
      return state
  }
}

export default inboxReducer;
