import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import CreateContact from 'components/views/modals/CreateContact';
import { deleteUserContact } from 'components/commons/api/contacts.js';
import { notifyInfo } from 'components/commons/Toasts.js';
import swal from 'sweetalert';
import { showModal } from 'actions/modalActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { startEditingContact } from 'actions/contactsActions.js';
import {ringback} from 'actions/phoneActions.js';
import { isUndefined } from 'lodash';
import Table from 'components/commons/tables/Table.js'

class UserTab extends Component {
  render() {
    const that = this;

    if (this.props.isLoading) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={10}><Preloader /></td>
            </tr>
          </tbody>
        </table>
      );
    }

    const columns = [
      { title: '', csvExport: false, dataField: 'call',
      formatter: (cell, rowData, rowIndex) => (<div style={{
          display: "table",
          width: "100%",
          textAlign: "right"
        }}>
        {
          ((rowData.telephone != null || rowData.mobile != null) && !isUndefined(that.props.phone) && that.props.phone.hasWebPhone) &&
            <div className="btn btn-success btn-circle" onClick={(e) => {
                  e.preventDefault();
                  
                  that.props.handleNumberModal(true, rowData.telephone, rowData.mobile, `${rowData.firstname} ${rowData.lastname}`)
                }}>
                <i className="fa fa-phone"></i>
              </div>
        }
      </div>)},
      { title: 'Civilité', text: 'Civilité', dataField: 'civility',  lookup: {
        'mr': 'Monsieur',
        'ms': 'Mademoiselle',
        'mrs': 'Madame'
      } },
      { title: 'Prénom', text: 'Prénom', dataField: 'firstname' },
      { title: 'Nom', text: 'Nom', dataField: 'lastname', defaultSort: 'asc'},
      {
      title: 'Fixe',
      text: 'Fixe',
      dataField: 'telephone',
      render: rowData => (<div style={{
          display: "table",
          width: "100%",
          textAlign: "right"
        }}>
        <p style={{
            display: "inline"
          }}>{rowData.telephone}</p>
      </div>)
    }, {
      title: 'Mobile',
      text: 'Mobile',
      dataField: 'mobile',
      render: rowData => (<div style={{
          display: "table",
          width: "100%",
          textAlign: "right"
        }}>
        <p style={{
            display: "inline"
          }}>{rowData.mobile}</p>
      </div>)
    },
      { title: 'Adresse mail', text: 'Adresse mail', dataField: 'mail'},
      { title: 'Fax', text: 'Fax', dataField: 'fax'},
      { title: 'Code postal', text: 'Code postal', dataField: 'postalcode'},
      { title: 'Ville', text: 'Ville', dataField: 'city'},
      { title: 'Action', text: 'Action', dataField: 'action', formatter: (cell, rowData) => {

        return (
          <div style={{ display: "inline-flex", justifyContent: "space-evenly", width: "100%", textAlign: "center" }}>
          <i className="fa fa-pencil" onClick={(event, data) => {
              this.props.startEditingContact(rowData.contact_id);
              return this.props.showModal({
                title: "Modifier un contact",
                component: CreateContact
              })
            }}>
          </i>{" "}
          <i className="fa fa-trash" onClick={async (event, data) => {
              return swal({
                title: "Confirmation",
                text: `Etes-vous sûr(e) de vouloir retirer ${isUndefined(rowData.firstname) ? "ce contact": rowData.firstname} de votre carnet d'adresse ?`,
                icon: "warning",
                buttons: {
                  cancel: {
                    text: "Annuler",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                  },
                  confirm: {
                    text: "Confimer",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                },
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                  return deleteUserContact(rowData.contact_id).then((result) => {
                    notifyInfo("Contact supprimé avec succès !");
                    return this.props.fetchUserContacts();
                  }).catch((e) => {
                    return console.log(e);
                  });
                }
              });
            }}>
          </i>
        </div>
        );
      }},
    ]

    return (
      <Table
        datas={this.props.contacts}
        columns={columns}
        searchPlaceholder={"Chercher un contact"} />
    );
  }
}

const mapStateToProps = (state) => {
  return ({phone: state.kertelphone})
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showModal,
    startEditingContact,
       ringback
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTab);
