import React, { Component } from 'react';
import Preloader from 'components/commons/Preloader.js';
import {connect} from 'react-redux';
import { getStatsCallsInformation} from 'components/commons/api/admin/statsCalls';
import { CSVDownload } from 'react-csv';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';

function rfc3339(d) {
    
    function pad(n) {
        return n < 10 ? "0" + n : n;
    }

    function timezoneOffset(offset) {
        var sign;
        if (offset === 0) {
            return "Z";
        }
        sign = (offset > 0) ? "-" : "+";
        offset = Math.abs(offset);
        return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
    }

    return d.getFullYear() + "-" +
        pad(d.getMonth() + 1) + "-" +
        pad(d.getDate()) + "T" +
        pad(d.getHours()) + ":" +
        pad(d.getMinutes()) + ":" +
        pad(d.getSeconds()) + 
        timezoneOffset(d.getTimezoneOffset());
}

function csvToArray (csv) {
    let rows = csv.split("\n");

    return rows.map(function (row) {
    	return row.split(";");
    });
};

class StatsCallsCsv extends Component {

  constructor(props) {
    super(props);
	this.state = {
      statsCalls: [],
	  isLoading: true,
	}
  }
  
  async componentDidMount() {
    try {
	  window.scrollTo(0, 0);
	  let numbers = [];
	  forEach(this.props.options.numbers, function(value, key) {
		if (!isEmpty(value.name)) numbers.push(value.name)
	  })
	  
	  let startDate = rfc3339(new Date(this.props.options.startDate));
	  let endDate = rfc3339(new Date(this.props.options.endDate));
	  
	  let datas = {
		"users": this.props.options.users,
		"numbers": numbers,
		"call_direction": this.props.options.typeCalls,
		"call_internal": this.props.options.internalCalls,
		"start_date": startDate,
		"end_date": endDate,
		"grain": this.props.options.grain,
		"rendering": this.props.options.rendering,
	  };
	  
	  return await getStatsCallsInformation(datas).then((result) => {
        this.setState({ statsCalls: csvToArray(result)} );
	  })
	} catch (e) {
	  console.log(e);
	} finally {
      this.setState({ isLoading: false });
    }
  }
  
  render() {
	
    if (this.state.isLoading) {
      return (
        <Preloader />
      );
    }
	
	return (
		<CSVDownload data={this.state.statsCalls} target="_blank" />
	);
  }
}

const mapStateToProps = (state) => {
  return ({})
}

export default connect(mapStateToProps, null)(StatsCallsCsv);
