export const loadingSvi = () => {
  return { type: 'LOADING_SVI' }
};

export const getSviMenus = (informations) => {
  return {
    type: 'GET_SVI_MENUS',
    payload: informations
  }
};

export const stopLoadingSvi = () => {
  return { type: 'STOP_LOADING_SVI' }
};

export const startEditingSviMenu = (menuId) => {
  return {
    type: 'START_EDITING_SVI_MENU',
    payload: menuId
  }
};

export const stopEditingSviMenu = () => {
  return { type: 'STOP_EDITING_SVI_MENU' }
};

export const startConfiguringSvi = (menuId) => {
  return {
    type: 'START_CONFIGURING_SVI',
    payload: menuId
  }
};

export const getSviConfig = (informations) => {
  return {
    type: 'GET_SVI_CONFIG',
    payload: informations
  }
};

export const stopConfiguringSvi = () => {
  return { type: 'STOP_CONFIGURING_SVI' }
};

export const loadingConfiguringSvi = () => {
  return { type: 'LOADING_CONFIGURING_SVI' }
};

export const stopLoadingConfiguringSvi = () => {
  return { type: 'STOP_LOADING_CONFIGURING_SVI' }
};

export const loadingSviFiles = () => {
  return { type: 'LOADING_SVI_FILES' }
};

export const getSviFiles = (informations) => {
  return {
    type: 'GET_SVI_FILES',
    payload: informations
  }
};

export const stopLoadingSviFiles = () => {
  return { type: 'STOP_LOADING_SVI_FILES' }
};
