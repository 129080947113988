const initialState = {
  datas: [],
  data: [],
  isLoading: false,
  editing: {
    isEditing: false,
    isLoading: false,
    datas: {},
    queueId: null
  }
}

const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initialState;
    case 'LOADING_WAITING_QUEUES':
      return { ...state, isLoading: true };
    case 'GET_WAITING_QUEUES':
      return { ...state, datas: action.payload  }
    case 'STOP_LOADING_WAITING_QUEUES':
      return { ...state, isLoading: false  };

    case 'LOADING_WAITING_QUEUE':
      return { ...state, isLoading: true  };
    case 'GET_WAITING_QUEUE':
      return { ...state, data: action.payload }
    case 'STOP_LOADING_WAITING_QUEUE':
      return { ...state, isLoading: false };

    case 'START_EDITING_WAITING_QUEUE':
      return { ...state, editing: { ...state.editing, isEditing: true, queueId: action.payload } }
    case 'STOP_EDITING_WAITING_QUEUE':
      return { ...state, editing: { ...state.editing, isEditing: false, queueId: null } }

    default:
      return state
  }
}

export default groupReducer;
