import React, {Component} from "react";
import {connect} from 'react-redux';
import InsertShortNumberModal from '../../modals/InsertShortNumberModal.js'
import InsertLongNumberModal from '../../modals/InsertLongNumberModal.js'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import forEach from 'lodash/forEach';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';

class EditProfileCallsInfos extends Component {

  constructor(props) {
    super(props);
	
    this.state = {
    };
  }

  createCustomModalHeader = (closeModal) => {
	const headerStyle = {
	  fontWeight: 'bold',
	  fontSize: 'large',
	  textAlign: 'center',
	  backgroundColor: '#eeeeee',
	  padding:'50px'
	};
	
	return (
	  <div className='modal-header' style={headerStyle} />
	);
  }
  
  createCustomModalFooter = (onClose, onSave) => {
    return (
      <div className='modal-footer' style={{ backgroundColor: '#ffffff' }}>
        <button className='btn btn-inverse mr-2' onClick={ onClose }>Annuler</button>
        <button className='btn btn-success' onClick={ onSave }>Enregistrer</button>
      </div>
    );
  }
  
  customNoConfirm(next) {
	next();
  }
  
  selectRowProp = {
	mode: 'checkbox'
  };


  createCustomShortNumberModal = (onModalClose, onSave, columns, validateState, ignoreEditable) => {
    const attr = {
      onModalClose, onSave, columns, validateState, ignoreEditable
    };
	
    return (
      <InsertShortNumberModal
        { ... attr }
        numbers={this.props.numbers}
        options={this.props.options}
      />
    );
  }

  createCustomLongNumberModal = (onModalClose, onSave, columns, validateState, ignoreEditable) => {
    const attr = {
      onModalClose, onSave, columns, validateState, ignoreEditable
    };
	
    return (
      <InsertLongNumberModal
        { ... attr }
        numbers={this.props.numbers}
        options={this.props.options}
      />
    );
  }

  render() {

    var longNumbersCallOff = [];
    var longNumbersCallOn = [];
    var shortNumbersCallOff = [];
    var shortNumbersCallOn = [];

    if(this.props.profileCalls.calls_off != null){
      if(this.props.profileCalls.calls_off.international != null) {
          var listLongNumbersCallOff = this.props.profileCalls.calls_off.international;
          listLongNumbersCallOff = listLongNumbersCallOff.filter(function (el) {
            return el != null;
          });
          forEach(listLongNumbersCallOff, function(value) {
            longNumbersCallOff.push({long: value});
          })
      }
	  
      if(this.props.profileCalls.calls_off.local != null) {
          var listShortNumbersCallOff = this.props.profileCalls.calls_off.local;
          listShortNumbersCallOff = listShortNumbersCallOff.filter(function (el) {
            return el != null;
          });
          forEach(listShortNumbersCallOff, function(value) {
            shortNumbersCallOff.push({short: value});
          })
      }
    }
	
    if(this.props.profileCalls.calls_on != null){
      if(this.props.profileCalls.calls_on.international != null) {
        var listLongNumbersCallOn = this.props.profileCalls.calls_on.international;
        listLongNumbersCallOn = listLongNumbersCallOn.filter(function (el) {
          return el != null;
        });
        forEach(listLongNumbersCallOn, function(value) {
          longNumbersCallOn.push({long: value});
        })
      }
	  
      if(this.props.profileCalls.calls_on.local != null) {
        var listShortNumbersCallOn = this.props.profileCalls.calls_on.local;
        listShortNumbersCallOn = listShortNumbersCallOn.filter(function (el) {
          return el != null;
        });
        forEach(listShortNumbersCallOn, function(value) {
          shortNumbersCallOn.push({short: value});
        })
      }
    }
	
    return (
	  <Container component="main">
		<Grid container spacing={2}>
		  <Grid item xs={12} sm={4}>
			<TextField name="name" label="Nom du profil d'appels" fullWidth value={this.props.profileCalls.name} onChange={this.props.handleOptions}/>
		  </Grid>
		</Grid>
		<Box m={5}/>
		<Box m={5}/>
		<Grid container spacing={2}>
		  <FormLabel component="legend">Numéros à autoriser</FormLabel>
			<Grid item xs={12} sm={4}>
			  <BootstrapTable
				data={ longNumbersCallOn }
				deleteRow
				insertRow
				selectRow={this.selectRowProp}
				renderAlert={false}
				options={{
				  handleConfirmDeleteRow: this.customNoConfirm,
				  afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addLongOn'),
				  afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delLongOn'),
				  insertModalHeader: this.createCustomModalHeader,
				  insertModalFooter: this.createCustomModalFooter,
				  insertModal: this.createCustomLongNumberModal,
				  noDataText: 'Aucun numéro disponible',
				  insertText: 'Ajouter',
				  deleteText: 'Supprimer',
				}}
			  >
				<TableHeaderColumn dataField='long' isKey >Numéros de téléphone</TableHeaderColumn>
			  </BootstrapTable>
			</Grid>
			<Grid item xs={12} sm={4}>
			  <BootstrapTable
				data={ shortNumbersCallOn }
				deleteRow
				insertRow
				selectRow={this.selectRowProp}
				renderAlert={false}
				options={{
				  handleConfirmDeleteRow: this.customNoConfirm,
				  afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addShortOn'),
				  afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delShortOn'),
				  insertModal: this.createCustomShortNumberModal,
				  noDataText: 'Aucun numéro disponible',
				  insertText: 'Ajouter',
				  deleteText: 'Supprimer',
				}}
			  >
				<TableHeaderColumn dataField='short' isKey >Numéro(s) court(s)</TableHeaderColumn>
			  </BootstrapTable>
			</Grid>
			<Grid item xs={12} sm={4} />
		</Grid>
        <Box m={3}/>
		<Grid container spacing={2}>
		  <FormLabel component="legend">Numéros à interdire</FormLabel>
			<Grid item xs={12} sm={4}>
			  <BootstrapTable
				data={ longNumbersCallOff }
				deleteRow
				insertRow
				selectRow={this.selectRowProp}
				renderAlert={false}
				options={{
				  handleConfirmDeleteRow: this.customNoConfirm,
				  afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addLongOff'),
				  afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delLongOff'),
				  insertModal: this.createCustomLongNumberModal,
				  noDataText: 'Aucun numéro disponible',
				  insertText: 'Ajouter',
				  deleteText: 'Supprimer',
				}}
			  >
				<TableHeaderColumn dataField='long' isKey >Numéros de téléphone</TableHeaderColumn>
			  </BootstrapTable>
			</Grid>
			<Grid item xs={12} sm={4}>
			  <BootstrapTable
				data={ shortNumbersCallOff }
				deleteRow
				insertRow
				selectRow={this.selectRowProp}
				renderAlert={false}
				options={{
				  handleConfirmDeleteRow: this.customNoConfirm,
				  afterInsertRow : this.props.handleOptionsNumbers.bind(null, 'addShortOff'),
				  afterDeleteRow : this.props.handleOptionsNumbers.bind(null, 'delShortOff'),
				  insertModal: this.createCustomShortNumberModal,
				  noDataText: 'Aucun numéro disponible',
				  insertText: 'Ajouter',
				  deleteText: 'Supprimer',
				}}
			  >
				<TableHeaderColumn dataField='short' isKey >Numéro(s) court(s)</TableHeaderColumn>
			  </BootstrapTable>
			</Grid>
			<Grid item xs={12} sm={4} />
		  </Grid>
    </Container>);
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, null)(EditProfileCallsInfos);
